import { useEffect, useState } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

import { AddButton } from 'app/components/Button/AddButton';
import { FormSelect } from 'app/components/Select/FormSelect';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES, SORT_BY_NAME_TABLE_QUERY } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import {
  checkParamSynced,
  useChangeParams,
  useGetQueryParams,
  useSyncQueryToState,
} from 'app/modules/query-state/hooks';

import { coverPath } from 'app/helpers';
import { useNavigateWithSendPath } from 'app/helpers/utils';
import { usePolicyInUser } from 'app/hooks/policy';
import { omit } from 'lodash';

import { DEFAULT_WARD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { ReactComponent as LogoThumbnail } from 'app/assets/icons/logo-thumbnail.svg';

import { useAuth } from '../auth';
import { useNavigateAddStockRunBySims } from '../inventory/stock-run/core/helpers';
import { getWards } from '../system-settings/locations/wards/core/requests';
import { Ward } from '../system-settings/locations/wards/core/type';
import { ExportButton } from './components/ExportButton';
import { reportColumns } from './core/columns';
import { getReports } from './core/request';
import { Report } from './core/type';

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select-body {
    background: var(--white);
  }
`;

const Button = styled.div`
  svg path[stroke] {
    stroke: var(--neutral-7);
  }
`;

export const DemandButton = styled.div`
  svg path {
    fill: var(--white);
  }
`;

const ReportPage = () => {
  useSyncQueryToState<{
    ward_id?: string;
    ward_name?: string;
  }>(window.location.pathname, SORT_BY_NAME_TABLE_QUERY);

  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);

  const { synced, curParams } = useGetQueryParams();
  const { onChangeParams } = useChangeParams();

  const isSyncedParam = checkParamSynced(synced);

  const navigate = useNavigateWithSendPath();
  const navigateAddStockRunBySims = useNavigateAddStockRunBySims(
    PATH.REPORT_DEMAND_PROJECTION_DETAIL,
  );
  const [wards, setWards] = useState<Ward[]>([]);

  const onClickBodyTable = (row: Row) => () => {
    const newReport = row.original as Report;
    if (newReport.id) {
      navigate(
        coverPath(PATH.REPORT_DETAIL, {
          wardId: curParams.ward_id,
          itemId: newReport.inventory.id,
        }),
      );
    }
  };

  useEffect(() => {
    if (isSyncedParam) {
      fetchWards();
    }
  }, [isSyncedParam]);

  const fetchWards = async () => {
    const dataResWards = await getWards(
      stringifyRequestQuery({
        page: 1,
        limit: 100,
      }),
    );
    const newResWards = dataResWards.data?.items || [];

    setWards(newResWards);

    if (!curParams.ward_id) {
      onChangeParams(
        {
          ward_id: String(newResWards[0].id),
          ward_name: newResWards[0].name,
        },
        { navigateOptions: { replace: true } },
      );
    }
  };

  return (
    <RootContentPage
      title=""
      header={
        <Header>
          <Typography variant="heading-6">Report</Typography>
          <FormSelect
            optionsSelect={wards.map((el) => {
              return {
                title: el.name,
                value: el.id ?? ' ',
              };
            })}
            activeSelect={Number(curParams.ward_id)}
            fieldActive="value"
            fieldSelect="title"
            styleRoot={{ width: 212, marginBottom: 0 }}
            handleChange={(select) => {
              onChangeParams({
                ward_id: select.value,
                ward_name: select.title,
                page: 1,
                search: '',
              });
            }}
            title="Ward:"
          />
        </Header>
      }
    >
      {Boolean(curParams.ward_id) && (
        <TableComponent
          getList={getReports}
          paramId={curParams.ward_id}
          nameQuery={QUERIES.REPORT_LIST}
          originColumns={reportColumns()}
          placeholderSearch="Search with item name and SKU"
          customListHeader={
            <div className="d-flex align-items-center">
              <Button>
                <ExportButton wardId={curParams.ward_id} theme="btn-light" />
              </Button>
              {(policy.viewProjection || policy.approveProjection) && (
                <DemandButton>
                  <AddButton
                    title="Demand projection"
                    icon={<LogoThumbnail style={{ marginRight: 12 }} />}
                    style={{ marginLeft: 12 }}
                    onClick={() => navigateAddStockRunBySims(Number(DEFAULT_WARD))}
                  />
                </DemandButton>
              )}
            </div>
          }
          onClickBodyTable={onClickBodyTable}
          isBorder={false}
          syncParamUrl
        />
      )}
    </RootContentPage>
  );
};

export default ReportPage;
