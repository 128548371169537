import Typography, { EmptyTableCell } from 'app/components/Typography';

import clsx from 'clsx';

interface Props {
  title: string;
  value?: string;
  styleRoot?: React.CSSProperties;
}
const InfoItemStockRunCard: React.FC<Props> = ({ title, value, styleRoot }) => {
  return (
    <div
      className={clsx('flex flex-column')}
      style={{
        width: '30%',
        ...styleRoot,
      }}
    >
      <Typography variant="title-5">{title}</Typography>
      {value ? (
        <Typography variant="body-5" color="neutral-10">
          {value}
        </Typography>
      ) : (
        EmptyTableCell
      )}
    </div>
  );
};
export default InfoItemStockRunCard;
