import { ID, Response } from 'app/components/Table/core/types';

import { ForecastItem } from 'app/modules/inventory/stock-run/core/type';
import { ForecastChartData } from 'app/modules/reporting/core/type';

import { API_URL } from 'app/constants/environments';

import { DemandApproveItem, DemandProjectionItem, ProjectionDetail } from './type';
import axios, { AxiosResponse } from 'axios';

const PROJECTION_URL = `${API_URL}/api/demand-projection`;

export const getProjectionDetail = (wardId: ID): Promise<ProjectionDetail | undefined> => {
  return axios
    .get(`${PROJECTION_URL}/${wardId}`)
    .then((response: AxiosResponse<Response<ProjectionDetail>>) => response.data)
    .then((response: Response<ProjectionDetail>) => response.data);
};

export const getProjectionList = (
  wardId: ID,
  demandId: ID,
): Promise<Response<{ items: Array<DemandProjectionItem> }>> => {
  return axios.get(`${PROJECTION_URL}/${wardId}/${demandId}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<DemandProjectionItem>;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

export const postApproveList = (
  demandId: ID,
  approve: DemandApproveItem[],
  reject: ID[],
): Promise<DemandProjectionItem[] | undefined> => {
  return axios
    .post(`${PROJECTION_URL}/${demandId}`, { approve: approve, reject: reject })
    .then((response: AxiosResponse<Response<DemandProjectionItem[]>>) => response.data)
    .then((response: Response<DemandProjectionItem[]>) => response.data);
};

export const getItemsForecast = (wardId: ID, demandId: ID) => {
  return axios
    .get(`${PROJECTION_URL}/${wardId}/${demandId}/forecast`)
    .then(
      (response: AxiosResponse<{ data: { items: ForecastItem[] } }>) => response.data.data.items,
    );
};

export const approvedItems = (demandId: ID) => {
  return axios.post(`${PROJECTION_URL}/${demandId}/save`);
};

export const getForecastChart = (wardId: ID, itemId: ID, callback?: () => void) => {
  return axios
    .get(`${PROJECTION_URL}/${wardId}/${itemId}/chart`)
    .then((response: AxiosResponse<{ data: ForecastChartData }>) => response.data.data)
    .finally(() => {
      if (callback) {
        callback();
      }
    });
};
