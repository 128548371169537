import Typography from 'app/components/Typography';

const EmptyLogCard = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{
        width: '100%',
      }}
    >
      <Typography
        style={{
          marginBottom: 4,
        }}
      >
        Activity log is empty.
      </Typography>
      <Typography>No actions have been recorded yet.</Typography>
    </div>
  );
};
export default EmptyLogCard;
