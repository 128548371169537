import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ButtonDefault from 'app/components/Button/ButtonDefault';
import CheckboxDefault from 'app/components/Checkbox/CheckboxDefault';
import InputDateFilter from 'app/components/Filter/InputDateFilter';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import Typography from 'app/components/Typography';

import { labelFilterDate } from 'app/modules/inventory/stock-run/core/helpers';

import { endOfDay, startOfDay } from 'date-fns';
import { cloneDeep } from 'lodash';

import { emptyFilterReportDetail } from '../core/constants';
import { ActionReport } from '../core/enum';
import { FilterReportDetail } from '../core/type';
import { ActionReportCard } from './ActionReportCard';

const Main = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  height: 80px;
  padding: 0px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-3);

  .clear_all:hover {
    text-decoration: underline;
  }
`;
const Body = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

interface Props {
  openMenu: boolean;
  originFilter: FilterReportDetail;
  onUpdateFilter: (newFilter: FilterReportDetail, newFecth: boolean) => void;
}
export const FilterReport: React.FC<Props> = ({ openMenu, originFilter, onUpdateFilter }) => {
  const { updateState, state } = useQueryRequest();

  const [filter, setFilter] = useState<FilterReportDetail>(emptyFilterReportDetail);

  useEffect(() => {
    if (!openMenu || JSON.stringify(originFilter) === JSON.stringify(filter)) {
      return;
    }
    setFilter(originFilter);
  }, [openMenu, originFilter]);

  const onChangeReportStatus = (newStatus: ActionReport, checked: boolean) => () => {
    const newFilter = cloneDeep(filter);
    if (checked) {
      delete newFilter.action[newStatus];
    } else {
      newFilter.action[newStatus] = newStatus;
    }
    setFilter(newFilter);
  };

  const onChangeDate = (
    newDate: {
      startDate: Date;
      endDate: Date;
      key: string;
    }[],
  ) => {
    if (!newDate.length) {
      return;
    }

    setFilter({
      ...filter,
      from_expriry_date: startOfDay(newDate[0].startDate),
      to_expriry_date: endOfDay(newDate[0].endDate),
    });
  };

  const clearAll = () => {
    setFilter(emptyFilterReportDetail);
  };

  const onClearDate = () => {
    setFilter({
      ...filter,
      from_expriry_date: null,
      to_expriry_date: null,
    });
  };

  const onSubmit = () => {
    onUpdateFilter(filter, state.page === 1);
    updateState({
      ...state,
      page: 1,
    });
  };

  return (
    <Main>
      <Header>
        <Typography variant="heading-6">Filter</Typography>
        <Typography
          className="clear_all"
          variant="body-5"
          onClick={clearAll}
          color="primary"
          style={{
            cursor: 'pointer',
          }}
        >
          Clear all
        </Typography>
      </Header>
      <Body>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <InputDateFilter
            label="Date modified"
            value={labelFilterDate(filter.from_expriry_date, filter.to_expriry_date)}
            onChange={(newDate) => onChangeDate(newDate)}
            state={[
              {
                endDate: filter.to_expriry_date ?? new Date(),
                startDate: filter.from_expriry_date ?? new Date(),
                key: 'selection',
              },
            ]}
            onClear={onClearDate}
          />
        </div>

        <Typography
          variant="body-7"
          color="gray-700"
          style={{
            marginBottom: 8,
            marginTop: 13,
          }}
        >
          Action
        </Typography>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          {Object.values(ActionReport).map((action, index) => (
            <div
              key={index}
              style={{
                marginBottom: 28,
              }}
            >
              <CheckboxDefault
                onChange={onChangeReportStatus(action, Boolean(filter.action[action]))}
                checked={Boolean(filter.action[action])}
                label={
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <ActionReportCard key={index} status={action} />
                  </div>
                }
              />
            </div>
          ))}
        </div>

        <div
          className="d-flex justify-content-end"
          style={{
            marginTop: 24,
          }}
        >
          <ButtonDefault
            onClick={onSubmit}
            style={{
              width: 160,
              borderRadius: 8,
            }}
          >
            Apply
          </ButtonDefault>
        </div>
      </Body>
    </Main>
  );
};
