import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { FormTextarea } from 'app/components/Input/FormTextarea';
import ConfirmationModal from 'app/components/Modal/ConfirmationModal';
import { FormSelect } from 'app/components/Select/FormSelect';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getSetting } from 'app/modules/system-settings/configuration/core/request';
import { SettingType } from 'app/modules/system-settings/configuration/core/type';

import { blockInvalidCharacters, compileFormData, trimObject } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { useNavigateWithPreviousPath } from 'app/helpers/utils';
import { useBoolean, useNumber, useString } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { StockTypeEnum, WeightUnitsEnum } from '../core/enum';
import { createItem, getItem, updateItem } from '../core/requests';
import { ItemRequest, QtyUnit, initialItemRequest } from '../core/type';
import AddImageItemCard from './AddImageItemCard';
import StockTypeCard from './StockTypeCard';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 28px 8px 28px;
  .action-btn {
    margin-top: 0px !important;
  }
`;
const Header = styled.div`
  .action-btn {
    margin-top: 0px !important;
  }
`;
const ItemType = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
`;
interface Props {
  itemId?: number;
}

const BodyFormItem: React.FC<Props> = ({ itemId }) => {
  const isLoading = useBoolean();
  const [formRequest] = useState<{
    name: string;
    info: string;
    skuId: string;
    skuSub: string;
    weight: number;
    volumePerPackage: number | null;
  }>({
    name: initialItemRequest.name,
    info: initialItemRequest.info,
    skuId: initialItemRequest.skuId,
    skuSub: initialItemRequest.skuSub,
    weight: initialItemRequest.weight,
    volumePerPackage: !itemId ? initialItemRequest.volumePerPackage : null,
  });
  const [file, setFile] = useState<File | null>(null);
  const stockType = useNumber(itemId ? -1 : StockTypeEnum['Non-Chargeable']);
  const qtyUnit = useNumber(0);
  const weightUnit = useNumber(WeightUnitsEnum.g);
  const isView = useBoolean(false);
  const isRemoveImage = useBoolean();
  const originImage = useString('');
  const [quantityUnits, setQuantityUnits] = useState<QtyUnit[]>([]);
  const navigate = useNavigate();
  const navigateWithPreviousPath = useNavigateWithPreviousPath();
  const originalStockType = useNumber(-1);
  const isItemAssigned = useBoolean(false);

  useEffect(() => {
    if (!itemId) {
      getQuantityUnits();
      return;
    }
    getDataItem(Number(itemId));
  }, [itemId]);

  const getDataItem = async (idItem: number) => {
    try {
      isLoading.setValue(true);
      const datRes = await getItem(
        stringifyRequestQuery({
          page: 1,
          limit: 10,
          orderBy: 'ward',
          orderDirection: 'ASC',
        }),
        idItem,
      );
      if (datRes) {
        formik.setValues({
          info: datRes.info,
          name: datRes.name,
          skuId: datRes.skuId,
          skuSub: datRes.skuSub,
          weight: datRes.weight,
          volumePerPackage: datRes.volumePerPackage || null,
        });
        originImage.setValue(datRes.image);
        stockType.setValue(Number(datRes.stockType));
        isItemAssigned.setValue(datRes.hasBin);
        originalStockType.setValue(Number(datRes.stockType));
        await getQuantityUnits(datRes.qtyUnit.id);
      }
    } catch (error) {
      navigate(PATH.ITEMS);
    } finally {
      isLoading.setValue(false);
    }
  };

  const getQuantityUnits = async (newQtyUnit?: number) => {
    isLoading.setValue(true);
    const res = await getSetting(SettingType.quantityUnit);
    setQuantityUnits(
      res.map((el) => ({
        title: el.name,
        value: el.id!,
      })),
    );
    qtyUnit.setValue(newQtyUnit ? newQtyUnit : qtyUnit.value);
    isLoading.setValue(false);
  };

  const redirectItemDetailPage = (idItem: number) => {
    navigateWithPreviousPath(PATH.ITEMS_DETAIL.replace(':id', String(idItem)));
  };

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Item name is required'),
      skuId: Yup.string().trim().max(20, 'Maximum 20 symbols').required('SKU is required'),
      skuSub: Yup.string().trim().max(20, 'Maximum 20 symbols'),
      weight: Yup.number().min(0, 'Minimum 0g').required('Weight is required'),
      volumePerPackage: Yup.number().min(1, 'Minimum quantity = 1'),
      info: Yup.string().trim(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const trimmedValues = trimObject(values) as ItemRequest;
      const request: ItemRequest = {
        ...trimmedValues,
        weight: Number(trimmedValues.weight),
        stockType: stockType.value,
        weightUnit: weightUnit.value,
        volumePerPackage: Number(values.volumePerPackage || 1),
        qtyUnit: qtyUnit.value,
      };
      if (file) {
        request.image = file;
      }
      try {
        if (itemId) {
          if (isRemoveImage.value) {
            request.removeImage = true;
          }
          const dataEditRes = await updateItem(itemId, compileFormData(request));
          toast.success(`Update Item successfully!`);
          if (dataEditRes) {
            redirectItemDetailPage(Number(dataEditRes.id));
          }
          return;
        }
        const dataAddRes = await createItem(compileFormData(request));
        toast.success(`Add Item successfully!`);
        if (dataAddRes) {
          redirectItemDetailPage(Number(dataAddRes.id));
        }
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  const onCancel = () => {
    if (itemId) {
      return redirectItemDetailPage(Number(itemId));
    }
    navigate(PATH.ITEMS);
  };

  const renderLabelButtonSubmit = () => {
    if (itemId) {
      return isView.value ? 'Edit' : 'Save';
    }
    return 'Add';
  };
  const renderButton = () => {
    return (
      <ActionButton
        customClass="action-btn"
        onCancel={onCancel}
        disableButton={
          formik.isSubmitting ||
          !formik.isValid ||
          !formik.values.name ||
          !formik.values.skuId ||
          !formik.touched ||
          !qtyUnit.value
        }
        isSubmitting={formik.isSubmitting}
        labelButtonSubmit={renderLabelButtonSubmit()}
      />
    );
  };

  const formTitle = itemId ? `Edit item` : 'Add item ';

  return (
    <form id="kt_modal_form_item" onSubmit={formik.handleSubmit} noValidate>
      <RootContentPage
        title=""
        header={
          <Header className="d-flex justify-content-between align-items-center">
            <Typography variant="heading-6">{`${formTitle}`}</Typography>
            {!itemId && renderButton()}
          </Header>
        }
      >
        <Main>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              width: '100%',
            }}
          >
            <Typography variant="title-1" lineHeight={32}>
              Detail
            </Typography>
            {itemId && renderButton()}
          </div>

          <div
            className="d-flex flex-column"
            style={{
              width: '75%',
              marginTop: 32,
            }}
          >
            <div className="d-flex">
              <AddImageItemCard
                file={file}
                setFile={setFile}
                image={originImage.value}
                isDisable={isView.value}
                isRemoveImage={isRemoveImage.value}
                setIsRemoveImage={isRemoveImage.setValue}
              />
              <div className="d-flex flex-column justify-content-between w-100 ps-10 position-relative">
                <FormInput
                  label="Item name"
                  placeholder="Item name"
                  formik={formik}
                  field="name"
                  isRequired
                  rootClass="w-100"
                  isDisable={isView.value}
                />
                <div>
                  <ItemType>Item type</ItemType>
                  <StockTypeCard
                    stockType={stockType}
                    isDisable={isView.value}
                    isInItemForm={true}
                  />
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    marginTop: 24,
                  }}
                >
                  <div className="w-50 pe-3">
                    <FormInput
                      label="SKU"
                      placeholder="SKU"
                      formik={formik}
                      field="skuId"
                      isRequired
                      rootClass="w-100"
                      isDisable={isView.value}
                    />
                  </div>
                  <div className="w-50 ps-3">
                    <FormInput
                      label="Sub SKU"
                      placeholder="Sub SKU"
                      formik={formik}
                      field="skuSub"
                      isDisable={isView.value}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="w-50 pe-3">
                    <FormSelect
                      label="Unit"
                      isRequired
                      optionsSelect={quantityUnits}
                      activeSelect={qtyUnit.value}
                      handleChange={(select) => qtyUnit.setValue(select.value)}
                      fieldActive="value"
                      fieldSelect="title"
                      styleSelectCard={{
                        width: 277,
                      }}
                      isDisable={isView.value}
                      inputPlaceholder="Select unit"
                    />
                  </div>
                  <div className="w-50 ps-3">
                    <FormInput
                      label="Weight (g)"
                      placeholder="Weight"
                      formik={formik}
                      field="weight"
                      typeInput="number"
                      onKeyDown={(e) => blockInvalidCharacters(e, 'float')}
                      isRequired
                      rootClass="w-100"
                      isDisable={isView.value}
                    />
                  </div>
                </div>
                <FormInput
                  label="Volume per package"
                  placeholder="Volume per package"
                  formik={formik}
                  field="volumePerPackage"
                  typeInput="number"
                  onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
                  rootClass="w-50"
                  isDisable={isView.value}
                />
                <FormTextarea
                  label="Description (optional)"
                  placeholder="Description about this item"
                  formik={formik}
                  field="info"
                  maxLength={200}
                  showCount
                  isDisable={isView.value}
                />
              </div>
            </div>
          </div>
        </Main>

        {isLoading.value && <LoadingCard />}
      </RootContentPage>
    </form>
  );
};
export default BodyFormItem;
