import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { blockInvalidCharacters, coverNameBed } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { Ward } from '../../wards/core/type';
import { MAX_BED } from '../core/constants';
import { createBed, updateBed } from '../core/requests';
import { Bed, BedRequest } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  bed: Bed;
  ward: Ward;
  originIsView?: boolean;
  onUpdateWhenAddBed: (countChildren: number) => void;
};

const FormBedTableCard: FC<Props> = ({ bed, ward, originIsView = false, onUpdateWhenAddBed }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { updateState, state } = useQueryRequest();

  const isView = useBoolean(originIsView);

  const [formRequest] = useState<{
    countChildren: number;
  }>({
    countChildren: 1,
  });
  useEffect(() => {
    isView.setValue(originIsView);
  }, [originIsView]);

  const getSchema = () => {
    const maxBed = MAX_BED - Number(ward.countChildren);
    const defaultSchema: any = {
      countChildren: Yup.number()
        .min(1, 'Minimum 1 bed')
        .max(maxBed, `Maximum ${maxBed} beds`)
        .required('Bed quantity is required'),
    };
    return defaultSchema;
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape(getSchema()),
    onSubmit: async (values, { setSubmitting }) => {
      if (isView.value) {
        isView.setValue(false);
        if (itemForUpdate) {
          setItemForUpdate({
            ...itemForUpdate,
            isView: false,
          });
        }
        return;
      }
      setSubmitting(true);
      const request: BedRequest = {
        bedQuantity: Number(values.countChildren) + Number(ward.countChildren),
        locationId: Number(ward.id),
      };
      try {
        if (bed) {
          await updateBed(bed.id, request);
          toast.success(`Update Bed successfully!`);
          cancel(true);
          return;
        }

        await createBed(request);
        toast.success(`Add Bed successfully!`);
        onUpdateWhenAddBed(Number(values.countChildren));
        if (state.page !== 1) {
          cancel();
          updateState({
            page: 1,
          });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const renderMessageAddBed = () => {
    let oldMessageQuantity = '001';
    let newMessageQuantity = '';
    const valueOldBedQuantity = Number(ward.countChildren) + 1;
    if (valueOldBedQuantity > 1) {
      oldMessageQuantity = coverNameBed(valueOldBedQuantity);
    }
    if (formik.values.countChildren > 1) {
      const newQuantity = Number(formik.values.countChildren) + Number(ward.countChildren);
      newMessageQuantity = newQuantity > 0 ? `-${coverNameBed(newQuantity)}` : '';
    }
    return `The added beds will be named ${oldMessageQuantity}${newMessageQuantity}`;
  };
  const renderLabelButtonSubmit = () => {
    if (bed) {
      return isView.value ? 'Edit' : 'Save';
    }
    return 'Add';
  };

  const isMaxBed = MAX_BED - Number(ward.countChildren) === 0;
  return (
    <>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div
            className="d-flex flex-column"
            style={{
              marginBottom: 24,
            }}
          >
            <Typography variant="title-4" color="neutral-6">
              Existed bed quantity
            </Typography>
            <Typography variant="body-5" color="neutral-10">
              {ward.countChildren ?? 0}
            </Typography>
          </div>
          {isMaxBed ? (
            <div>
              <Typography variant="title-4" color="danger">
                Maximum bed number has reached.
              </Typography>
            </div>
          ) : (
            <FormInput
              label={`Add beds with a quantity of (Max: ${MAX_BED - Number(ward.countChildren)})`}
              placeholder="Eg: 12"
              formik={formik}
              field="countChildren"
              message={renderMessageAddBed()}
              isDisable={isView.value}
              typeInput="number"
              onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
            />
          )}
        </div>
        <ActionButton
          onCancel={() => cancel()}
          disableButton={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            MAX_BED - Number(ward.countChildren) === 0
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={renderLabelButtonSubmit()}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormBedTableCard };
