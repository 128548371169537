import { FC } from 'react';
import styled from 'styled-components';

import { useOrderStore } from 'app/modules/inventory/stock-run/store';

import { ReactComponent as IconSortASC } from 'app/assets/icons/icon-sort-asc.svg';
import { ReactComponent as IconSortDefault } from 'app/assets/icons/icon-sort-default.svg';
import { ReactComponent as IconSortDESC } from 'app/assets/icons/icon-sort-desc.svg';

import Typography from '../../Typography';

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 12px 16px 12px;
`;
export const FormTableHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  height: 72px;
  margin-top: 0;
  padding: 0px 16px;
  padding-top: 32px;
  background-color: var(--white);
  border-bottom: 1px solid var(--neutral-3);
`;

export const CustomHeader: FC<{
  title?: string;
  styleHeader?: React.CSSProperties;
  id: string;
  isOrder?: boolean;
}> = ({ title, styleHeader, id, isOrder = true }) => {
  const orderBy = useOrderStore((s) => s.state.orderBy);
  const orderDirection = useOrderStore((s) => s.state.orderDirection);

  const isSelectedForSorting = id === orderBy;

  const sortColumn = () => {
    if (!isSelectedForSorting) {
      // enable sort asc
      useOrderStore.getState().setState({ orderBy: id, orderDirection: 'ASC' });
      return;
    }

    if (isSelectedForSorting && orderDirection !== undefined) {
      if (orderDirection === 'ASC') {
        // enable sort desc
        useOrderStore.getState().setState({ orderBy: id, orderDirection: 'DESC' });
        return;
      }
      // disable sort
      useOrderStore.getState().setState({ orderBy: id, orderDirection: 'ASC' });
    }
  };

  const renderIconSort = () => {
    if (!isSelectedForSorting) {
      return (
        <IconSortDefault
          style={{
            marginLeft: 3,
            marginBottom: 2,
          }}
        />
      );
    }
    if (isSelectedForSorting && orderDirection !== undefined) {
      if (orderDirection === 'ASC') {
        return (
          <IconSortASC
            style={{
              marginLeft: 3,
              marginBottom: 2,
            }}
          />
        );
      }
      return (
        <IconSortDESC
          style={{
            marginLeft: 3,
            marginBottom: 2,
          }}
        />
      );
    }
    return null;
  };

  return (
    <Header style={{ ...styleHeader }} onClick={sortColumn} className="header-column">
      <div className="d-flex align-items-center">
        <Typography fontSize={14} fontWeight={700} lineHeight={23} color="neutral-6">
          {title}
        </Typography>
        {isOrder && <div>{renderIconSort()}</div>}
      </div>
    </Header>
  );
};
