import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';
import { cloneDeep } from 'lodash';

import { TypeHoliday } from '../core/enum';
import { updateSetting } from '../core/request';
import { Setting, SettingType } from '../core/type';
import ActionButtonCard from './ActionButtonCard';
import SwitchButtonDefault from 'theme/layout/components/SwitchButton/SwitchButtonDefault';

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
`;
interface Props {
  holidays: Setting[];
  currentSelectedSetting: SettingType | null;
  setCurrentSelectedSetting: (payload: SettingType | null) => void;
}
const HolidayStockRunCard: React.FC<Props> = ({
  holidays,
  currentSelectedSetting,
  setCurrentSelectedSetting,
}) => {
  const [currentHolidays, setCurrentHolidays] = useState<Setting[]>([]);
  const [originHolidays, setOriginHolidays] = useState<Setting[]>([]);

  const isSubmitting = useBoolean(false);
  useEffect(() => {
    setCurrentHolidays(holidays);
    setOriginHolidays(holidays);
  }, [JSON.stringify(holidays)]);

  const isEdit = useBoolean(false);
  const onCancel = () => {
    isEdit.setValue(false);
    setCurrentHolidays(originHolidays);
  };

  useEffect(() => {
    if (currentSelectedSetting !== SettingType.logoutTimeout) {
      onCancel();
    }
  }, [currentSelectedSetting]);

  const onChangeHoliday = (newValue: TypeHoliday, index: number) => async () => {
    const newHolidays = cloneDeep(currentHolidays);
    newHolidays[index].value = newValue;
    setCurrentHolidays(newHolidays);
  };
  const onSubmit = async () => {
    isSubmitting.setValue(true);

    const allPromises = currentHolidays.map(async (setting: Setting) => {
      return updateSetting(Number(setting.id), {
        name: setting.name,
        type: SettingType.logoutTimeout,
        value: String(setting.value),
      });
    });
    await Promise.all(allPromises);

    setOriginHolidays(currentHolidays);
    toast.success('Update setting show holidays successfully!');
    isSubmitting.setValue(false);
    isEdit.setValue(false);
  };
  return (
    <Main>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{
          marginBottom: 32,
          width: '100%',
        }}
      >
        <Typography variant="heading-7" color="gray-700">
          Show Holidays when select Complete date
        </Typography>
        <ActionButtonCard
          isEdit={isEdit.value}
          onCancel={onCancel}
          disableButton={isSubmitting.value}
          isSubmitting={isSubmitting.value}
          onClickEdit={() => {
            isEdit.setValue(true);
            setCurrentSelectedSetting(SettingType.logoutTimeout);
          }}
          onSubmit={onSubmit}
        />
      </div>
      <div
        style={{
          minHeight: 30,
        }}
      >
        {currentHolidays.map((holiday, index) => {
          return (
            <SwitchButtonDefault
              key={index}
              labelButton="Show Holidays"
              disabled={!isEdit.value || isSubmitting.value}
              checked={holiday.value === TypeHoliday.off ? false : true}
              onChange={onChangeHoliday(
                holiday.value === TypeHoliday.off ? TypeHoliday.on : TypeHoliday.off,
                index,
              )}
            />
          );
        })}
      </div>
    </Main>
  );
};
export default HolidayStockRunCard;
