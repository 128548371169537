import { ID } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { STATUS_USER } from './constants';
import { User, UserRequest, UsersQueryResponse } from './type';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/api/users`;
const getUsers = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${USER_URL}?${query}`);
  return {
    data: d.data.data,
  };
};

const getUserById = async (id: ID): Promise<User | undefined> => {
  const response = await axios.get(`${USER_URL}/${id}`);
  const response_1 = response.data;
  return response_1.data;
};

const createUser = async (userRequest: UserRequest): Promise<User | undefined> => {
  const response = await axios.post(USER_URL, trimObject(userRequest));
  const response_1 = response.data;
  return response_1.data;
};

const updateUser = async (idUser: ID, userRequest: UserRequest): Promise<User | undefined> => {
  const response = await axios.patch(`${USER_URL}/${idUser}`, trimObject(userRequest));
  const response_1 = response.data;
  return response_1.data;
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`);
};

const changeStatusUser = async (
  idUser: ID,
  request: {
    activate: STATUS_USER;
  },
): Promise<User | undefined> => {
  const response = await axios.patch(`${USER_URL}/${idUser}/activate`, request);
  const response_1 = response.data;
  return response_1.data;
};

const resetPasswordUser = async (
  idUser: ID,
  request: {
    password: string;
    confirmPassword: string;
  },
): Promise<User | undefined> => {
  const response = await axios.patch(`${USER_URL}/${idUser}/reset-password`, request);
  const response_1 = response.data;
  return response_1.data;
};

export {
  getUsers,
  deleteUser,
  getUserById,
  createUser,
  updateUser,
  changeStatusUser,
  resetPasswordUser,
};
