import { ID, Response } from 'app/components/Table/core/types';

import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { API_URL } from 'app/constants/environments';

import {
  ForecastItem,
  StockRun,
  StockRunHistory,
  StockRunHistoryQueryResponse,
  StockRunItem,
  StockRunItemRequest,
  StockRunItemsQueryResponse,
  StockRunRequest,
  StockRunsQueryResponse,
} from './type';
import axios, { AxiosResponse } from 'axios';

const STOCK_RUN_URL = `${API_URL}/api/stock-run`;
const STOCK_RUN_ITEMS_URL = `${API_URL}/api/stock-run-items`;

const getStockRuns = (
  query: string,
  _id?: ID,
  moreParam?: string,
): Promise<StockRunsQueryResponse> => {
  return axios.get(`${STOCK_RUN_URL}?${query}${moreParam || ''}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<StockRun>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const getStockRun = (idStockRun: ID): Promise<StockRun | undefined> => {
  return axios
    .get(`${STOCK_RUN_URL}/${idStockRun}`)
    .then((response: AxiosResponse<Response<StockRun>>) => response.data)
    .then((response: Response<StockRun>) => response.data);
};

const createStockRun = (request: StockRunRequest): Promise<StockRun | undefined> => {
  return axios
    .post(STOCK_RUN_URL, request)
    .then((response: AxiosResponse<Response<StockRun>>) => response.data)
    .then((response: Response<StockRun>) => response.data);
};

const updateStockRun = (
  idStockRun: ID,
  request: StockRunRequest,
): Promise<StockRun | undefined> => {
  return axios
    .patch(`${STOCK_RUN_URL}/${idStockRun}`, request)
    .then((response: AxiosResponse<Response<StockRun>>) => response.data)
    .then((response: Response<StockRun>) => response.data);
};

const deleteStockRun = async (idStockRun: ID): Promise<void> => {
  try {
    await axios.delete(`${STOCK_RUN_URL}/${idStockRun}`);
  } catch (error) {
    console.log(`Error deleting stock run ${idStockRun}`);
    throw error;
  }
};
const getStockRunItems = (query: string, idStockRun: ID): Promise<StockRunItemsQueryResponse> => {
  return axios.get(`${STOCK_RUN_ITEMS_URL}/stock-run/${idStockRun}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<StockRunItem>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const getHistoryStockRunItems = (
  query: string,
  idStockRun: ID,
): Promise<StockRunHistoryQueryResponse> => {
  return axios.get(`${STOCK_RUN_URL}/${idStockRun}/history?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<StockRunHistory>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const createStockRunItem = (request: {
  items: StockRunItemRequest[];
  removeItems: number[];
}): Promise<StockRunItem[] | undefined> => {
  return axios
    .post(STOCK_RUN_ITEMS_URL, request)
    .then((response: AxiosResponse<Response<StockRunItem[]>>) => response.data)
    .then((response: Response<StockRunItem[]>) => response.data);
};

const updateStatusStockRun = (
  idStockRun: ID,
  request: { status: number },
): Promise<StockRunItem | undefined> => {
  return axios
    .patch(`${STOCK_RUN_URL}/${idStockRun}/status`, request)
    .then((response: AxiosResponse<Response<StockRunItem>>) => response.data)
    .then((response: Response<StockRunItem>) => response.data);
};

const getForecastingWards = () =>
  axios
    .get(`${API_URL}/api/wards/suggestion`)
    .then((response: AxiosResponse<Response<Ward[]>>) => ({
      data: {
        items: response.data.data || [],
        total: response.data.data?.length || 0,
      },
    }));

const getWardForecast = (wardId: ID) => {
  return axios
    .get(`${API_URL}/api/wards/${wardId}/suggestion`)
    .then((response: AxiosResponse<{ data: ForecastItem[] }>) => response.data.data);
};

export {
  getStockRuns,
  getStockRun,
  createStockRun,
  updateStockRun,
  deleteStockRun,
  getStockRunItems,
  getHistoryStockRunItems,
  createStockRunItem,
  updateStatusStockRun,
  getWardForecast,
};
