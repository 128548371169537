import { Column } from 'react-table';

import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { formatCustomDate } from 'app/helpers';

import { SecurityGroup } from '../../security-groups/core/type';

export const usersRoleColumns = (): ReadonlyArray<Column<SecurityGroup>> => {
  const actions = (item: SecurityGroup) => {
    const listActions: ActionTable[] = [{ name: 'Edit', type: TYPE_MODAL.EDIT }];
    if (!item.isDefault) {
      listActions.push({ name: 'Delete', type: TYPE_MODAL.DELETE });
    }
    return listActions;
  };

  return [
    {
      id: 'createdAt',
      accessor: 'createdAt',
      Header: (props) => <CustomHeader tableProps={props} title="Created at" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].createdAt)}
        </Typography>
      ),
    },
    {
      id: 'name',
      accessor: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Role" />,
      Cell: ({ ...props }) => (
        <p
          className="fw-normal"
          style={{
            maxWidth: 200,
            marginBottom: 0,
          }}
        >{`${props.data[props.row.index].name} `}</p>
      ),
      width: '80%',
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={actions(props.data[props.row.index])}
        />
      ),
    },
  ];
};
