import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import InputDateFilter from 'app/components/Filter/InputDateFilter';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage, { Body } from 'app/components/layout/RootContentPage';

import { useBoolean } from 'app/hooks';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

import { labelFilterDate } from '../inventory/stock-run/core/helpers';
import { BarChart } from './components/BarChart';
import { SessionsCharts } from './components/SessionsCharts';
import {
  DEFAULT_STOCK_CHART_DATA,
  getItemsPickUp,
  getParLevelData,
  getPostedStockRunChartData,
} from './core/requests';
import { ItemsPickUpData, ParLevelData, PostedStockRunChartData } from './core/types';

const Container = styled(RootContentPage)`
  height: 100%;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const RowWrapper = styled.div`
  grid-column: span 2;
`;

export interface SessionFilter {
  from: Date;
  to: Date;
}

export const weekStartTime = startOfWeek(new Date(), { weekStartsOn: 1 });
export const weekEndTime = endOfWeek(new Date(), { weekStartsOn: 1 });
const monthStartTime = startOfMonth(new Date());
const monthEndTime = endOfMonth(new Date());

export const DashboardPage = () => {
  const [top10Filter, setTop10Filter] = useState<SessionFilter>({
    from: monthStartTime,
    to: monthEndTime,
  });
  const [parFilter, setParFilter] = useState<SessionFilter>({
    from: monthStartTime,
    to: monthEndTime,
  });

  const [stockRunChartData, setStockRunChartData] =
    useState<PostedStockRunChartData>(DEFAULT_STOCK_CHART_DATA);
  const [itemsPickUp, setItemsPickUp] = useState<ItemsPickUpData>();
  const [parLevelData, setParLevelData] = useState<ParLevelData>();
  const isLoading = useBoolean();

  const fetchStockRunChartData = async () => {
    const res = await getPostedStockRunChartData();
    setStockRunChartData(res);
  };

  const fetchItemsPickUpData = async () => {
    isLoading.setValue(true);
    const itemsRes = await getItemsPickUp(
      new Date(top10Filter.from).toISOString(),
      new Date(top10Filter.to).toISOString(),
    );
    isLoading.setValue(false);
    setItemsPickUp(itemsRes ?? []);
  };

  const fetchParLevelData = async () => {
    isLoading.setValue(true);
    const dataRes = await getParLevelData(
      new Date(parFilter.from).toISOString(),
      new Date(parFilter.to).toISOString(),
    );
    isLoading.setValue(false);
    setParLevelData(dataRes);
  };

  useEffect(() => {
    fetchParLevelData();
  }, [JSON.stringify(parFilter)]);

  useEffect(() => {
    fetchItemsPickUpData();
  }, [JSON.stringify(top10Filter)]);

  useEffect(() => {
    fetchStockRunChartData();
  }, []);

  const renderItemsPickUpChart = useMemo(
    () => (
      <div>
        <BarChart
          data={{
            labels: itemsPickUp?.itemsPickUp.items ?? [],
            datasets: [
              {
                label: 'Total quantity',
                data: itemsPickUp?.itemsPickUp.values ?? [],
                maxBarThickness: 20,
              },
            ],
          }}
        />
      </div>
    ),
    [itemsPickUp],
  );

  const renderPerformanceChart = useMemo(
    () => (
      <>
        <div style={{ marginBottom: 56 }}>
          <Typography variant="title-1" lineHeight={32} style={{ marginBottom: 32 }}>
            Top 20 par level performance
          </Typography>
          <BarChart
            data={{
              labels: parLevelData?.topParLevel.items ?? [],
              datasets: [
                {
                  label: 'Multiple of par value',
                  data: parLevelData?.topParLevel.values ?? [],
                  maxBarThickness: 20,
                },
              ],
            }}
          />
        </div>

        <div>
          <Typography variant="title-1" lineHeight={32} style={{ marginBottom: 32 }}>
            Bottom 20 par level performance
          </Typography>
          <BarChart
            data={{
              labels: parLevelData?.bottomParLevel.items ?? [],
              datasets: [
                {
                  label: 'Multiple of par value',
                  data: parLevelData?.bottomParLevel.values ?? [],
                  maxBarThickness: 20,
                },
              ],
            }}
          />
        </div>
      </>
    ),
    [parLevelData],
  );

  return (
    <Container title="Dashboard" noWrap>
      <Body style={{ height: '100%', padding: 28 }}>
        <SessionsCharts />
      </Body>

      <Body style={{ height: '100%', padding: 28, marginTop: 24 }}>
        <RowWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 32,
          }}
        >
          <Typography variant="title-1" lineHeight={32}>
            Top 10 items pickup
          </Typography>
          <InputDateFilter
            value={labelFilterDate(top10Filter.from, top10Filter.to)}
            containerStyle={{ width: 300, marginBottom: 0 }}
            onChange={(newDate) => {
              setTop10Filter({
                from: startOfDay(newDate[0].startDate),
                to: endOfDay(newDate[0].endDate),
              });
            }}
            state={[
              {
                endDate: top10Filter.to ? new Date(top10Filter.to) : new Date(),
                startDate: top10Filter.from ? new Date(top10Filter.from) : new Date(),
                key: 'selection',
              },
            ]}
            inputDateStyle={{ marginBottom: 0 }}
          />
        </RowWrapper>

        <RowWrapper>{renderItemsPickUpChart}</RowWrapper>
      </Body>

      <Body style={{ height: '100%', padding: 28, marginTop: 24 }}>
        <RowWrapper
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <InputDateFilter
            value={labelFilterDate(parFilter.from, parFilter.to)}
            containerStyle={{ width: 300 }}
            onChange={(newDate) => {
              setParFilter({
                from: startOfDay(newDate[0].startDate),
                to: endOfDay(newDate[0].endDate),
              });
            }}
            state={[
              {
                endDate: parFilter.to ? new Date(parFilter.to) : new Date(),
                startDate: parFilter.from ? new Date(parFilter.from) : new Date(),
                key: 'selection',
              },
            ]}
            inputDateStyle={{ marginBottom: 32 }}
          />
        </RowWrapper>
        {renderPerformanceChart}
      </Body>

      <Body style={{ height: '100%', padding: 28, marginTop: 24 }}>
        <Typography variant="title-1" lineHeight={32} style={{ marginBottom: 32 }}>
          Total stock run posted for the next 30 days
        </Typography>
        <BarChart
          data={{
            labels: stockRunChartData.time ?? [],
            datasets: [
              {
                label: 'Number of posted stock runs',
                data: stockRunChartData.count,
                maxBarThickness: 20,
              },
            ],
          }}
          options={{
            scales: {
              y: {
                ticks: { stepSize: 1 },
                suggestedMax: 5,
              },
            },
          }}
          type="stock-run"
        />
      </Body>
      {isLoading.value && <LoadingCard />}
    </Container>
  );
};
