import { ActionTable, QueryState, TYPE_MODAL } from './types';

export const QUERIES = {
  USERS_WEB_LIST: 'users-web-list',
  USERS_TABLET_LIST: 'users-tablet-list',
  WARDS_LIST: 'wards-list',
  ROOMS_LIST: 'rooms-list',
  BEDS_LIST: 'beds-list',
  ITEMS_LIST: 'items-list',
  ITEMS_DETAIL_LIST: 'items-detail-list',
  DEVICE_TABLET_LIST: 'device-tablet-list',
  DEVICE_GATEWAY_LIST: 'device-gateway-list',
  DEVICE_SENSOR_LIST: 'device-sensor-list',
  RACK_LIST: 'rack-list',
  STOCK_RUN_LIST: 'stock-run-list',
  USERS_ROLE_WEB_LIST: 'users-role-web-list',
  USERS_ROLE_TABLET_LIST: 'users-role-tablet-list',
  HISTORY_RACK_LIST: 'history-rack-list',
  HISTORY_BIN_LIST: 'history-bin-list',
  BIN_IN_RACK_LIST: 'bin-in-rack-list',
  SETTING_LIST: 'setting-list',
  ACTIVITY_LOG_LIST: 'activity-log-list',
  HISTORY__STOCK_RUN_LIST: 'history-stock-run-list',
  ITEM__STOCK_RUN_LIST: 'item-stock-run-list',
  REPORT_LIST: 'report-list',
};

export const DEFAULT_ACTION_TABLE: ActionTable[] = [
  {
    name: 'Edit',
    type: TYPE_MODAL.EDIT,
  },
  {
    name: 'Delete',
    type: TYPE_MODAL.DELETE,
  },
];
export interface ItemForUpdateListView {
  originalData: any;
  type: TYPE_MODAL;
  isView: boolean;
  titleModal: string;
}
export const emptyListViewProvider: ItemForUpdateListView = {
  originalData: undefined,
  type: TYPE_MODAL.NONE,
  isView: false,
  titleModal: '',
};

export const DEFAULT_PAGINATION_QUERY: QueryState = {
  limit: 10,
  page: 1,
};

export const DEFAULT_TABLE_QUERY: QueryState = {
  ...DEFAULT_PAGINATION_QUERY,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export const SORT_BY_NAME_TABLE_QUERY: QueryState = {
  ...DEFAULT_PAGINATION_QUERY,
  orderBy: 'name',
  orderDirection: 'ASC',
};

export const SORT_SEARCH_FIELDS = ['search', 'orderBy', 'orderDirection'];
