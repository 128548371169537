import { CSSProperties, FC } from 'react';
import { Bar } from 'react-chartjs-2';

import { merge, sumBy } from 'lodash';

import { getBarChartOptions } from '../core/helpers';
import { Chart, ChartData, ChartOptions, LineElement, PointElement, Title } from 'chart.js';

Chart.register(PointElement, LineElement, Title);

const customOptions: ChartOptions<'bar'> = {
  scales: {
    x: { stacked: true },
    y: {
      stacked: true,
      afterDataLimits(scale) {
        const range = scale.max - scale.min;
        const grace = range * 0.05;
        scale.max += grace;
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 0,
    },
  },
  interaction: { mode: 'index' },
  plugins: {
    tooltip: {
      callbacks: {
        footer: (items) => {
          return `Total: ${sumBy(items, 'raw')}`;
        },
      },
    },
  },
};

export const StackedBarChart: FC<{
  options: ChartOptions<'bar'>;
  data: ChartData<'bar'>;
  containerStyle?: CSSProperties;
}> = ({ data, options, containerStyle }) => {
  const mergeOptions = getBarChartOptions(merge(customOptions, options));
  return (
    <div style={{ height: '460px', ...containerStyle }}>
      <Bar options={mergeOptions} data={data} />
    </div>
  );
};
