import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { Bin } from 'app/modules/inventory/bins/core/type';
import LabelStockTypeCard from 'app/modules/inventory/items/components/LabelStockTypeCard';

import { Transaction } from '../RackTestPage';
import QuantityInput from './QuantityInput';

const BinItem = styled.div`
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  min-height: 126px;
  .action {
    opacity: 0;
  }
  &:hover .action {
    opacity: 1;
  }
  cursor: pointer;
`;

const ShowImage = styled.div`
  min-width: 43px;
  min-height: 43px;
  width: 43px;
  height: 43px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
`;

interface Props {
  bin: Bin;
  onEmitTransaction: (data: Partial<Transaction>, bin: Bin) => void;
  disabled: boolean;
}
const BinTestCard: React.FC<Props> = ({ bin, onEmitTransaction, disabled }) => {
  const params = useParams<{ wardId: string }>();

  const onChangeValue = (value: number) => {
    onEmitTransaction(
      {
        bin: bin.index,
        weight: bin.item?.weight ? String(bin.item?.weight) : '',
        qty: String(value),
        weight_unit: bin.item?.weightUnit ? String(bin.item?.weightUnit) : '',
        sku: bin.item?.skuId || '',
        ward_id: params.wardId,
      },
      bin,
    );
  };

  return (
    <BinItem>
      <div className="d-flex align-items-center justify-content-between">
        <Typography fontWeight={600} lineHeight={26} color="neutral-10">
          {bin.name}
        </Typography>
      </div>

      {bin.item ? (
        <div className="d-flex flex-column">
          <div
            className="d-flex"
            style={{
              marginTop: 6,
            }}
          >
            <ShowImage
              style={{
                backgroundImage: bin.item.image ? `url(${bin.item.image})` : 'inherit',
              }}
            />
            <div
              className="d-flex flex-column"
              style={{
                marginLeft: 8,
                flexGrow: 1,
              }}
            >
              <Typography
                className="text-overflow-ellipsis"
                fontWeight={400}
                lineHeight={20}
                color="neutral-10"
              >
                {bin.item.name}
              </Typography>
              <LabelStockTypeCard stockType={Number(bin.item.stockType)} />

              <div
                className="d-flex algin-items-center justify-content-between"
                style={{
                  marginTop: 6,
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  fontWeight={400}
                  lineHeight={22}
                  fontSize={13}
                  color="neutral-7"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {bin.item.skuId}
                </Typography>
                <Typography
                  fontWeight={400}
                  lineHeight={23}
                  fontSize={14}
                  color="neutral-10"
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: 5,
                  }}
                >
                  {`Qty: ${bin.item.qty ?? 0}`}
                </Typography>
              </div>
              <div
                className="d-flex flex-row"
                style={{ justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <QuantityInput
                  currentValue={bin.item.qty}
                  onChangeValue={onChangeValue}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Typography fontWeight={400} lineHeight={20} color="neutral-6">
          No assigned item
        </Typography>
      )}
    </BinItem>
  );
};

export default BinTestCard;
