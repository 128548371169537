import React from 'react';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { ReactComponent as IconDanger } from 'app/assets/icons/icon-danger.svg';
import { ReactComponent as IconSync } from 'app/assets/icons/icon-sync-active.svg';

const Main = styled.div<{ isSync?: boolean }>`
  height: 29px;
  border-radius: 4px;
  min-height: 29px;
  padding: 0px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  ${({ isSync }) =>
    `background-color:${isSync ? 'var(--success-light-2)' : 'var(--danger-light-2)'}`}
`;
interface Props {
  isSync: boolean;
}
const LabelSyncDataRack: React.FC<Props> = ({ isSync }) => {
  return (
    <Main isSync={isSync}>
      {isSync ? (
        <React.Fragment>
          <IconSync />
          <Typography variant="title-4" color="success" style={{ marginLeft: 6 }}>
            Your changes are in sync
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IconDanger />
          <Typography variant="title-4" color="danger" style={{ marginLeft: 6 }}>
            The changes have not been synced. Auto sync in 5 minutes if no activity.
          </Typography>
        </React.Fragment>
      )}
    </Main>
  );
};
export default LabelSyncDataRack;
