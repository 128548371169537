import { Column } from 'react-table';

import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { formatCustomDate } from 'app/helpers';

import StatusDeviceCard from '../components/StatusDeviceCard';
import { DeviceType } from './enum';
import { Device } from './type';

export const devicesColumns = (typeSub: DeviceType): ReadonlyArray<Column<Device>> => {
  const actions = (devices: Device) => {
    const listActions: ActionTable[] = [
      {
        name: 'Edit',
        type: TYPE_MODAL.EDIT,
      },
    ];
    listActions.push({
      name: devices.linked ? 'Generate new code' : 'Connect tablet',
      type: devices.linked ? TYPE_MODAL.GENERATE_CODE : TYPE_MODAL.CONNECT_TABLET,
    });
    listActions.push({
      name: 'Delete',
      type: TYPE_MODAL.DELETE,
    });
    return listActions;
  };
  return [
    {
      id: 'createdAt',
      accessor: 'createdAt',
      Header: (props) => <CustomHeader tableProps={props} title="Created at" />,
      width: '20%',
      Cell: ({ ...props }) => (
        <Typography style={{ width: '11ch' }} variant="body-5" color="neutral-10">
          {formatCustomDate(props.data[props.row.index].createdAt)}
        </Typography>
      ),
    },
    {
      id: 'name',
      accessor: 'name',
      width: '35%',
      Header: (props) => (
        <CustomHeader
          tableProps={props}
          title={typeSub === DeviceType.tablet ? 'Tablet' : 'Gateway'}
        />
      ),
    },

    {
      id: 'wardName',
      accessor: 'inventoryLocation',
      Header: (props) => <CustomHeader tableProps={props} title="Belong to ward" />,
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {props.data[props.row.index].inventoryLocation?.location?.name}{' '}
        </Typography>
      ),
      width: '20%',
    },
    {
      id: 'status',
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => <StatusDeviceCard device={props.data[props.row.index]} />,
      width: '20%',
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={actions(props.data[props.row.index])}
        />
      ),
    },
  ];
};
