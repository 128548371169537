import React from 'react';
import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

export enum ViewMode {
  list = 0,
  nonList = 1,
}

const Button = styled.div<{ isActive?: boolean }>`
  width: 43px;
  height: 43px;
  cursor: pointer;
  border-radius: 8px;
  z-index: 1;
  :hover {
    svg path[stroke] {
      stroke: var(--primary);
    }
    background: var(--primary-3);
  }
  ${({ isActive }) =>
    isActive
      ? `svg path[stroke] {
            stroke: var(--primary);
        }`
      : ''}
`;

const Switch = styled.div`
  position: absolute;
  background: #d1eaff;
  width: 43px;
  height: 43px;
  border-radius: 8px;
  transition: all 0.3s;
`;

interface Props {
  view: ViewMode;
  onClickButton: (view: ViewMode) => () => void;
  messages: string[];
  iconList: React.FC[];
}
const ButtonSwitchView: React.FC<Props> = ({ view, onClickButton, messages, iconList }) => {
  const IconLeft = iconList[0];
  const IconRight = iconList[1];
  return (
    <TippyDefault
      arrow={true}
      placement="top"
      theme="dark"
      allowHTML
      content={
        <Typography variant="body-4" color="white">
          {view === ViewMode.list ? messages[0] : messages[1]}
        </Typography>
      }
    >
      <div
        className="d-flex align-items-center"
        style={{ background: 'var(--neutral-2)', borderRadius: 8, position: 'relative' }}
      >
        <Button
          onClick={onClickButton(ViewMode.list)}
          isActive={view === ViewMode.list}
          className="d-flex align-items-center justify-content-center"
        >
          <IconLeft />
        </Button>
        <Button
          onClick={onClickButton(ViewMode.nonList)}
          isActive={view === ViewMode.nonList}
          className="d-flex align-items-center justify-content-center"
        >
          <IconRight />
        </Button>
        <Switch style={{ left: view === ViewMode.list ? 0 : 43 }} />
      </div>
    </TippyDefault>
  );
};

export default ButtonSwitchView;
