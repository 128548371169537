import { useParams } from 'react-router-dom';

import BodyFormStockRun from './components/BodyFormStockRun';

const EditStockRunPage = () => {
  const params = useParams<any>();
  return <BodyFormStockRun idStockRun={params.id} />;
};

export default EditStockRunPage;
