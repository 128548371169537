import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { EventTypeLogRack } from '../core/enum';

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  margin-top: 9px;
  border-radius: 4px;
`;
interface Props {
  type: EventTypeLogRack;
}
const EventTypeLogCard: React.FC<Props> = ({ type }) => {
  const getBgAndColorEventTYpe = () => {
    let bg = 'var(--success-light-1-5)';
    let color = 'success';
    switch (type) {
      case EventTypeLogRack.PickUp:
        bg = 'var(--danger-light-1-5)';
        color = 'danger';
        break;

      case EventTypeLogRack.AutoEdit:
      case EventTypeLogRack.UpdateRack:
        bg = 'var(--primary-3)';
        color = 'primary';
        break;
      case EventTypeLogRack.AbnormalAction:
        bg = 'var(--danger-light-2)';
        color = 'danger-dark';
        break;
      case EventTypeLogRack.ManualEdit:
        bg = 'var(--neutral-3)';
        color = 'neutral-7';
        break;
    }
    return {
      bg,
      color,
    };
  };
  return (
    <Main
      style={{
        backgroundColor: getBgAndColorEventTYpe().bg,
      }}
    >
      <Typography
        fontWeight={600}
        lineHeight={20}
        fontSize={12}
        color={getBgAndColorEventTYpe().color}
      >
        {type}
      </Typography>
    </Main>
  );
};
export default EventTypeLogCard;
