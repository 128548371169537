import { tippy } from '@tippyjs/react';

import { getPathWithParams } from 'app/helpers/utils';
import { differenceInCalendarWeeks, format, isToday, lastDayOfMonth, startOfMonth } from 'date-fns';

import { PATH } from 'app/constants/path';

export const onCellDayClick =
  (navigate: any, date: number, onSimInputClick: (startDate?: number) => void) => (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!isToday(date)) {
      navigate(getPathWithParams(PATH.INVENTORY_STOCK_RUN_ADD, { startDate: new Date(date) }));
      return;
    }

    const menuEl = document.createElement('div');
    menuEl.className = 'dd-menu-sr';

    const userInputItem = document.createElement('div');
    userInputItem.innerHTML = 'Add manually';
    userInputItem.className = 'dd-menu-sr-item';
    userInputItem.onclick = () => {
      navigate(getPathWithParams(PATH.INVENTORY_STOCK_RUN_ADD, { startDate: new Date(date) }));
      instance?.destroy();
    };
    menuEl.appendChild(userInputItem);

    const simsInputItem = document.createElement('div');
    simsInputItem.innerHTML = 'Add by SIMS';
    simsInputItem.className = 'dd-menu-sr-item';
    simsInputItem.onclick = () => {
      onSimInputClick(date);
      instance?.destroy();
    };
    menuEl.appendChild(simsInputItem);

    const tippyAnchorEl = ev.target.type === 'button' ? ev.target.parentElement : ev.target;
    const instance: any = tippy(tippyAnchorEl, {
      content: menuEl,
      arrow: false,
      placement: 'top',
      interactive: true,
      trigger: 'click',
      theme: 'light-menu',
      offset: [0, -94],
      appendTo: () => document.body,
      onClickOutside: (ins) => ins.destroy(),
    });
    instance.show();
  };

export const getCalendarDateValue = (d: Date) => format(d, 'yyyy-MM-dd');

export const getWeeksInMonth = (date) =>
  differenceInCalendarWeeks(lastDayOfMonth(date), startOfMonth(date), { weekStartsOn: 1 }) + 1;
