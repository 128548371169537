import { toast } from 'react-toastify';

import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';

import { API_URL } from 'app/constants/environments';

import { StatusItem } from './constants';
import {
  Item,
  ItemsQueryResponse,
  StockValueSetting,
  StockValueSettingQueryResponse,
} from './type';
import axios, { AxiosResponse } from 'axios';

const ITEM_URL = `${API_URL}/api/items`;
const getItems = (query: string): Promise<ItemsQueryResponse> => {
  return axios.get(`${ITEM_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Item>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const getItem = (query: string, idItem: ID): Promise<Item | undefined> => {
  return axios
    .get(`${ITEM_URL}/${idItem}?${query}`)
    .then((response: AxiosResponse<Response<Item>>) => response.data)
    .then((response: Response<Item>) => response.data);
};
const getItemTable = (
  query: string,
  idItem: ID,
): Promise<StockValueSettingQueryResponse | undefined> => {
  return axios.get(`${ITEM_URL}/${idItem}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          stockValue: {
            items: Array<StockValueSetting>;
            total: number;
            dataItem: Item;
          };
        };
      }>,
    ) => {
      return {
        data: {
          ...d.data.data.stockValue,
          dataItem: d.data.data as any,
        },
      };
    },
  );
};

const createItem = (request: FormData): Promise<Item | undefined> => {
  return axios
    .post(ITEM_URL, request)
    .then((response: AxiosResponse<Response<Item>>) => response.data)
    .then((response: Response<Item>) => response.data);
};

const updateItem = (idItem: ID, request: FormData): Promise<Item | undefined> => {
  return axios
    .patch(`${ITEM_URL}/${idItem}`, request)
    .then((response: AxiosResponse<Response<Item>>) => response.data)
    .then((response: Response<Item>) => response.data);
};

const deleteItem = async (idItem: ID): Promise<void> => {
  try {
    await axios.delete(`${ITEM_URL}/${idItem}`);
  } catch (error) {
    console.log(`Error deleting item ${idItem}`);
    throw error;
  }
};

const changeStatusItem = (
  itemId: ID,
  request: { activate: StatusItem },
): Promise<Item | undefined> => {
  return axios
    .patch(`${ITEM_URL}/${itemId}/activate`, request)
    .then((response: AxiosResponse<Response<Item>>) => response.data)
    .then((response: Response<Item>) => response.data);
};

const patchStockValueItem = (
  itemId: ID,
  request: {
    wardId: number;
    restockValue: number;
    alarmValue: number;
    parValue: number;
  },
  onSuccess: () => void,
  onError: () => void,
  onFinally: () => void,
): Promise<void> => {
  return axios
    .patch(`${ITEM_URL}/${itemId}/stock-value`, request)
    .then((response: AxiosResponse<Response<StockValueSetting>>) => {
      onSuccess();
    })
    .catch((error) => {
      getResponseError(error);
      onError();
    })
    .finally(onFinally);
};

export {
  getItems,
  getItem,
  getItemTable,
  createItem,
  updateItem,
  deleteItem,
  changeStatusItem,
  patchStockValueItem,
};
