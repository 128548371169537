import { FC } from 'react';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';

const ShowAvatar = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`;

const Information = styled.div`
  .btn.btn-active-light:hover:not(.btn-active) {
    background-color: var(--primary-2) !important;
    p {
      color: var(--primary) !important;
    }
  }
  .btn.btn-active-light:active:not(.btn-active) {
    background-color: var(--primary-2) !important;
    p {
      color: var(--primary) !important;
    }
  }
  .btn.btn-active-light.show {
    background-color: var(--primary-2) !important;
    p {
      color: var(--primary) !important;
    }
  }
`;
const Topbar: FC = () => {
  const { currentUser } = useAuth();
  return (
    <div
      className="d-flex align-items-stretch flex-shrink-0"
      style={{
        borderTop: `1px solid var(--neutral-3)`,
        padding: '12px 0',
        marginRight: 28,
      }}
    >
      <div className="topbar d-flex align-items-stretch flex-shrink-0">
        {/* begin::Theme mode */}
        {/* <div className={"d-flex align-items-center ms-lg-5"}>
          <ThemeModeSwitcher toggleBtnClass="btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3" />
        </div> */}
        {/* end::Theme mode */}

        {/* begin::User */}
        <Information>
          <div className="d-flex align-items-center" id="kt_header_user_menu_toggle">
            <div
              className="btn btn-active-light d-flex align-items-center bg-hover-light"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="right-end"
              style={{ padding: '10px 12px', width: 188 }}
            >
              <ShowAvatar>
                <img src={toAbsoluteUrl('/media/avatars/icon-avatar-default.svg')} alt="avatar" />
              </ShowAvatar>
              <div className="d-none d-md-flex flex-column align-items-start justify-content-center">
                <Typography variant="title-4" color="neutral-10">
                  {`${currentUser?.firstName} ${currentUser?.lastName}`}
                </Typography>
                <Typography fontSize={12} fontWeight={500} lineHeight={20} color="neutral-6">
                  {currentUser?.role.name}
                </Typography>
              </div>
            </div>
            <HeaderUserMenu />
          </div>
        </Information>
        {/* end::User */}
      </div>
    </div>
  );
};

export { Topbar };
