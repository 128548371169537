import { ActionButton } from 'app/components/Button/ActionButton';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import Typography from 'app/components/Typography';

interface Props {
  content: string;
}

export const InfoCard = ({ content }: Props) => {
  const { setItemForUpdate } = useListView();

  const onCancel = () => {
    setItemForUpdate(emptyListViewProvider);
  };

  return (
    <div className="d-flex flex-column">
      <Typography variant="body-3">{content}</Typography>
      <ActionButton
        labelButtonSubmit="Cancel"
        onSubmit={onCancel}
        onCancel={onCancel}
        isShowButtonReset={false}
      />
    </div>
  );
};
