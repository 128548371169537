import styled from 'styled-components';

import { InventoryMovementType } from 'app/modules/inventory/racks/core/enum';

const MainStatus = styled.div<{ bg?: string }>`
  min-width: 68px;
  width: fit-content;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0px 10px;
  ${({ bg }) => `background-color:${bg}`}
`;
const NameStatus = styled.div<{ color?: string }>`
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  ${({ color }) => `color:${color}`};
`;
interface Props {
  isActive: boolean;
  label: string;
  action?: InventoryMovementType;
  styleMain?: React.CSSProperties;
}

const getStatusColor = (isActive: boolean, action?: InventoryMovementType) => {
  if (action === InventoryMovementType.manualEdit) {
    return {
      backgroundColor: 'var(--neutral-3)',
      color: 'var(--neutral-7)',
    };
  }
  if (action === InventoryMovementType.autoEdit) {
    return {
      backgroundColor: 'var(--primary-3)',
      color: 'var(--primary)',
    };
  }
  return isActive
    ? {
        backgroundColor: 'var(--success-light-1-5)',
        color: 'var(--success)',
      }
    : { backgroundColor: 'var(--danger-light-1-5)', color: 'var(--danger)' };
};

const getStatusLabel = (label: string, action?: InventoryMovementType) => {
  switch (action) {
    case InventoryMovementType.manualEdit:
      return 'Manual edit';
    case InventoryMovementType.autoEdit:
      return 'Auto edit';
    default:
      return label;
  }
};

const StatusDefault: React.FC<Props> = ({ isActive, label, action, styleMain }) => {
  const { backgroundColor, color } = getStatusColor(isActive, action);
  return (
    <MainStatus bg={backgroundColor} style={styleMain}>
      <NameStatus color={color} style={{ whiteSpace: 'nowrap', width: 'fit-content' }}>
        {getStatusLabel(label, action)}
      </NameStatus>
    </MainStatus>
  );
};
export default StatusDefault;
