import { ID } from 'app/components/Table/core/types';

import { DemandApproveItem, ForecastCurve } from './type';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  approvedIds: DemandApproveItem[];
  isAllowApprove: boolean;
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
  blockApprove: () => void;
  setApproveStatus: (id: ID) => (curve: ForecastCurve | null) => void;
}

const initState: State = {
  approvedIds: [],
  isAllowApprove: false,
};

export const useProjectionStore = create<Store>()(
  immer((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },

    blockApprove: () => {
      set((store) => {
        store.state.isAllowApprove = !store.state.isAllowApprove;
      });
    },

    /**
     *
     * @param id, curve
     * @returns void
     * This function handles the action of approve/unapprove/changing approved values in ML Demand Projection page
     *
     */
    setApproveStatus: (id) => (curve) => {
      set((store) => {
        //Check if item is already in approve list, return its index
        const itemIndex = store.state.approvedIds.findIndex((el) => el.itemDemandId === id);

        if (itemIndex >= 0) {
          //Index found
          if (!curve || curve === store.state.approvedIds[itemIndex].approvedType) {
            // Unapprove if user tick the same value again
            store.state.approvedIds.splice(itemIndex, 1);
          } else {
            //Change Approved qty
            store.state.approvedIds[itemIndex].approvedType = curve;
          }
        } else if (curve) {
          //If item not existed in approved list and approveType is valid, add new record to store
          store.state.approvedIds.push({ itemDemandId: Number(id), approvedType: curve });
        }

        if (!store.state.isAllowApprove) {
          store.state.isAllowApprove = true;
        }
      });
    },
  })),
);

export const approveStatusSelector = (id: ID) => {
  return (store: Store) => {
    return store.state.approvedIds.find((el) => el.itemDemandId === id)?.approvedType || null;
  };
};

export const allowApproveSelector = (store: Store) => {
  return store.state.isAllowApprove;
};

export const approveStatusLengthSelector = (store: Store) => {
  return store.state.approvedIds.length;
};
