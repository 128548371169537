import Typography from 'app/components/Typography';

interface Props {
  label: string;
  value: string;
  colorValue?: string;
}
const InfoRackItemCard: React.FC<Props> = ({ label, value, colorValue }) => {
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        padding: '0px 24px',
      }}
    >
      <Typography variant="title-5">{label}</Typography>
      <Typography fontSize={14} lineHeight={26} color={colorValue ?? 'neutral-10'} fontWeight={400}>
        {value}
      </Typography>
    </div>
  );
};
export default InfoRackItemCard;
