import { FC, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PATH } from 'app/constants/path';

import { MenuComponent } from '../assets/ts/components';
import { WithChildren } from '../helpers';
import { Content } from './components/Content';
import { ScrollTop } from './components/ScrollTop';
import { AsideDefault } from './components/aside/AsideDefault';
import {
  BANNER_HEIGHT,
  ForecastBanner,
  useCheckDashboardLocation,
} from './components/header/ForecastBanner';
import { PageDataProvider, useLayout } from './core';
import clsx from 'clsx';

const MainContent = styled.div``;

const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  const divMasterRef = useRef<HTMLDivElement>(null);

  const { isDashboardPage } = useCheckDashboardLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    location;
  }, []);

  if (divMasterRef.current) {
    divMasterRef.current.scrollTop = 0;
  }

  return (
    <PageDataProvider>
      <div
        className="page d-flex flex-column flex-column-fluid"
        style={{
          backgroundColor: 'var(--neutral-2)',
        }}
      >
        <ForecastBanner />
        <div
          id="kt_content_container"
          className={clsx(
            'd-flex flex-column-fluid align-items-stretch',
            classes.contentContainer.join(' '),
          )}
          style={{
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          {location.pathname.includes(PATH.RACK_TEST.replace('/:wardId', '')) ? null : (
            <AsideDefault />
          )}

          <MainContent
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
            style={{
              height: '100%',
              maxHeight: `calc(100vh${isDashboardPage ? ' - ' + BANNER_HEIGHT + 'px' : ''})`,
              overflow: 'auto',
            }}
            ref={divMasterRef}
          >
            <div className="flex-column-fluid" id="kt_content">
              <div
                className="post h-100"
                id="kt_post"
                style={{
                  borderLeft: 'none',
                  paddingRight: 40,
                }}
              >
                <Content>
                  <Outlet context={divMasterRef} />
                </Content>
              </div>
            </div>
          </MainContent>

          {/* <Sidebar /> */}
        </div>
      </div>

      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
