import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { checkParamSynced, useGetQueryParams } from 'app/modules/query-state/hooks';

import { filterBySearch, haveKeysIn } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { cloneDeep, isUndefined, omit, orderBy, pick } from 'lodash';

import { useQueryRequest } from './QueryRequestProvider';
import { SORT_SEARCH_FIELDS } from './constants';
import { createResponseContext, stringifyRequestQuery } from './helpers';
import {
  ID,
  PaginationState,
  WithChildren,
  initialQueryResponse,
  initialQueryState,
} from './types';

interface Props extends WithChildren {
  nameQuery: string;
  moreQuery?: string;
  getList: (query: string, id?: ID, moreParam?: string) => Promise<any>;
  paramId?: ID;
  getTotal?: (total: number) => void;
  isSortAndSearchFrontEnd?: boolean;
  refetchTimestamp?: number;
  isAutoFetch?: boolean;
  getDataItemResponse?: (data: any, total: number) => void;
  syncParamUrl?: boolean;
  mappingDataMiddleware?: (data: any) => any;
  customSearchFields?: string[];
  isSortLowerCase?: boolean;
}

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const QueryResponseProvider: FC<Props> = ({
  children,
  nameQuery,
  moreQuery,
  paramId,
  isSortAndSearchFrontEnd,
  getList,
  getTotal,
  refetchTimestamp,
  isAutoFetch,
  getDataItemResponse,
  syncParamUrl,
  mappingDataMiddleware,
  customSearchFields = ['name', 'item.name', 'item.skuId'],
  isSortLowerCase,
}) => {
  const { curParams, synced, skipFields = [] } = useGetQueryParams();
  const { state, setState } = useQueryRequest();

  const haveFetched = useBoolean(false);

  const isSyncedParam = checkParamSynced(synced);

  const updatedQuery = useMemo(() => {
    let params = syncParamUrl ? (omit(curParams, skipFields) as any) : state;
    if (isSortAndSearchFrontEnd && isSyncedParam) {
      params = omit(params, SORT_SEARCH_FIELDS);
    }
    return stringifyRequestQuery({
      ...params,
      limit: params.limit || 10,
      page: params.page || 1,
    });
  }, [
    state,
    syncParamUrl && curParams,
    syncParamUrl && skipFields,
    syncParamUrl && isSyncedParam,
    isSortAndSearchFrontEnd,
  ]);
  const [dataSort, setDataSort] = useState<any>(null);

  const enableQueryInternal =
    isSortAndSearchFrontEnd && haveKeysIn(state, SORT_SEARCH_FIELDS) ? !haveFetched.value : true;

  const enableQuery = useMemo(
    () => (syncParamUrl ? isSyncedParam && enableQueryInternal : enableQueryInternal),
    [isSyncedParam, enableQueryInternal],
  );

  const { isFetching, refetch, data } = useQuery(
    `${nameQuery}-${updatedQuery}`,
    () => {
      haveFetched.setValue(true);
      return getList(`${updatedQuery}${moreQuery ?? ''}`, paramId, state.param);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enableQuery,
    },
  );

  useEffect(() => {
    if (syncParamUrl && isSyncedParam) {
      setState(curParams);
      if (enableQuery && haveFetched.value) {
        refetch();
      }
    }
  }, [syncParamUrl && curParams, syncParamUrl && isSyncedParam, enableQuery, haveFetched.value]);

  useEffect(() => {
    if (isSortAndSearchFrontEnd && data?.data?.total) {
      const newOrderBy = isSortLowerCase
        ? [(item) => item[String(state.orderBy)]?.toLowerCase()]
        : state.orderBy;

      const newData = mappingDataMiddleware
        ? mappingDataMiddleware(data).data.items
        : data.data?.items;

      const newDataSort = orderBy(
        newData,
        newOrderBy,
        state.orderDirection === 'ASC' ? 'asc' : 'desc',
      );
      const dataSearch = filterBySearch(newDataSort, state.search || '', customSearchFields);

      setDataSort({
        data: {
          items: state.search ? dataSearch : newDataSort,
          total: data.data?.total,
        },
      });
    }
  }, [
    JSON.stringify(pick(state, 'orderBy', 'orderDirection', 'search')),
    data,
    isSortAndSearchFrontEnd,
    mappingDataMiddleware,
  ]);

  useEffect(() => {
    if (isUndefined(getTotal) || !data) {
      return;
    }
    getTotal(data.data.total ? data.data.total : 0);
  }, [data, getTotal]);

  useEffect(() => {
    if (isUndefined(getDataItemResponse) || !data) {
      return;
    }
    getDataItemResponse(data.data.dataItem, data.data.total);
  }, [data, getDataItemResponse]);

  useEffect(() => {
    if (refetchTimestamp) {
      console.log('refetchTimestamp');
      refetch();
    }
  }, [refetchTimestamp]);

  useEffect(() => {
    if (!isUndefined(moreQuery) && state.page === 1 && isAutoFetch) {
      console.log('refetch moreQuery');
      refetch();
    }
  }, [moreQuery, isAutoFetch, state.page]);

  const response = useMemo(() => {
    const list = isSortAndSearchFrontEnd ? dataSort : data;
    const newList = cloneDeep(list);
    return newList;
  }, [isSortAndSearchFrontEnd, dataSort, data]);

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        query: updatedQuery,
        response,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response?.data.items) {
    return [];
  }

  return response?.data.items || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  const { state } = useQueryRequest();

  if (!response?.data.total) {
    return defaultPaginationState;
  }
  const total = response?.data.total || 0;
  const totalPage = state.limit ? Number(total) / Number(state.limit) : 0;

  const newLinks = Array.from(new Array(Math.ceil(totalPage))).map((_el, index) => {
    return {
      label: String(index + 1),
      page: index + 1,
      active: Boolean(index + 1 === state.page),
    };
  });

  return {
    links: newLinks,
    page: state.page,
    limit: state.limit,
  };
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
