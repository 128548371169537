import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { OutlineButton } from 'app/components/Button/ButtonDefault';
import Typography from 'app/components/Typography';

import { useAuth } from 'app/modules/auth';
import { useNavigateAddStockRunBySims } from 'app/modules/inventory/stock-run/core/helpers';

import { isSafari } from 'app/helpers';
import { usePolicyInUser } from 'app/hooks/policy';

import { DEFAULT_WARD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import BannerIllusImage from 'app/assets/images/banner-illustration.jpg';

export const BANNER_HEIGHT = 82; // px

const Container = styled.div<{ hide?: boolean }>`
  display: flex;
  height: ${({ hide }) => (hide ? 0 : BANNER_HEIGHT)}px;
  background: linear-gradient(270deg, #192440 0%, #1b367a 100%);
  ${isSafari() ? '' : 'transition: height 0.5s ease-out;'}
`;

const Content = styled.div<{ hide?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s;
  background-image: url(${BannerIllusImage});
  background-repeat: no-repeat;
  background-position-x: -1px;
  background-position-y: -1px;
  background-size: cover;
`;

export const ForecastBanner = () => {
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);
  const { isDashboardPage } = useCheckDashboardLocation();
  const navigate = useNavigateAddStockRunBySims(PATH.STOCK_RUN_DEMAND_PROJECTION_DETAIL);
  return (
    <Container hide={isDashboardPage === false}>
      <Content hide={isDashboardPage === false}>
        <Typography variant="title-1" color="white">
          Never run out of stock again by creating stock run with SIMS forecast. Try it now!
        </Typography>
        <OutlineButton
          style={{ marginLeft: 16 }}
          onClick={() => {
            if (!policy.viewProjection && !policy.approveProjection) {
              return toast.error(
                "Your account's role does not have permission to access this page.",
              );
            }
            navigate(Number(DEFAULT_WARD));
          }}
        >
          ProphesyAI
        </OutlineButton>
      </Content>
    </Container>
  );
};

export const useCheckDashboardLocation = () => {
  const location = useLocation();

  const isDashboardPage = location.pathname === PATH.DASHBOARD;
  return { isDashboardPage };
};

// export const useSubscribeDashboardLocation = () => {
//   const location = useLocation();
//   const [transitioning, setPageTransitioning] = useState(false);
//   // const [isDashboardPage, setIsDashboardPage] = useState(location.pathname === PATH.DASHBOARD);
//   const timeoutRef = useRef<any>(null);

//   const isDashboardPage = location.pathname === PATH.DASHBOARD;

//   useEffect(() => {
//     setPageTransitioning(true);
//     timeoutRef.current = setTimeout(() => {
//       setPageTransitioning(false);
//     }, 500);

//     return () => {
//       timeoutRef.current = null;
//     };
//   }, [location.pathname]);

//   return { transitioning, isDashboardPage };
// };
