import { MouseEventHandler } from 'react';

import Typography from 'app/components/Typography';

import { ReactComponent as IconCloseModal } from 'app/assets/icons/icon-close-modal.svg';

interface Props {
  title: string;
  onClickCloseModal?: MouseEventHandler<HTMLDivElement>;
}
const ModalHeader: React.FC<Props> = ({ title, onClickCloseModal }) => {
  return (
    <div
      className="modal-header"
      style={{
        padding: '0px 28px',
        height: 80,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="heading-6">{title}</Typography>
      <div onClick={onClickCloseModal}>
        <IconCloseModal style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export { ModalHeader };
