import { ID, Response } from 'app/components/Table/core/types';

import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { DeviceType } from './enum';

export type Device = {
  id: ID;
  name: string;
  createdAt: string;
  updatedAt: string;
  macAddress: string;
  typeSub: DeviceType;
  wardId: number;
  code: string;
  expired: string;
  info: string;
  linked: boolean;
  inventoryLocation?: {
    location: Ward;
  };
};

export type DevicesQueryResponse = Response<{
  items: Array<Device>;
  total: number;
}>;

export type DeviceRequest = {
  name: string;
  typeSub?: DeviceType;
  wardId: number;
};

export const initialDeviceRequest: DeviceRequest = {
  name: '',
  wardId: 0,
};
