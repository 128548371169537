import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ID } from 'app/components/Table/core/types';
import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

import ItemInfoCard from 'app/modules/inventory/items/components/ItemInfoCard';
import SKUCard from 'app/modules/inventory/items/components/SKUCard';

import { formatDate } from 'app/helpers';
import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconArrow } from 'app/assets/icons/icon-arrow-down.svg';

import { StatusNotification } from '../core/enum';
import { changeStatusNotification } from '../core/request';
import { Notification } from '../core/type';
import { notificationByIdSelector, useNotificationStore } from '../store';
import { KTCard } from 'theme/helpers';

const Content = styled.div`
  .card:hover {
    border: 1px solid var(--primary) !important;
    cursor: pointer;
  }
  .stockrun-link {
    color: var(--primary);
    cursor: pointer;
    z-index: 9;
    font-weight: 600;
    text-decoration: underline;
    :hover {
      text-decoration: underline !important;
    }
  }
`;

const Table = styled.div`
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  margin-left: 18px;
  margin-top: 12px;
  .table > :not(caption) > * > * {
    padding: 10px 26px;
  }
  .table th {
    color: var(--neutral-6);
    font-size: 12px;
    font-weight: 600;
  }
  .table td {
    cursor: pointer;
  }
`;

const ButtonToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 32px;
  margin-left: 18px;
  margin-top: 12px;
  background: var(--neutral-2);
  border-radius: 8px;
  cursor: pointer;
  .description {
    color: var(--neutral-7);
  }

  svg {
    height: 12px;
    width: 12px;
  }

  svg path[stroke] {
    stroke: var(--neutral-7);
  }
  :hover {
    background: var(--neutral-3);
    svg path[stroke] {
      stroke: var(--neutral-10);
    }
    .description {
      color: var(--neutral-10);
    }
  }
`;

const Point = styled.div`
  width: 10px;
  height: 10px;
  background: var(--primary);
  border-radius: 50%;
  margin-right: 8px;
`;

const renderTable = (notification: Notification) => (
  <Table className="table-responsive">
    <table id="kt_table_users" className="table align-middle table-row-dashed dataTable no-footer">
      <thead>
        <tr
          className="text-start text-muted fw-bolder gs-0"
          style={{
            borderBottom: `1px solid var(--neutral-3)`,
          }}
        >
          <th style={{ width: '50%' }}>Item</th>
          <th style={{ width: '20%' }}>SKU</th>
          <th style={{ width: '10%' }}>Rack</th>
          <th style={{ width: '10%' }}>Bin</th>
          <th style={{ width: '10%' }}>Qty</th>
        </tr>
      </thead>
      <tbody className="fw-normal">
        {notification.items?.map((item, index) => (
          <tr
            key={item.skuId}
            style={{
              background: index % 2 === 0 ? 'var(--neutral-1)' : 'var(--white)',
              cursor: 'default',
            }}
          >
            <td>
              <ItemInfoCard
                image={item.image}
                name={item.name}
                stockType={item.stockType}
                imageStyle={{
                  height: 40,
                  width: 40,
                  minWidth: 40,
                  minHeight: 40,
                }}
                nameStyle={{
                  lineHeight: '20px',
                }}
                stockTypeStyle={{
                  fontSize: '14px',
                }}
                leftContentStyle={{ paddingLeft: '8px' }}
              />
            </td>
            <td>
              <SKUCard
                skuId={item.skuId}
                skuSub={item.skuSub}
                subSkuStyle={{ marginTop: 0, fontSize: '14px' }}
                skuIdStyle={{ lineHeight: '20px' }}
              />
            </td>
            <td>
              <Typography variant="body-1" lineHeight={20} style={{ whiteSpace: 'nowrap' }}>
                {item.nameRack}
              </Typography>
            </td>
            <td>
              <Typography variant="body-1" lineHeight={20}>
                {item.bin}
              </Typography>
            </td>
            <td>
              <Typography variant="body-1" lineHeight={20}>
                {item.qty}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Table>
);

const onChangeStatusNotification = (id: ID, status: boolean) => async () => {
  await changeStatusNotification(id, {
    isRead: status ? StatusNotification.Unread : StatusNotification.Read,
  });
  useNotificationStore.getState().onChangeReadNoti(id, status);
};

export const NotiItem: FC<{
  notification: Notification;
}> = ({ notification }) => {
  const [linkHovering, setLinkHovering] = useState(false);
  const contentRef = useRef<any>();
  const navigate = useNavigateWithSendPath();
  const isTableExpand = useBoolean(false);
  const isTooltipVisible = useBoolean(false);

  useEffect(() => {
    const link = contentRef?.current.querySelector('.stockrun-link');
    if (!link) {
      return;
    }

    const onMouseEnter = (enter: boolean) => (e) => {
      e.stopPropagation();
      setLinkHovering(enter);
    };

    const onLinkClick = async (e) => {
      const currentNoti = notificationByIdSelector(notification.id)(
        useNotificationStore.getState(),
      );
      if (!currentNoti) return;
      e.stopPropagation();
      e.preventDefault();
      // Prevent hooking change, make event listener duplicates
      if (!currentNoti.read) {
        useNotificationStore.getState().onChangeReadNoti(currentNoti.id, currentNoti.read);
        await changeStatusNotification(currentNoti.id, {
          isRead: StatusNotification.Read,
        });
      }
      navigate(PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(currentNoti.stockRunId)));
    };

    link.addEventListener('mouseenter', onMouseEnter(true));
    link.addEventListener('mouseout', onMouseEnter(false));
    link.addEventListener('click', onLinkClick);

    return () => {
      link.removeEventListener('mouseenter', onMouseEnter(true));
      link.removeEventListener('mouseout', onMouseEnter(false));
      link.removeEventListener('click', onLinkClick);
    };
  }, []);

  const getLinkHoverMessage = () => {
    return `${isTableExpand.value ? 'Hide' : 'View'} details`;
  };

  const readStatus = notification.read ? 'unread' : 'read';

  return (
    <Content
      onClick={onChangeStatusNotification(notification.id, notification.read)}
      ref={contentRef}
      onMouseEnter={() => {
        isTooltipVisible.setValue(true);
      }}
      onMouseLeave={() => {
        isTooltipVisible.setValue(false);
      }}
    >
      <TippyDefault
        arrow={false}
        placement="top"
        content={linkHovering ? getLinkHoverMessage() : `Mark as ${readStatus}`}
        delay={[300, 0]}
        visible={isTooltipVisible.value}
      >
        <KTCard
          contentStyle={{
            padding: '24px 28px 24px 10px',
          }}
        >
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-center">
              {!notification.read && <Point className="point"></Point>}
              <div
                dangerouslySetInnerHTML={{
                  __html: `${notification.header}`,
                }}
                style={{
                  paddingLeft: notification.read ? 18 : 0,
                  paddingRight: 16,
                  fontSize: 16,
                  lineHeight: '26px',
                }}
              />
            </div>

            <Typography variant="body-4" style={{ minWidth: 140 }}>
              {formatDate(notification.time, 'dd MMM yyyy')} at{' '}
              {formatDate(notification.time, 'H:mm:ss')}
            </Typography>
          </div>
          {Boolean(notification.items?.length) && (
            <ButtonToggle
              className="toggle-list-item-btn"
              onClick={(e) => {
                e.stopPropagation();
                isTableExpand.setValue(!isTableExpand.value);
              }}
              onMouseEnter={() => {
                setLinkHovering(true);
              }}
              onMouseLeave={() => {
                setLinkHovering(false);
              }}
            >
              <IconArrow
                style={{
                  margin: '10px 6px 10px 10px',
                  transform: `rotate(${isTableExpand.value ? 180 : 0}deg)`,
                }}
              />
              <Typography
                className="description"
                fontSize={14}
                fontWeight={400}
                lineHeight={23}
                style={{
                  whiteSpace: 'nowrap',
                  marginRight: 8,
                }}
              >
                {`${isTableExpand.value ? 'Hide' : 'Show'} ${notification.items?.length} item(s)`}
              </Typography>
            </ButtonToggle>
          )}
          {isTableExpand.value && renderTable(notification)}
        </KTCard>
      </TippyDefault>
    </Content>
  );
};
