import { useEffect, useRef } from 'react';

import { TimePickerInput } from 'app/components/Input/TimePickerInput';
import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';

import { TimeFrame } from '../core/type';
import { getTimeShifts } from '../core/utils';

interface Props {
  isAnd?: boolean;
  isEdit: boolean;
  startTime: Date;
  setStartTime: (newDate: Date) => void;
  endTime: Date;
  setEndTime: (newDate: Date) => void;
  index?: number;
  shifts?: TimeFrame[];
}

const TimeSelectionItem: React.FC<Props> = ({
  isEdit,
  isAnd,
  startTime,
  endTime,
  setEndTime,
  setStartTime,
  index,
  shifts,
}) => {
  const refEnd = useRef<HTMLDivElement>(null);
  const refStart = useRef<HTMLDivElement>(null);

  const { from: startTimeRange, to: endTimeRange } = getTimeShifts(shifts ?? [], index ?? 0);

  const isOpenSelectEndTime = useBoolean(false);
  const isOpenSelectStartTime = useBoolean(false);

  useEffect(() => {
    if (!isOpenSelectEndTime.value || !refEnd.current) {
      return;
    }
    const endIndex = endTimeRange.findIndex(
      (el) => el.getHours() === endTime?.getHours() && el.getMinutes() === endTime.getMinutes(),
    );
    //height card item date = 40
    refEnd.current.scrollTo(0, (endIndex > 3 ? endIndex - 3 : 0) * 40);
  }, [isOpenSelectEndTime.value, endTime, JSON.stringify(endTimeRange)]);

  useEffect(() => {
    if (!isOpenSelectStartTime.value || !refStart.current) {
      return;
    }
    const startIndex = startTimeRange.findIndex(
      (el) => el.getHours() === startTime?.getHours() && el.getMinutes() === startTime.getMinutes(),
    );
    refStart.current.scrollTo(0, (startIndex > 3 ? startIndex - 3 : 0) * 40);
  }, [isOpenSelectStartTime.value, startTime, JSON.stringify(startTimeRange)]);

  return (
    <div className="d-flex align-items-center position-relative">
      <TimePickerInput
        timeFrame={startTimeRange}
        popupProps={{
          disabled: !isEdit,
          placement: 'bottom-start',
        }}
        time={startTime}
        setTime={(d) => setStartTime(d ?? new Date())}
        disabled={!isEdit}
        isShowSelectDate={false}
      />
      <Typography
        variant="body-5"
        color="neutral-6"
        style={{
          margin: '0px 8px',
        }}
      >
        -
      </Typography>

      <TimePickerInput
        timeFrame={endTimeRange}
        popupProps={{
          disabled: !isEdit,
          placement: 'bottom-end',
        }}
        time={endTime}
        setTime={(d) => setEndTime(d ?? new Date())}
        disabled={!isEdit}
        isShowSelectDate={false}
        durationStartTime={startTime}
      />

      {isAnd && (
        <Typography
          variant="body-5"
          color="neutral-6"
          style={{
            margin: '0px 8px',
          }}
        >
          and
        </Typography>
      )}
    </div>
  );
};
export default TimeSelectionItem;
