import { FC } from 'react';

import Typography from 'app/components/Typography';

import { ReactComponent as EmptyChartIcon } from 'app/assets/icons/empty-chart.svg';

export const EmptyChart: FC<{ type?: string }> = ({ type }) => {
  return (
    <div className="d-flex flex-column" style={{ height: '100%' }}>
      <div className="d-flex flex-column flex-center" style={{ flexGrow: 1 }}>
        <EmptyChartIcon style={{ marginBottom: 24 }} />
        <Typography
          variant="body-2"
          lineHeight={20}
          color="neutral-6"
          style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
        >
          {type === 'stock-run'
            ? 'There’s no posted stock run in the next 30 days.'
            : `No data to display.\nPlease try selecting a different time range.`}
        </Typography>
      </div>
    </div>
  );
};
