import styled from 'styled-components';

const MainStatus = styled.div<{ bg?: string }>`
  width: fit-content;
  padding: 10px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  ${({ bg }) => `background-color:${bg}`}
`;
const NameStatus = styled.div<{ color?: string }>`
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  white-space: nowrap;
  ${({ color }) => `color:${color}`}
`;
interface Props {
  isApproved: boolean;
  label: string;
  styleMain?: React.CSSProperties;
}

const ProjectStatus: React.FC<Props> = ({ isApproved, label, styleMain }) => {
  return (
    <MainStatus bg={isApproved ? `var(--primary-3)` : `var(--neutral-3)`} style={styleMain}>
      <NameStatus color={isApproved ? `var(--primary)` : `var(--neutral-7)`}>{label}</NameStatus>
    </MainStatus>
  );
};
export default ProjectStatus;
