import styled from 'styled-components';

import clsx from 'clsx';

type TypoVariant =
  | 'default'
  | 'title-1'
  | 'title-2'
  | 'title-4'
  | 'title-5'
  | 'body-1'
  | 'body-2'
  | 'body-3'
  | 'body-4'
  | 'body-5'
  | 'body-6'
  | 'body-7'
  | 'body-8'
  | 'heading-6'
  | 'heading-7';

const TYPO_CONFIG: { [key in TypoVariant]: TypographyStyles } = {
  default: {
    fw: 400,
    fs: 16,
    lh: 20,
    cl: 'var(--neutral-6)',
  },
  'title-1': {
    fw: 600,
    fs: 18,
    cl: 'var(--neutral-10)',
  },
  'title-2': {
    fs: 16,
    lh: 20,
  },
  'title-4': {
    fw: 600,
    fs: 14,
    lh: 23,
  },
  'title-5': {
    fw: 600,
    fs: 13,
    lh: 22,
    cl: 'var(--neutral-6)',
  },
  'body-1': {
    fw: 400,
    fs: 16,
    cl: 'var(--neutral-10)',
  },
  'body-2': {
    fw: 400,
    fs: 16,
    cl: 'var(--neutral-10)',
  },
  'body-3': {
    fw: 400,
    fs: 14,
    lh: 23,
    cl: 'var(--neutral-10)',
  },
  'body-4': {
    fw: 400,
    fs: 13,
    lh: 22,
    cl: 'var(--neutral-7)',
  },
  'body-5': {
    fw: 400,
    fs: 16,
    lh: 26,
  },
  'body-6': {
    fw: 600,
    fs: 16,
    lh: 26,
    cl: 'var(--white)',
  },
  'body-7': {
    fw: 700,
    fs: 14,
    lh: 23,
    cl: 'var(--neutral-6)',
  },
  'body-8': {
    fw: 600,
    fs: 12,
    lh: 20,
  },
  'heading-6': {
    fw: 700,
    fs: 22,
    lh: 35,
    cl: 'var(--neutral-10)',
  },
  'heading-7': {
    fw: 700,
    fs: 16,
    lh: 26,
    cl: 'var(--gray-700)',
  },
};

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  color?: string;
  fontWeight?: 400 | 500 | 600 | 700;
  fontSize?: number;
  lineHeight?: number;
  variant?: TypoVariant;
}

type TypographyStyles = {
  fw?: number;
  fs?: number;
  lh?: number;
  cl?: string;
};

const TypographyDefault = styled.p<
  TypographyStyles & {
    variant: TypoVariant;
  }
>`
  margin-bottom: 0px;
  ${({ fw, fs, lh, cl, variant }) =>
    `
      font-weight: ${fw || TYPO_CONFIG[variant].fw};
      font-size: ${fs || TYPO_CONFIG[variant].fs}px;
      line-height: ${lh || TYPO_CONFIG[variant].lh}px;
      color: ${cl || TYPO_CONFIG[variant].cl};
    `}
`;

const getTypoColor = (color?: string) => {
  if (color && !color.includes('#') && !color.includes('rgb')) {
    return `var(--${color})`;
  }
  return color;
};

const Typography: React.FC<Props> = ({
  color,
  fontWeight,
  fontSize,
  lineHeight,
  children,
  variant = 'default',
  ...otherProps
}) => {
  return (
    <TypographyDefault
      className={clsx(color)}
      fw={fontWeight}
      fs={fontSize}
      lh={lineHeight}
      cl={getTypoColor(color)}
      variant={variant}
      {...otherProps}
    >
      {children}
    </TypographyDefault>
  );
};

export default Typography;

export const EmptyTableCell = (
  <Typography className="number-width-5" variant="body-5" color="neutral-6">
    -
  </Typography>
);
