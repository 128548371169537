import styled from 'styled-components';

import SelectTippy, { SelectTippyProps } from '../Tippy/SelectTippy';
import clsx from 'clsx';

const Label = styled.div<{ disabled?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
  ${({ disabled }) => (disabled ? `color:var(--neutral-6)` : `color:var(--gray-700)`)}
`;
interface Props extends SelectTippyProps {
  label?: string;
  rootClass?: string;
  isRequired?: boolean;
  isDisable?: boolean;
  styleRoot?: React.CSSProperties;
}
const FormSelect: React.FC<Props> = ({
  label,
  rootClass,
  isRequired = false,
  isDisable,
  styleRoot,
  ...propSelect
}) => {
  return (
    <div
      className={clsx('fv-row', rootClass)}
      style={{
        marginBottom: 24,
        ...styleRoot,
      }}
    >
      {label && (
        <Label disabled={isDisable} className={clsx('mb-2', isRequired ? 'required' : '')}>
          {label}
        </Label>
      )}
      <SelectTippy {...propSelect} isDisable={isDisable} />
    </div>
  );
};

export { FormSelect };
