import React, { ReactNode, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import { LoadingCard } from '../Table/loading/LoadingCard';
import Typography from '../Typography';
import ModalDefault, { ModalProps } from './ModalDefault';

const ModalHeader = styled.div`
  position: sticky;
  background-color: var(--white);
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
const Body = styled.div`
  display: flex;
  height: 88px;
  padding: 16px 8px;
`;

interface FormModalProps extends ModalProps {
  Heading?: ReactNode;
  showNoData?: boolean;
  showLoading?: boolean;
}
type Ref = any;

export const FormModal = React.forwardRef<Ref, FormModalProps>(
  ({ Heading, showNoData, showLoading, children, ...props }, ref) => {
    const divRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        if (divRef.current) {
          divRef.current.scrollTop = 0;
        }
      },
    }));

    return (
      <ModalDefault
        styleModalDialog={{
          minWidth: 800,
        }}
        styleBodyModalDialog={{
          minHeight: '75vh',
          padding: 0,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 'inherit',
        }}
        showHeader={false}
        {...props}
      >
        {Heading ? <ModalHeader>{Heading}</ModalHeader> : null}

        <div style={{ flexGrow: 1, overflow: 'auto' }} ref={divRef}>
          {children}
          {showNoData ? (
            <Body className="d-flex justify-content-center">
              <Typography variant="body-6" color="neutral-6">
                No items
              </Typography>
            </Body>
          ) : null}
        </div>

        {showLoading && <LoadingCard />}
      </ModalDefault>
    );
  },
);
