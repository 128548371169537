import { FC } from 'react';
import styled from 'styled-components';

import ModalDefault from 'app/components/Modal/ModalDefault';
import Typography from 'app/components/Typography';

export const CloseBtn = styled.button`
  height: 43px;
  width: 160px;
  background-color: var(--primary);
  color: var(--white) !important;

  &:hover {
    background-color: var(--primary-8);
  }
`;

export const UnableToRemoveItemModal: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  if (!show) {
    return null;
  }
  return (
    <ModalDefault
      title="Unable to remove item"
      onClickCloseModal={onClose}
      isConfirmationModal
      closeOnBackdrop
    >
      <Typography variant="body-3" style={{ paddingBottom: 12 }}>
        To remove the assigned item from the bin, it needs to meet the following conditions:
      </Typography>

      <Typography variant="body-3">
        <span className="menu-bullet">
          <span
            className="bullet bullet-dot"
            style={{
              backgroundColor: 'var(--neutral-10)',
              marginRight: 8,
              marginBottom: 2,
            }}
          ></span>
        </span>
        The item’s quantity in the bin needs to be 0.
      </Typography>

      <Typography variant="body-3">
        <span className="menu-bullet">
          <span
            className="bullet bullet-dot"
            style={{
              backgroundColor: 'var(--neutral-10)',
              marginRight: 8,
              marginBottom: 2,
            }}
          ></span>
        </span>
        This bin needs to not exist in any stock run (except for completed stock run)
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 48,
        }}
      >
        <CloseBtn onClick={onClose} className="btn">
          Cancel
        </CloseBtn>
      </div>
    </ModalDefault>
  );
};
