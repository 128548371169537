import Typography from 'app/components/Typography';

import { ReactComponent as IconEmpty } from 'app/assets/icons/icon-empty-item.svg';

interface Props {
  label: string;
}
const EmptyCard: React.FC<Props> = ({ label }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{
        padding: '100px 0px',
      }}
    >
      <IconEmpty />
      <Typography
        style={{
          marginTop: 28,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};
export default EmptyCard;
