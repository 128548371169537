import { Dispatch, ReactNode, SetStateAction } from 'react';

import { ItemForUpdateListView } from './constants';

export type WithChildren = {
  children?: ReactNode;
};

export type ID = undefined | null | number | string;

export enum TYPE_MODAL {
  NONE,
  ADD,
  EDIT,
  DELETE,
  RESET_PASSWORD,
  STATUS,
  ASSIGN_ITEM,
  REMOVE_ITEM,
  INFO,
  GENERATE_CODE,
  CONNECT_TABLET,
  UNABLE_DELETE_UNIT,
  IN_SESSION,
  SHORT_FOR,
  UNABLE_CHANGE_ACTIVE_STATUS,
  EDIT_QUANTITY,
}

export type PageLimit = 1 | 10 | 30 | 50 | 100 | 9999999;

export type PaginationState = {
  page: number;
  limit: number;
  links?: Array<{
    label: string;
    active: boolean;
    url: string | null;
    page: number | null;
  }>;
};

export type SortState = {
  orderBy?: string;
  orderDirection?: 'ASC' | 'DESC';
};

export type FilterState = {
  filter?: any;
};

export type SearchState = {
  search?: string;
};

export type Response<T> = {
  data?: T;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
    pagination?: PaginationState;
  };
};

export type QueryState = PaginationState &
  SortState &
  FilterState & {
    type?: number;
  } & SearchState & {
    param?: string;
    search?: string;
  };

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
  setState: (updates: QueryState) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {
    //do nothing
  },
  setState: () => {
    //do nothing
  },
};

export type QueryResponseContextProps<T> = {
  response?: Response<T>;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = {
  refetch: () => {
    //do nothing
  },
  isLoading: false,
  query: '',
};

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForUpdate?: ItemForUpdateListView;
  setItemForUpdate: Dispatch<SetStateAction<ItemForUpdateListView>>;
  isAllSelected: boolean;
  disabled: boolean;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {
    //do nothing
  },
  onSelectAll: () => {
    //do nothing
  },
  clearSelected: () => {
    //do nothing
  },
  setItemForUpdate: () => {
    //do nothing
  },
  isAllSelected: false,
  disabled: false,
};

export interface ActionTable {
  type: TYPE_MODAL;
  name: string;
  redirect?: string;
  titleModal?: string;
}

export type TableSpacing = 'xxxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'none';
