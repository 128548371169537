import { useParams } from 'react-router-dom';

import BodyFormItem from './components/BodyFormItem';

const EditItemPage = () => {
  const params = useParams<any>();

  return <BodyFormItem itemId={Number(params.id)} />;
};

export default EditItemPage;
