import { toast } from 'react-toastify';

import { ID, Response } from 'app/components/Table/core/types';

import { getResponseError } from 'app/helpers/errors';
import { throttle } from 'lodash';

import { API_URL } from 'app/constants/environments';

import { useRackStore } from '../../racks/store';
import { Bin, BinsQueryResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const BIN_URL = `${API_URL}/api/bins`;
const BIN_URL_V2 = `${API_URL}/api/bins-v2`;
const fetchGetBinInRack = (query: string): Promise<BinsQueryResponse> => {
  return axios.get(`${BIN_URL_V2}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Bin>;
          total: number;
        };
      }>,
    ) => {
      useRackStore.getState().setState({ savedItems: [] });
      return {
        data: d.data.data,
      };
    },
  );
};

const getBinInRack = throttle(fetchGetBinInRack, 1000);

const getBin = (binId: ID): Promise<Bin | undefined> => {
  return axios
    .get(`${BIN_URL}/${binId}`)
    .then((response: AxiosResponse<Response<Bin>>) => response.data)
    .then((response: Response<Bin>) => response.data);
};

const updateBin = (
  binId: ID,
  request: {
    itemId: number;
  },
): Promise<Bin | undefined> => {
  return axios
    .patch(`${BIN_URL}/${binId}/items`, request)
    .then((response: AxiosResponse<Response<Bin>>) => response.data)
    .then((response: Response<Bin>) => response.data);
};

const updateQuantity = (
  binId: ID,
  request: {
    newQty: number;
    itemId: number;
  },
  callback: () => void,
): Promise<void> => {
  return axios
    .post(`${BIN_URL}/${binId}/set-quantity`, request)
    .then(() => {
      toast.success('Edited quantity successfully!');
      callback();
    })
    .catch((errors) => {
      getResponseError(errors);
    });
};

const deleteBin = async (
  binId: ID,
  itemId: ID,
  onSuccess: () => void,
  onError: (error: any) => void,
) => {
  axios
    .delete(`${BIN_URL}/${binId}/items`, {
      data: {
        itemId: itemId,
      },
    })
    .then(onSuccess)
    .catch(onError);
};

export { getBinInRack, getBin, updateBin, deleteBin, updateQuantity };
