import { RadioInput } from 'app/components/Input/RadioInput';

import { STOCK_TYPE } from '../core/constants';

interface Props {
  stockType: {
    value: number;
    setValue: (newValue: number) => void;
  };
  isDisable: boolean;
  isInItemForm?: boolean;
}
const StockTypeCard: React.FC<Props> = ({ isDisable, stockType, isInItemForm }) => {
  return (
    <div className={isInItemForm ? 'd-flex' : 'position-absolute bottom-0'}>
      {STOCK_TYPE.map((stock, index) => {
        const checked = Boolean(Number(stockType.value) === stock.value);
        return (
          <div className="me-10">
            <RadioInput
              key={index}
              className="form-check-input me-1"
              type="radio"
              value={stock.value}
              checked={checked}
              onChange={() => {
                if (!isDisable) {
                  stockType.setValue(stock.value);
                }
              }}
              label={stock.title}
            />
          </div>
        );
      })}
    </div>
  );
};
export default StockTypeCard;
