import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row } from 'react-table';
import styled from 'styled-components';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { QueryState } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { formatDate } from 'app/helpers';
import { useNumber } from 'app/hooks';
import { cloneDeep, orderBy } from 'lodash';

import { PATH } from 'app/constants/path';

import EmptyLogCard from './components/EmptyLogCard';
import HeaderRackActivityLogCard from './components/HeaderRackActivityLogCard';
import ListActivityLogCard from './components/ListActivityLogCard';
import { activityLogStockUpAndPickUpColumns, activityLogUpdateRackColumns } from './core/columns';
import { EventTypeLogRack, TypeLog } from './core/enum';
import {
  getActivityLogDetail,
  getRack,
  getRackActivityLogs,
  getRackActivityLogsList,
} from './core/requests';
import { LogDetail, LogItemDetail, Rack, emptyRack } from './core/type';
import { ScrollTopComponent } from 'theme/assets/ts/components';

const LogTableCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  overflow: auto;
`;

const RackLogPage = () => {
  const params = useParams();
  const rackId = parseInt(params.rackId!);
  const navigate = useNavigate();

  const [rack, setRack] = useState<Rack>(emptyRack);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<LogDetail[]>([]);
  const [logListItems, setLogListItems] = useState<{
    [key: string]: LogItemDetail[];
  }>({});
  const [selectLogItem, setSelectLogItem] = useState<LogItemDetail>({
    eventType: EventTypeLogRack.UpdateRack,
    id: 0,
    updatedAt: '',
    user: {
      firstName: '',
      lastName: '',
    },
  });
  const [query, setQuery] = useState<QueryState>({
    limit: 10,
    page: 1,
    orderBy: 'bin',
    orderDirection: 'ASC',
  });
  const [openMenu, setOpenMenu] = useState<{
    [key: string]: boolean;
  }>({});

  const currentLoadPage = useNumber(1);

  useEffect(() => {
    const getRackDetail = async () => {
      if (rackId) {
        try {
          const dataRes = await getRack(rackId);
          if (dataRes) {
            setRack(dataRes);
            return;
          }
          navigate(PATH.RACKS);
        } catch (error) {
          navigate(PATH.RACKS);
        }
      }
    };

    getRackDetail();
  }, [navigate, rackId]);

  useEffect(() => {
    if (!rack.id) {
      return;
    }
    const getActivityLogs = async () => {
      if (rack.id) {
        const activityLogs = await getRackActivityLogs(rack.id);
        if (activityLogs) {
          const newLogs: LogDetail[] = [];
          activityLogs.months.forEach((date) => {
            newLogs.push({
              ...date,
              type: TypeLog.months,
            });
          });
          activityLogs.dates.forEach((date) => {
            newLogs.push({
              ...date,
              type: TypeLog.dates,
            });
          });
          const orderByLogs = orderBy(newLogs, ['log_to_month', 'log_to_day'], ['desc', 'desc']);
          setLoading(false);
          if (orderByLogs.length) {
            onClickAccordion(orderByLogs[0], true)();
          }
          setLogs(orderByLogs);
        }
      }
    };

    getActivityLogs();
  }, [rack]);

  const onClickAccordion =
    (newLog: LogDetail, isSaveSelectLogItem = false, newOpenMenu = true) =>
    async () => {
      const isOpen = newLog.log_to_day ?? newLog.log_to_month;
      if (isOpen === undefined) {
        return;
      }
      currentLoadPage.setValue(1);
      const newLogListItems = cloneDeep(logListItems);
      ScrollTopComponent.goTop();
      setOpenMenu({
        [isOpen]: newOpenMenu,
      });
      if (newLogListItems[newLog.log_to_day]) {
        return;
      }
      const dataRes = await getRackActivityLogsList(
        rack.id,
        newLog.log_to_day,
        newLog.log_to_month,
      );
      if (dataRes.data) {
        newLogListItems[isOpen] = dataRes.data.items;
        if (isSaveSelectLogItem && dataRes.data.items.length > 0) {
          setLoading(true);
          setSelectLogItem(dataRes.data.items[0]);
          updateQuery(dataRes.data.items[0]);
        }
      }
      setLogListItems(newLogListItems);
      setLoading(false);
    };

  const onLoadMore = (log: LogDetail) => async () => {
    const dataRes = await getRackActivityLogsList(
      rack.id,
      log.log_to_day,
      log.log_to_month,
      currentLoadPage.value + 1,
    );
    if (dataRes?.data?.items?.length) {
      const isOpen = log.log_to_day ?? log.log_to_month;
      const newLogListItems = cloneDeep(logListItems);
      newLogListItems[isOpen].push(...dataRes.data.items);
      setLogListItems(newLogListItems);
      currentLoadPage.setValue(currentLoadPage.value + 1);
    }
  };

  const onClickViewTableLog = (newItem: LogItemDetail) => () => {
    if (newItem.id === selectLogItem.id) return;
    setLoading(true);
    setSelectLogItem(newItem);
    updateQuery(newItem, true);
    ScrollTopComponent.goTop();
  };
  const updateQuery = (item: LogItemDetail, clearSearch?: boolean) => {
    const newQuery = cloneDeep(query);
    newQuery.param = item.id?.toString();
    newQuery.orderBy = item.eventType === EventTypeLogRack.UpdateRack ? 'updatedAt' : 'bin';
    newQuery.orderDirection = item.eventType === EventTypeLogRack.UpdateRack ? 'DESC' : 'ASC';

    setQuery({ ...newQuery, search: clearSearch ? '' : undefined });
  };
  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };

  if (!rack.id) {
    return null;
  }

  const searchPlaceholder =
    selectLogItem.eventType === EventTypeLogRack.UpdateRack
      ? 'Search with action and keyword'
      : 'Search with bin and item';

  const selectedColumn =
    selectLogItem.eventType === EventTypeLogRack.UpdateRack
      ? activityLogUpdateRackColumns()
      : activityLogStockUpAndPickUpColumns(selectLogItem);

  return (
    <RootContentPage
      title=""
      header={<HeaderRackActivityLogCard rack={rack} />}
      rootBody={{
        minHeight: 'calc(100vh - 147px)',
        display: 'flex',
      }}
    >
      {logs.length ? (
        <React.Fragment>
          <ListActivityLogCard
            logs={logs}
            logListItems={logListItems}
            selectLogItem={selectLogItem}
            openMenu={openMenu}
            onClickViewTableLog={onClickViewTableLog}
            onClickAccordion={onClickAccordion}
            onLoadMore={onLoadMore}
          />
          <LogTableCard>
            <div
              className="d-flex align-items-center"
              style={{
                height: 67,
                borderBottom: `1px solid var(--neutral-3)`,
                padding: '0px 28px',
                width: '100%',
              }}
            >
              <Typography variant="body-6" color="neutral-10">
                {formatDate(selectLogItem.updatedAt, 'dd MMM yyyy, H:mm:ss')}
              </Typography>
            </div>

            {Boolean(selectLogItem.id) && (
              <TableComponent
                getList={getActivityLogDetail(() => {
                  setLoading(false);
                })}
                nameQuery={QUERIES.ACTIVITY_LOG_LIST}
                originColumns={selectedColumn}
                titleModal="Item"
                placeholderSearch={searchPlaceholder}
                styleModalForm={{
                  paddingRight: 0,
                }}
                onClickBodyTable={onClickBodyTable}
                paramId={rackId}
                isBorder={false}
                query={query}
                isRowClickable={false}
                tableContainerStyle={{ opacity: loading ? 0 : 1 }}
              />
            )}
          </LogTableCard>
        </React.Fragment>
      ) : (
        <EmptyLogCard />
      )}
      {loading && <LoadingCard />}
    </RootContentPage>
  );
};
export default RackLogPage;
