import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { BinItem } from 'app/modules/inventory/bins/components/BinDetailCard';

import { blockInvalidCharacters } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { groupBy, orderBy } from 'lodash';

import ActionButtonCard, {
  ActionButtonWrapper,
} from '../../configuration/components/ActionButtonCard';
import { NameSetting } from '../../configuration/core/enum';
import { createSetting } from '../../configuration/core/request';
import { SettingType } from '../../configuration/core/type';
import { RackZeroWeightSetting, SenSorRow } from '../core/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const DEFAULT_SENSOR_ROWS: ReadonlyArray<SenSorRow> = ['R1', 'R2-3', 'R4-5-6', 'R7'];

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const orderData = (data: RackZeroWeightSetting[]) =>
  data.map((rack) => ({
    rackId: rack?.rackId,
    settings: orderBy(rack?.settings || [], ['row', 'column']),
  })) || [];

interface Props {
  index: number;
  originalData: RackZeroWeightSetting[];
  refetch: () => void;
}

const validateSchema = () =>
  Yup.array().of(
    Yup.object().shape({
      settings: Yup.array().of(
        Yup.object().shape({
          value: Yup.number(),
        }),
      ),
    }),
  );

const RackZeroWeightEditCard: FC<Props> = ({ index, originalData, refetch }) => {
  const rackSensorRows =
    (Object.keys(groupBy(originalData[index]?.settings || [], 'row')) as SenSorRow[]) ||
    DEFAULT_SENSOR_ROWS;

  const [formRequest, setFormRequest] = useState<RackZeroWeightSetting[]>(orderData(originalData));

  useEffect(() => {
    const orderedData = orderData(originalData);
    setFormRequest(orderedData);
    formik.setValues(orderedData);
  }, [originalData]);

  const isEdit = useBoolean(false);

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: validateSchema(),
    onSubmit: async (data, { setSubmitting }) => {
      console.log(data);
      try {
        await createSetting({
          name: NameSetting.zeroWeight,
          value: JSON.stringify(data),
          type: SettingType.binSensor,
        });
        setFormRequest(data);
        toast.success('Update W0 setting successfully!');
        refetch();
      } catch (error) {
        getResponseError(error);
      } finally {
        isEdit.setValue(false);
        setSubmitting(false);
      }
    },
  });

  const errors: any = formik.errors;

  const onCancel = () => {
    isEdit.setValue(false);
    formik.setValues(formRequest);
  };
  useEffect(onCancel, [index]);

  const getRowInfo = (row: SenSorRow, col) => {
    if (row) {
      return formRequest[index]?.settings?.findIndex(
        (setting) => setting.row === row && setting.column === col,
      );
    }
    return -1;
  };

  const getRowName = (row: SenSorRow) => {
    if (row.length <= 2) return row;
    return `R[${row.slice(1)}]`;
  };

  if (!formRequest.length || !formRequest[index]?.settings?.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <Typography className="label" variant="body-3">
          No record found
        </Typography>
      </div>
    );
  }

  return (
    <Main>
      <form
        id={`form-${formRequest[index]?.rackId}`}
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            marginBottom: 16,
          }}
        >
          <div>
            <Typography variant="body-1">Edit W0 values for each sensors</Typography>
          </div>
          <ActionButtonWrapper style={{ marginBottom: 18 }}>
            <ActionButtonCard
              isEdit={isEdit.value}
              onCancel={onCancel}
              disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
              isSubmitting={formik.isSubmitting}
              onClickEdit={() => isEdit.setValue(true)}
            />
          </ActionButtonWrapper>
        </div>
        <div className="d-flex flex-column">
          {rackSensorRows.map((row) => {
            const rowEl = formRequest[index]?.settings.filter((el) => el.row === row) || [];
            return (
              <div className="d-flex flex-row gap-5" key={row}>
                {rowEl.map((el, colIndex) => (
                  <BinItem
                    style={{ width: 180, marginBottom: 16, cursor: 'default' }}
                    key={el.column + el.row}
                  >
                    <Typography
                      variant="body-6"
                      color={!isEdit.value ? 'neutral-6' : 'neutral-10'}
                      style={{
                        marginLeft: 8,
                        marginTop: 7,
                        marginBottom: 12,
                      }}
                    >
                      {`${getRowName(row)}C${colIndex + 1}`}
                    </Typography>
                    <div className="d-flex flex-row">
                      <FormInput
                        placeholder=""
                        formik={formik}
                        field={`[${index}].settings[${getRowInfo(row, el.column)}].value`}
                        isDisable={!isEdit.value}
                        typeInput="number"
                        touched={true}
                        onKeyDown={(e) => blockInvalidCharacters(e, 'negativeFloat')}
                        messageError={errors[index]?.settings?.[getRowInfo(row, el.column)]?.value}
                        defaultValue="0"
                      />
                      <Typography
                        variant="body-3"
                        color="neutral-7"
                        style={{
                          marginLeft: 5,
                          marginTop: 9,
                        }}
                      >
                        g
                      </Typography>
                    </div>
                  </BinItem>
                ))}
              </div>
            );
          })}
        </div>
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </Main>
  );
};

export default RackZeroWeightEditCard;
