import { Column } from 'react-table';

import { RadioInput } from 'app/components/Input/RadioInput';
import StatusDefault from 'app/components/Status/StatusDefault';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import Typography from 'app/components/Typography';

import { formatCustomDate, getLabelAction, isActiveLabelAction } from 'app/helpers';

import ItemInfoCard from '../../items/components/ItemInfoCard';
import SKUCard from '../../items/components/SKUCard';
import { UserInfo } from '../../items/components/UserInfo';
import { Item } from '../../items/core/type';
import { QtyTypography } from '../../racks/components/Typography';
import { HistoryBin } from './type';

export const historyBinColumns = (): ReadonlyArray<Column<HistoryBin>> => {
  return [
    {
      id: 'expriryDate',
      accessor: 'expriryDate',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].expriryDate)}
        </Typography>
      ),
    },
    {
      id: 'item',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].inventory?.image || ''}
          name={props.data[props.row.index].inventory?.name || ''}
          stockType={props.data[props.row.index].inventory?.stockType}
          isNotNavigatable={true}
        />
      ),
    },
    {
      id: 'skuId',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      width: '15%',
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].inventory?.skuId}
          skuSub={props.data[props.row.index].inventory?.skuSub}
        />
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      Header: (props) => <CustomHeader tableProps={props} title="Action" />,
      width: '100px',
      Cell: ({ ...props }) => (
        <StatusDefault
          isActive={isActiveLabelAction(Number(props.data[props.row.index].quantity))}
          label={getLabelAction(Number(props.data[props.row.index].quantity))}
          action={props.data[props.row.index].movementType}
        />
      ),
    },
    {
      id: 'qtyToStockUp',
      accessor: 'qtyToStockUp',
      Header: (props) => <CustomHeader tableProps={props} title="Stock on hand" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {Number(props.data[props.row.index].qtyToStockUp)}
        </Typography>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props) => <CustomHeader tableProps={props} title="Adjustment qty" />,
      width: '10%',
      Cell: ({ ...props }) => <QtyTypography qty={props.data[props.row.index].quantity ?? ''} />,
    },
    {
      id: 'qtyAvailable',
      accessor: 'qtyAvailable',
      Header: (props) => <CustomHeader tableProps={props} title="Available qty" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].qtyAvailable}
        </Typography>
      ),
    },
    {
      id: 'user',
      accessor: 'episode',
      Header: (props) => <CustomHeader tableProps={props} title="By user" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <UserInfo
          userName={props.data[props.row.index].userName}
          roleName={props.data[props.row.index].roleName}
          movementType={props.data[props.row.index].movementType}
        />
      ),
    },
  ];
};

export const itemColumns = (): ReadonlyArray<Column<Item>> => {
  return [
    {
      id: 'name',
      accessor: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Item name" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].image || ''}
          name={props.data[props.row.index].name || ''}
          stockType={props.data[props.row.index].stockType}
          nameStyle={{ whiteSpace: 'normal', width: 212 }}
        />
      ),
      width: 324,
    },
    {
      id: 'skuId',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].skuId}
          skuSub={props.data[props.row.index].skuSub}
        />
      ),
      width: 160,
    },
    {
      Header: () => <div style={{ width: 156 }}></div>,
      id: 'radio',
      Cell: ({ ..._props }, selected: boolean) => (
        <RadioInput
          className="form-check-input"
          type="radio"
          labelAlign="left"
          checked={selected}
        />
      ),
    },
  ];
};
