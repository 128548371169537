import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import InfoRackItemCard from './components/InfoRackItemCard';
import { historyRackColumns } from './core/columns';
import { getHistory, getRack } from './core/requests';
import { Rack, emptyRack } from './core/type';

const RackHistoryPage = () => {
  const params = useParams();
  const goBack = usePageGoBack(PATH.RACK_DETAIL.replace(':rackId', String(params?.rackId || '')));
  const [rack, setRack] = useState<Rack>(emptyRack);
  const isLoading = useBoolean(false);
  useEffect(() => {
    if (!params?.rackId) {
      return;
    }

    getDataRack(Number(params.rackId));
  }, [params.rackId]);

  const getDataRack = async (rackId: number) => {
    try {
      isLoading.setValue(true);
      const dataRes = await getRack(rackId);
      if (dataRes) {
        setRack(dataRes);
        return;
      }
      goBack();
    } catch (error) {
      goBack();
    } finally {
      isLoading.setValue(false);
    }
  };
  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex align-items-center">
          <IconRedirect
            style={{
              cursor: 'pointer',
            }}
            onClick={goBack}
          />
          <div
            className="h-100 d-flex align-items-center"
            style={{
              borderRight: `1px solid var(--neutral-4)`,
              paddingRight: 24,
            }}
          >
            <Typography variant="heading-6">Item history</Typography>
          </div>
          <InfoRackItemCard label="Rack" value={rack.name} />
        </div>
      }
    >
      <TableComponent
        getList={getHistory}
        nameQuery={QUERIES.HISTORY_RACK_LIST}
        originColumns={historyRackColumns()}
        onClickBodyTable={onClickBodyTable}
        defaultState={{
          limit: 10,
          page: 1,
          orderBy: 'expriryDate',
          orderDirection: 'DESC',
        }}
        paramId={params.rackId || ''}
        isBorder={false}
        isRowClickable={false}
        placeholderSearch="Search with bin, item, SKU"
      />
    </RootContentPage>
  );
};
export default RackHistoryPage;
