import { useMemo, useState } from 'react';
import { Row } from 'react-table';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { isUndefined } from 'lodash';

import { PATH } from 'app/constants/path';

import { Bin } from '../../bins/core/type';
import { StatusStockUp } from '../../stock-run/core/enum';
import { StockRun } from '../../stock-run/core/type';
import { shortForColumns } from '../core/columns';
import { addItemToStockRun, getShortForStockRuns } from '../core/requests';
import { stringify } from 'qs';

interface Props {
  onClickCloseModal?: (updateBin?: Bin) => void;
  selectBin: Bin | null;
  wardId: ID;
  wardName: string;
}

export const ShortForModal: React.FC<Props> = ({
  selectBin,
  onClickCloseModal,
  wardId,
  wardName,
}) => {
  const { refetch } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const [selectItem, setSelectItem] = useState<StockRun | null>(null);
  const isLoading = useBoolean();
  const isLoadingSubmit = useBoolean();
  const navigate = useNavigateWithSendPath();

  const onSubmit = async () => {
    if (!selectBin?.id || !selectItem?.id) {
      return;
    }
    isLoadingSubmit.setValue(true);
    try {
      await addItemToStockRun(selectItem.id, {
        itemId: selectBin.item?.id,
        binId: selectBin?.id,
      });
      toast.success(`Added item to stock run successfully!`);
      if (selectItem.status !== StatusStockUp.Draft) {
        toast.info(
          <div className="d-flex align-items-center">
            <Typography variant="body-3">
              Stock run has been switched back to Draft status.
            </Typography>
          </div>,
        );
      }
      navigate(PATH.INVENTORY_STOCK_RUN_EDIT.replace(':id', String(selectItem.id)));
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoadingSubmit.setValue(false);
    }
  };
  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onClickCloseModal)) {
      onClickCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const onClickBodyTable = (row: Row) => () => {
    const newItem = row.original as StockRun;
    setSelectItem(newItem);
  };

  return (
    <ModalDefault
      title={`Add the selected item to${wardName ? ' ward ' + wardName : ''}`}
      onClickCloseModal={() => cancel()}
      childrenAction={
        <ActionButton
          onCancel={() => cancel()}
          disableButton={isLoadingSubmit.value || !selectItem}
          isSubmitting={isLoadingSubmit.value}
          labelButtonSubmit="Add"
          onSubmit={onSubmit}
        />
      }
      styleModalDialog={{ minWidth: 760 }}
      modalContentStyle={{ height: 'calc(100vh - 112px)' }}
      styleBodyModalDialog={{
        padding: 0,
        flexGrow: 1,
        height: 0,
        maxHeight: 'none',
        overflow: 'hidden',
      }}
    >
      <TableComponent
        originColumns={useMemo(shortForColumns, [])}
        moreQuery={`&${stringify({ wardId })}`}
        nameQuery="stock-run-to-add"
        getList={getShortForStockRuns}
        isBorder={false}
        onClickBodyTable={onClickBodyTable}
        placeholderSearch="Search with stock run index and status"
        choseRowId={Number(selectItem?.id)}
        spacing={'sm'}
        isLoadMore={true}
        isSortAndSearchFrontEnd
        tableContainerStyle={{ flexGrow: 1, height: 0 }}
        customSearchFields={['index', 'statusName']}
        cardStyle={{ height: '100%' }}
        tableContentStyle={{ height: '100%' }}
        theadStyle={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--white)',
          boxShadow: `inset 0px -1px 0 var(--neutral-3)`,
        }}
        tableStyle={{ tableLayout: 'fixed' }}
        isSortLowerCase
      />

      {isLoading.value || isLoadingSubmit.value ? <LoadingCard /> : null}
    </ModalDefault>
  );
};
