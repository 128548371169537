import { toast } from 'react-toastify';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { cloneDeep } from 'lodash';

import { DEFAULT_SHIFTS } from '../core/constants';
import { NameSetting } from '../core/enum';
import { updateSetting } from '../core/request';
import { SettingType, TimeFrame, WorkShift } from '../core/type';
import { getTimeFromDate, stringifyTimeFrame } from '../core/utils';
import ActionButtonCard, { ActionButtonWrapper } from './ActionButtonCard';
import TimeSelectionItem from './TimeSelectionItem';

const Main = styled.div`
  height: auto;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  margin-top: 4px;
`;

interface Props {
  workShift: WorkShift | null;
  shifts: TimeFrame[];
  setShifts: (shifts: TimeFrame[]) => void;
}

const SHIFT_NAMES = ['Morning shift', 'Afternoon shift', 'Night shift'];

const WorkShiftSettingCard: React.FC<Props> = ({ workShift, setShifts, shifts }) => {
  const isEdit = useBoolean(false);

  const isSubmitting = useBoolean(false);

  const onChangeTime = (key: 'from' | 'to', index: number) => (newTime: Date) => {
    const newData = cloneDeep(shifts);
    newData[index][key] = newTime;
    setShifts(newData);
  };

  const onCancel = () => {
    isEdit.setValue(false);
    const shiftSettings = workShift?.value || DEFAULT_SHIFTS;
    setShifts(
      shiftSettings.map((el) => ({
        from: getTimeFromDate(el.from),
        to: getTimeFromDate(el.to),
      })),
    );
  };

  const onSubmit = async () => {
    isSubmitting.setValue(true);

    try {
      await updateSetting(workShift?.id, {
        name: NameSetting.workShift,
        type: SettingType.workShift,
        value: stringifyTimeFrame(shifts),
      });
      isEdit.setValue(false);
      toast.success('Update setting Work Shift Hours successfully!');
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isSubmitting.setValue(false);
    }
  };

  return (
    <Main>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{
          marginBottom: 32,
          width: '100%',
        }}
      >
        <div className="d-flex flex-column">
          <Typography variant="body-6" color="gray-700">
            Work shift setting
          </Typography>
          <Typography variant="body-3" color="neutral-7">
            The pickup sessions and stock runs in the Dashboard page will be sorted according to the
            work shifts you set up here.
          </Typography>
        </div>

        <ActionButtonWrapper>
          <ActionButtonCard
            isEdit={isEdit.value}
            onCancel={onCancel}
            disableButton={Boolean(isSubmitting.value)}
            isSubmitting={isSubmitting.value}
            onClickEdit={() => isEdit.setValue(true)}
            onSubmit={onSubmit}
          />
        </ActionButtonWrapper>
      </div>
      {shifts.map((shift, index) => (
        <div
          key={index}
          className="d-flex flex-column"
          style={{ marginBottom: index === 2 ? 0 : 32 }}
        >
          <Typography
            fontSize={14}
            lineHeight={23}
            fontWeight={700}
            color={isEdit.value ? 'neutral-10' : 'neutral-6'}
            style={{ marginBottom: 4 }}
          >
            {SHIFT_NAMES[index]}
          </Typography>

          <div className="d-flex align-items-center">
            <TimeSelectionItem
              startTime={shift.from}
              endTime={shift.to}
              setStartTime={onChangeTime('from', index)}
              setEndTime={onChangeTime('to', index)}
              isEdit={isEdit.value}
              index={index}
              shifts={shifts}
            />
          </div>
        </div>
      ))}
    </Main>
  );
};

export default WorkShiftSettingCard;
