import { FC } from 'react';
import styled from 'styled-components';

import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import Typography, { EmptyTableCell } from 'app/components/Typography';

import { StockTypeEnum } from 'app/modules/inventory/items/core/enum';
import ShowChartButton from 'app/modules/reporting/components/ShowChartButton';
import { getStockHealth } from 'app/modules/reporting/core/columns';

import { approveStatusSelector, useProjectionStore } from '../core/store';
import { DemandProjectionItem } from '../core/type';
import ProjectStatus from './ProjectionStatus';
import QtyCheckBox from './QtyCheckBox';
import QuantityByML from './QuantityByML';

const Body = styled.div<{ isApprove: boolean; index: number }>`
  display: flex;
  height: 95px;
  padding: 16px 28px;
  width: 100%;
  background-color: ${(props) => (props.index % 2 === 0 ? 'var(--neutral-1)' : 'var(--white)')};
  :hover {
    background-color: ${(props) => (props.isApprove ? 'var(--neutral-2)' : '')};
  }
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  flex-grow: 1;
`;
const SKU = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 190px;
  padding: 0 12px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  min-width: 90px;
  padding: 0 12px;
`;

const Qty = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  padding: 0 12px;
`;

const RecommendedQty = styled.div`
  display: flex;
  align-items: center;
  min-width: 120px;
  padding: 0 12px;
`;

const QtyBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 130px;
  padding: 0 12px;
`;

const Select = styled.div`
  display: flex;
  width: 190px;
  align-items: center;
  padding: 0 12px;
`;

const ParValue = styled.div`
  display: flex;
  align-items: center;
  min-width: 90px;
  padding: 0 12px;
`;

interface Props {
  item: DemandProjectionItem;
  index: number;
  isApproved: boolean;
  onViewConsumptionChart: () => void;
  onViewBalanceChart: () => void;
}
export const ViewItemInProjection: FC<Props> = ({
  item,
  index,
  isApproved,
  onViewConsumptionChart,
  onViewBalanceChart,
}) => {
  const status = useProjectionStore(approveStatusSelector(item.id));
  return (
    <Body
      style={{
        borderBottom: `1px solid var(--neutral-3)`,
      }}
      isApprove={isApproved}
      index={index}
    >
      <div className="d-flex align-items-center" style={{ minWidth: '50px', padding: '0 12px' }}>
        <Typography variant="body-2" lineHeight={26}>
          {index + 1}
        </Typography>
      </div>
      <ItemName>
        <div className="d-flex">
          <ShowImageDefault
            image={item.image ?? ''}
            styleMain={{ position: 'inherit', height: 40, width: 40, minWidth: 40, minHeight: 40 }}
          />
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: 16,
            }}
          >
            <Typography variant="body-2" lineHeight={26} style={{ maxWidth: 220 }}>
              {item.itemName}
            </Typography>
            <Typography className="text-overflow-ellipsis" variant="body-3" color="neutral-7">
              {StockTypeEnum[Number(item.stockType)]}
            </Typography>
          </div>
        </div>
      </ItemName>

      <SKU>
        <Typography
          className="text-overflow-ellipsis"
          variant="body-2"
          lineHeight={26}
          style={{ width: '100%' }}
        >
          {item.skuId}
        </Typography>
        <Typography
          className="text-overflow-ellipsis"
          variant="body-3"
          color="neutral-7"
          style={{ width: '100%' }}
        >
          {item.skuSub}
        </Typography>
      </SKU>
      <Column>{getStockHealth(item.stockLevel)}</Column>
      <ParValue>
        {item.parValue ? (
          <Typography variant="body-5" color="neutral-7">
            {item.parValue}
          </Typography>
        ) : (
          EmptyTableCell
        )}
      </ParValue>
      <QtyBalance>
        <Typography variant="body-5" color="neutral-7">
          {item.qtyBalance}
        </Typography>
        <ShowChartButton onClick={onViewBalanceChart} type="balance" />
      </QtyBalance>
      <Qty>
        <QuantityByML quantity={item.projectedDemand} approved={status} />
        <ShowChartButton onClick={onViewConsumptionChart} type="forecast" />
      </Qty>
      <QtyBalance style={{ minWidth: 140 }}>
        <Typography variant="body-5" color="neutral-7">
          {item.plannedQty}
        </Typography>
      </QtyBalance>
      <RecommendedQty>
        <QuantityByML quantity={item.recommendedQty} approved={status} />
      </RecommendedQty>
      <Select>
        {isApproved ? (
          <QtyCheckBox
            recommendQty={item.recommendedQty}
            approved={status}
            onChooseValue={useProjectionStore.getState().setApproveStatus(item.id)}
          />
        ) : (
          <ProjectStatus
            isApproved={status !== null}
            label={status ? 'Approved' : 'Not approved'}
          />
        )}
      </Select>
    </Body>
  );
};
