import { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useAuth } from 'app/modules/auth';
import { LOGIN_TYPE } from 'app/modules/auth/core/enum';
import { useChangeParams } from 'app/modules/query-state/hooks';

import { getResponseError } from 'app/helpers/errors';
import { isUndefined } from 'lodash';

import { createSecurityGroups, updateSecurityGroups } from '../../security-groups/core/requests';
import { SecurityGroup, SecurityGroupRequest } from '../../security-groups/core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const FormUsersRole: FC<{
  usersRole: SecurityGroup;
  loginType: LOGIN_TYPE;
  onCloseModal?: (data?: SecurityGroup) => void;
}> = ({ usersRole, loginType, onCloseModal }) => {
  const { currentUser, setCurrentUser } = useAuth();

  const { setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const { onChangeParams } = useChangeParams();
  const [role] = useState<{ name: string; description: string }>({
    name: usersRole ? usersRole.name : '',
    description: usersRole ? usersRole.description : '',
  });

  const formik = useFormik({
    initialValues: role,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Role name is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const request: SecurityGroupRequest = {
        ...values,
        description: 'string',
        type: loginType,
      };
      try {
        if (usersRole) {
          const dataRes = await updateSecurityGroups(usersRole.id, request);
          if (dataRes && currentUser?.role && dataRes.id === currentUser.role.id) {
            setCurrentUser({
              ...currentUser,
              role: {
                ...currentUser.role,
                name: dataRes.name,
              },
            });
          }
          toast.success(`Update role successfully!`);
          if (!isUndefined(onCloseModal) && dataRes) {
            onCloseModal(dataRes);
            return;
          }
          cancel(true);
          return;
        }
        await createSecurityGroups(request);
        toast.success(`Add role successfully!`);

        if (Number(state.page) !== 1) {
          cancel();
          onChangeParams({ page: 1 });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onCloseModal)) {
      onCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };
  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <FormInput
            label="Role name"
            placeholder="Role name"
            formik={formik}
            field="name"
            isRequired
          />
        </div>
        <ActionButton
          onCancel={cancel}
          disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={usersRole ? 'Save' : 'Add'}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};
