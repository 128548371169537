import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { addMinutes } from 'date-fns';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { cloneDeep, includes, pick, remove } from 'lodash';

import { ReactComponent as IconButton } from 'app/assets/icons/icon-button-add.svg';
import { ReactComponent as IconRemove } from 'app/assets/icons/icon-remove-date.svg';

import { Ward } from '../../locations/wards/core/type';
import { NameSetting } from '../core/enum';
import { createSetting } from '../core/request';
import { Setting, SettingType, WardHoursStockRun } from '../core/type';
import ActionButtonCard, { ActionButtonWrapper } from './ActionButtonCard';
import TimeSelectionItem from './TimeSelectionItem';

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  margin-top: 24px;
`;
const ButtonAddWard = styled.button`
  min-width: 133px;
  height: 43px;
  background: var(--neutral-2) !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: var(--neutral-7) !important;
  svg path[stroke] {
    stroke: var(--neutral-7);
  }
`;
const ButtonAddTime = styled.button`
  width: 98px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: var(--primary);
  border: none !important;
  background: white;
  margin: 0px 8px;
`;

interface Props {
  wards: Ward[];
  operationalHours: Setting[];
  currentSelectedSetting: SettingType | null;
  setCurrentSelectedSetting: (payload: SettingType) => void;
}
const OperationalHoursInWardCard: React.FC<Props> = ({
  wards,
  operationalHours,
  currentSelectedSetting,
  setCurrentSelectedSetting,
}) => {
  const isEdit = useBoolean(false);

  const isAddWard = useBoolean(false);

  const [currentWardHours, setCurrentWardHours] = useState<WardHoursStockRun[]>([]);
  const [originWardHours, setOriginWardHours] = useState<WardHoursStockRun[]>([]);
  const isSubmitting = useBoolean(false);

  useEffect(() => {
    if (!operationalHours.length || !wards.length) {
      return;
    }
    const newData: WardHoursStockRun[] = operationalHours.map((el) => {
      const wardDetail = wards.find((ward) => ward.id === el.wardId);
      return {
        nameWard: wardDetail ? wardDetail.name : '',
        wardId: Number(el.wardId),
        shifts:
          el.shifts && el.shifts.length
            ? el.shifts?.map((shift) => {
                return {
                  from: new Date(shift.from),
                  to: new Date(shift.to),
                };
              })
            : [],
      };
    });
    setCurrentWardHours(newData);
    setOriginWardHours(newData);
  }, [JSON.stringify(operationalHours), JSON.stringify(wards)]);
  const defaultTime = {
    from: setHours(setMinutes(new Date(), 0), new Date().getHours()),
    to: setHours(setMinutes(new Date(), 0), new Date().getHours() + 1),
  };
  const onChangeSelectWard = (originWard: Ward) => () => {
    const newAddWardHours = cloneDeep(currentWardHours);
    newAddWardHours.push({
      wardId: Number(originWard.id),
      nameWard: originWard.name,
      shifts: [defaultTime],
    });
    setCurrentWardHours(newAddWardHours);
    isAddWard.setValue(false);
  };
  const onChangeTime =
    (key: 'from' | 'to', indexTime: number, idWard: number) => (newDate: Date) => {
      const newData = cloneDeep(currentWardHours);
      const index = newData.findIndex((el) => el.wardId === idWard);
      if (index < 0) {
        return;
      }

      newData[index].shifts[indexTime][key] = newDate;
      setCurrentWardHours(newData);
    };
  const onClickAddTime = (idWard: number) => {
    const newData = cloneDeep(currentWardHours);

    const index = newData.findIndex((el) => el.wardId === idWard);
    if (index < 0) {
      return;
    }
    const wardShifts = newData[index].shifts;
    newData[index].shifts.push(
      newData[index].shifts.length
        ? {
            from: wardShifts[wardShifts.length - 1].to,
            to: addMinutes(wardShifts[wardShifts.length - 1].to, 30),
          }
        : defaultTime,
    );
    setCurrentWardHours(newData);
  };
  const onClickRemoveTime = (indexTime: number, idWard: number) => {
    const newData = cloneDeep(currentWardHours);
    const index = newData.findIndex((el) => el.wardId === idWard);
    if (index < 0) {
      return;
    }
    indexTime === 0
      ? remove(newData, (el) => el.wardId === idWard)
      : remove(newData[index].shifts, (_el, indexRemove) => indexRemove === indexTime);

    setCurrentWardHours(newData);
  };
  const onCancel = () => {
    isEdit.setValue(false);
    setCurrentWardHours(originWardHours);
  };

  useEffect(() => {
    if (currentSelectedSetting !== SettingType.location) {
      onCancel();
    }
  }, [currentSelectedSetting]);

  const onSubmit = async () => {
    isSubmitting.setValue(true);
    try {
      await createSetting({
        name: NameSetting.workingTime,
        type: SettingType.location,
        value: JSON.stringify(
          currentWardHours.map((el) => {
            return pick(el, ['wardId', 'shifts']);
          }),
        ),
      });
      setOriginWardHours(currentWardHours);
      isEdit.setValue(false);
      toast.success('Update setting MMD Operational Hours in Ward successfully!');
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isSubmitting.setValue(false);
    }
  };
  return (
    <Main>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{
          marginBottom: 32,
          width: '100%',
        }}
      >
        <div className="d-flex flex-column">
          <Typography variant="heading-7" color="gray-700">
            MMD Operational Hours in Ward
          </Typography>
          <Typography variant="body-3" color="neutral-7">
            The MMD User can only stock up for the ward during the selected time.
          </Typography>
        </div>
        <ActionButtonWrapper>
          <ActionButtonCard
            isEdit={isEdit.value}
            onCancel={onCancel}
            disableButton={isSubmitting.value}
            isSubmitting={isSubmitting.value}
            onClickEdit={() => {
              isEdit.setValue(true);
              setCurrentSelectedSetting(SettingType.location);
            }}
            onSubmit={onSubmit}
          />
        </ActionButtonWrapper>
      </div>
      {Boolean(currentWardHours) &&
        currentWardHours.map((el, index) => {
          const isFullDay =
            el.shifts[0].from.getHours() === el.shifts[el.shifts.length - 1].to.getHours() &&
            el.shifts[0].from.getMinutes() === el.shifts[el.shifts.length - 1].to.getMinutes();

          return (
            <div
              key={index}
              className="d-flex flex-column"
              style={{
                marginBottom: 32,
              }}
            >
              <Typography
                variant="body-7"
                color={isEdit.value ? 'gray-700' : 'neutral-6'}
                style={{
                  marginBottom: 4,
                }}
              >
                {el.nameWard}
              </Typography>
              <div className="d-flex align-items-center">
                {el.shifts.map((time, indexTime) => {
                  return (
                    <div key={indexTime} className="d-flex align-items-center">
                      <TimeSelectionItem
                        startTime={time.from}
                        endTime={time.to}
                        setStartTime={onChangeTime('from', indexTime, el.wardId)}
                        setEndTime={onChangeTime('to', indexTime, el.wardId)}
                        isEdit={isEdit.value}
                        isAnd={indexTime + 1 < el.shifts.length}
                        index={indexTime}
                        shifts={el.shifts}
                      />
                    </div>
                  );
                })}
                {el.shifts.length < 3 && isEdit.value && isFullDay === false && (
                  <ButtonAddTime onClick={() => onClickAddTime(el.wardId)}>Add time</ButtonAddTime>
                )}
                {isEdit.value && Boolean(el.shifts.length) && (
                  <IconRemove
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => onClickRemoveTime(el.shifts.length - 1, el.wardId)}
                  />
                )}
              </div>
            </div>
          );
        })}

      {isEdit.value && Boolean(wards.length) && wards.length > currentWardHours.length && (
        <TippyDefault
          interactive
          arrow={false}
          containerClass="action-menu"
          placement="bottom-start"
          offset={[0, 5]}
          trigger="click"
          visible={isAddWard.value}
          onClickOutside={() => isAddWard.setValue(false)}
          allowHTML
          content={
            <MenuAction
              style={{
                width: 160,
              }}
            >
              {Boolean(wards.length) &&
                wards
                  .filter(
                    (el) =>
                      !includes(
                        currentWardHours.map((elAdd) => elAdd.wardId),
                        el.id,
                      ),
                  )
                  .map((el, index) => {
                    return (
                      <MenuItem onClick={onChangeSelectWard(el)} key={index}>
                        {el.name}
                      </MenuItem>
                    );
                  })}
            </MenuAction>
          }
        >
          <ButtonAddWard
            type="button"
            className="btn d-flex align-items-center justify-content-center"
            onClick={() => isAddWard.setValue(true)}
          >
            <IconButton
              style={{
                marginRight: 10,
                color: 'var(--white)',
              }}
            />
            Add ward
          </ButtonAddWard>
        </TippyDefault>
      )}
    </Main>
  );
};
export default OperationalHoursInWardCard;
