import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { replacePath } from 'app/helpers';

import { PATH } from 'app/constants/path';

import BedManagementPage from './beds/BedManagementPage';
import WardManagementPage from './wards/WardManagementPage';

const LocationsManagementPage = () => {
  return (
    <Routes>
      <Route
        element={
          <React.Fragment>
            <Outlet />
          </React.Fragment>
        }
      >
        <Route
          path={replacePath(PATH.WARDS, PATH.LOCATIONS, '')}
          element={<WardManagementPage />}
        />
        <Route path={replacePath(PATH.BEDS, PATH.LOCATIONS, '')} element={<BedManagementPage />} />

        <Route index element={<Navigate to={PATH.WARDS} />} />
      </Route>
    </Routes>
  );
};

export default LocationsManagementPage;
