import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { AddButton } from 'app/components/Button/AddButton';
import ButtonDefault from 'app/components/Button/ButtonDefault';
import InputSearch from 'app/components/Input/InputSearch';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { trimValue } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import {
  filterBySearch,
  getPathWithParams,
  useNavigateWithSendPath,
  usePageGoBack,
} from 'app/helpers/utils';
import { useBoolean, useString } from 'app/hooks';
import { usePolicyInUser } from 'app/hooks/policy';
import { orderBy } from 'lodash';

import { PATH } from 'app/constants/path';

import { ReactComponent as ApproveIcon } from 'app/assets/icons/approve-icon.svg';
import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';
import { ReactComponent as IconToastInfo } from 'app/assets/icons/icon-toast-info.svg';
import { ReactComponent as LogoThumbnail } from 'app/assets/icons/logo-thumbnail.svg';

import { useAuth } from '../auth';
import InfoItemStockRunCard from '../inventory/stock-run/components/InfoItemStockRunCard';
import { StockLevel } from '../inventory/stock-run/core/enum';
import { useOrderStore } from '../inventory/stock-run/store';
import { DemandButton } from '../reporting/ReportPage';
import { ProjectionList } from './components/ProjectionList';
import { getTimeRange } from './core/helper';
import {
  approvedItems,
  getProjectionDetail,
  getProjectionList,
  postApproveList,
} from './core/requests';
import {
  allowApproveSelector,
  approveStatusLengthSelector,
  useProjectionStore,
} from './core/store';
import { DemandApproveItem, DemandProjectionItem, ProjectionDetail } from './core/type';

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const Information = styled.div`
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 8px;
`;

const FormInputBody = styled.div`
  width: 80%;
  margin-top: 32px;
`;

const Main = styled.div`
  .card-toolbar {
    flex-direction: row-reverse;
    margin: 0;
  }
  padding-top: 28px;
  .custom-button {
    .indicator-label {
      padding: 0 18px;
    }
  }
  .action-button {
    margin-top: 0px !important;
  }
`;

const InfoBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: 28px;
  padding: 3px 10px;
  border-radius: 4px;
  background-color: var(--primary-3);
`;

const renderCount = (count: number, length: number) => (
  <Typography
    variant="title-1"
    lineHeight={32}
    style={{
      marginBottom: 16,
      paddingLeft: 28,
      paddingTop: 28,
    }}
  >
    {` Projection detail (${count}/${length} approved demands)`}
  </Typography>
);

const ProjectionPage = () => {
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);

  const location = useLocation();
  const goBack = usePageGoBack(
    location.pathname.indexOf('report') !== -1 ? PATH.REPORT : PATH.INVENTORY_STOCK_RUN,
  );
  const isLoading = useBoolean(true);
  const [projectionDetail, setProjectionDetail] = useState<ProjectionDetail>();
  const [timeFrame, setTimeFrame] = useState<number>();
  const searchItem = useString('');
  const [items, setItems] = useState<DemandProjectionItem[]>([]);
  const isApproved = useBoolean();
  const navigate = useNavigateWithSendPath();

  const [searchParams] = useSearchParams();
  const forecastingWard = searchParams.get('forecastingWard');

  const sortOrderBy = useOrderStore((s) => s.state.orderBy);
  const sortOrderDirection = useOrderStore((s) => s.state.orderDirection);

  const blockAllow = useProjectionStore.getState().blockApprove;
  const approveCount = useProjectionStore(approveStatusLengthSelector);
  const isAllowApprove = useProjectionStore(allowApproveSelector);
  const orignialApprovedId = items.length
    ? items
        .filter((item) => item.approvedType !== null)
        .map(
          (item) =>
            ({ itemDemandId: item.id, approvedType: item.approvedType } as DemandApproveItem),
        )
    : [];

  const displayItems: DemandProjectionItem[] = useMemo(() => {
    const newDataSort = orderBy(
      items.map((item) => ({
        ...item,
        stockLevelOrder: Object.values(StockLevel).indexOf(item.stockLevel),
        statusName: item.status ? 'Approved' : 'Not Aprroved',
      })),
      sortOrderBy,
      sortOrderDirection === 'ASC' ? 'asc' : 'desc',
    );
    return filterBySearch(newDataSort, trimValue(searchItem.value), ['itemName', 'skuId']);
  }, [searchItem.value, sortOrderBy, sortOrderDirection, items]);

  const getData = async () => {
    try {
      isLoading.setValue(true);
      const dataRes = await getProjectionDetail(forecastingWard);
      setProjectionDetail(dataRes);
    } catch (error) {
      console.log('error', error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const getListItem = async () => {
    try {
      isLoading.setValue(true);
      const dataRes = await getProjectionList(forecastingWard, timeFrame);
      setItems(dataRes.data?.items ?? []);
    } catch (error) {
      console.log('error', error);
    } finally {
      isLoading.setValue(false);
    }
  };

  useEffect(() => {
    if (timeFrame) {
      getListItem();
    }
  }, [timeFrame]);

  useEffect(() => {
    useProjectionStore.getState().setState({ approvedIds: orignialApprovedId });
  }, [items]);

  useEffect(() => {
    if (projectionDetail?.items) {
      setTimeFrame(Number(orderBy(projectionDetail.items, 'index')?.[0]?.id));
    }
  }, [projectionDetail]);

  useEffect(() => {
    if (forecastingWard) {
      getData();
    }
  }, [forecastingWard]);

  const onChangeTimeFrame = (value: number) => {
    isApproved.setValue(false);
    setTimeFrame(value);
  };

  const handleInputChangeSearchItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchItem.setValue(event.target.value);
  };

  const onAddStockRun = async () => {
    const time = getTimeRange(projectionDetail?.items.find((item) => item.id == timeFrame));
    try {
      isLoading.setValue(true);
      await approvedItems(timeFrame);
      navigate(
        getPathWithParams(PATH.INVENTORY_STOCK_RUN_ADD, {
          forecastingWard,
          startDate: time.startDate,
          demandId: timeFrame,
          timeFrame: projectionDetail?.items.find((item) => item.id == timeFrame)?.timeFrame,
          endDate: time.endDate,
        }),
      );
    } catch (error: any) {
      getResponseError(error.response.data.data, {
        className: 'text-error',
      });
    } finally {
      isLoading.setValue(false);
    }
  };

  const handleSubmit = async () => {
    const approved = useProjectionStore.getState().state.approvedIds;
    const ApprovedIds = approved.map((item) => item.itemDemandId);
    const rejected = items
      .filter((item) => !ApprovedIds.includes(Number(item.id)))
      .map((item) => item.id);
    const res = await postApproveList(timeFrame, approved, rejected);
    if (res) {
      toast.success('Projected demand posted successfully!');
      getListItem();
      getData();
      blockAllow();
    }
    isApproved.setValue(false);
  };

  const handleCancel = () => {
    useProjectionStore.getState().setState({ approvedIds: orignialApprovedId });
    isApproved.setValue(false);
    blockAllow();
  };

  const renderButton = () => {
    if (isApproved.value) {
      return (
        <ActionButton
          labelButtonSubmit="Approve"
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          customClass="action-button"
          disableButton={!isAllowApprove}
        />
      );
    }
    return (
      <div className="d-flex align-items-center">
        {policy.approveProjection && (
          <ButtonDefault
            theme="btn-light"
            className="d-flex align-items-center justify-content-center custom-button"
            style={{ width: 'fit-content' }}
            onClick={() => isApproved.setValue(true)}
          >
            <ApproveIcon style={{ marginRight: 8 }} />
            Approve demand
          </ButtonDefault>
        )}
        {policy.item && (
          <DemandButton>
            <AddButton
              title="Add stock run"
              icon={<LogoThumbnail style={{ marginRight: 12 }} />}
              style={{ marginLeft: 12 }}
              onClick={onAddStockRun}
            />
          </DemandButton>
        )}
      </div>
    );
  };

  if (!projectionDetail) {
    return <LoadingCard />;
  }

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <Header>
            <IconRedirect
              style={{
                cursor: 'pointer',
              }}
              onClick={goBack}
            />
            <Typography variant="heading-6">Demand projection</Typography>
          </Header>
          <Information>
            <Typography variant="title-1" lineHeight={32} style={{ marginBottom: 32 }}>
              Information
            </Typography>
            <div className="d-flex" style={{ width: '80%' }}>
              <InfoItemStockRunCard
                title="Ward"
                value={projectionDetail?.ward?.name}
                styleRoot={{ width: '50%' }}
              />
              <InfoItemStockRunCard
                title="Last approved by"
                value={
                  timeFrame
                    ? projectionDetail?.items.filter((item) => item.id == timeFrame)[0].userName
                    : ''
                }
                styleRoot={{ width: '50%' }}
              />
            </div>
            <InfoItemStockRunCard
              title="Time frame"
              value={
                timeFrame
                  ? projectionDetail?.items.filter((item) => item.id == timeFrame)[0]?.timeFrame
                  : ''
              }
              styleRoot={{ width: '50%', marginTop: 32 }}
            />
            {/* 26/10/2023 : Temporarily disable time frame select feature 
            <FormInputBody>
              <FormSelect
                label="Time frame"
                handleChange={(select) => onChangeTimeFrame(select.value)}
                optionsSelect={projectionDetail?.items.map((item) => ({
                  title: item.timeFrame,
                  value: item.id,
                }))}
                activeSelect={timeFrame}
                fieldActive="value"
                fieldSelect="title"
                styleSelectCard={{
                  width: 260,
                }}
                inputPlaceholder="Select a time frame to view projection"
                isDisable
              />
            </FormInputBody> */}
          </Information>
        </div>
      }
    >
      {timeFrame ? (
        <Main>
          {renderCount(approveCount, items.length)}
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ padding: '0 28px', marginBottom: 32 }}
          >
            <InputSearch
              value={searchItem.value}
              onChange={handleInputChangeSearchItem}
              placeholder="Search with item name and SKU"
            />
            {renderButton()}
          </div>
          <InfoBanner>
            <IconToastInfo width={18} height={18} style={{ marginRight: 6 }} />
            <Typography variant="title-4" color="primary">
              When a ML Projected Demand is approved, the corresponding ML Stock Run Quantity can be
              added to a stock run.
            </Typography>
          </InfoBanner>
          <ProjectionList
            items={displayItems}
            isApproved={isApproved.value}
            wardId={Number(forecastingWard)}
          />
        </Main>
      ) : (
        <></>
      )}
      {isLoading.value && <LoadingCard />}
    </RootContentPage>
  );
};

export default ProjectionPage;
