import { Column } from 'react-table';

import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import Typography from 'app/components/Typography';

import { getStockHealth } from 'app/modules/reporting/core/columns';

import ItemInfoCard from '../../items/components/ItemInfoCard';
import SKUCard from '../../items/components/SKUCard';
import { getItemAttribute } from './helpers';
import { StockRunItem } from './type';

export const itemsStockRunColumns = (): ReadonlyArray<Column<StockRunItem>> => {
  return [
    {
      id: '#',
      accessor: 'id',
      Header: '#',
      width: '5%',
    },
    {
      id: 'name',
      accessor: 'inventory',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].inventory?.image || ''}
          name={props.data[props.row.index].inventory?.name || ''}
          stockType={props.data[props.row.index].inventory?.stockType}
          stockupQuantity={Number(props.data[props.row.index].qtyCompleted)}
          isStockUpCompleted={props.data[props.row.index].isCompleted}
          isNotNavigatable={true}
        />
      ),
      width: '30%',
    },
    {
      id: 'sku',
      accessor: 'inventory',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].inventory?.skuId || ''}
          skuSub={props.data[props.row.index].inventory?.skuSub || ''}
          stockRunQtyCompleted={Number(props.data[props.row.index].qtyCompleted)}
          isStockRunCompleted={props.data[props.row.index].isCompleted}
        />
      ),
      width: '15%',
    },
    {
      id: 'stockLevel',
      width: '15%',
      Header: (props) => <CustomHeader tableProps={props} title="Stock health" />,
      Cell: ({ ...props }) => getStockHealth(props.data[props.row.index].stockLevel),
    },
    {
      id: 'rack',
      accessor: 'rack',
      Header: (props) => <CustomHeader tableProps={props} title="Rack" />,

      Cell: ({ ...props }) => (
        <Typography
          color={getItemAttribute('color', {
            isCompleted: props.data[props.row.index].isCompleted,
            qtyCompleted: props.data[props.row.index].qtyCompleted,
          })}
          variant="body-5"
          style={{
            whiteSpace: 'nowrap',
            textDecoration: getItemAttribute('textDecoration', {
              isCompleted: props.data[props.row.index].isCompleted,
              qtyCompleted: props.data[props.row.index].qtyCompleted,
            }),
          }}
        >
          {props.data[props.row.index].bin?.warehouse?.name}
        </Typography>
      ),
    },
    {
      id: 'bin',
      accessor: 'bin',
      Header: (props) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <Typography
          color={getItemAttribute('color', {
            isCompleted: props.data[props.row.index].isCompleted,
            qtyCompleted: props.data[props.row.index].qtyCompleted,
          })}
          variant="body-5"
          style={{
            textDecoration: getItemAttribute('textDecoration', {
              isCompleted: props.data[props.row.index].isCompleted,
              qtyCompleted: props.data[props.row.index].qtyCompleted,
            }),
          }}
        >
          {props.data[props.row.index].bin?.name}
        </Typography>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props) => <CustomHeader tableProps={props} title="Qty to stock up" />,
      Cell: ({ ...props }) => (
        <Typography
          color={getItemAttribute('qtyColor', {
            isCompleted: props.data[props.row.index].isCompleted,
            qtyCompleted: props.data[props.row.index].qtyCompleted,
          })}
          variant="body-5"
        >
          {Number(props.data[props.row.index].qtyCompleted) === 0 &&
          props.data[props.row.index].isCompleted
            ? 'Removed'
            : props.data[props.row.index].quantity}
        </Typography>
      ),
    },
    {
      id: 'qtyCompleted',
      accessor: 'qtyCompleted',
      Header: (props) => <CustomHeader tableProps={props} title=" Qty completed" />,
      Cell: ({ ...props }) => (
        <Typography
          color={getItemAttribute('color', {
            isCompleted: props.data[props.row.index].isCompleted,
            qtyCompleted: props.data[props.row.index].qtyCompleted,
          })}
          variant="body-5"
        >
          {Number(props.data[props.row.index].qtyCompleted) === 0 &&
          props.data[props.row.index].isCompleted
            ? '-'
            : props.data[props.row.index].qtyCompleted}
        </Typography>
      ),
    },
  ];
};
