import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { StatusBed } from '../core/enum';
import { changeStatusBed } from '../core/requests';
import { Bed } from '../core/type';

interface Props {
  nameQuery: string;
  originalData: Bed;
}

const FormChangeStatusCard: React.FC<Props> = ({ nameQuery, originalData }) => {
  const { refetch, query } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const queryClient = useQueryClient();
  const loading = useBoolean(false);
  const labelStatus = String(originalData.status) === StatusBed[1] ? 'Inactivate' : 'Activate';
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const onSubmit = async () => {
    loading.setValue(true);

    try {
      await changeStatusBed(originalData?.id, {
        activate:
          String(originalData.status) === StatusBed[1] ? StatusBed.Inactive : StatusBed.Active,
      });
      toast.success(`${labelStatus} bed successfully!`);
      refetch();
      queryClient.invalidateQueries([`${nameQuery}-${query}`]);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      loading.setValue(false);
      cancel();
    }
  };

  return (
    <div className="d-flex flex-column">
      <Typography variant="body-3">
        {`Are you sure to ${labelStatus.toLocaleLowerCase()} this bed?`}
      </Typography>
      <ActionButton
        onCancel={cancel}
        labelButtonSubmit={labelStatus}
        isSubmitting={loading.value}
        onSubmit={onSubmit}
        themeButton={String(labelStatus) === 'Inactivate' ? 'btn-danger' : 'btn-primary'}
      />
    </div>
  );
};
export default FormChangeStatusCard;
