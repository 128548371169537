import styled from 'styled-components';

import { connectionSelector, useHeartBeatStore } from 'app/modules/system-settings/locations/store';
import {
  InventoryConnectionStatus,
  InventoryConnectionType,
} from 'app/modules/system-settings/locations/wards/core/type';

import _ from 'lodash';

const heartBeatStyle = {
  [InventoryConnectionStatus.online]: {
    backgroundColor: 'var(--success-light-2)',
    color: 'var(--success)',
  },
  [InventoryConnectionStatus.offline]: {
    backgroundColor: 'var(--danger-light-2)',
    color: 'var(--danger)',
  },
  [InventoryConnectionStatus.notReady]: {
    backgroundColor: 'var(--neutral-3)',
    color: 'var(--neutral-7)',
  },
};

const MainStatus = styled.div<{ bg?: string }>`
  width: fit-content;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-radius: 99px;
  ${({ bg }) => `background-color:${bg}`}
`;

const Point = styled.div<{ color?: string }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 7px;
  ${({ color }) => `background-color:${color}`}
`;

const NameStatus = styled.div<{ color?: string }>`
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  white-space: nowrap;
  ${({ color }) => `color:${color}`};
`;

const getStatusLabel = (
  connectionStatus?: InventoryConnectionStatus,
  connectionType?: InventoryConnectionType,
  numberOfFailed?: number,
) => {
  if (connectionStatus === InventoryConnectionStatus.notReady) {
    return 'Not ready';
  }
  if (connectionStatus === InventoryConnectionStatus.online) {
    return 'Normal';
  }
  if (numberOfFailed) {
    return `${numberOfFailed} failed`;
  }
  switch (connectionType) {
    case InventoryConnectionType.rack_top:
      return 'Top: Failed';
    case InventoryConnectionType.rack_bottom:
      return 'Bottom: Failed';
    default:
      return 'Failed';
  }
};

const renderStatusTag = (
  connectionStatus: InventoryConnectionStatus,
  label: string,
  styleMain?: React.CSSProperties,
) => {
  const { backgroundColor, color } = heartBeatStyle[connectionStatus];

  return (
    <MainStatus bg={backgroundColor} style={styleMain}>
      <Point color={color} />
      <NameStatus color={color}>{label}</NameStatus>
    </MainStatus>
  );
};
interface Props {
  pageType: 'ward' | 'rack';
  targetId: number;
  isRackDetailPage?: boolean;
  styleMain?: React.CSSProperties;
}

const HeartBeatStatus: React.FC<Props> = ({
  pageType,
  targetId,
  isRackDetailPage = false,
  styleMain,
}) => {
  const { notReady, offlineConnection } = useHeartBeatStore(connectionSelector(pageType, targetId));

  if (notReady) {
    return renderStatusTag(
      InventoryConnectionStatus.notReady,
      getStatusLabel(InventoryConnectionStatus.notReady),
      styleMain,
    );
  }
  const onlineStatus = offlineConnection.length
    ? InventoryConnectionStatus.offline
    : InventoryConnectionStatus.online;

  if (pageType === 'ward' || onlineStatus === InventoryConnectionStatus.online) {
    return renderStatusTag(onlineStatus, getStatusLabel(onlineStatus), styleMain);
  }
  if (isRackDetailPage) {
    return (
      <div className="d-flex">
        {_.sortBy(offlineConnection, 'type').map((connection) =>
          renderStatusTag(
            connection.status,
            getStatusLabel(connection.status, connection.type),
            styleMain,
          ),
        )}
      </div>
    );
  }
  return renderStatusTag(
    onlineStatus,
    getStatusLabel(onlineStatus, undefined, offlineConnection.length),
    styleMain,
  );
};
export default HeartBeatStatus;
