import { AuthModel, LoginRequest, UserModel } from './type';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`;

// Server should return AuthModel
export function login(request: LoginRequest) {
  return axios.post<{ data: AuthModel; message: string; statusCode: number }>(LOGIN_URL, request);
}

export function getUserByToken(token: string) {
  return axios.get<{ data: UserModel }>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function forgotPassword(email: string) {
  return axios.post<{ data: AuthModel; message: string; statusCode: number }>(FORGOT_PASSWORD_URL, {
    email,
  });
}
export function resetPassword(password: string, token: string) {
  return axios.post<{ data: AuthModel; message: string; statusCode: number }>(RESET_PASSWORD_URL, {
    password,
    token,
  });
}
