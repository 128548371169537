import { CSSProperties, FC } from 'react';

import Typography from '../Typography';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  label?: any;
  labelAlign?: 'left' | 'right';
  containerStyle?: CSSProperties;
  containerClass?: string;
  showBG?: boolean;
  hideRadio?: boolean;
  highlight?: boolean;
}

export const RadioInput: FC<Props> = ({
  disabled,
  label,
  labelAlign = 'right',
  containerStyle,
  containerClass,
  hideRadio,
  highlight,
  showBG,
  ...props
}) => {
  const getBgColor = (isActive?: boolean) => {
    if (isActive) {
      return disabled ? 'var(--primary-5)' : 'var(--primary)';
    }
    return disabled ? 'var(--neutral-4)' : 'var(--neutral-2-5)';
  };

  return (
    <div
      className={`form-check form-check-custom form-check-solid ${containerClass || ''}`}
      onClick={(e: any) => props.onChange?.(e)}
      style={{
        cursor: disabled ? 'inherit' : 'pointer',
        width: 'auto',
        backgroundColor: !showBG
          ? undefined
          : props.checked
          ? 'var(--primary-2)'
          : highlight
          ? 'var(--neutral-1)'
          : 'var(--white)',
        ...containerStyle,
      }}
    >
      {labelAlign === 'left' && (
        <label
          className="form-check-label"
          htmlFor="kt_modal_update_role_option_0"
          style={{ width: '100%', marginLeft: 0, opacity: 1 }}
        >
          <Typography
            variant="body-5"
            style={{
              color: disabled ? 'var(--neutral-6)' : 'var(--neutral-10)',
              cursor: disabled ? 'default' : 'pointer',
            }}
          >
            {label}
          </Typography>
        </label>
      )}

      <input
        className="form-check-input me-3"
        type="radio"
        style={{
          cursor: disabled ? 'inherit' : 'pointer',
          backgroundColor: getBgColor(props.checked),
          opacity: hideRadio ? 0 : 1,
        }}
        checked
        disabled
        {...props}
      />

      {labelAlign === 'right' && (
        <label
          className="form-check-label"
          htmlFor="kt_modal_update_role_option_0"
          style={{ width: '100%', opacity: 1 }}
        >
          <Typography
            variant="body-5"
            style={{
              color: disabled ? 'var(--neutral-6)' : 'var(--neutral-10)',
              cursor: disabled ? 'default' : 'pointer',
            }}
          >
            {label}
          </Typography>
        </label>
      )}
    </div>
  );
};
