import styled from 'styled-components';

import { STATUS_USER } from '../core/constants';

const MainStatus = styled.div<{ bg?: string }>`
  width: 90px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  ${({ bg }) => `background-color:${bg}`}
`;
const NameStatus = styled.div<{ color?: string }>`
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  ${({ color }) => `color:${color}`}
`;
interface Props {
  status: number;
}

const StatusUserCard: React.FC<Props> = ({ status }) => {
  const isActive = status === STATUS_USER.ACTIVATE;
  return (
    <MainStatus bg={isActive ? 'var(--success-light-1-5)' : 'var(--danger-light-1-5)'}>
      <NameStatus color={isActive ? 'var(--success)' : 'var(--danger)'}>
        {isActive ? 'Active' : 'Inactive'}
      </NameStatus>
    </MainStatus>
  );
};
export default StatusUserCard;
