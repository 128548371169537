import { Navigate } from 'react-router-dom';

import { PATH } from 'app/constants/path';

import { SuspensedView } from './PrivateRoutes';

interface Props {
  accept: boolean;
  children: JSX.Element;
}
const RoutePolicy: React.FC<Props> = ({ accept, children }) => {
  return accept ? <SuspensedView> {children}</SuspensedView> : <Navigate to={PATH.NOT_FOUND} />;
};
export default RoutePolicy;
