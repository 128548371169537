import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { CustomCheckbox } from 'app/components/Checkbox/CheckboxDefault';
import InputSearch from 'app/components/Input/InputSearch';
import { FormModal } from 'app/components/Modal/FormModal';
import { CustomHeader, FormTableHeader } from 'app/components/Modal/components/CustomHeader';
import { FormSelect } from 'app/components/Select/FormSelect';
import Typography from 'app/components/Typography';

import { trimValue } from 'app/helpers';
import { filterBySearch } from 'app/helpers/utils';
import { useBoolean, useNumber, useString } from 'app/hooks';
import { cloneDeep, countBy, maxBy, omit, orderBy, xorBy } from 'lodash';

import { Rack } from '../../racks/core/type';
import ViewItemInBinDetail from '../components/ViewItemInBinDetail';
import { StockLevel } from '../core/enum';
import { getStockLevel } from '../core/helpers';
import { StockRunItem } from '../core/type';
import { useOrderStore } from '../store';

const SelectAll = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  p {
    font-weight: 700;
    white-space: nowrap;
  }
  padding: 0 12px 16px 12px;
`;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onSubmitSelection: (selectBins: StockRunItem[]) => void;
  originStockRunItems: StockRunItem[];
  wardId: number;
  racks: Rack[];
}
const FormAddItemStockRun: React.FC<Props> = ({
  isOpen,
  originStockRunItems,
  onClose,
  onSubmitSelection,
  racks,
}) => {
  const rackId = useNumber(0);
  const isLoading = useBoolean(true);
  const searchItem = useString('');

  const sortOrderBy = useOrderStore((s) => s.state.orderBy);
  const sortOrderDirection = useOrderStore((s) => s.state.orderDirection);

  const [selectStockRunItems, setSelectStockRunItems] = useState<StockRunItem[]>([]);
  const modalRef = useRef<any>(null);

  const stockRunItems = useMemo(() => {
    const binItems = rackId.value
      ? racks.find((el) => el.id === rackId.value)?.bins || []
      : racks.flatMap((rack) => rack.bins) || [];
    return binItems
      .filter((el) => el.item)
      .map(({ item, ...restBin }) => {
        const originItem = originStockRunItems.find((itm) => itm.bin?.id === restBin?.id);
        return {
          bin: restBin,
          inventory: { ...item, ...originItem?.inventory },
          rack: racks.find((r) => r.id === restBin?.warehouse?.id),
          quantity: originItem?.quantity || '0',
          ...omit(originItem, 'inventory', 'quantity'),
          ...omit(item, 'id'),
        } as StockRunItem;
      });
  }, [originStockRunItems, racks, rackId.value]);

  const displayItems: StockRunItem[] = useMemo(() => {
    const newDataSort = orderBy(
      stockRunItems.map((item) => ({
        ...item,
        stockLevel: Object.values(StockLevel).indexOf(getStockLevel(item)),
      })),
      sortOrderBy,
      sortOrderDirection === 'ASC' ? 'asc' : 'desc',
    );
    return filterBySearch(newDataSort, trimValue(searchItem.value), ['name', 'skuId', 'bin.name']);
  }, [searchItem.value, sortOrderBy, sortOrderDirection, stockRunItems]);

  const selectedItemsByRack =
    rackId.value === 0
      ? selectStockRunItems
      : selectStockRunItems.filter((el) => el.bin?.warehouse?.id === rackId.value);

  const isSelectAll =
    selectedItemsByRack.length > 0 && selectedItemsByRack.length === stockRunItems.length;

  useEffect(() => {
    modalRef.current.scrollToTop();
  }, [rackId.value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      isLoading.setValue(false);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    setSelectStockRunItems(originStockRunItems);
  }, [isOpen]);

  useEffect(() => {
    useOrderStore.getState().clearState();
  }, []);

  const onChangeRack = (newValue: number) => {
    rackId.setValue(newValue);
  };

  const onSelectStockRunItem = (newSelectBin: StockRunItem) => () => {
    setSelectStockRunItems((prev) => {
      return xorBy(prev, [newSelectBin], 'bin.id');
    });
  };

  const onChangeSelectAllItem = (newSelectAll: boolean) => {
    const originSelectsStockRun = cloneDeep(selectStockRunItems);
    const newSelects =
      rackId.value === 0
        ? []
        : originSelectsStockRun.filter((el) => el.bin?.warehouse?.id !== rackId.value);
    setSelectStockRunItems(newSelectAll ? newSelects.concat(stockRunItems) : newSelects);
  };

  const handleInputChangeSearchItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchItem.setValue(event.target.value);
  };

  const onSubmitAdd = () => {
    let maxOrder = maxBy(originStockRunItems, 'order')?.order ?? -1;
    const newItemCount = countBy(selectStockRunItems, (el) => el.order === undefined).true;
    const newItemsWithOrder = selectStockRunItems.map((el) => {
      if (typeof el.order === 'number') {
        return el;
      }
      maxOrder--;
      return {
        ...el,
        order: maxOrder + 1 + newItemCount,
      };
    });
    onSubmitSelection(newItemsWithOrder);
  };

  return (
    <FormModal
      ref={modalRef}
      title="Add items"
      onClickCloseModal={onClose}
      childrenAction={
        <ActionButton
          onCancel={onClose}
          disableButton={xorBy(selectStockRunItems, originStockRunItems, 'bin.id').length === 0}
          isSubmitting={false}
          onSubmit={onSubmitAdd}
          labelButtonSubmit="Select"
        />
      }
      styleModalDialog={{ minWidth: 1000 }}
      showLoading={isLoading.value}
      Heading={
        <div className="d-flex justify-content-between" style={{ padding: '28px 28px 0 28px' }}>
          <div style={{ width: 200 }}>
            <FormSelect
              label="Rack"
              handleChange={(select) => onChangeRack(select.value)}
              optionsSelect={[{ title: 'All racks', value: 0 }].concat(
                racks.map((el) => {
                  return {
                    title: el.name,
                    value: Number(el.id),
                  };
                }),
              )}
              activeSelect={rackId.value}
              fieldActive="value"
              fieldSelect="title"
              styleSelectCard={{
                width: 200,
              }}
              styleRoot={{ marginBottom: 0 }}
            />
          </div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <InputSearch
              value={searchItem.value}
              onChange={handleInputChangeSearchItem}
              placeholder="Search with item name, SKU, or Bin"
            />
          </div>
        </div>
      }
    >
      {!isLoading.value && (
        <>
          <FormTableHeader>
            <CustomHeader title="Item" styleHeader={{ width: '34%' }} id="inventory.name" />
            <CustomHeader title="SKU" styleHeader={{ width: '24%' }} id="inventory.skuId" />
            <CustomHeader title="Rack" styleHeader={{ width: '11%' }} id="rack.name" />
            <CustomHeader title="Bin" styleHeader={{ width: '11%' }} id="bin.name" />
            <CustomHeader title="Qty" styleHeader={{ width: '9%' }} id="inventory.qty" />
            <CustomHeader
              title="Stock health"
              styleHeader={{ width: '10%', whiteSpace: 'normal' }}
              id="stockLevel"
            />
            <SelectAll>
              {Boolean(stockRunItems.length) && (
                <CustomCheckbox
                  checked={isSelectAll}
                  onChange={() => onChangeSelectAllItem(!isSelectAll)}
                  label="Select all"
                  indeterminate={selectedItemsByRack.length > 0 && isSelectAll === false}
                />
              )}
            </SelectAll>
          </FormTableHeader>
          {displayItems.length ? (
            displayItems.map((elStockRunItem, index) => (
              <ViewItemInBinDetail
                stockRunItem={elStockRunItem}
                key={index}
                selectStockRunItems={selectStockRunItems.map((el) => Number(el.bin?.id))}
                onSelect={onSelectStockRunItem}
                highlight={index % 2 === 0}
              />
            ))
          ) : (
            <div className="d-flex justify-content-center" style={{ padding: '16px 10px' }}>
              <Typography variant="body-3">No matching records found</Typography>
            </div>
          )}
        </>
      )}
    </FormModal>
  );
};
export default FormAddItemStockRun;
