import styled from 'styled-components';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { quantityUnitsColumns } from './core/columns';
import { deleteSetting, getSettings } from './core/request';
import { Setting, SettingType } from './core/type';
import { FormQuantityUnitTableCard } from './modal/FormQuantityUnitTableCard';
import UnableDeleteUnit from './modal/UnableDeleteUnit';

const Body = styled.div``;

export const ConfigQuantityUnitPage = () => {
  const renderModalForm = (originalData: Setting, _typeModal: TYPE_MODAL, isView?: boolean) => {
    return <FormQuantityUnitTableCard setting={originalData} originIsView={isView} />;
  };

  const renderModalOutsideTable = (_originalData: Setting, typeModal?: TYPE_MODAL) => {
    if (typeModal === TYPE_MODAL.UNABLE_DELETE_UNIT) {
      return <UnableDeleteUnit />;
    }

    return <div />;
  };

  return (
    <Body>
      <TableComponent
        getList={getSettings}
        nameQuery={QUERIES.SETTING_LIST}
        originColumns={quantityUnitsColumns()}
        ModalForm={renderModalForm}
        titleModal="Unit"
        placeholderSearch="Search with unit"
        nameBtnAdd="Add unit"
        ModalOutsideTable={renderModalOutsideTable}
        deleteById={deleteSetting}
        moreQuery={`&type=${SettingType.quantityUnit}`}
        isBorder={false}
        fieldCheckShowCursorInRow="meta"
        spacing="xxl"
      />
    </Body>
  );
};
