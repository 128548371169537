import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import ButtonDefault from 'app/components/Button/ButtonDefault';
import { FormInputDate } from 'app/components/Input/FormInputDate';
import ModalDefault from 'app/components/Modal/ModalDefault';
import { FormSelect } from 'app/components/Select/FormSelect';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getItemsForecast } from 'app/modules/projection/core/requests';
import { TypeHoliday } from 'app/modules/system-settings/configuration/core/enum';
import { getSetting } from 'app/modules/system-settings/configuration/core/request';
import { Setting, SettingType } from 'app/modules/system-settings/configuration/core/type';
import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { getResponseError } from 'app/helpers/errors';
import { useNavigateWithPreviousPath, usePageGoBack } from 'app/helpers/utils';
import { useBlocker, useBoolean, useNumber, useString } from 'app/hooks';
import { addDays, endOfDay, isAfter, isSameDay, max, startOfDay } from 'date-fns';
import { subDays } from 'date-fns/esm';
import { differenceBy, differenceWith, flatMap, orderBy, uniqBy } from 'lodash';

import { PATH } from 'app/constants/path';

import { getRacks } from '../../racks/core/requests';
import { Rack } from '../../racks/core/type';
import { STOCK_TYPE_TITLE } from '../core/constants';
import { StatusStockUp, StockRunType } from '../core/enum';
import {
  getCompleteDate,
  getForecastings,
  getRecommendedQty,
  getStartDate,
  isDateGreaterThan,
  useUpdateStockRunStatus,
} from '../core/helpers';
import {
  createStockRun,
  createStockRunItem,
  getStockRun,
  getStockRunItems,
  updateStatusStockRun,
  updateStockRun,
} from '../core/requests';
import { ForecastItem, StockRunItem, StockRunItemRequest, StockRunRequest } from '../core/type';
import { ConfirmLeavingModal } from '../modal/ConfirmLeaving';
import InfoItemStockRunCard from './InfoItemStockRunCard';
import ListItemInStockRun from './ListItemInStockRun';
import { Transition } from 'history';
import { parse } from 'qs';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Information = styled.div`
  margin-top: 24px;
  border-radius: 8px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 8px;
`;
const FormInputBody = styled.div`
  width: 80%;
  margin-top: 32px;
`;
const ListItemBody = styled.div`
  border-radius: 8px;
  padding: 28px 0px;
`;
const ActionButtonCard = styled.div`
  .btn-light {
    background-color: transparent !important;
  }
`;

interface Props {
  idStockRun?: ID;
}
const BodyFormStockRun: React.FC<Props> = ({ idStockRun }) => {
  const locationSearch = useLocation().search;
  const params = parse(locationSearch.substring(1));

  const goBack = usePageGoBack(
    idStockRun
      ? PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(idStockRun))
      : PATH.INVENTORY_STOCK_RUN,
  );
  const navigateWithPreviousPath = useNavigateWithPreviousPath();
  const navigate = useNavigate();
  const { getStatusKey, setStatusKey, removeStatusKey } = useUpdateStockRunStatus();

  const statusRef = useRef<number>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [completeDate, setCompleteDate] = useState<Date | null>(new Date());
  const wardId = useNumber(0);
  const [stockRunItems, setStockRunItems] = useState<StockRunItem[]>([]);
  const [wards, setWards] = useState<Ward[]>([]);
  const [statusStockRun, setStatusStockRun] = useState(StatusStockUp.Draft);
  const [originStatusStockRun, setOriginStatusStockRun] = useState(StatusStockUp.Draft);
  const isLoading = useBoolean(false);
  const indexStockRun = useString('');
  const isSubmitting = useBoolean();
  const [warningChangeWard, setWarningChangeWard] = useState({
    wardId: 0,
    open: false,
  });
  const isConfirmLeavingModalOpen = useBoolean(false);
  const [calendarHoliday, setCalendarHoliday] = useState<Date[]>([]);
  const [originStockRunItems, setOriginStockRunItems] = useState<StockRunItem[]>([]);
  const [forecasting, setForecasting] = useState<ForecastItem[] | null>(null);

  const [racks, setRacks] = useState<Rack[]>([]);
  const initForecastingItems = useBoolean(false);
  const [stockrunType, setStockRunType] = useState<StockRunType | null>(null);

  const [transition, setTransition] = useState<Transition | null>(null);

  const isItemListDifferent =
    differenceWith(originStockRunItems, stockRunItems, (a, b) => {
      return a.bin?.id === b.bin?.id && Number(a.quantity) === Number(b.quantity);
    }).length > 0;

  const hasUnsavedChange =
    idStockRun != null &&
    (isItemListDifferent || originStockRunItems.length !== stockRunItems.length);

  const changeStatusStockRun = async (idStockrun: number, status: StatusStockUp) => {
    try {
      await updateStatusStockRun(Number(idStockrun), { status });
    } catch (error) {
      console.log(error);
    }
    removeStatusKey();
  };

  useEffect(() => {
    getData();
  }, [idStockRun]);

  useEffect(() => {
    if (!wardId.value) return;
    fetchWardForecasting();
    fetchRacks();
  }, [wardId.value]);

  const today = new Date();

  const blocker = async (tx: Transition) => {
    setTransition(tx);
    if (idStockRun != null && getStatusKey() != null) {
      await changeStatusStockRun(Number(idStockRun), Number(getStatusKey()));
      tx.retry();
      return;
    }
    if (hasUnsavedChange === true) {
      isConfirmLeavingModalOpen.setValue(true);
    } else {
      tx.retry();
    }
  };

  useBlocker(blocker, !isSubmitting.value || (idStockRun != null && getStatusKey() != null));

  const minDateCalendar = params.startDate
    ? max([new Date(String(params.startDate)), today])
    : today;
  const maxDateCalendar = params.endDate
    ? new Date(String(params.endDate))
    : new Date(minDateCalendar.getTime());

  const setStartDateInHoliday = () => {
    let start_date = minDateCalendar;
    while (calendarHoliday.find((item) => isSameDay(item, start_date))) {
      start_date = addDays(start_date, 1);
    }

    setStartDate(start_date);
  };

  const setCompleteDateInHoliday = () => {
    let complete_date = maxDateCalendar;
    while (calendarHoliday.find((item) => isSameDay(item, complete_date))) {
      if (params.demandId) {
        complete_date = subDays(complete_date, 1);
      } else {
        complete_date = addDays(complete_date, 1);
      }
    }

    setCompleteDate(complete_date);
  };

  useEffect(() => {
    if (params.forecastingWard) {
      wardId.setValue(Number(params.forecastingWard));
    }

    if (idStockRun) {
      return;
    }

    const startDateAfterNow = isDateGreaterThan(minDateCalendar, today);
    if (startDateAfterNow) {
      setStartDate(minDateCalendar);
      setCompleteDate(maxDateCalendar);
    } else if (params.forecastingWard) {
      setCompleteDate(maxDateCalendar);
    }
    setStartDateInHoliday();
    setCompleteDateInHoliday();
  }, [params.forecastingWard, params.startDate, JSON.stringify(calendarHoliday)]);

  useEffect(() => {
    if (
      idStockRun ||
      !params.forecastingWard ||
      !racks.length ||
      initForecastingItems.value ||
      forecasting === null
    )
      return;

    const forecastingBinItems = flatMap(racks.map((el) => el.bins));

    const forecastingBins = uniqBy(
      // Priotize fill forecast for bin have lowest qty
      orderBy(forecastingBinItems, ['item.qty', 'index'], ['asc', 'asc']),
      'item.id',
    );

    const reorderForecastingBins = orderBy(
      forecastingBins,
      ['warehouse.name', 'index'],
      ['desc', 'desc'],
    );

    const forecastingMappingItems: StockRunItem[] = reorderForecastingBins
      .map(({ item, ...bin }, index) => ({
        bin,
        inventory: item,
        quantity: getRecommendedQty(item?.id, forecasting) as any,
        order: index,
        qtyBalance: item?.qtyBalance || 0,
        restockValue: item?.restockValue || 0,
        alarmValue: item?.alarmValue || 0,
        parValue: item?.parValue || 0,
      }))
      .filter((el) => typeof el.quantity === 'number');
    setStockRunItems(forecastingMappingItems);
    initForecastingItems.setValue(true);
  }, [
    params.forecastingWard,
    racks.toString(),
    initForecastingItems.value,
    forecasting?.toString(),
  ]);

  const fetchRacks = async () => {
    const dataResRacks = await getRacks(
      `${stringifyRequestQuery({
        page: 1,
        limit: 100,
      })}&wardId=${wardId.value}`,
    );
    if (dataResRacks.data?.items.length) {
      const newRacks = orderBy(dataResRacks.data?.items, 'name').map((el) => {
        const newBins = el.bins.map((elBin) => ({
          ...elBin,
          warehouse: el,
          quantity: 0,
        }));
        return {
          ...el,
          bins: newBins,
        };
      });
      setRacks(newRacks);
    }
  };

  const getData = async () => {
    try {
      isLoading.setValue(true);
      const dataWardRes = await getWards(
        stringifyRequestQuery({
          page: 1,
          limit: 9999999,
        }) + `&hasRack=1`,
      );
      setWards(dataWardRes.data?.items ?? []);
      await getSettingHoliday();
      if (idStockRun) {
        await getStockRunItem();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const fetchWardForecasting = async () => {
    if (!params.demandId || idStockRun) {
      return;
    }
    const forecastRes = await getItemsForecast(wardId.value, Number(params.demandId));
    setForecasting(forecastRes);
  };

  const getStockRunItem = async () => {
    try {
      const dataStockRunRes = await getStockRun(idStockRun);
      if (!dataStockRunRes) {
        return;
      }

      statusRef.current = dataStockRunRes.status;
      setStockRunType(dataStockRunRes.type ?? null);
      if (
        dataStockRunRes.status === StatusStockUp.Posted ||
        dataStockRunRes.status === StatusStockUp['Past-due']
      ) {
        const requestStockRun: StockRunRequest = {
          endTime: dataStockRunRes.endTime,
          wardId: Number(dataStockRunRes.location?.id),
          status: StatusStockUp.Draft,
          startTime: dataStockRunRes.startTime,
        };
        try {
          await updateStockRun(idStockRun, requestStockRun);
          toast.info(
            <div className="d-flex align-items-center">
              <Typography variant="body-3">
                Stock run has been switched back to Draft status.
              </Typography>
            </div>,
          );
        } catch (err: any) {
          removeStatusKey();
          getResponseError(err.response.data.data);
          navigateWithPreviousPath(
            PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(idStockRun)),
          );
        }
      }

      const responseStartTime = new Date(dataStockRunRes.startTime);
      const responseCompleteTime = new Date(dataStockRunRes.endTime);

      //If the stock run's start day is in the future then set the stock run's start date, otherwise set the current date
      if (isAfter(responseStartTime, startOfDay(new Date()))) {
        setStartDate(new Date(dataStockRunRes.startTime));
      }

      if (isAfter(responseCompleteTime, endOfDay(new Date()))) {
        setCompleteDate(responseCompleteTime);
      }
      setOriginStatusStockRun(StatusStockUp.Draft);
      setStatusStockRun(StatusStockUp.Draft);

      wardId.setValue(Number(dataStockRunRes.location?.id));
      indexStockRun.setValue(dataStockRunRes.index);

      const dataRes = await getStockRunItems(
        stringifyRequestQuery({
          page: 1,
          limit: 100,
          orderBy: 'bin',
          orderDirection: 'ASC',
        }),
        Number(dataStockRunRes.id),
      );

      if (dataRes.data?.items) {
        setStockRunItems(dataRes.data?.items);
        setOriginStockRunItems(dataRes.data?.items);
        const oldForecasting = dataStockRunRes?.demandProjections?.map((el) => ({
          ...el,
          itemId: Number(el.inventory?.id),
          recommendedQty: el.recommendedQty,
        }));
        const newForecasting = dataRes.data?.items.map((el) => ({
          ...el,
          itemId: Number(el.inventory?.id),
          recommendedQty: el.recommendedQty,
        }));

        const forcestings = getForecastings(oldForecasting ?? [], newForecasting);
        setForecasting(forcestings);
      }
    } catch (error: any) {
      console.log('error', error);
      removeStatusKey();
      getResponseError(error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const getSettingHoliday = async () => {
    const holidays: Setting[] = await getSetting(SettingType.holiday);
    if (holidays.length && holidays[0].value === TypeHoliday.on && holidays[0].calendar?.length) {
      setCalendarHoliday(holidays[0].calendar.map((el) => new Date(el)));
    }
  };

  const onChangeWard = (value: number) => {
    if (stockRunItems.length) {
      setWarningChangeWard({
        open: true,
        wardId: value,
      });
      return;
    }
    wardId.setValue(value);
  };

  const fetchUpdatestockRunItem = async (stockRunId: number) => {
    const request: StockRunItemRequest[] = stockRunItems.length
      ? stockRunItems
          .filter((el) => el.quantity)
          .map((el) => {
            return {
              id: el.id,
              binId: Number(el.bin?.id),
              itemId: Number(el.inventory?.id),
              quantity: Number(el.quantity),
              stockRunId,
              order: el.order,
              recommendedQty: params.demandId
                ? getRecommendedQty(el.inventory?.id, forecasting as ForecastItem[])
                : undefined,
            };
          })
      : [];

    let removeItems = differenceBy(originStockRunItems, stockRunItems, 'id').map((item) =>
      Number(item.id),
    );

    if (stockRunId) {
      const editToZeroItems = stockRunItems.filter((el) => el.quantity == '0');
      removeItems = removeItems.concat(editToZeroItems.map((el) => Number(el.id)));
    }

    if (request.length || removeItems.length)
      await createStockRunItem({
        items: orderBy(request, 'order', 'desc'),
        removeItems,
      });
  };

  const onSubmitAddStockRun = (newStatus: StatusStockUp) => async () => {
    if (!completeDate || !startDate) {
      return toast.error('Complete Date is required');
    }
    // if (stockRunItems.filter((el) => Number(el.quantity) < 1).length) {
    //   return toast.error('Item minimum qty 1');
    // }
    if (newStatus === StatusStockUp.Posted && !stockRunItems.length) {
      return toast.error('At least one item must be added.');
    }
    isSubmitting.setValue(true);
    removeStatusKey();
    try {
      setStatusStockRun(newStatus);
      const requestStockRun: StockRunRequest = {
        endTime: getCompleteDate(completeDate),
        startTime: getStartDate(startDate),
        wardId: wardId.value,
        status: newStatus,
        type: params.demandId ? StockRunType.SIMS : StockRunType.manually,
        demandId: Number(params.demandId),
      };
      if (idStockRun) {
        await updateStockRun(idStockRun, requestStockRun);
        await fetchUpdatestockRunItem(Number(idStockRun));
        statusRef.current = newStatus;
        setStockRunItems([]);
        toast.success(`Update stock run successfully!`);
        isLoading.setValue(true);
        if (newStatus === StatusStockUp.Draft) {
          localStorage.removeItem('stock_run_status');
        }
        if (transition) {
          transition.retry();
        } else {
          navigateWithPreviousPath(
            PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(idStockRun)),
            {
              replace: true,
            },
          );
        }
        return;
      }
      const dataRes = await createStockRun(requestStockRun);
      if (dataRes) {
        if (stockRunItems.length) {
          await fetchUpdatestockRunItem(Number(dataRes.id));
        }
        navigateWithPreviousPath(
          PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(dataRes?.id)),
          {
            replace: true,
          },
        );
        toast.success(`Add stock run successfully!`);
      }
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isSubmitting.setValue(false);
    }
  };

  const onChangeWardWhenWarning = () => {
    wardId.setValue(warningChangeWard.wardId);
    setStockRunItems([]);
    setWarningChangeWard({
      open: false,
      wardId: 0,
    });
  };

  const isDisableBtn = isSubmitting.value || !completeDate || !wardId.value;

  const minDate =
    params.demandId && isDateGreaterThan(minDateCalendar, today) ? minDateCalendar : today;

  const getStockRunTypeTitle = () => {
    if (stockrunType !== null) return STOCK_TYPE_TITLE[stockrunType];
    if (!params.demandId && !idStockRun) {
      return STOCK_TYPE_TITLE[StockRunType.manually];
    }
    return '';
  };

  if (!hasUnsavedChange && transition && !getStatusKey()) {
    transition.retry();
  }

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <Header>
            <Typography fontWeight={700} fontSize={22} lineHeight={35} color="neutral-10">
              {`${idStockRun ? `Edit ${indexStockRun.value}` : 'Add stock run'}`}
            </Typography>

            <ActionButtonCard className="d-flex align-items-center">
              <ButtonDefault
                onClick={() => {
                  if (idStockRun) {
                    navigateWithPreviousPath(
                      PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(idStockRun)),
                    );
                  } else {
                    goBack();
                  }
                }}
                disabled={isSubmitting.value}
                theme="btn-light"
              >
                Cancel
              </ButtonDefault>
              {originStatusStockRun === StatusStockUp.Draft ? (
                <React.Fragment>
                  <ButtonDefault
                    isSubmitting={Boolean(
                      isSubmitting.value && statusStockRun === StatusStockUp.Draft,
                    )}
                    disabled={isDisableBtn}
                    theme="btn-none"
                    style={{
                      marginRight: 12,
                      marginLeft: 12,
                    }}
                    onClick={onSubmitAddStockRun(StatusStockUp.Draft)}
                    className="btn-white"
                  >
                    Save as draft
                  </ButtonDefault>

                  <ButtonDefault
                    onClick={onSubmitAddStockRun(StatusStockUp.Posted)}
                    isSubmitting={Boolean(
                      isSubmitting.value && statusStockRun === StatusStockUp.Posted,
                    )}
                    disabled={isDisableBtn || stockRunItems.every((el) => el.quantity == '0')}
                  >
                    Post
                  </ButtonDefault>
                </React.Fragment>
              ) : (
                <ButtonDefault
                  onClick={onSubmitAddStockRun(originStatusStockRun)}
                  isSubmitting={Boolean(isSubmitting.value)}
                  disabled={isDisableBtn}
                >
                  Save
                </ButtonDefault>
              )}
            </ActionButtonCard>
          </Header>
          <Information>
            <Typography variant="title-1" lineHeight={32}>
              Information
            </Typography>
            <FormInputBody>
              <FormSelect
                label="Ward"
                handleChange={(select) => onChangeWard(select.value)}
                optionsSelect={wards.map((el) => ({
                  title: el.name,
                  value: (el.id ?? ' ') as any,
                }))}
                activeSelect={wardId.value}
                fieldActive="value"
                fieldSelect="title"
                styleSelectCard={{
                  width: 300,
                }}
                isRequired
                isDisable={!!idStockRun || Boolean(params.demandId)}
                inputPlaceholder="Select ward"
              />
              <div className="d-flex justify-content-between">
                <div className="w-50 pe-3">
                  <FormInputDate
                    label="Start date"
                    placeholder="Start date"
                    isRequired
                    onChange={setStartDate}
                    selectedDate={startDate}
                    excludeDates={calendarHoliday}
                    minDate={minDate}
                    maxDate={params.demandId ? maxDateCalendar : undefined}
                    readOnly
                  />
                </div>
                <div className="w-50 ps-3">
                  <FormInputDate
                    label="Complete date"
                    placeholder="Complete date"
                    isRequired
                    onChange={setCompleteDate}
                    selectedDate={completeDate}
                    excludeDates={calendarHoliday}
                    minDate={minDate}
                    maxDate={params.demandId ? maxDateCalendar : undefined}
                    readOnly
                  />
                </div>
              </div>
              <InfoItemStockRunCard
                title="Stock run type"
                value={
                  params.demandId ? STOCK_TYPE_TITLE[StockRunType.SIMS] : getStockRunTypeTitle()
                }
              />
            </FormInputBody>
          </Information>
        </div>
      }
    >
      {wardId.value && !isLoading.value ? (
        <ListItemBody>
          <ListItemInStockRun
            stockRunItems={stockRunItems}
            forecasting={forecasting || []}
            setStockRunItems={setStockRunItems}
            wardId={wardId.value}
            racks={racks}
            timeFrame={String(params.timeFrame)}
          />
        </ListItemBody>
      ) : null}
      {warningChangeWard.open && (
        <ModalDefault
          title="Warning"
          onClickCloseModal={() =>
            setWarningChangeWard({
              open: false,
              wardId: 0,
            })
          }
          isConfirmationModal
        >
          <Typography variant="body-3" style={{ paddingBottom: 24 }}>
            All chosen things will be deleted if the ward is changed.
          </Typography>
          <ActionButton
            onCancel={() =>
              setWarningChangeWard({
                open: false,
                wardId: 0,
              })
            }
            labelButtonSubmit="Change"
            themeButton="btn-primary"
            onSubmit={onChangeWardWhenWarning}
          />
        </ModalDefault>
      )}
      {isConfirmLeavingModalOpen.value && (
        <ConfirmLeavingModal
          onCloseModal={() => {
            isConfirmLeavingModalOpen.setValue(false);
            setTransition(null);
          }}
          onCancel={() => {
            setStockRunItems(originStockRunItems);
          }}
          onSubmit={onSubmitAddStockRun(StatusStockUp.Draft)}
        />
      )}
      {isLoading.value && <LoadingCard />}
    </RootContentPage>
  );
};

export default BodyFormStockRun;
