import { useEffect, useState } from 'react';
import { Row } from 'react-table';

import HeartBeatStatus from 'app/components/Status/HeartBeatStatus';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES, SORT_BY_NAME_TABLE_QUERY } from 'app/components/Table/core/constants';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useSyncQueryToState } from 'app/modules/query-state/hooks';

import { useNavigateWithSendPath } from 'app/helpers/utils';

import { HIDDEN_BUTTON_ADD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { getCustomers } from '../../company-information/core/requests';
import { Customer, initialCustomer } from '../../company-information/core/type';
import { wardsColumns } from './core/columns';
import { deleteWard, getWards } from './core/requests';
import { InventoryConnectionStatus, InventoryConnectionType, Ward } from './core/type';
import { FormWardTableCard } from './modal/FormWardTableCard';

const WardManagementPage = () => {
  useSyncQueryToState(window.location.pathname, SORT_BY_NAME_TABLE_QUERY);

  const [customer, setCustomer] = useState<Customer>(initialCustomer);
  const navigate = useNavigateWithSendPath();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const dataRes = await getCustomers();
    if (dataRes.data) setCustomer(dataRes.data);
  };

  const renderModalForm = (originalData: any) => {
    return <FormWardTableCard ward={originalData} customer={customer} />;
  };
  const onClickBodyTable = (row: Row) => () => {
    const newWard = row.original as Ward;
    if (newWard.id) {
      navigate(PATH.BEDS.replace(':id', String(newWard.id)));
    }
  };
  return (
    <RootContentPage
      rootBody={{
        padding: 0,
        border: 'none',
      }}
      title="Wards"
    >
      <TableComponent
        getList={getWards}
        nameQuery={QUERIES.WARDS_LIST}
        originColumns={wardsColumns()}
        titleModal="Ward"
        ModalForm={renderModalForm}
        deleteById={deleteWard}
        placeholderSearch="Search with ward"
        onClickBodyTable={onClickBodyTable}
        nameBtnAdd={!HIDDEN_BUTTON_ADD ? 'Add ward' : ''}
        isBorder={false}
        syncParamUrl
        spacing="xxl"
      />
    </RootContentPage>
  );
};

export default WardManagementPage;
