export enum WeightUnitsEnum {
  'g' = 0,
}
export enum QtyUnitsEnum {
  'Piece' = 0,
  'Set' = 1,
  'Box' = 2,
}
export enum StockTypeEnum {
  'Non-Chargeable' = 0,
  'Chargeable' = 1,
}
