export enum StatusStockUp {
  Draft = 1,
  Posted = 2,
  WIP = 3,
  Completed = 4,
  'Past-due' = 5,
}

export enum StockLevel {
  normal = 'normal',
  par = 'par', // below par level
  restock = 'restock', // below restock level
  alarm = 'alarm', // below alarm level
}

export enum TAB_STOCK_RUN_DETAIL {
  ITEM = 1,
  HISTORY = 2,
}

export enum StockRunType {
  manually,
  SIMS,
}
