import { Line } from 'react-chartjs-2';

import { COLOR } from 'app/components/Typography/core/enum';

import { getLineChartOptions } from 'app/modules/dashboard/core/helpers';
import { ForecastCurve } from 'app/modules/projection/core/type';

import { uniq } from 'lodash';

import { ItemLineData, LineChartDataset } from '../core/type';
import { LINE_STYLE_CONFIG } from './ForecastChart';
import {
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  Color,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

interface Props {
  report?: ItemLineData | null;
  ward?: string;
  customTitle?: string;
}

export const ReportChart: React.FC<Props> = ({ report, ward, customTitle }) => {
  const chartLabel = customTitle || (ward ? `Quantity balance in ward ${ward}` : undefined);

  const forecastLabels = uniq([
    ...(report?.balanceForecast?.normal?.map((el) => el.x) || []),
    ...(report?.balanceForecast?.upper?.map((el) => el.x) || []),
    ...(report?.balanceForecast?.lower?.map((el) => el.x) || []),
  ]);

  const labels = uniq([...(report?.balance?.time ?? []), ...forecastLabels]);

  const forecastBase: (number | null)[] =
    report?.balance?.qty?.slice(0, -1).map((el, index) => {
      if (index === (report?.balance?.qty?.length || 0) - 2) return el;
      return null;
    }) || [];

  const getForecastDataset = (forecast?: LineChartDataset) => {
    if (!forecast?.length) return [];
    return forecastBase.concat(forecast?.map((el) => el.y) || []);
  };

  const getStockValueDataset = (stockValue: (number | null)[]) => {
    return stockValue.concat(forecastLabels.map((el) => stockValue[stockValue.length - 1]));
  };

  const getForeCastLineStyle = (approvedType: ForecastCurve, data?: (number | null)[]) => {
    return {
      label: `Projected qty (${LINE_STYLE_CONFIG[approvedType].label})`,
      data: data,
      borderColor: LINE_STYLE_CONFIG[approvedType].color,
      backgroundColor: LINE_STYLE_CONFIG[approvedType].color,
      borderWidth: 3,
      borderDash: [9, 5],
    } as ChartDataset<'line', (number | null)[]>;
  };

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        label: 'Qty',
        data: report?.balance?.qty ?? [],
        borderColor: COLOR.PRIMARY, // Xanh
        backgroundColor: COLOR.PRIMARY,
      },
      {
        label: 'AVG (MA 30)',
        data: report?.balance?.avg ?? [],
        borderColor: COLOR.neutral_7,
        backgroundColor: COLOR.neutral_7,
        borderWidth: 3,
        borderCapStyle: 'round',
      },
      getForeCastLineStyle(ForecastCurve.upper, getForecastDataset(report?.balanceForecast?.upper)),
      getForeCastLineStyle(
        ForecastCurve.normal,
        getForecastDataset(report?.balanceForecast?.normal),
      ),
      getForeCastLineStyle(ForecastCurve.lower, getForecastDataset(report?.balanceForecast?.lower)),
      {
        label: 'Par level',
        data: getStockValueDataset(report?.balance?.par ?? []),
        borderColor: COLOR.success_3,
        backgroundColor: COLOR.success_3,
      },
      {
        label: 'Restock level',
        data: getStockValueDataset(report?.balance?.restock ?? []),
        borderColor: COLOR.WARNING,
        backgroundColor: COLOR.WARNING, // Vang
      },
      {
        label: 'Alarm level',
        data: getStockValueDataset(report?.balance?.alarm ?? []),
        borderColor: COLOR.DANGER,
        backgroundColor: COLOR.DANGER,
      },
    ],
  };

  const options = getLineChartOptions({
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart: Chart) => {
            return chart.data.datasets
              .filter((dataset) => dataset.data.length > 0)
              .map((item) => ({
                text: item.label || '',
                lineDash: (item.label || '').includes('Projected') ? [4, 6] : [],
                strokeStyle: item.borderColor as Color,
                fillStyle: item.backgroundColor as Color,
                lineWidth: 5,
                pointStyle: 'line',
                lineCap: 'round',
                fontColor: COLOR.neutral_7,
              }));
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            //At the connecting point between qty line and forecast lines, show qty only
            if (
              context.parsed.x === forecastBase.length - 1 &&
              context.dataset.label?.includes('Projected')
            ) {
              return [];
            }
          },
          labelColor: (context) => {
            return {
              borderColor: context.dataset.borderColor as Color,
              backgroundColor: context.dataset.backgroundColor as Color,
            };
          },
        },
      },
      title: {
        display: true,
        padding: { bottom: ward || customTitle ? 32 : 0 },
        text: chartLabel,
        font: { size: 18 },
      },
    },
    scales: {
      y: {
        suggestedMax: 5,
        min: 0,
        ticks: { stepSize: 1 },
      },
    },
  });

  return <Line options={options} data={data} />;
};
