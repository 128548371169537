import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useBoolean } from 'app/hooks';

import StockLevelSettingCard from './components/StockLevelSettingCard';
import { getStockValue, updateSetting } from './core/request';
import { SettingType, StockLevelSetting, StockLevelSettingType, StockValues } from './core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Body = styled.div`
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
`;

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
`;

const validateSchema = (alarm?: boolean) =>
  Yup.object().shape({
    stockValue: Yup.object().shape({
      value: Yup.object().shape({
        chargeable: Yup.number().required(`${alarm ? 'Alarm' : 'Restock'} level is required`),
        nonChargeable: Yup.number().required(`${alarm ? 'Alarm' : 'Restock'} level is required`),
      }),
    }),
  });

type StockTypeState = { stockValue: StockValues | null };

const StockValuesPage = () => {
  const isLoading = useBoolean(false);

  const isEditRestock = useBoolean(false);
  const isEditAlarm = useBoolean(false);

  const [restockLevel, setRestockLevel] = useState<StockValues | null>(null);

  const [alarmLevel, setAlarmLevel] = useState<StockValues | null>(null);

  const onSubmit =
    (alarm?: boolean) =>
    async ({ stockValue }, { setSubmitting }) => {
      if (stockValue === null) {
        return;
      }
      setSubmitting(true);
      const newValue: StockLevelSetting = {
        chargeable: parseFloat(stockValue?.value?.chargeable),
        nonChargeable: parseFloat(stockValue?.value?.nonChargeable),
      };
      updateSetting(Number(stockValue.id), {
        name: stockValue.name,
        type: SettingType.stockLevel,
        value: JSON.stringify(newValue),
      });

      setSubmitting(false);
      if (alarm) {
        isEditAlarm.setValue(false);
        setAlarmLevel(
          (prev) =>
            ({
              ...prev,
              value: newValue,
            } as StockValues),
        );
      } else {
        isEditRestock.setValue(false);
        setRestockLevel(
          (prev) =>
            ({
              ...prev,
              value: newValue,
            } as StockValues),
        );
      }
      toast.success(`Update ${alarm ? 'Alarm' : 'Restock'} level successfully!`);
    };

  const restockFormik = useFormik<StockTypeState>({
    initialValues: { stockValue: null },
    validationSchema: validateSchema(),
    onSubmit: onSubmit(),
  });

  const alarmFormik = useFormik<StockTypeState>({
    initialValues: { stockValue: null },
    validationSchema: validateSchema(true),
    onSubmit: onSubmit(true),
  });

  const fetchStockValues = async () => {
    isLoading.setValue(true);
    const dataRes = await getStockValue();

    const dataRestockLevel = dataRes.find((value) => value.name === StockLevelSettingType.restock);
    const dataAlarmLevel = dataRes.find((value) => value.name === StockLevelSettingType.alarm);

    if (dataRestockLevel) {
      restockFormik.setValues({
        stockValue: dataRestockLevel,
      });
      setRestockLevel(dataRestockLevel);
    }

    if (dataAlarmLevel) {
      alarmFormik.setValues({
        stockValue: dataAlarmLevel,
      });
      setAlarmLevel(dataAlarmLevel);
    }
    isLoading.setValue(false);
  };

  useEffect(() => {
    fetchStockValues();
  }, []);

  useEffect(() => {
    if (isEditRestock.value === true) {
      isEditAlarm.setValue(false);
      alarmFormik.setValues({
        stockValue: alarmLevel,
      });
    }
  }, [isEditRestock.value]);

  useEffect(() => {
    if (isEditAlarm.value === true) {
      isEditRestock.setValue(false);
      restockFormik.setValues({
        stockValue: restockLevel,
      });
    }
  }, [isEditAlarm.value]);

  return (
    <Body className="d-flex flex-column">
      <StockLevelSettingCard
        isEdit={isEditRestock.value}
        setEdit={isEditRestock.setValue}
        originValue={restockLevel}
        formik={restockFormik}
      />
      <StockLevelSettingCard
        isEdit={isEditAlarm.value}
        setEdit={isEditAlarm.setValue}
        originValue={alarmLevel}
        formik={alarmFormik}
        containerStyle={{ marginTop: 24, marginBottom: 28 }}
      />
      {restockLevel === null && <LoadingCard />}
    </Body>
  );
};

export default StockValuesPage;
