import { FC } from 'react';
import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

import { ReactComponent as ChartIcon } from 'app/assets/icons/icon-chart.svg';

const Button = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--neutral-3);
  background: var(--white);
  cursor: pointer;
  :hover {
    background: var(--neutral-3);
    svg {
      path {
        stroke: var(--neutral-10) !important;
      }
    }
  }
`;

interface Props {
  onClick: () => void;
  type: 'forecast' | 'balance';
}

const ShowChartButton: FC<Props> = ({ onClick, type }) => {
  return (
    <TippyDefault
      arrow={true}
      theme="dark"
      content={
        <Typography variant="body-4" color="white">
          {type === 'forecast' ? 'View ML Projected Demand chart' : 'View Quantity Balance chart'}
        </Typography>
      }
      containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Button onClick={onClick}>
        <ChartIcon />
      </Button>
    </TippyDefault>
  );
};

export default ShowChartButton;
