import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';

import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { ReactComponent as IconThreeDots } from 'app/assets/icons/icon-3-dots.svg';

import { useListView } from '../core/ListViewProvider';
import { DEFAULT_ACTION_TABLE } from '../core/constants';
import { ActionTable, TYPE_MODAL } from '../core/types';
import { MenuComponent } from 'theme/assets/ts/components';

const MENU_CONTAINER_WIDTH = 162;
const TOTAL_PADDING = 32;
const DEFAULT_ITEM_CONTENT_WIDTH = MENU_CONTAINER_WIDTH - TOTAL_PADDING;

const ButtonAction = styled.div`
  width: 38px;
  height: 38px;
  max-width: 38px;
  max-height: 38px;
  border-radius: 8px;
  background: var(--white);
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background: var(--neutral-3);
  }
`;
const Main = styled.div``;
export const MenuOptionsDivider = styled.div<{ length: number }>`
  height: 1px;
  width: ${({ length }) => length}px;
  margin: 8px;
  background-color: #d9d9d9;
  border-radius: 1px;
`;

type Props = {
  item: any;
  actions?: ActionTable[];
  inSession?: boolean;
  dividerIndexes?: number[];
};

const ActionsCell: FC<Props> = ({
  item,
  actions = DEFAULT_ACTION_TABLE,
  inSession,
  dividerIndexes,
}) => {
  const { setItemForUpdate } = useListView();
  const isOpenMenu = useBoolean(false);
  const navigateWithSendPath = useNavigateWithSendPath();
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openChangeModal = (action: ActionTable) => () => {
    if (
      inSession &&
      [TYPE_MODAL.REMOVE_ITEM, TYPE_MODAL.ASSIGN_ITEM, TYPE_MODAL.EDIT_QUANTITY].includes(
        action.type,
      )
    ) {
      return toast.error(
        'This function has been momentarily suspended due to an active tablet session. Please try again later.',
      );
    }
    isOpenMenu.setValue(false);
    if (action.redirect) {
      navigateWithSendPath(action.redirect.replace(':id', item.id));
      return;
    }
    setItemForUpdate({
      originalData: item,
      type: action.type,
      isView: false,
      titleModal: action.titleModal ?? '',
    });
  };

  return (
    <>
      {actions.length ? (
        <Main>
          <TippyDefault
            interactive
            arrow={false}
            containerClass="action-menu"
            placement="bottom-end"
            offset={[0, 5]}
            trigger="click"
            visible={isOpenMenu.value}
            onClickOutside={() => isOpenMenu.setValue(false)}
            allowHTML
            content={
              <MenuAction
                style={{
                  padding: 8,
                  width: MENU_CONTAINER_WIDTH,
                }}
              >
                {actions.map((el, index) => {
                  return (
                    <>
                      <MenuItem
                        style={{ marginBottom: index === actions.length - 1 ? 0 : 4 }}
                        key={index}
                        onClick={openChangeModal(el)}
                      >
                        {el.name}
                      </MenuItem>
                      {dividerIndexes?.includes(index) && index < actions.length - 1 ? (
                        <MenuOptionsDivider length={DEFAULT_ITEM_CONTENT_WIDTH} />
                      ) : null}
                    </>
                  );
                })}
              </MenuAction>
            }
          >
            <div className="d-flex">
              <ButtonAction onClick={() => isOpenMenu.setValue(!isOpenMenu.value)}>
                <IconThreeDots />
              </ButtonAction>
            </div>
          </TippyDefault>
        </Main>
      ) : null}
    </>
  );
};

export { ActionsCell };
