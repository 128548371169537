import { useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

import { AddButton } from 'app/components/Button/AddButton';
import FilterDefault from 'app/components/Filter/FilterDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { QueryState } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useAuth } from 'app/modules/auth';
import {
  checkParamSynced,
  useChangeParams,
  useGetQueryParams,
  useSyncQueryToState,
} from 'app/modules/query-state/hooks';
import { DemandButton } from 'app/modules/reporting/ReportPage';
import { TypeHoliday } from 'app/modules/system-settings/configuration/core/enum';
import { getSetting } from 'app/modules/system-settings/configuration/core/request';
import { Setting, SettingType } from 'app/modules/system-settings/configuration/core/type';
import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { usePolicyInUser } from 'app/hooks/policy';
import {
  addDays,
  addMonths,
  differenceInMonths,
  endOfMonth,
  getDate,
  getMonth,
  setDate,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { omit } from 'lodash';

import { DEFAULT_WARD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { ReactComponent as CalendarIcon } from 'app/assets/icons/icon-date.svg';
import { ReactComponent as IconList } from 'app/assets/icons/icon-view-list-rack.svg';
import { ReactComponent as LogoThumbnail } from 'app/assets/icons/logo-thumbnail.svg';

import ButtonSwitchView, { ViewMode } from '../../../components/Button/ButtonSwitchView';
import StockRunFilter from './components/StockRunFilter';
import { CalendarNavigator, StockRunCalendar } from './components/calendar';
import { stockRunsColumns } from './core/columns';
import { EVENT_TAG_BG } from './core/constants';
import { coverFilterToQuery, useNavigateAddStockRunBySims } from './core/helpers';
import { deleteStockRun, getStockRuns } from './core/requests';
import { StockRun, StockRunFilterDetail } from './core/type';
import { NoRecommendModal } from './modal/NoRecommend.modal';
import { stringify } from 'qs';

const Main = styled.div`
  .card-toolbar {
    flex-direction: row-reverse;
  }
  .active {
    background: var(--primary-3);
    color: var(--primary);
  }
`;

const STOCK_RUN_MESSAGE = ['Switch to Calendar view', 'Switch to Table view'];

export type StockRunParam = Partial<QueryState> &
  Partial<StockRunFilterDetail> & {
    mode?: ViewMode;
    c_from_commence_date?: string;
    c_to_commence_date?: string;
  };

const DEFAULT_MODE = ViewMode.nonList;

const DEFAULT_STOCK_RUN_QUERY: QueryState = {
  limit: 10,
  page: 1,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

const now = new Date();
const startOfMonthDate = subDays(startOfMonth(now), 6).toUTCString();
const endOfMonthDate = addDays(endOfMonth(now), 6).toUTCString();

const getEventList = (events: StockRun[] | null) => {
  return events
    ? events.map((ev) => ({
        start: new Date(ev.actualStartTime ?? ev.startTime),
        end: new Date(ev.actualStartTime ?? ev.startTime),
        title: ev.index,
        tagColor: EVENT_TAG_BG[ev.status],
        status: ev.status,
        stockLevel: ev.stockLevel,
        id: ev.id,
      }))
    : null;
};

const StockRunManagementPage = () => {
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);
  const mode = new URLSearchParams(window.location.search).get('mode');
  useSyncQueryToState<StockRunParam>(
    window.location.pathname,
    {
      mode: (mode as any) || DEFAULT_MODE,
      c_from_commence_date: mode === String(ViewMode.list) ? undefined : startOfMonthDate,
      c_to_commence_date: mode === String(ViewMode.list) ? undefined : endOfMonthDate,
    },
    { skipFields: ['mode', 'c_from_commence_date', 'c_to_commence_date'] },
  );
  const { synced, curParams } = useGetQueryParams<StockRunParam>();
  const { onChangeParams } = useChangeParams();
  const { c_from_commence_date, c_to_commence_date } = curParams;
  const isSynced = checkParamSynced(synced);
  const navigateAddStockRunBySims = useNavigateAddStockRunBySims(
    PATH.STOCK_RUN_DEMAND_PROJECTION_DETAIL,
  );

  const calendarDate = useMemo(() => {
    if (!c_from_commence_date) {
      return now;
    }
    const fromDate = new Date(c_from_commence_date);

    const specialCurrentDay =
      getDate(now) >= getDate(endOfMonth(getMonth(fromDate) + 1)) ? 1 : getDate(now);

    const currentDay = differenceInMonths(now, fromDate) === 1 ? getDate(now) : specialCurrentDay;
    return setDate(addMonths(fromDate, 1), currentDay);
  }, [c_from_commence_date]);

  const navigate = useNavigateWithSendPath();

  const viewMode = curParams.mode ?? mode ?? DEFAULT_MODE;

  const [wards, setWards] = useState<Ward[]>([]);
  const [holidays, setHolidays] = useState<Date[] | undefined>();

  const openMenu = useBoolean(false);
  const isLoadingEvent = useBoolean(false);
  const openNoRecommend = useBoolean(false);
  const selectDate = useRef<number | null>(null);

  const isCalendarMode = String(viewMode) === String(ViewMode.nonList);

  const [events, setEvents] = useState<StockRun[] | null>(null);

  const addStockRunMenuItems = [
    { title: 'Add manually', onClick: () => navigate(PATH.INVENTORY_STOCK_RUN_ADD) },
    {
      title: 'Add by SIMS',
      onClick: (startDate?: number) => navigateAddStockRunBySims(Number(DEFAULT_WARD), startDate),
    },
  ];

  const fetchHoliday = async () => {
    const holidayItems: Setting[] = await getSetting(SettingType.holiday);
    if (
      holidayItems.length &&
      holidayItems[0].value === TypeHoliday.on &&
      holidayItems[0].calendar?.length
    ) {
      setHolidays(holidayItems[0].calendar.map((el) => startOfDay(new Date(el))));
    }
  };

  const fetchEvent = async () => {
    setEvents(null);
    isLoadingEvent.setValue(true);
    const eventRes = await getStockRuns(
      stringify({
        limit: 9999999,
        page: 1,
        orderBy: 'startTime',
        orderDirection: 'DESC',
      }) + coverFilterToQuery(omit(curParams, 'from_commence_date', 'to_commence_date')),
    );
    isLoadingEvent.setValue(false);
    setEvents(eventRes.data?.items || []);
  };

  useEffect(() => {
    getListWard();
    fetchHoliday();
  }, []);

  useEffect(() => {
    if (!isSynced || !isCalendarMode) {
      return;
    }

    const fromStartDateParam = new URLSearchParams(window.location.search).get(
      'c_from_commence_date',
    );
    const toStartDateParam = new URLSearchParams(window.location.search).get('c_to_commence_date');
    if (!fromStartDateParam || !toStartDateParam) {
      onChangeParams({
        c_from_commence_date: startOfMonthDate,
        c_to_commence_date: endOfMonthDate,
      });
      return;
    }

    if (!c_from_commence_date || !c_to_commence_date) {
      return;
    }

    fetchEvent();
  }, [isSynced, isCalendarMode, JSON.stringify(curParams)]);

  const onChangeMonth = (type: 'prev' | 'next') => {
    if (!c_from_commence_date || !c_to_commence_date) {
      return;
    }
    setEvents(null);
    const newDate = type === 'prev' ? subMonths(calendarDate, 1) : addMonths(calendarDate, 1);

    onChangeParams({
      c_from_commence_date: subDays(startOfMonth(newDate), 6),
      c_to_commence_date: addDays(endOfMonth(newDate), 6),
    });
  };

  const onChangeViewMode = (view: ViewMode) => () => {
    if (String(view) === String(viewMode)) {
      // Same view do nothing
      return;
    }

    // Autual change view mode
    if (view === ViewMode.nonList) {
      setEvents(null);
      onChangeParams({
        mode: view,
        c_from_commence_date: startOfMonthDate,
        c_to_commence_date: endOfMonthDate,
      });
    } else {
      onChangeParams({ ...DEFAULT_STOCK_RUN_QUERY, ...curParams, mode: view });
    }
  };

  const getListWard = async () => {
    try {
      const dataRes = await getWards(stringifyRequestQuery({ page: 1, limit: 100 }));
      setWards(dataRes.data?.items ?? []);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onRedirectPage = () => {
    navigate(PATH.INVENTORY_STOCK_RUN_ADD);
  };

  const onClickBodyTable = (row: Row) => () => {
    const newStockRun = row.original as StockRun;
    if (newStockRun.id) {
      navigate(PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(newStockRun.id)));
    }
  };

  const onUpdateFilter = () => {
    openMenu.setValue(false);
  };

  return (
    <RootContentPage
      title="Stock run"
      rootBody={{
        padding: 0,
        border: 'none',
        pointerEvents: isLoadingEvent.value ? 'none' : undefined,
      }}
      AdditionHeaderComponent={
        <div className="d-flex align-items-center">
          <AddButton
            title={addStockRunMenuItems[0].title}
            onClick={() => addStockRunMenuItems[0].onClick()}
            style={{ margin: '0 12px' }}
            color="white"
          />
          {(policy.viewProjection || policy.approveProjection) && (
            <DemandButton>
              <AddButton
                title={addStockRunMenuItems[1].title}
                onClick={() => addStockRunMenuItems[1].onClick()}
                style={{ minWidth: 153.25 }}
                icon={<LogoThumbnail style={{ marginRight: 12 }} />}
              />
            </DemandButton>
          )}
        </div>
      }
    >
      <Main>
        <TableComponent
          syncParamUrl
          getList={getStockRuns}
          nameQuery={QUERIES.STOCK_RUN_LIST}
          originColumns={stockRunsColumns()}
          titleModal="stock run"
          deleteById={deleteStockRun}
          styleModalDialog={{
            minWidth: 800,
          }}
          onRedirectPage={onRedirectPage}
          onClickBodyTable={onClickBodyTable}
          placeholderSearch="Search with index, ward"
          isBorder={false}
          hideBody={isCalendarMode}
          CustomToolbar={{
            Left: isCalendarMode ? (
              <CalendarNavigator date={calendarDate} onChange={onChangeMonth} />
            ) : undefined,
          }}
          customListHeader={
            <>
              <ButtonSwitchView
                view={Number(viewMode)}
                onClickButton={onChangeViewMode}
                messages={STOCK_RUN_MESSAGE}
                iconList={[IconList, CalendarIcon]}
              />
              <FilterDefault
                visible={openMenu.value}
                onClickFilter={(type) => openMenu.setValue(type === 'on')}
                isFilter={Boolean(
                  coverFilterToQuery(
                    (isCalendarMode
                      ? omit(
                          curParams,
                          'c_from_commence_date',
                          'c_to_commence_date',
                          'from_commence_date',
                          'to_commence_date',
                        )
                      : omit(curParams, 'c_from_commence_date', 'c_to_commence_date')) as any,
                  ),
                )}
                content={
                  <StockRunFilter
                    originWards={wards}
                    onUpdateFilter={onUpdateFilter}
                    openMenu={openMenu.value}
                  />
                }
              />
            </>
          }
          spacing="lg"
        />

        {isCalendarMode ? (
          <StockRunCalendar
            date={calendarDate}
            events={getEventList(events)}
            holidays={holidays}
            forecastingWardLoaded={false}
            onSimInputClick={(startDate) => {
              if (startDate) {
                selectDate.current = startDate;
              }
              addStockRunMenuItems[1].onClick();
            }}
          />
        ) : null}
        {isLoadingEvent.value && <LoadingCard />}

        {openNoRecommend.value && (
          <NoRecommendModal onClose={() => openNoRecommend.setValue(false)} />
        )}
      </Main>
    </RootContentPage>
  );
};

export default StockRunManagementPage;
