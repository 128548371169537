import { StatusStockUp, StockRunType } from './enum';
import { HeaderTableItem, StockRunFilterDetail } from './type';

export const LIST_STATUS_STOCK_RUN = [StatusStockUp.Draft, StatusStockUp.Posted];

export const HEADERS_LIST_ITEM_STOCK_RUN: HeaderTableItem[] = [
  {
    title: '#',
    field: 'index',
    style: {
      width: 52,
      maxWidth: 52,
      minWidth: 52,
    },
  },
  {
    title: 'Item',
    field: 'item',
    style: {
      width: 'calc(55% - 232px)',
    },
  },
  {
    title: 'SKU',
    field: 'sku',
    style: {
      width: '15%',
      minWidth: 150,
    },
  },
  {
    title: 'Rack',
    field: 'rack',
    style: {
      width: '15%',
      minWidth: 90,
    },
  },
  {
    title: 'Bin',
    field: 'bin',
    style: {
      width: '10%',
      minWidth: 80,
    },
  },
  {
    title: 'Par level',
    field: 'parValue',
    style: {
      width: '10%',
      minWidth: 80,
    },
  },
  {
    title: 'Restock level',
    field: 'restockValue',
    style: {
      width: '10%',
      minWidth: 80,
    },
  },
  {
    title: 'Alarm level',
    field: 'alarmValue',
    style: {
      width: '10%',
      minWidth: 80,
    },
  },
  {
    title: 'Qty balance',
    field: 'qtyBalance',
    style: {
      width: '10%',
      minWidth: 80,
    },
  },
  {
    title: 'Stock health',
    field: 'stock-level',
    style: {
      width: '10%',
    },
  },
  {
    title: 'Qty to stock up',
    field: 'qty',
    style: {
      width: 242,
      boxSizing: 'border-box',
    },
  },
];

export const HEADERS_LIST_ITEM_STOCK_RUN_DETAIL: HeaderTableItem[] = [
  {
    title: '#',
    field: 'index',
    style: {
      width: 52,
      maxWidth: 52,
      minWidth: 52,
    },
  },
  {
    title: 'Item',
    field: 'item',
    style: {
      width: 'calc(37% - 52px)',
    },
  },
  {
    title: 'SKU',
    field: 'sku',
    style: {
      width: '15%',
    },
  },
  {
    title: 'Rack',
    field: 'rack',
    style: {
      width: '12%',
    },
  },
  {
    title: 'Bin',
    field: 'bin',
    style: {
      width: '12%',
    },
  },
  {
    title: 'Qty to stock up',
    field: 'qty_to_stock_up',
    style: {
      width: '12%',
    },
  },
  {
    title: 'Qty completed',
    field: 'qty_completed',
    style: {
      width: '12%',
    },
  },
];

export const emptyStockRunFilter: StockRunFilterDetail = {
  status: [],
  wardIds: [],
  from_commence_date: null,
  from_complete_date: null,
  to_commence_date: null,
  to_complete_date: null,
};

export const EVENT_TAG_BG: { [key in StatusStockUp]: string } = {
  [StatusStockUp.Draft]: 'var(--neutral-6)',
  [StatusStockUp.Posted]: 'var(--primary)',
  [StatusStockUp.WIP]: 'var(--warning)',
  [StatusStockUp.Completed]: 'var(--success-3)',
  [StatusStockUp['Past-due']]: 'var(--danger)',
};

export const STOCK_RUN_STATUS_BG: { [key in StatusStockUp]: string } = {
  [StatusStockUp.Draft]: 'var(--neutral-3)',
  [StatusStockUp.Posted]: 'var(--primary-3)',
  [StatusStockUp.WIP]: 'var(--warning-2)',
  [StatusStockUp.Completed]: 'var(--success-light-1-5)',
  [StatusStockUp['Past-due']]: 'var(--danger-light-1-5)',
};

export const STOCK_RUN_STATUS_BG_DETAIL: { [key in StatusStockUp]: string } = {
  [StatusStockUp.Draft]: 'var(--neutral-3)',
  [StatusStockUp.Posted]: 'var(--primary-3)',
  [StatusStockUp.WIP]: 'var(--warning-light-3)',
  [StatusStockUp.Completed]: 'var(--success-light-2)',
  [StatusStockUp['Past-due']]: 'var(--danger-light-2)',
};

export const STOCK_RUN_STATUS_COLOR: { [key in StatusStockUp]: string } = {
  [StatusStockUp.Draft]: 'neutral-7',
  [StatusStockUp.Posted]: 'primary',
  [StatusStockUp.WIP]: 'warning-active-2',
  [StatusStockUp.Completed]: 'success',
  [StatusStockUp['Past-due']]: 'danger',
};

export const STOCK_TYPE_TITLE = {
  [StockRunType.SIMS]: 'Added by SIMS',
  [StockRunType.manually]: 'Added manually',
};
