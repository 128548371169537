import { MouseEventHandler } from 'react';

import Typography from 'app/components/Typography';

import { ReactComponent as IconCloseModal } from 'app/assets/icons/icon-close-modal.svg';

interface Props {
  title?: string;
  customHeader?: React.ReactNode;
  onClickCloseModal?: MouseEventHandler<HTMLDivElement>;
  headerModalStyle?: React.CSSProperties;
}
const Header: React.FC<Props> = ({ title, customHeader, onClickCloseModal, headerModalStyle }) => {
  return (
    <div
      className="modal-header"
      style={{
        padding: '0px 28px',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        ...headerModalStyle,
      }}
    >
      {title && <Typography variant="heading-6">{title}</Typography>}
      {customHeader}
      <div onClick={onClickCloseModal}>
        <IconCloseModal style={{ cursor: 'pointer', transform: 'translateX(12px)' }} />
      </div>
    </div>
  );
};

export { Header };
