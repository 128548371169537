import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  orderBy: string;
  orderDirection: 'DESC' | 'ASC' | undefined;
}

interface Store {
  state: State;
  setState: (payload: State) => void;
  clearState: () => void;
}

const initState: State = {
  orderBy: '',
  orderDirection: undefined,
};

export const useOrderStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);
