import { ID, Response } from 'app/components/Table/core/types';

import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { Device } from '../../../system-settings/devices/core/type';
import { Bin } from '../../bins/core/type';
import { Item } from '../../items/core/type';
import { EventTypeLogRack, InventoryMovementType, TypeLog } from './enum';

export type Rack = {
  id: ID;
  name: string;
  type: string;
  code: string;
  items?: number;
  info: string;
  location?: Ward;
  inventoryLocation?: {
    inventory?: Device;
  };
  bins: Bin[];
  version: number;
  isSync: boolean;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
};

export const emptyRack: Rack = {
  code: '',
  id: '',
  info: '',
  name: '',
  type: '',
  items: 0,
  bins: [],
  version: 0,
  isSync: true,
  createdAt: '',
  deletedAt: '',
  updatedAt: '',
};

export type RacksQueryResponse = Response<{
  items: Array<Rack>;
  total: number;
}>;

export type RackRequest = {
  name: string;
  info: string;
  code: string;
  wardId?: number;
  gatewayId?: number | null;
};

export const initialRackRequest: RackRequest = {
  name: '',
  info: '',
  wardId: 0,
  code: '',
  gatewayId: 0,
};

export type HistoryRack = {
  bin?: Bin;
  id: ID;
  inventory?: Item;
  expriryDate: string;
  type: string;
  quantity: number;
  quantityUnit: string;
  weight: number;
  weightUnit: string;
  qtyAvailable: number;
  qtyToStockUp: number;
  movementType: InventoryMovementType;
  episode: {
    user: {
      firstName: string;
      id: string;
      lastName: string;
    };
  };
  userName: string;
  roleName: string;
};
export interface LogDetail {
  log_to_day: string;
  log_to_month?: string;
  count: string;
  type: TypeLog;
}

export interface ActivityLog {
  dates: LogDetail[];
  months: LogDetail[];
}

export interface LogItemDetail {
  id: ID;
  updatedAt: string;
  eventType: EventTypeLogRack;
  user: {
    firstName: string;
    lastName: string;
  };
}

export type RacksActivityLogResponse = Response<{
  items: Array<LogItemDetail>;
  total: number;
}>;

export type ActivityLogDetailQueryResponse = Response<{
  items: Array<LogItemDetail>;
  total: number;
}>;

export type ActivityLogRack = {
  bin?: Bin;
  id: ID;
  inventory?: Item;
  expriryDate: string;
  type: string;
  quantity: number;
  quantityUnit: string;
  weight: number;
  weightUnit: string;
  qtyAvailable: number;
  action?: string;
  detail?: string;
  user?: {
    firstName: string;
    lastName: string;
  };
  roleName: string;
  updatedAt: string;
  movementType: InventoryMovementType;
};
