import { Column } from 'react-table';

import StatusDefault from 'app/components/Status/StatusDefault';
import { StockLevelAlertIcon, hasStockLevelAlert } from 'app/components/Status/StockLevelAlertIcon';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import Typography, { EmptyTableCell } from 'app/components/Typography';

import ItemInfoCard from 'app/modules/inventory/items/components/ItemInfoCard';
import SKUCard from 'app/modules/inventory/items/components/SKUCard';
import { UserInfo } from 'app/modules/inventory/items/components/UserInfo';
import { QtyTypography } from 'app/modules/inventory/racks/components/Typography';
import { StatusStockUp, StockLevel } from 'app/modules/inventory/stock-run/core/enum';

import { formatCustomDate, formatDate, getLabelAction, isActiveLabelAction } from 'app/helpers';

import { ReportDetailList, ReportV2 } from './type';

export const getStockHealth = (stockHealth: StockLevel, status?: StatusStockUp) => {
  return (
    <div
      className="d-flex flex-column"
      style={{ width: 36, alignItems: 'center', textAlign: 'center' }}
    >
      {hasStockLevelAlert(status, stockHealth) ? (
        <StockLevelAlertIcon stockLevel={stockHealth} status={status} marginLeft={0} />
      ) : (
        EmptyTableCell
      )}
    </div>
  );
};

export const reportColumns = (): ReadonlyArray<Column<ReportV2>> => {
  return [
    {
      id: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].imgae}
          name={props.data[props.row.index].name}
          stockType={props.data[props.row.index].itemType}
        />
      ),
      width: '25%',
    },
    {
      id: 'skuId',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].sku}
          skuSub={props.data[props.row.index].skuSub}
        />
      ),
      width: '20%',
    },
    {
      id: 'parValue',
      accessor: 'parValue',
      Header: (props) => <CustomHeader tableProps={props} title="Par level" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].parValue}`}
        </Typography>
      ),
      width: '12%',
    },
    {
      id: 'restockValue',
      accessor: 'restockValue',
      Header: (props) => <CustomHeader tableProps={props} title="Restock level" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].restockValue}`}
        </Typography>
      ),
      width: '12%',
    },
    {
      id: 'alarmValue',
      accessor: 'alarmValue',
      Header: (props) => <CustomHeader tableProps={props} title="Alarm level" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].alarmValue}`}
        </Typography>
      ),
      width: '12%',
    },
    {
      id: 'lastStockUp',
      accessor: 'lastStockUp',
      Header: (props) => <CustomHeader tableProps={props} title="Last stock up" />,
      Cell: ({ ...props }) => (
        <div className="d-flex flex-column">
          {formatDate(props.data[props.row.index].lastStockUp) ? (
            <Typography className="text-nowrap" variant="body-5" color="neutral-10">
              {formatDate(props.data[props.row.index].lastStockUp)}
            </Typography>
          ) : (
            EmptyTableCell
          )}
        </div>
      ),
      width: '10%',
    },
    {
      id: 'quantity',
      accessor: 'qtyBalance',
      Header: (props) => <CustomHeader tableProps={props} title="Qty balance" />,
      Cell: ({ ...props }) => (
        <div className="d-flex flex-row">
          {props.data[props.row.index].qtyBalance != null ? (
            <Typography
              variant="body-1"
              lineHeight={26}
              className="number-width-5 d-flex align-items-center"
            >
              {props.data[props.row.index].qtyBalance}
            </Typography>
          ) : (
            EmptyTableCell
          )}
        </div>
      ),
      width: '15%',
    },
    {
      id: 'stockLevel',
      accessor: 'stockLevel',
      Header: (props) => <CustomHeader tableProps={props} title="Stock health" />,
      Cell: ({ ...props }) => getStockHealth(props.data[props.row.index].stockLevel),
      width: '14%',
    },
  ];
};

export const reportDetailColumns = (): ReadonlyArray<Column<ReportDetailList>> => {
  return [
    {
      id: 'expriryDate',
      accessor: 'expriryDate',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].expriryDate)}
        </Typography>
      ),
      width: '5%',
    },
    {
      id: 'ward',
      accessor: 'episode',
      Header: 'Ward',
      Cell: ({ ...props }) => {
        const wardName = props.data[props.row.index].wardName;
        return <Typography variant="body-1">{wardName}</Typography>;
      },
      width: '12%',
    },
    {
      id: 'rack',
      accessor: 'rackName',
      Header: (props) => <CustomHeader tableProps={props} title="Rack" />,
      Cell: ({ ...props }) => {
        const rackName = props.data[props.row.index].rackName;
        return <Typography variant="body-1">{rackName}</Typography>;
      },
      width: '10%',
    },
    {
      id: 'bin',
      accessor: 'bin',
      Header: (props) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].bin.name}`}
        </Typography>
      ),
      width: '10%',
    },
    {
      id: 'type',
      accessor: 'type',
      Header: (props) => <CustomHeader tableProps={props} title="Action" />,
      Cell: ({ ...props }) => (
        <StatusDefault
          isActive={isActiveLabelAction(Number(props.data[props.row.index].quantity))}
          label={getLabelAction(Number(props.data[props.row.index].quantity))}
          action={props.data[props.row.index].movementType}
        />
      ),
      width: '12%',
    },
    {
      id: 'qtyToStockUp',
      accessor: 'qtyToStockUp',
      Header: (props) => <CustomHeader tableProps={props} title="Stock on hand" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {Number(props.data[props.row.index].qtyToStockUp)}
        </Typography>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props) => <CustomHeader tableProps={props} title="Adjustment qty" />,
      width: '10%',
      Cell: ({ ...props }) => <QtyTypography qty={props.data[props.row.index].quantity ?? ''} />,
    },
    {
      id: 'qtyAvailable',
      accessor: 'qtyAvailable',
      Header: (props) => <CustomHeader tableProps={props} title="New qty" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].qtyAvailable}
        </Typography>
      ),
    },
    {
      id: 'user',
      accessor: 'episode',
      Header: (props) => <CustomHeader tableProps={props} title="By user" />,
      Cell: ({ ...props }) => (
        <UserInfo
          movementType={props.data[props.row.index].movementType}
          userName={props.data[props.row.index].userName}
          roleName={props.data[props.row.index].roleName}
        />
      ),
      width: '10%',
    },
  ];
};
