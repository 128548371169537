import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import InputDateFilter from 'app/components/Filter/InputDateFilter';
import Typography from 'app/components/Typography';
import { COLOR } from 'app/components/Typography/core/enum';

import { labelFilterDate } from 'app/modules/inventory/stock-run/core/helpers';

import { differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns';

import { SessionFilter, weekEndTime, weekStartTime } from '../Dashboard';
import { useSummaryInfo } from '../SummaryInfo';
import { SUMMARY_CONFIG_1, SUMMARY_CONFIG_2 } from '../core/constants';
import { getSessionChartData } from '../core/requests';
import { SessionChartData } from '../core/types';
import { EmptyChart } from './EmptyChart';
import { StackedBarChart } from './StackedBarChart';

const PanelGridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 16px;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
`;
const EmptyDiv = styled.div`
  height: 32px;
`;

const Seperator = styled.div`
  height: 100;
  width: 1px;
  border-radius: 1px;
  background-color: ${COLOR.neutral_3};
`;

const MAX_DAY_RANGE_ONE_ROW = 27; // February can have 28 days

const CHART_STACK_COLOR = [
  {
    default: COLOR.primary_5,
    hover: COLOR.primary_7,
  },
  {
    default: COLOR.primary_6,
    hover: '#3F87CB',
  },
  {
    default: '#0056C6',
    hover: '#054AA8',
  },
];

export const SessionsCharts: FC = () => {
  const [chartData, setChartData] = useState<SessionChartData>();
  const [sessionFilter, setSessionFilter] = useState<SessionFilter>({
    from: weekStartTime,
    to: weekEndTime,
  });
  const fetchCharts = async () => {
    const res = await getSessionChartData(
      `from=${sessionFilter.from.toISOString()}&to=${sessionFilter.to.toISOString()}&wardId=1`,
    );
    setChartData(res);
  };

  useEffect(() => {
    fetchCharts();
  }, [sessionFilter.from, sessionFilter.to]);

  const sessionDayDiff = differenceInCalendarDays(sessionFilter.to, sessionFilter.from) + 1;
  const expandPanelFullRow = sessionDayDiff > MAX_DAY_RANGE_ONE_ROW;
  const { renderSummaries } = useSummaryInfo(sessionFilter.from, sessionFilter.to);
  return (
    <div className="d-flex flex-column">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <InputDateFilter
          label="Filter by"
          value={labelFilterDate(sessionFilter.from, sessionFilter.to)}
          containerStyle={{ width: 300 }}
          onChange={(newDate) => {
            setSessionFilter({
              from: startOfDay(newDate[0].startDate),
              to: endOfDay(newDate[0].endDate),
            });
          }}
          state={[
            {
              endDate: sessionFilter.to ? new Date(sessionFilter.to) : new Date(),
              startDate: sessionFilter.from ? new Date(sessionFilter.from) : new Date(),
              key: 'selection',
            },
          ]}
        />
      </div>
      <div className={`d-flex h-100 ${expandPanelFullRow ? 'flex-column' : 'flex-row'}`}>
        <div
          style={{
            width: expandPanelFullRow ? '100%' : '50%',
            paddingRight: expandPanelFullRow ? 0 : 32,
          }}
        >
          <Typography variant="title-1" lineHeight={32} style={{ marginBottom: '32px' }}>
            Total pickup quantity
          </Typography>
          {chartData?.pickUp.isEmpty ? (
            <>
              <StackedBarChart
                data={{
                  datasets:
                    chartData?.pickUp.shifts.map((shift, index) => ({
                      label: shift.label,
                      data: shift.count,
                      backgroundColor: CHART_STACK_COLOR[index].default,
                      hoverBackgroundColor: CHART_STACK_COLOR[index].hover,
                      maxBarThickness: 20,
                    })) ?? [],
                  labels: chartData?.pickUp.time ?? [],
                }}
                options={{
                  scales: { y: { ticks: { stepSize: 1 }, suggestedMax: 5 } },
                }}
                containerStyle={{
                  width: '100%',
                  paddingBottom: 24,
                }}
              />
              <EmptyDiv />
              <PanelGridWrapper>{renderSummaries(SUMMARY_CONFIG_1)}</PanelGridWrapper>
            </>
          ) : (
            <EmptyChart />
          )}
        </div>

        <Seperator />

        <div
          style={{
            width: expandPanelFullRow ? '100%' : '50%',
            paddingLeft: expandPanelFullRow ? 0 : 32,
          }}
        >
          <Typography
            variant="title-1"
            lineHeight={32}
            style={{ marginTop: expandPanelFullRow ? 32 : 0, marginBottom: '32px' }}
          >
            Total stock-runs completion
          </Typography>
          {chartData?.stockUp.isEmpty ? (
            <>
              <StackedBarChart
                data={{
                  datasets:
                    chartData?.stockUp.shifts.map((shift, index) => ({
                      label: shift.label,
                      data: shift.count,
                      backgroundColor: CHART_STACK_COLOR[index].default,
                      hoverBackgroundColor: CHART_STACK_COLOR[index].hover,
                      maxBarThickness: 20,
                    })) ?? [],
                  labels: chartData?.stockUp.time ?? [],
                }}
                options={{
                  scales: { y: { ticks: { stepSize: 1 }, suggestedMax: 5 } },
                }}
                containerStyle={{
                  width: '100%',
                  paddingBottom: 24,
                }}
              />
              <EmptyDiv />
              <PanelGridWrapper style={{ paddingLeft: 16 }}>
                {renderSummaries(SUMMARY_CONFIG_2, { firstColPadding: 12 })}
              </PanelGridWrapper>
            </>
          ) : (
            <EmptyChart />
          )}
        </div>
      </div>

      <EmptyDiv />
    </div>
  );
};
