import { ID } from 'app/components/Table/core/types';

import { pick } from 'lodash';

import { Notification, NotificationGroup } from './core/type';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  notificationGroup: NotificationGroup;
  total: number;
  fetched: boolean;
  currentOpenGroupIndex: number;
  unReadCount: number;
  initUnReadCount: number;
  refreshTimestamp: number;
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
  onChangeReadNoti: (id: ID, status: boolean) => void;
  onMarkAllRead: () => void;
}

const initState: State = {
  notificationGroup: { items: [], total: 0 },
  total: 0,
  refreshTimestamp: 0,

  fetched: false,
  currentOpenGroupIndex: 0,
  unReadCount: 0,
  initUnReadCount: 0,
};

export const useNotificationStore = create<Store>()(
  immer((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = {
          ...store.state,
          ...pick(
            initState,
            'notificationGroup',
            'currentOpenGroupIndex',
            'total',
            'refreshTimestamp',
          ),
        };
      });
    },
    onChangeReadNoti: (notiId, status) => {
      set((store) => {
        const { initUnReadCount, unReadCount } = store.state;
        const newUnreadCount = status === false ? unReadCount - 1 : unReadCount + 1;
        const newInitUnreadCount = status === false ? initUnReadCount - 1 : initUnReadCount + 1;

        store.state.notificationGroup.items.some((day) => {
          const targetedNoti = day.items.find((noti) => noti.id === notiId);
          if (targetedNoti) {
            targetedNoti.read = !status;
          }
        });

        store.state.initUnReadCount =
          newInitUnreadCount >= newUnreadCount ? newUnreadCount : newInitUnreadCount;
        store.state.unReadCount = newUnreadCount;
      });
    },
    onMarkAllRead: () => {
      set((store) => {
        store.state.notificationGroup.items.forEach((day) => {
          day.items.forEach((notiItem) => {
            notiItem.read = true;
          });
        });
      });
    },
  })),
);

export const newUnreadNotiSelector = (s: Store) => s.state.unReadCount - s.state.initUnReadCount;

export const notificationByIdSelector = (notiId: ID) => {
  return (store: Store) => {
    return store.state.notificationGroup.items
      .flatMap((day) => day.items)
      .find((noti) => noti.id === notiId);
  };
};

export const hasNewUnreadNotiSelector = (s: Store) => s.state.initUnReadCount < s.state.unReadCount;
