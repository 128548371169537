export enum COLOR {
  WHITE = '#FFFFFF',
  PRIMARY = '#0080F0',
  SUCCESS = '#47BE7D',
  INFO = '#7239ea',
  WARNING = '#EDB32B',
  DANGER = '#f1416c',
  GRAY_200 = '#eff2f5',
  GRAY_300 = '#e4e6ef',
  GRAY_400 = '#b5b5c3',
  GRAY_700 = '#5e6278',
  GRAY_800 = '#3f4254',
  neutral_1 = '#F7F9FC',
  neutral_2 = '#F2F4F7',
  neutral_2_5 = '#EBEDF2',
  neutral_3 = '#E8E9ED',
  neutral_4 = '#D9DBE4',
  neutral_6 = '#A1A5B7',
  neutral_7 = '#7E8299',
  neutral_10 = '#181C32',
  primary_2 = '#F0F9FF',
  primary_3 = '#E0F1FF',
  primary_5 = '#A7D6FF',
  primary_6 = '#49A3F1',
  primary_7 = '#8AB1D6',
  primary_8 = '#006FDE',
  warning_active = '#E1A416',
  warning_active_2 = '#D39400',
  warning_2 = '#FFF5DD',
  warning_light_3 = '#FAEED2',
  success_2 = '#E8FFF3',
  success_3 = '#50CD89',
  success_light_1_5 = '#D9FCEA',
  success_light_2 = '#D0F5E2',
  danger_light = '#FFF5F8',
  danger_active = '#D9214E',
  danger_light_1_5 = '#FFEBF1',
  danger_light_2 = '#FAE3EA',
  danger_dark = '#9F1F40',
}
