import EmptyCard from './EmptySelectItemCard';

interface Props {
  isShowCard: boolean;
  total: number;
  children: React.ReactNode;
  label: string;
}
const BodyFormTableStockRunItemCard: React.FC<Props> = ({ isShowCard, total, label, children }) => {
  return (
    <div
      style={{
        display: isShowCard ? 'flex' : 'none',
        width: '100%',
      }}
    >
      <div
        style={{
          display: total ? 'flex' : 'none',
          width: '100%',
        }}
      >
        {children}
      </div>
      <div
        style={{
          display: !total ? 'flex' : 'none',
          width: '100%',
        }}
      >
        <EmptyCard label={label} />
      </div>
    </div>
  );
};
export default BodyFormTableStockRunItemCard;
