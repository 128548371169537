import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean, useString, useTiming } from 'app/hooks';
import { isUndefined } from 'lodash';

import { generateCodeDevice } from '../core/requests';
import { Device } from '../core/type';

const Main = styled.div`
  .btn-primary:disabled {
    opacity: 1;
    background-color: var(--neutral-3);
  }
`;

interface Props {
  device: Device | null;
  onCloseModal?: () => void;
  type: TYPE_MODAL;
}
const FormCodeDeviceModal: React.FC<Props> = ({ onCloseModal, device, type }) => {
  const isExpired = useBoolean(false);
  const code = useString('');
  const isLoadingGenerate = useBoolean(false);
  const isLoading = useBoolean(false);

  const { setItemForUpdate } = useListView();
  const { time, timingRedirect } = useTiming(() => {
    isExpired.setValue(true);
    time.setValue(300);
  }, 300);
  useEffect(() => {
    if (!device) {
      return;
    }
    getGenerateCodeDevice(device);
  }, [device]);
  const getGenerateCodeDevice = async (newDevice: Device) => {
    if (newDevice.code) {
      code.setValue(newDevice.code);
      isExpired.setValue(false);
      timingRedirect();
      return;
    }
    isLoading.setValue(true);
    await generateCode(Number(newDevice.id));
  };
  const generateCode = async (idDevice: number) => {
    try {
      const dataRes = await generateCodeDevice(idDevice);
      if (dataRes && dataRes.code) {
        code.setValue(dataRes.code);
        isExpired.setValue(false);
        timingRedirect();
      }
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoadingGenerate.setValue(false);
      isLoading.setValue(false);
    }
  };

  const onSubmit = async () => {
    if (!device || !device.id) {
      return;
    }
    isLoadingGenerate.setValue(true);
    await generateCode(Number(device.id));
  };
  const cancel = () => {
    if (!isUndefined(onCloseModal)) {
      onCloseModal();
      return;
    }
    setItemForUpdate(emptyListViewProvider);
  };
  const renderBodyConnectTablet = () => {
    return (
      <React.Fragment>
        <Typography
          variant="body-3"
          style={{
            marginBottom: 10,
          }}
        >
          To connect your tablet with the system, follow these steps:
        </Typography>
        <Typography variant="body-3">
          1. Open the Digital Shelve Manager app on your tablet.
        </Typography>
        <Typography variant="body-3">
          2. Type in the code below and tap on ‘Connect’ button:
        </Typography>
        <Typography
          fontWeight={700}
          fontSize={30}
          lineHeight={47}
          color="neutral-10"
          style={{
            margin: '4px 0px',
            minHeight: 47,
          }}
        >
          {code.value || device?.code}
        </Typography>
        {isExpired.value ? (
          <Typography variant="body-3" color="danger">
            This code has expired.
            <span
              style={{
                color: 'var(--neutral-10)',
                marginLeft: 4,
              }}
            >
              Generate another code to connect your tablet.
            </span>
          </Typography>
        ) : (
          <Typography variant="body-3">
            {`  This code will expire in ${time.value} seconds. You can generate another code
            after that.`}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  const renderBodyGenerateNewCode = () => {
    return (
      <React.Fragment>
        <Typography variant="body-3">The newly generated code of this tablet is:</Typography>
        <Typography
          fontWeight={700}
          fontSize={30}
          lineHeight={47}
          color="neutral-10"
          style={{
            margin: '4px 0px',
            minHeight: 47,
          }}
        >
          {code.value || device?.code}
        </Typography>
        {isExpired.value ? (
          <Typography variant="body-3" color="danger">
            This code has expired.
          </Typography>
        ) : (
          <Typography variant="body-3">
            {`This code will expire in ${time.value} seconds. You can generate another
            code after that.`}
          </Typography>
        )}
      </React.Fragment>
    );
  };
  return (
    <ModalDefault
      title={type === TYPE_MODAL.CONNECT_TABLET ? 'Connect tablet' : ' Generate new code'}
      onClickCloseModal={() => cancel()}
      isConfirmationModal
    >
      <Main>
        <div
          className="d-flex flex-column"
          style={{
            paddingBottom: 24,
          }}
        >
          {type === TYPE_MODAL.CONNECT_TABLET
            ? renderBodyConnectTablet()
            : renderBodyGenerateNewCode()}
        </div>
        <ActionButton
          onCancel={() => cancel()}
          disableButton={!isExpired.value || isLoadingGenerate.value}
          isSubmitting={isLoadingGenerate.value}
          onSubmit={onSubmit}
          labelButtonSubmit="Renew code"
        />
      </Main>
      {isLoading.value && <LoadingCard />}
    </ModalDefault>
  );
};
export default FormCodeDeviceModal;
