import { FC } from 'react';
import { ColumnInstance } from 'react-table';

import { getHeaderTableStyle } from '../core/helpers';

type Props = {
  column: ColumnInstance;
  extraProps?: any;
};

const HeaderColumn: FC<Props> = ({ column, extraProps }) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th
        style={{
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '23px',
          color: 'var(--neutral-6)',
          width: column.width,
          whiteSpace: 'nowrap',
          ...getHeaderTableStyle(extraProps.index, extraProps.headers.length),
        }}
        {...column.getHeaderProps()}
      >
        {column.render('Header', extraProps)}
      </th>
    ) : (
      column.render('Header', extraProps)
    )}
  </>
);

export { HeaderColumn };
