import Tippy, { useSingleton } from '@tippyjs/react';
import { useState } from 'react';
import styled from 'styled-components';

import { getSpacingConfig } from 'app/components/Table/columns/InfoRow';
import { getHeaderTableStyle } from 'app/components/Table/core/helpers';
import { ID } from 'app/components/Table/core/types';
import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

import { getStockHealth } from 'app/modules/reporting/core/columns';

import { blockInvalidCharacters } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { cloneDeep, isNil, orderBy } from 'lodash';

import { ReactComponent as IconAddition } from 'app/assets/icons/icon-addition.svg';
import { ReactComponent as IconRemoveItem } from 'app/assets/icons/icon-remove-item.svg';
import { ReactComponent as IconSubtraction } from 'app/assets/icons/icon-subtraction.svg';

import ItemInfoCard from '../../items/components/ItemInfoCard';
import SKUCard from '../../items/components/SKUCard';
import { Rack } from '../../racks/core/type';
import { HEADERS_LIST_ITEM_STOCK_RUN } from '../core/constants';
import { getRecommendedQty, getStockLevel } from '../core/helpers';
import { ForecastItem, HeaderTableItem, StockRunItem } from '../core/type';
import FormAddItemStockRun from '../modal/FormAddItemStockRun';
import { RemoveItemModal } from '../modal/RemoveItem.modal';
import EmptyCard from './EmptySelectItemCard';
import { ForecastTooltipContent, ItemForecasting } from './ItemForecasting';
import clsx from 'clsx';

const ButtonChangeQty = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? 'var(--neutral-1)' : 'var(--white)')};
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  svg {
    path {
      stroke: ${(props) => (props.disabled ? 'var(--neutral-4)' : 'var(--neutral-7)')};
    }
  }
  :hover {
    background: ${(props) => (props.disabled ? 'var(--neutral-1)' : 'var(--neutral-3)')};
  }
`;
const ButtonRemoveItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`;

const ButtonAddItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-right: 8px;
  cursor: pointer;
  :hover {
    background: var(--primary);
    border-radius: 8px;
    p {
      color: var(--white);
    }
  }
`;
const ColumnTh = styled.th`
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 23px !important;
  color: var(--neutral-6) !important;
  white-space: normal;
`;
const InputQuantity = styled.input`
  padding: 0px 5px;
  height: 32px;
  min-width: 32px;
  text-align: center;
  color: var(--neutral-10) !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  background-color: transparent !important;
  border: none;
  :focus {
    background-color: rgb(249, 249, 249) !important;
  }
`;
const HideArrowInputNumber = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  margin: 0px 6px;
`;
const MIN_VALUE = 0;
interface Props {
  stockRunItems: StockRunItem[];
  setStockRunItems: (newSelectBins: StockRunItem[]) => void;
  isShowAddItem?: boolean;
  headers?: HeaderTableItem[];
  wardId: number;
  forecasting: ForecastItem[];
  racks: Rack[];
  timeFrame: string;
}
const ListItemInStockRun: React.FC<Props> = ({
  stockRunItems,
  isShowAddItem = true,
  headers,
  wardId,
  forecasting,
  setStockRunItems,
  racks,
  timeFrame,
}) => {
  const [source, target] = useSingleton();

  const isAddItem = useBoolean(false);
  const orderedItems = orderBy(stockRunItems, 'order', 'desc');
  const onSubmitSelection = (selectItem: StockRunItem[]) => {
    isAddItem.setValue(false);
    setStockRunItems(selectItem);
  };
  const isRemoveItem = useBoolean(false);
  const [removeItemId, setRemoveItemId] = useState<ID>();

  const onChangeQuantity =
    (key: 'subtraction' | 'addition', originStockRunItem: StockRunItem) => () => {
      const newAddBinItems = cloneDeep(stockRunItems);
      const newIndex = newAddBinItems.findIndex((el) => el.bin?.id === originStockRunItem.bin?.id);
      if (newIndex > -1) {
        const newValue =
          key === 'subtraction'
            ? Number(originStockRunItem.quantity) - 1
            : Number(originStockRunItem.quantity) + 1;

        newAddBinItems[newIndex].quantity =
          newValue < MIN_VALUE ? String(MIN_VALUE) : String(newValue);
      }

      setStockRunItems(newAddBinItems);
    };

  const onChangeInputQuantity =
    (originStockRunItem: StockRunItem, overrideValue?: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAddBinItems = cloneDeep(stockRunItems);
      const newIndex = newAddBinItems.findIndex((el) => el.bin?.id === originStockRunItem.bin?.id);
      if (newIndex > -1) {
        newAddBinItems[newIndex].quantity =
          typeof overrideValue === 'number' ? String(overrideValue) : event.target.value;
      }
      setStockRunItems(newAddBinItems);
    };

  const onDelete = (id: ID) => {
    const oldStockRunItems = stockRunItems.filter(
      (el) => typeof el.bin?.id === 'number' && el.bin?.id != id,
    );
    setStockRunItems(oldStockRunItems);
  };

  const onRemoveItem = (binId: ID) => () => {
    if (forecasting.length) {
      isRemoveItem.setValue(true);
      setRemoveItemId(binId);
    } else {
      onDelete(binId);
    }
  };

  const renderBody = (index: number, row: StockRunItem, column: HeaderTableItem) => {
    switch (column.field) {
      case 'index':
        return (
          <Typography color="neutral-10" fontSize={14} fontWeight={400} lineHeight={26}>
            {index + 1}
          </Typography>
        );
      case 'item':
        return (
          <ItemInfoCard
            image={row.inventory?.image || ''}
            name={row.inventory?.name || ''}
            stockType={row.inventory?.stockType}
            isNotNavigatable={true}
          />
        );
      case 'stock-level':
        return getStockHealth(getStockLevel(row));
      case 'sku':
        return <SKUCard skuId={row.inventory?.skuId ?? ''} skuSub={row.inventory?.skuSub ?? ''} />;
      case 'rack':
        return (
          <Typography
            color="neutral-10"
            variant="body-5"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {row.bin?.warehouse?.name}
          </Typography>
        );
      case 'bin':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.bin?.name}
          </Typography>
        );
      case 'parValue':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.parValue}
          </Typography>
        );
      case 'restockValue':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.restockValue}
          </Typography>
        );
      case 'alarmValue':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.alarmValue}
          </Typography>
        );
      case 'qtyBalance':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.qtyBalance}
          </Typography>
        );
      case 'qty': {
        const forecastValue = getRecommendedQty(row.inventory?.id, forecasting);
        return (
          <div className="d-flex align-items-center justify-content-between position-relative">
            <TippyDefault
              singleton={target}
              disabled={isNil(forecastValue)}
              content={
                <ForecastTooltipContent forecastValue={forecastValue || 0} timeFrame={timeFrame} />
              }
            >
              <div
                className="d-flex align-items-center"
                style={{ width: isNil(forecastValue) ? 30 : 113 }}
              >
                <ButtonChangeQty
                  onClick={onChangeQuantity('subtraction', row)}
                  style={{
                    cursor: String(row.quantity) === String(MIN_VALUE) ? 'default' : 'pointer',
                  }}
                  disabled={String(row.quantity) === String(MIN_VALUE)}
                >
                  <IconSubtraction />
                </ButtonChangeQty>

                <HideArrowInputNumber>
                  <InputQuantity
                    className={clsx('form-control form-control-solid')}
                    type="number"
                    value={row.quantity ?? ''}
                    onChange={onChangeInputQuantity(row)}
                    onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
                    style={{ padding: 0 }}
                  />
                </HideArrowInputNumber>

                <ButtonChangeQty onClick={onChangeQuantity('addition', row)}>
                  <IconAddition />
                </ButtonChangeQty>
              </div>
            </TippyDefault>

            <ItemForecasting
              singletonTarget={target}
              value={forecastValue}
              fullfilled={parseInt(row.quantity || '0') === forecastValue}
              onClick={onChangeInputQuantity(row, forecastValue) as () => void}
              timeFrame={timeFrame}
            />

            <ButtonRemoveItem onClick={onRemoveItem(row.bin?.id)}>
              <IconRemoveItem />
            </ButtonRemoveItem>

            {Number(row.quantity) < MIN_VALUE && (
              <Typography
                fontWeight={400}
                fontSize={12}
                lineHeight={22}
                color="danger"
                style={{
                  position: 'absolute',
                  bottom: -22,
                }}
              >
                Minimum Qty {MIN_VALUE}
              </Typography>
            )}
          </div>
        );
      }
      case 'qty_to_stock_up':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.quantity}
          </Typography>
        );

      case 'qty_completed':
        return (
          <Typography color="neutral-10" variant="body-5">
            {row.qtyCompleted}
          </Typography>
        );
    }
  };
  const newHeaders = headers ?? HEADERS_LIST_ITEM_STOCK_RUN;

  const orderedItemsCount = orderedItems.length ? `(${orderedItems.length})` : '(0)';

  return (
    <div className="d-flex flex-column">
      <Tippy singleton={source} theme="dark-2" moveTransition="transform 0.2s ease-out" />

      <div className="d-flex align-items-center justify-content-between mb-10">
        <Typography
          variant="title-1"
          lineHeight={32}
          style={{
            paddingLeft: 28,
          }}
        >
          {`Items ${orderedItemsCount}`}
        </Typography>
        {isShowAddItem && (
          <ButtonAddItem onClick={() => isAddItem.setValue(true)}>
            <Typography variant="title-4" color="primary">
              Select item
            </Typography>
          </ButtonAddItem>
        )}
      </div>
      {orderedItems.length ? (
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed dataTable no-footer"
          >
            <thead>
              <tr
                style={{
                  borderBottom: `1px solid var(--neutral-3)`,
                }}
              >
                {newHeaders.map((column: HeaderTableItem, index: number) => (
                  <ColumnTh
                    key={index}
                    style={{
                      ...getHeaderTableStyle(index, newHeaders.length),
                      ...column.style,
                      verticalAlign: 'middle',
                    }}
                  >
                    <Typography variant="body-7"> {column.title ? column.title : ''}</Typography>
                  </ColumnTh>
                ))}
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {orderedItems.map((row: StockRunItem, i) => {
                return (
                  <tr
                    key={row.id}
                    style={{
                      borderBottom: `1px solid var(--neutral-3)`,
                      backgroundColor: i % 2 === 0 ? 'var(--neutral-1)' : '',
                    }}
                  >
                    {newHeaders.map((column, index) => {
                      return (
                        <td
                          key={column.field}
                          style={{
                            ...getSpacingConfig('md', {
                              left: index === 0,
                              right: index === newHeaders.length - 1,
                            }),
                            ...column.style,
                          }}
                        >
                          {renderBody(i, row, column)}{' '}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyCard label="No item selected" />
      )}

      {isAddItem.value && (
        <FormAddItemStockRun
          isOpen={isAddItem.value}
          onClose={() => isAddItem.setValue(false)}
          originStockRunItems={orderedItems}
          onSubmitSelection={onSubmitSelection}
          wardId={wardId}
          racks={racks}
        />
      )}

      {isRemoveItem.value && (
        <RemoveItemModal
          onCancel={() => isRemoveItem.setValue(false)}
          onSubmit={() => {
            onDelete(removeItemId);
            isRemoveItem.setValue(false);
          }}
        />
      )}
    </div>
  );
};

export default ListItemInStockRun;
