import styled from 'styled-components';

import CheckboxDefault from 'app/components/Checkbox/CheckboxDefault';
import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import Typography from 'app/components/Typography';

import { getStockHealth } from 'app/modules/reporting/core/columns';

import { includes } from 'lodash';

import { StockTypeEnum } from '../../items/core/enum';
import { getStockLevel } from '../core/helpers';
import { StockRunItem } from '../core/type';

const ItemName = styled.div`
  display: flex;
  align-items: center;
  width: 34%;
  padding: 0 12px;
`;
const SKU = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
`;

const Rack = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 11%;
  padding: 0 12px;
`;
const BinName = styled.div<{ colorBin?: string }>`
  display: flex;
  align-items: center;
  width: 11%;
  padding: 0 12px;
`;
const Qty = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 9%;
  padding: 0 12px;
`;
const Select = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;
const StockHealth = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const Body = styled.div<{
  isItemSelected: boolean;
}>`
  display: flex;
  min-height: 76px;
  padding: 10px 16px;
  :hover {
    background-color: ${(props) => {
      return props.isItemSelected ? '' : 'var(--neutral-2)';
    }} !important;
  }
  cursor: pointer;
`;

interface Props {
  stockRunItem: StockRunItem;
  selectStockRunItems: number[];
  onSelect: (originBin: StockRunItem) => () => void;
  highlight?: boolean;
}
const ViewItemInBinDetail: React.FC<Props> = ({
  stockRunItem,
  selectStockRunItems,
  onSelect,
  highlight,
}) => {
  const isItemSelected = includes(selectStockRunItems, stockRunItem.bin?.id);
  const rowBackgroundColor = highlight ? 'var(--neutral-1)' : '';
  return (
    <Body
      isItemSelected={isItemSelected}
      style={{
        borderBottom: `1px solid var(--neutral-3)`,
        backgroundColor: isItemSelected ? 'var(--primary-2)' : rowBackgroundColor,
      }}
      onClick={onSelect(stockRunItem)}
    >
      <ItemName>
        <ShowImageDefault
          image={stockRunItem.inventory?.image ?? ''}
          styleMain={{ position: 'inherit' }}
        />
        <div
          className="d-flex flex-column"
          style={{
            marginLeft: 16,
            marginRight: 10,
          }}
        >
          <Typography variant="body-5" color="neutral-10">
            {stockRunItem.inventory?.name}
          </Typography>
          <Typography fontSize={13} fontWeight={400} lineHeight={23} color="neutral-6">
            {StockTypeEnum[Number(stockRunItem.inventory?.stockType)]}
          </Typography>
        </div>
      </ItemName>
      <SKU>
        <Typography className="text-overflow-ellipsis" variant="body-5" color="neutral-10">
          {stockRunItem.inventory?.skuId}
        </Typography>
        <Typography fontSize={13} fontWeight={400} lineHeight={23} color="neutral-6">
          {stockRunItem.inventory?.skuSub}
        </Typography>
      </SKU>
      <Rack>
        <Typography className="text-overflow-ellipsis" variant="body-5" color="neutral-10">
          {stockRunItem.rack?.name}
        </Typography>
      </Rack>
      <BinName>
        <Typography variant="body-5" color="neutral-10">
          {stockRunItem.bin?.name}
        </Typography>
      </BinName>
      <Qty>
        <Typography
          className="text-overflow-ellipsis  number-width-5"
          fontSize={16}
          fontWeight={400}
          lineHeight={26}
          color="neutral-10"
        >
          {stockRunItem.inventory?.qty}
        </Typography>
      </Qty>
      <StockHealth>{getStockHealth(getStockLevel(stockRunItem))}</StockHealth>
      <Select>
        <CheckboxDefault
          checked={isItemSelected}
          onChange={() => {
            //do nothing
          }}
        />
      </Select>
    </Body>
  );
};

export default ViewItemInBinDetail;
