import { ID, Response } from 'app/components/Table/core/types';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { SecurityGroup } from '../../security-groups/core/type';

export type User = {
  id: ID;
  username: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  status: number;
  role: SecurityGroup;
  provider: string;
};

export type UsersQueryResponse = Response<{
  items: Array<User>;
  total: number;
}>;

export type UserRequest = {
  firstName: string;
  lastName: string;
  username?: string;
  type: LOGIN_TYPE;
  roleId: number;
  rfidCode?: string;
  email?: string;
};

export const initialUser: User = {
  id: '',
  firstName: '',
  lastName: '',
  username: '',
  createdAt: '',
  updatedAt: '',
  status: 1,
  role: {
    description: '',
    email: '',
    id: '',
    name: '',
    type: '',
    createdAt: '',
  },
  provider: '',
};
