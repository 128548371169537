import { useState } from 'react';

interface Props {
  currentValue: number;
  onChangeValue: (value: number) => void;
  disabled: boolean;
}

const QuantityInput = ({ currentValue, onChangeValue, disabled }: Props) => {
  const [value, setValue] = useState(0);

  const isEmptyValue = !value;

  const onPickUp = () => {
    if (disabled || currentValue === 0) return;
    if (isEmptyValue) {
      onChangeValue(-1);
    } else {
      onChangeValue(-value);
    }
    setValue(0);
  };

  const onStockUp = () => {
    if (disabled) return;
    if (isEmptyValue) {
      onChangeValue(1);
    } else {
      onChangeValue(value);
    }
    setValue(0);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 24 24"
        fill="none"
        style={{
          opacity: disabled ? 0.4 : 1,
        }}
        onClick={onPickUp}
      >
        <path
          d="M9 12H15"
          stroke="rgb(241, 65, 108)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"
          stroke="rgb(241, 65, 108)"
          strokeWidth="2"
        />
      </svg>
      <input
        style={{
          outline: 'none',
          width: 50,
          margin: '0 5px',
          textAlign: 'center',
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
        type="number"
        value={value ? String(value) : ''}
        onChange={(e) => setValue(parseInt(e.target.value))}
        min={0}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 24 24"
        fill="none"
        style={{
          opacity: disabled ? 0.4 : 1,
        }}
        onClick={onStockUp}
      >
        <path
          d="M9 12H15"
          stroke="rgb(71, 190, 125)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9L12 15"
          stroke="rgb(71, 190, 125)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"
          stroke="rgb(71, 190, 125)"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default QuantityInput;
