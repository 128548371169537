import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import Typography from 'app/components/Typography';

const UnableDeleteUnit = () => {
  const { setItemForUpdate } = useListView();

  const cancel = () => {
    setItemForUpdate(emptyListViewProvider);
  };

  return (
    <ModalDefault title="Unable to delete unit" onClickCloseModal={cancel} isConfirmationModal>
      <Typography
        fontWeight={400}
        lineHeight={23}
        fontSize={14}
        color="neutral-10"
        style={{ paddingBottom: 24 }}
      >
        To delete this unit, you have to remove all the items from this unit.
      </Typography>
      <ActionButton
        onSubmit={cancel}
        labelButtonSubmit="Cancel"
        themeButton="btn-primary"
        isShowButtonReset={false}
        onCancel={cancel}
      />
    </ModalDefault>
  );
};
export default UnableDeleteUnit;
