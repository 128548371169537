import { FC } from 'react';

import ModalDefault from 'app/components/Modal/ModalDefault';

import InfoItemStockRunCard from 'app/modules/inventory/stock-run/components/InfoItemStockRunCard';
import { ForecastChart } from 'app/modules/reporting/components/ForecastChart';
import { ReportChart } from 'app/modules/reporting/components/ReportChart';
import { ForecastChartData, ItemLineData } from 'app/modules/reporting/core/type';

import { DemandProjectionItem } from '../core/type';

interface Props {
  item: DemandProjectionItem | null;
  onCancel: () => void;
  forecastChartData?: ForecastChartData;
  balanceChartData?: ItemLineData;
}
export const ForecastChartModal: FC<Props> = ({
  item,
  onCancel,
  forecastChartData,
  balanceChartData,
}) => {
  console.log(item);
  return (
    <ModalDefault
      customHeader={
        <>
          <InfoItemStockRunCard
            title="Item name"
            value={item?.itemName}
            styleRoot={{ width: '50%' }}
          />
          <InfoItemStockRunCard title="SKU" value={item?.skuId} styleRoot={{ width: '50%' }} />
        </>
      }
      onClickCloseModal={onCancel}
      modalContentStyle={{ height: 512 }}
      styleModalDialog={{ minWidth: 1250 }}
      styleBodyModalDialog={{
        padding: 28,
        flexGrow: 1,
        height: 0,
        maxHeight: 'none',
        overflow: 'hidden',
      }}
      headerModalStyle={{ borderBottom: `1px solid var(--neutral-3)` }}
      closeOnBackdrop
    >
      {forecastChartData && <ForecastChart showTitle={true} chartData={forecastChartData} />}
      {balanceChartData && <ReportChart report={balanceChartData} customTitle="Quantity balance" />}
    </ModalDefault>
  );
};
