import { FC } from 'react';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { REACT_APP_VERSION } from 'app/constants/environments';

import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';

const ShowAvatar = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`;

const Text = styled.a`
  border-left: none !important;
  :hover {
    background-color: var(--primary-2) !important;
    p {
      color: var(--primary);
    }
  }
`;

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item" style={{ padding: 24 }}>
        <div className="d-flex align-items-center ">
          <ShowAvatar>
            <img alt="Logo" src={toAbsoluteUrl('/media/avatars/icon-avatar-default.svg')} />
          </ShowAvatar>

          <div className="d-flex flex-column">
            <Typography variant="title-4" color="neutral-10">
              {`${currentUser?.firstName} ${currentUser?.lastName}`}
            </Typography>
            <Typography fontSize={12} fontWeight={500} lineHeight={20} color="neutral-6">
              {currentUser?.role.name}
            </Typography>
          </div>
        </div>
      </div>

      <div className="separator"></div>
      <div className="menu-item" style={{ padding: '12px 16px' }}>
        <Text onClick={logout} className="menu-link" style={{ padding: '6px 8px' }}>
          <Typography variant="body-3">Sign Out</Typography>
        </Text>
        {REACT_APP_VERSION && (
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={20}
            color="neutral-7"
            style={{ marginLeft: '8px', marginTop: '12px' }}
          >
            {REACT_APP_VERSION}
          </Typography>
        )}
      </div>
    </div>
  );
};

export { HeaderUserMenu };
