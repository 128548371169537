import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { StockTypeEnum } from '../core/enum';

const MainLabel = styled.div<{
  color?: string;
}>`
  display: flex;
  align-items: center;
  padding: 0px 4px;
  height: 18px;
  border-radius: 4px;
  margin-top: 3px;
  width: fit-content;
  ${({ color }) => `border:1px solid var(--${color})`}
`;
interface Props {
  stockType: number;
}
const LabelStockTypeCard: React.FC<Props> = ({ stockType }) => {
  const color = 'neutral-7';
  return (
    <MainLabel color={color}>
      <Typography fontWeight={500} lineHeight={20} fontSize={12} color={color}>
        {StockTypeEnum[stockType]}
      </Typography>
    </MainLabel>
  );
};
export default LabelStockTypeCard;
