import { useEffect, useState } from 'react';
import styled from 'styled-components';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { getSecurityGroups } from '../security-groups/core/requests';
import { SecurityGroup } from '../security-groups/core/type';
import { usersColumns } from './core/columns';
import { getUserById, getUsers } from './core/requests';
import FormChangeStatusCard from './modal/FormChangeStatusCard';
import { FormResetPasswordCard } from './modal/FormResetPasswordCard';
import { FormUserTableCard } from './modal/FormUserTableCard';

const Main = styled.div`
  .custom-input-search {
    width: 400px;
  }
`;

const UserWebManagement = () => {
  const [securityGroups, setSecurityGroups] = useState<SecurityGroup[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const dataRes = await getSecurityGroups(
        stringifyRequestQuery({
          page: 1,
          limit: 100,
        }) + `&type=1`,
      );
      setSecurityGroups(dataRes.data?.items ?? []);
    } catch (error) {
      console.log('error', error);
    }
  };
  const renderModalForm = (originalData: any, typeModal: TYPE_MODAL, isView?: boolean) => {
    if (typeModal === TYPE_MODAL.RESET_PASSWORD) {
      return <FormResetPasswordCard user={originalData} />;
    }
    return (
      <FormUserTableCard
        user={originalData}
        securityGroups={securityGroups}
        loginType={LOGIN_TYPE.WEB}
        originIsView={isView}
      />
    );
  };
  const renderFormConfirmation = (originalData: any, _typeModal: TYPE_MODAL) => {
    return <FormChangeStatusCard originalData={originalData} nameQuery={QUERIES.USERS_WEB_LIST} />;
  };
  return (
    <Main>
      <TableComponent
        getList={getUsers}
        nameQuery={QUERIES.USERS_WEB_LIST}
        originColumns={usersColumns(LOGIN_TYPE.WEB)}
        titleModal="Account"
        getById={getUserById}
        ModalForm={renderModalForm}
        ModalFormConfirmation={renderFormConfirmation}
        nameBtnAdd="Add account"
        moreQuery={`&type=${LOGIN_TYPE.WEB}`}
        placeholderSearch="Search with email, first name, last name"
        isBorder={false}
        spacing="lg"
      />
    </Main>
  );
};

export default UserWebManagement;
