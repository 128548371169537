import { Column } from 'react-table';

import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { Setting } from './type';

export const quantityUnitsColumns = (): ReadonlyArray<Column<Setting>> => {
  const actions = (data: Setting) => {
    const listActions: ActionTable[] = [
      {
        name: 'Edit',
        type: TYPE_MODAL.EDIT,
      },
    ];
    listActions.push({
      name: 'Delete',
      type: data.canDelete ? TYPE_MODAL.DELETE : TYPE_MODAL.UNABLE_DELETE_UNIT,
    });
    return listActions;
  };
  return [
    {
      id: 'name',
      accessor: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Name" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {String(props.data[props.row.index].name)}
        </Typography>
      ),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={!props.data[props.row.index].meta ? actions(props.data[props.row.index]) : []}
        />
      ),
    },
  ];
};
