import { addDays, endOfDay, format, startOfDay } from 'date-fns';

import { TimeFrame } from './type';

export const getTimeRange = (time?: TimeFrame) => {
  if (!time) {
    return {
      startDate: format(startOfDay(new Date()), 'dd MMM yyyy'),
      endDate: format(endOfDay(addDays(new Date(), 6)), 'dd MMM yyyy'),
    };
  }
  if (time.index === 0) {
    return {
      startDate: format(startOfDay(new Date(time.createdAt)), 'dd MMM yyyy'),
      endDate: format(endOfDay(addDays(new Date(time.createdAt), 6)), 'dd MMM yyyy'),
    };
  }
  if (time.index === 1) {
    return {
      startDate: format(startOfDay(addDays(new Date(time.createdAt), 7)), 'dd MMM yyyy'),
      endDate: format(endOfDay(addDays(new Date(time.createdAt), 13)), 'dd MMM yyyy'),
    };
  }
  return {
    startDate: format(startOfDay(new Date()), 'dd MMM yyyy'),
    endDate: format(endOfDay(addDays(new Date(), 6)), 'dd MMM yyyy'),
  };
};
