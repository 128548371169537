import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'app/assets/icons/icon-arrow-down.svg';
import { ReactComponent as AddIcon } from 'app/assets/icons/icon-button-add.svg';

import { CustomSpinner } from '../Spinner';

type AddButtonColor = 'primary' | 'white';

export const AddButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    title: string;
    color?: AddButtonColor;
    loading?: boolean;
    icon?: JSX.Element;
  },
) => {
  const {
    title,
    color = 'primary',
    style,
    loading,
    icon = <AddIcon style={{ marginRight: 10 }} />,
    ...rest
  } = props;
  return (
    <button
      type="button"
      className={`btn btn-${color} d-flex align-items-center justify-content-center`}
      style={{
        minWidth: 130,
        height: 43,
        borderRadius: 8,
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '23px',
        ...style,
      }}
      {...rest}
    >
      {loading && <CustomSpinner />}

      {loading ? null : icon}

      {loading ? null : title}
    </button>
  );
};

const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  margin: 4px;
  margin-left: 8px;
  path {
    stroke: var(--white);
  }
`;

export const DropdownAddButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & { title: string },
) => {
  const { title, ...rest } = props;
  return (
    <button
      type="button"
      className="btn btn-primary d-flex align-items-center justify-content-center"
      style={{
        minWidth: 130,
        height: 43,
        backgroundColor: 'var(--primary)',
        borderRadius: 8,
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '23px',
        padding: '10px 14px 10px 20px',
      }}
      {...rest}
    >
      {title}
      <StyledArrowIcon />
    </button>
  );
};
