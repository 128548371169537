import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import { NavigationDetail } from 'app/components/Navigation/core/types';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { replacePath } from 'app/helpers';

import { PATH } from 'app/constants/path';

import DeviceTabletManagementPage from './DeviceTabletManagementPage';

const DeviceManagementPage = () => {
  const navigationLocation: NavigationDetail[] = [
    {
      title: 'Tablet',
      path: PATH.SYSTEM_SETTING_DEVICE_TABLET,
    },
    // {
    //   title: "Gateway",
    //   path: PATH.SYSTEM_SETTING_DEVICE_GATEWAY,
    // },
  ];

  const [wards, setWards] = useState<Ward[]>([]);

  const getListWard = async () => {
    const dataRes = await getWards(stringifyRequestQuery({ page: 1, limit: 30 }));
    setWards(dataRes.data?.items ?? []);
  };

  useEffect(() => {
    getListWard();
  }, []);

  return (
    <Routes>
      <Route
        element={
          <RootContentPage title="Devices">
            <NavigationDefault navigation={navigationLocation} />
            <Outlet context={wards} />
          </RootContentPage>
        }
      >
        <Route
          path={replacePath(PATH.SYSTEM_SETTING_DEVICE_TABLET, PATH.SYSTEM_SETTING_DEVICE, '')}
          element={<DeviceTabletManagementPage />}
        />
        {/* <Route
          path={replacePath(
            PATH.SYSTEM_SETTING_DEVICE_GATEWAY,
            PATH.SYSTEM_SETTING_DEVICE,
            ""
          )}
          element={<DeviceGatewayManagementPage />}
        /> */}
        {/* <Route
          path={replacePath(
            PATH.SYSTEM_SETTING_DEVICE_SENSOR,
            PATH.SYSTEM_SETTING_DEVICE,
            ""
          )}
          element={<DeviceSensorManagementPage />}
        /> */}

        <Route index element={<Navigate to={PATH.SYSTEM_SETTING_DEVICE_TABLET} />} />
      </Route>
    </Routes>
  );
};

export default DeviceManagementPage;
