import { FC } from 'react';
import { Line } from 'react-chartjs-2';

import { COLOR } from 'app/components/Typography/core/enum';

import { getLineChartOptions } from 'app/modules/dashboard/core/helpers';
import { ForecastCurve } from 'app/modules/projection/core/type';

import { slice, uniqBy } from 'lodash';

import { ApprovedDataset, ForecastChartData, LineChartDataset } from '../core/type';
import {
  CategoryScale,
  ChartDataset,
  Chart as ChartJS,
  Color,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

export const LINE_STYLE_CONFIG = {
  [ForecastCurve.normal]: {
    label: 'Nominal',
    color: '#9E76FF',
    background: '#D5CEFF80',
  },
  [ForecastCurve.lower]: {
    label: 'Low',
    color: '#EE51E8',
    background: '#FCBBF980',
  },
  [ForecastCurve.upper]: {
    label: 'High',
    color: '#1ED9D9',
    background: '#A1F6F680',
  },
};

export const ForecastChart: FC<{ chartData?: ForecastChartData; showTitle?: boolean }> = ({
  chartData,
  showTitle = true,
}) => {
  const historyBase = slice(chartData?.historyData, 0, 6).map((el) => ({
    x: el.x,
    y: undefined as any,
  }));

  const getApprovedBase = () => {
    const approveData = chartData?.approvedData?.[0]?.data;
    return historyBase.concat(
      approveData
        ? slice(approveData, 0, approveData.length - 1).map((el) => ({
            x: el.x,
            y: undefined as any,
          }))
        : [],
    );
  };

  const getDatasetBase = (approveType: ForecastCurve) => {
    return chartData?.approvedData?.[0]?.approvedType === approveType
      ? getApprovedBase()
      : historyBase;
  };

  const getDataset = (approveType: ForecastCurve, lineData?: LineChartDataset) => {
    if (lineData && lineData.length > 1) {
      return getDatasetBase(approveType).concat(lineData);
    }
    return [];
  };

  const nominal = getDataset(ForecastCurve.normal, chartData?.forecastData?.normal);
  const upper = getDataset(ForecastCurve.upper, chartData?.forecastData?.upper);
  const low = getDataset(ForecastCurve.lower, chartData?.forecastData?.lower);
  const approved = historyBase.concat(chartData?.approvedData?.[0]?.data || []);

  const labels = uniqBy(
    [
      ...(chartData?.historyData?.map((item) => item.x) ?? []),
      ...(nominal.map((el) => el.x) ?? []),
      ...(approved.map((el) => el.x) ?? []),
    ],
    'x',
  );

  const options = getLineChartOptions({
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets
              .filter((dataset) => dataset.data.length > 0)
              .map((item, key) => {
                return {
                  datasetIndex: key,
                  text: item.label || '',
                  lineDash: (item.label || '').includes('Project') ? [4, 6] : [],
                  strokeStyle:
                    item.label === 'Historical' ? COLOR.WARNING : (item.borderColor as Color),
                  fillStyle: item.backgroundColor as Color,
                  lineWidth: 5,
                  pointStyle: 'line',
                  lineCap: 'round',
                  fontColor: COLOR.neutral_7,
                };
              });
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            //Determine approvedType(if any) : 'High'| 'Nominal' | 'Low' | null
            const approvedTitle = chartData?.approvedData?.[0]
              ? LINE_STYLE_CONFIG[chartData?.approvedData[0].approvedType].label
              : null;
            if (
              //If context belongs to a Projected line with the same type as Approved line and overlap with Approved line, hide the tooltip label
              approvedTitle &&
              context.dataset.label?.includes(approvedTitle) &&
              !context.dataset.label?.includes('Approved') &&
              context.parsed.x <= approved.length - 1
            ) {
              return [];
            }

            //If lines overlap with Historical line, display label from Historical only
            const areLinesOverlapped =
              context.dataset.label !== 'Historical' &&
              context.parsed.x === (chartData?.historyData?.length || 0) - 1;
            if (areLinesOverlapped) {
              return [];
            }
          },
        },
      },
      title: {
        text: 'Statistics and consumption trends over the next 14 days',
        display: showTitle,
        padding: { bottom: 32 },
      },
    },
    scales: {
      y: {
        suggestedMax: 5,
        min: 0,
        ticks: { stepSize: 1 },
      },
    },
  });

  const histories = chartData?.historyData;

  const getForeCastLineStyle = (approvedType: ForecastCurve, data?: LineChartDataset) => {
    return {
      label: `Projected demand (${LINE_STYLE_CONFIG[approvedType].label})`,
      data: data,
      borderColor: LINE_STYLE_CONFIG[approvedType].color,
      backgroundColor: 'transparent',
      pointHoverBackgroundColor: LINE_STYLE_CONFIG[approvedType].color,
      borderWidth: 3,
      borderDash: [9, 5],
      pointBackgroundColor: LINE_STYLE_CONFIG[approvedType].color,
    } as ChartDataset<'line', LineChartDataset | undefined>;
  };

  const getApprovedLineStyle = (dataset?: ApprovedDataset) => {
    if (dataset) {
      return {
        label: `Approved demand (${LINE_STYLE_CONFIG[dataset.approvedType].label})`,
        data: approved,
        fill: true,
        borderColor: LINE_STYLE_CONFIG[dataset.approvedType].color,
        backgroundColor: LINE_STYLE_CONFIG[dataset.approvedType].background,
        pointHoverBackgroundColor: LINE_STYLE_CONFIG[dataset.approvedType].color,
        borderWidth: 3,
        pointBackgroundColor: LINE_STYLE_CONFIG[dataset.approvedType].color,
      } as ChartDataset<'line', LineChartDataset | undefined>;
    }
    return {} as ChartDataset<'line', LineChartDataset | undefined>;
  };

  const getForecastDataset = () => {
    const returnVal = [
      {
        label: 'Historical',
        data: histories,
        fill: true,
        borderColor: COLOR.WARNING,
        backgroundColor: '#FFEBBD80',
        borderWidth: 3,
        pointBackgroundColor: COLOR.WARNING,
      } as ChartDataset<'line', LineChartDataset | undefined>,
      getForeCastLineStyle(ForecastCurve.upper, upper),
      getForeCastLineStyle(ForecastCurve.normal, nominal),
      getForeCastLineStyle(ForecastCurve.lower, low),
      getApprovedLineStyle(chartData?.approvedData?.[0]),
    ];
    if (!chartData) return [];
    return returnVal;
  };

  return (
    <Line
      options={options}
      data={{
        labels,
        datasets: getForecastDataset(),
      }}
    />
  );
};
