import Typography, { EmptyTableCell } from 'app/components/Typography';

interface Props {
  label: string;
  value?: string;
  valueList?: string[];
  colorValue?: string;
  content?: any;
  fontWeightValue?: 400 | 500 | 600 | 700;
}
const InfoRackItemCard: React.FC<Props> = ({
  label,
  value,
  valueList,
  content,
  colorValue,
  fontWeightValue,
}) => {
  const renderEmptyTableCell = () => {
    if (!value && !valueList && !content) {
      return EmptyTableCell;
    }
    return null;
  };
  const renderValueList = () =>
    valueList?.map((v, index) => (
      <Typography
        key={index}
        className="text-overflow-ellipsis"
        fontSize={16}
        lineHeight={26}
        color={colorValue ?? 'neutral-10'}
        fontWeight={fontWeightValue ?? 400}
      >
        {v}
      </Typography>
    ));
  return (
    <div
      className="d-flex flex-column"
      style={{
        padding: '0px 24px',
        maxWidth: '317px',
      }}
    >
      <Typography variant="title-5">{label}</Typography>
      {value ? (
        <Typography
          fontSize={16}
          lineHeight={26}
          color={colorValue ?? 'neutral-10'}
          fontWeight={fontWeightValue ?? 400}
        >
          {value}
        </Typography>
      ) : (
        renderValueList()
      )}
      {content}
      {renderEmptyTableCell()}
    </div>
  );
};
export default InfoRackItemCard;
