import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useBoolean } from 'app/hooks';

import { getWards } from '../locations/wards/core/requests';
import { Ward } from '../locations/wards/core/type';
import HolidayStockRunCard from './components/HolidayStockRunCard';
import MQTTSettingCard from './components/MQTTSettingCard';
import OperationalHoursInWardCard from './components/OperationalHoursInWardCard';
import { NameSetting } from './core/enum';
import { getSetting } from './core/request';
import { Setting, SettingType, StockRunSettingType } from './core/type';

const Body = styled.div`
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 28px;
`;

const ConfigStockRunPage = () => {
  const isLoading = useBoolean(false);

  const [mqttSetting, setMqttSetting] = useState<Setting[] | null>(null);
  const [holidays, setHolidays] = useState<Setting[]>([]);
  const [operationalHours, setOperationalHours] = useState<Setting[]>([]);
  const [wards, setWards] = useState<Ward[]>([]);
  const [currentSelectedSetting, setCurrentSelectedSetting] = useState<SettingType | null>(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    isLoading.setValue(true);
    try {
      const quantityBalanceUpdateItem: Setting[] = await getSetting(SettingType.stockRun, {
        name: StockRunSettingType.qtyUpdateMethod,
      });
      const holidayItems: Setting[] = await getSetting(SettingType.holiday, {
        name: NameSetting.holiday,
      });
      const operationalHourItems: Setting[] = await getSetting(SettingType.location, {
        name: NameSetting.workingTime,
      });
      await getListWard();
      setMqttSetting(quantityBalanceUpdateItem);
      setHolidays(holidayItems);
      setOperationalHours(operationalHourItems);
      isLoading.setValue(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  const getListWard = async () => {
    try {
      const dataRes = await getWards(stringifyRequestQuery({ page: 1, limit: 100 }));
      setWards(dataRes.data?.items ?? []);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <Body className="d-flex flex-column">
      <div>
        <MQTTSettingCard
          mqttSetting={mqttSetting}
          setMqttSetting={setMqttSetting}
          currentSelectedSetting={currentSelectedSetting}
          setCurrentSelectedSetting={setCurrentSelectedSetting}
        />
        {Boolean(holidays) && (
          <HolidayStockRunCard
            holidays={holidays}
            currentSelectedSetting={currentSelectedSetting}
            setCurrentSelectedSetting={setCurrentSelectedSetting}
          />
        )}

        <OperationalHoursInWardCard
          wards={wards}
          operationalHours={operationalHours}
          currentSelectedSetting={currentSelectedSetting}
          setCurrentSelectedSetting={setCurrentSelectedSetting}
        />
      </div>
      {isLoading.value && <LoadingCard />}
    </Body>
  );
};

export default ConfigStockRunPage;
