import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Header } from './components/Header';

const Modal = styled.div`
  .custom-loading {
    left: 0 !important;
    position: absolute !important;
  }
`;
export interface ModalProps {
  onClickCloseModal: MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
  title?: string;
  customHeader?: React.ReactNode;
  childrenAction?: React.ReactNode;
  styleModalDialog?: React.CSSProperties;
  styleBodyModalDialog?: React.CSSProperties;
  showHeader?: boolean;
  isConfirmationModal?: boolean;
  closeOnBackdrop?: boolean;
  modalContentStyle?: React.CSSProperties;
  headerModalStyle?: React.CSSProperties;
}

const ModalDefault: React.FC<ModalProps> = ({
  children,
  childrenAction,
  onClickCloseModal,
  styleModalDialog,
  title,
  customHeader,
  styleBodyModalDialog,
  showHeader = true,
  isConfirmationModal = false,
  closeOnBackdrop,
  modalContentStyle,
  headerModalStyle,
}) => {
  return (
    <Modal>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
        onClick={closeOnBackdrop ? onClickCloseModal : undefined}
      >
        {/* begin::Modal dialog */}
        <div
          className="modal-dialog modal-dialog-centered"
          style={{
            minWidth: isConfirmationModal ? 440 : 640,
            width: 440,
            ...styleModalDialog,
          }}
          onClick={(ev) => ev.stopPropagation()}
        >
          {/* begin::Modal content */}
          <div className="modal-content" style={{ ...modalContentStyle }}>
            {showHeader && (
              <Header
                onClickCloseModal={onClickCloseModal}
                title={title}
                customHeader={customHeader}
                headerModalStyle={headerModalStyle}
              />
            )}
            {/* begin::Modal body */}
            <div
              className="modal-body scroll-y"
              style={{
                maxHeight: showHeader ? '65vh' : '75vh',
                padding: 28,
                ...styleBodyModalDialog,
              }}
            >
              {children}
            </div>
            {childrenAction && (
              <div style={{ paddingRight: 28, paddingBottom: 28 }}>{childrenAction}</div>
            )}

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </Modal>
  );
};
export default ModalDefault;
