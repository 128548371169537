import styled from 'styled-components';

import Typography from 'app/components/Typography';

import './switch-button.css';

const getBackgroundColor = (checked?: boolean, disabled?: boolean) => {
  if (checked) {
    return disabled ? 'var(--primary-5)' : 'var(--primary)';
  }
  return disabled ? 'var(--neutral-4)' : 'var(--neutral-2-5)';
};

const Main = styled.div<{ checked?: boolean; disabled?: boolean }>`
  input[disabled] {
    ${({ checked }) =>
      checked
        ? `.slider {background-color: var(--primary-5);}`
        : ` background-color: var(--neutral-4)`}
  }
  ${({ checked, disabled }) =>
    `.slider {background-color:${getBackgroundColor(checked, disabled)}}`}
`;
export interface SwitchButtonDefaultProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelButton?: string;
}

const SwitchButtonDefault = (props: SwitchButtonDefaultProps) => {
  const { labelButton, disabled, checked, ...otherProps } = props;
  return (
    <Main checked={checked} disabled={disabled} className="d-flex align-items-center">
      <label className="switch">
        <input type="checkbox" disabled={disabled} checked={checked} {...otherProps} />
        <span className="slider round"></span>
      </label>
      {labelButton ? (
        <Typography
          variant="body-5"
          color={disabled ? 'var(--neutral-6)' : 'var(--neutral-10)'}
          style={{
            marginLeft: 9,
          }}
        >
          {labelButton}
        </Typography>
      ) : null}
    </Main>
  );
};
export default SwitchButtonDefault;
