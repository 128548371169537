import { Column } from 'react-table';

import { RadioInput } from 'app/components/Input/RadioInput';
import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography, { EmptyTableCell } from 'app/components/Typography';

import { getStockHealth } from 'app/modules/reporting/core/columns';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { formatCustomDate, formatDate } from 'app/helpers';
import { includes } from 'lodash';

import { PATH } from 'app/constants/path';

import ItemInfoCard from '../../items/components/ItemInfoCard';
import SKUCard from '../../items/components/SKUCard';
import { UserInfo } from '../../items/components/UserInfo';
import { QtyTypography } from '../../racks/components/Typography';
import StatusStockRunCard from '../components/StatusStockRunCard';
import { StatusStockUp } from './enum';
import { StockRun, StockRunHistory } from './type';

const PADDING_SIZE = 12;

export const stockRunsColumns = (): ReadonlyArray<Column<StockRun>> => {
  const actions = (item: StockRun) => {
    if (
      !includes(
        [StatusStockUp.Draft, StatusStockUp.Posted, StatusStockUp['Past-due']],
        item.status,
      ) ||
      item.activate === 1
    ) {
      return [];
    }
    const listActions: ActionTable[] = [
      {
        name: 'Edit',
        type: TYPE_MODAL.EDIT,
        redirect: PATH.INVENTORY_STOCK_RUN_EDIT,
      },
    ];
    if (item.status === StatusStockUp.Draft) {
      listActions.push({
        name: 'Delete',
        type: TYPE_MODAL.DELETE,
      });
    }
    return listActions;
  };

  return [
    {
      id: 'createdAt',
      accessor: 'createdAt',
      Header: (props) => <CustomHeader tableProps={props} title="Created at" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].createdAt)}
        </Typography>
      ),
    },
    {
      id: 'index',
      accessor: 'index',
      Header: (props) => <CustomHeader tableProps={props} title="Stock run index" />,
      width: '15%',
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {`${props.data[props.row.index].index}`}
        </Typography>
      ),
    },
    {
      id: 'wardName',
      accessor: `location`,
      Header: (props) => <CustomHeader tableProps={props} title="Ward" />,
      width: '15%',
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {props.data[props.row.index].location?.name}
        </Typography>
      ),
    },
    {
      id: 'completedRacks',
      accessor: `noOfRacksCompeted`,
      Header: 'Completed racks',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].noOfRacksCompeted} / 
          ${props.data[props.row.index].noOfRacks}`}{' '}
        </Typography>
      ),
    },
    {
      id: 'completedBins',
      accessor: `noOfBinsCompeted`,
      Header: 'Completed bins',
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {`${props.data[props.row.index].noOfBinsCompeted} / 
          ${props.data[props.row.index].noOfBins}`}
        </Typography>
      ),
    },
    {
      id: 'startTime',
      accessor: `startTime`,
      Header: (props) => <CustomHeader tableProps={props} title="Start date" />,
      width: '15%',
      Cell: ({ ...props }) =>
        props.data[props.row.index].startTime ? (
          <Typography
            variant="body-5"
            color="neutral-10"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {formatDate(
              props.data[props.row.index].actualStartTime ?? props.data[props.row.index].startTime,
            )}
          </Typography>
        ) : (
          EmptyTableCell
        ),
    },
    {
      id: 'endTime',
      accessor: `endTime`,
      Header: (props) => <CustomHeader tableProps={props} title="Complete date" />,
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {formatDate(
            props.data[props.row.index].actualEndTime ?? props.data[props.row.index].endTime,
          )}
        </Typography>
      ),
      width: '12%',
    },
    {
      id: 'status',
      accessor: `status`,
      width: '0%',
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => (
        <StatusStockRunCard
          status={props.data[props.row.index].status}
          styleLabel={{
            whiteSpace: 'nowrap',
          }}
        />
      ),
    },
    {
      id: 'stockLevel',
      accessor: 'stockLevel',
      Header: (props) => <CustomHeader tableProps={props} title="Stock health" />,
      Cell: ({ ...props }) =>
        getStockHealth(props.data[props.row.index].stockLevel, props.data[props.row.index].status),
      width: '10%',
    },
    {
      id: 'actions',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      width: '5%',
      Cell: ({ ...props }) => (
        <div className="d-flex align-items-center justify-content-between">
          <ActionsCell
            item={props.data[props.row.index]}
            actions={actions(props.data[props.row.index])}
          />
        </div>
      ),
    },
  ];
};

export const historyStockRunColumns = (): ReadonlyArray<Column<StockRunHistory>> => {
  return [
    {
      id: 'dateModified',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].expriryDate)}
        </Typography>
      ),
    },
    {
      id: 'item',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].inventory?.image || ''}
          name={props.data[props.row.index].inventory?.name || ''}
          stockType={props.data[props.row.index].inventory?.stockType}
          isNotNavigatable={true}
        />
      ),
      width: '20%',
    },
    {
      id: 'sku',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      width: '12%',
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].inventory?.skuId}
          skuSub={props.data[props.row.index].inventory?.skuSub}
        />
      ),
    },
    {
      id: 'rack',
      Header: (props) => <CustomHeader tableProps={props} title="Rack" />,
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {props.data[props.row.index].bin?.warehouse?.name}
        </Typography>
      ),
      width: '10%',
    },
    {
      id: 'bin',
      Header: (props) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].bin?.name}
        </Typography>
      ),
    },
    {
      id: 'qtyToStockUp',
      accessor: 'qtyToStockUp',
      Header: (props) => <CustomHeader tableProps={props} title="Qty to stock up" />,
      width: '1%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {Number(props.data[props.row.index].qtyToStockUp)}
        </Typography>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props) => <CustomHeader tableProps={props} title="Adjustment qty" />,
      width: '1%',
      Cell: ({ ...props }) => <QtyTypography qty={props.data[props.row.index].quantity ?? ''} />,
    },
    {
      id: 'qtyCompleted',
      accessor: 'qtyCompleted',
      Header: (props) => <CustomHeader tableProps={props} title="Qty completed" />,
      width: '1%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {Number(props.data[props.row.index].qtyCompleted)}
        </Typography>
      ),
    },
    {
      id: 'user',
      accessor: 'episode',
      Header: (props) => <CustomHeader tableProps={props} title="By user" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <UserInfo
          userName={props.data[props.row.index].userName}
          roleName={props.data[props.row.index].roleName}
          userNameStyle={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            wordWrap: 'break-word',
          }}
        />
      ),
    },
  ];
};

export const forecastWardColumns: ReadonlyArray<Column<Ward>> = [
  {
    id: 'name',
    accessor: 'name',
    width: '60%',
    Header: ({ ...props }) => <CustomHeader tableProps={props} title="Ward" />,
    Cell: ({ ...props }) => (
      <Typography variant="body-1">{props.data[props.row.index].name}</Typography>
    ),
  },
  {
    id: 'item',
    accessor: 'item',
    Header: (props) => <CustomHeader tableProps={props} title="Item" />,
    Cell: ({ ...props }) => (
      <Typography variant="body-1">{props.data[props.row.index].item}</Typography>
    ),
  },
  {
    Header: () => <div style={{ width: 32.5 }}></div>,
    id: 'radio',
    width: 32.5,
    Cell: (_props, selected: boolean) => (
      <RadioInput className="form-check-input" type="radio" labelAlign="left" checked={selected} />
    ),
  },
];
