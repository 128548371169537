import { ID } from 'app/components/Table/core/types';

import { formatDate } from 'app/helpers';
import { getPathWithParams, useNavigateWithSendPath } from 'app/helpers/utils';
import { TransactionItem } from 'app/hooks/socket';
import { differenceInCalendarDays, format, startOfDay } from 'date-fns';
import { groupBy, sumBy } from 'lodash';

import { StockLevel } from './enum';
import { ForecastItem, InventoryStockValue, StockRunFilterDetail } from './type';

export const labelFilterDate = (from: Date | null, to: Date | null) => {
  if (!from || !to) {
    return '';
  }
  return `${formatDate(from)} - ${formatDate(to)}`;
};
export const coverFilterToQuery = (filter: Partial<StockRunFilterDetail>) => {
  let query = ``;
  const queryStatus = filter.status
    ? Object.values(filter.status)
        .map((status) => {
          return `&status[]=${status}`;
        })
        .join('')
    : '';
  const queryWard = filter.wardIds
    ? filter.wardIds
        .map((ward) => {
          return `&wardIds[]=${ward}`;
        })
        .join('')
    : '';

  if (filter.from_commence_date && filter.to_commence_date) {
    query = query.concat(
      `&from_commence_date=${new Date(
        filter.from_commence_date,
      ).toISOString()}&to_commence_date=${new Date(filter.to_commence_date).toISOString()}`,
    );
  }
  if (filter.c_from_commence_date && filter.c_to_commence_date) {
    query = query.concat(
      `&from_commence_date=${new Date(
        filter.c_from_commence_date,
      ).toISOString()}&to_commence_date=${new Date(filter.c_to_commence_date).toISOString()}`,
    );
  }
  if (filter.from_complete_date && filter.to_complete_date) {
    query = query.concat(
      `&from_complete_date=${new Date(
        filter.from_complete_date,
      ).toISOString()}&to_complete_date=${new Date(filter.to_complete_date).toISOString()}`,
    );
  }
  return query.concat(queryStatus).concat(queryWard);
};

export const isDateGreaterThan = (date1: Date, date2: Date) => {
  return differenceInCalendarDays(date1, date2) > 0;
};

const ITEM_ATTRIBUTE_CONFIG_DEFAULT = {
  color: 'neutral-10',
  textDecoration: 'none',
  qtyColor: 'neutral-10',
};
const ITEM_ATTRIBUTE_CONFIG_REMOVE = {
  color: 'neutral-6',
  textDecoration: 'line-through',
  qtyColor: 'danger',
};

export const getStockLevel = function (value: InventoryStockValue) {
  switch (true) {
    case value.parValue + value.restockValue + value.alarmValue === 0:
      return StockLevel.normal;

    case value.qtyBalance <= value.alarmValue:
      return StockLevel.alarm;

    case value.qtyBalance <= value.restockValue:
      return StockLevel.restock;

    case value.qtyBalance <= value.parValue:
      return StockLevel.par;

    default:
      return StockLevel.normal;
  }
};

export const getItemAttribute = (
  type: 'color' | 'textDecoration' | 'qtyColor',
  props: {
    qtyCompleted?: number;
    isCompleted?: boolean;
  },
) =>
  Number(props.qtyCompleted) === 0 && props.isCompleted
    ? ITEM_ATTRIBUTE_CONFIG_REMOVE[type]
    : ITEM_ATTRIBUTE_CONFIG_DEFAULT[type];

export const getChangeQtyGroupByItem = (changeItems: TransactionItem[]) => {
  const groupedItems = groupBy(changeItems, 'sku');
  return Object.entries(groupedItems).map(([_, value]) => ({
    itemId: value[0].item?.id || '',
    totalChangeQty: sumBy(value, 'qty'),
  }));
};

const STOCK_RUN_UPDATE_KEY = 'stock_run_status';
export const useUpdateStockRunStatus = () => {
  const setStatusKey = (value: string) => localStorage.setItem(STOCK_RUN_UPDATE_KEY, value);

  const getStatusKey = () => localStorage.getItem(STOCK_RUN_UPDATE_KEY);

  const removeStatusKey = () => localStorage.removeItem(STOCK_RUN_UPDATE_KEY);

  return { setStatusKey, getStatusKey, removeStatusKey };
};

export const getBinItemForecast = (
  itemId: ID,
  parValue: number,
  qtyBalance: number,
  forecasting: ForecastItem[],
) => {
  if (!parValue) {
    return 0;
  }

  const forecastValue =
    forecasting.find((el) => itemId && el.itemId === itemId)?.recommendedQty || 0;

  if (qtyBalance > parValue) {
    return forecastValue;
  }

  return forecastValue ? Math.ceil(parValue + forecastValue - qtyBalance) : 0;
};

export const useNavigateAddStockRunBySims = (path: string) => {
  const navigate = useNavigateWithSendPath();

  return (forecastingWard?: ID, startDate?: number) => {
    navigate(getPathWithParams(path, { forecastingWard, startDate }));
  };
};

export const getStartDate = (date: Date) => {
  return format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss');
};

export const getCompleteDate = (date: Date) => {
  return format(startOfDay(date).setHours(12), 'yyyy-MM-dd HH:mm:ss');
};

export const getRecommendedQty = (itemId: ID, forecasting: ForecastItem[]) => {
  return forecasting.find((el) => itemId && el.itemId === itemId)?.recommendedQty;
};

export const getForecastings = (oldForestings: ForecastItem[], newForecastings: ForecastItem[]) => {
  return oldForestings.map((oldForesting) => {
    const obj = newForecastings.find((el) => el.itemId === oldForesting.itemId);
    if (obj) {
      oldForesting.recommendedQty = obj.recommendedQty;
    }
    return oldForesting;
  });
};
