import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import TableComponent from 'app/components/Table/TableComponent';
import { DEFAULT_TABLE_QUERY, QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { useSyncQueryToState } from 'app/modules/query-state/hooks';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { includes } from 'lodash';

import { devicesColumns } from './core/columns';
import { DeviceType } from './core/enum';
import { deleteDevice, getDevices } from './core/requests';
import { Device } from './core/type';
import FormCodeDeviceModal from './modal/FormCodeDeviceModal';
import { FormDeviceTableCard } from './modal/FormDeviceTableCard';

const DeviceTabletManagementPage = () => {
  useSyncQueryToState(window.location.pathname, DEFAULT_TABLE_QUERY);

  const wards = useOutletContext();
  const [codeDevice, setCodeDevice] = useState<{
    type: TYPE_MODAL;
    data: Device | null;
  }>({
    type: TYPE_MODAL.NONE,
    data: null,
  });
  const onShowCodeWhenAddDevice = (newDevice: Device) => {
    setCodeDevice({
      data: newDevice,
      type: TYPE_MODAL.CONNECT_TABLET,
    });
  };
  const renderModalForm = (originalData: Device, typeModal: TYPE_MODAL, isView?: boolean) => {
    if (!includes([TYPE_MODAL.EDIT, TYPE_MODAL.ADD], typeModal)) {
      return <div />;
    }
    return (
      <FormDeviceTableCard
        device={originalData}
        typeSub={DeviceType.tablet}
        titleNotification="Tablet"
        wards={wards as Ward[]}
        originIsView={isView}
        onShowCodeWhenAddDevice={onShowCodeWhenAddDevice}
      />
    );
  };
  const onCloseCodeDeviceModal = () => {
    setCodeDevice({
      data: null,
      type: TYPE_MODAL.NONE,
    });
  };
  const renderModalOutsideTable = (originalData: Device, typeModal?: TYPE_MODAL) => {
    if (includes([TYPE_MODAL.CONNECT_TABLET, TYPE_MODAL.GENERATE_CODE], typeModal)) {
      return (
        <FormCodeDeviceModal device={originalData} type={typeModal ?? TYPE_MODAL.CONNECT_TABLET} />
      );
    }
    return <div />;
  };

  return (
    <div>
      <TableComponent
        getList={getDevices}
        nameQuery={QUERIES.DEVICE_TABLET_LIST}
        originColumns={devicesColumns(DeviceType.tablet)}
        titleModal="Tablet"
        ModalForm={renderModalForm}
        nameBtnAdd="Add tablet"
        deleteById={deleteDevice}
        ModalOutsideTable={renderModalOutsideTable}
        moreQuery={`&typeSub=${DeviceType.tablet}`}
        contentModal="Deleting this tablet will makes it unable to send any data to the system."
        placeholderSearch="Search with tablet"
        isBorder={false}
        syncParamUrl
        spacing="lg"
      />
      {codeDevice.data && (
        <FormCodeDeviceModal
          onCloseModal={onCloseCodeDeviceModal}
          device={codeDevice.data}
          type={codeDevice.type}
        />
      )}
    </div>
  );
};

export default DeviceTabletManagementPage;
