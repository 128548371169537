import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import {
  SecurityGroup,
  SecurityGroupDetail,
  SecurityGroupRequest,
  SecurityGroupsQueryResponse,
} from './type';
import axios, { AxiosResponse } from 'axios';

const SECURITY_GROUPS_URL = `${API_URL}/api/security-groups`;
const SECURITY_GROUPS_POLICY_URL = `${API_URL}/api/security-group-policy`;

const getSecurityGroups = (query: string): Promise<SecurityGroupsQueryResponse> => {
  return axios.get(`${SECURITY_GROUPS_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<SecurityGroup>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const updateSecurityGroups = (
  securityGroupID: ID,
  usersRoleRequest: SecurityGroupRequest,
): Promise<SecurityGroup | undefined> => {
  return axios
    .patch(`${SECURITY_GROUPS_URL}/${securityGroupID}`, trimObject(usersRoleRequest))
    .then((response: AxiosResponse<Response<SecurityGroup>>) => response.data)
    .then((response: Response<SecurityGroup>) => response.data);
};

const createSecurityGroups = (
  usersRoleRequest: SecurityGroupRequest,
): Promise<SecurityGroup | undefined> => {
  return axios
    .post(SECURITY_GROUPS_URL, trimObject(usersRoleRequest))
    .then((response: AxiosResponse<Response<SecurityGroup>>) => response.data)
    .then((response: Response<SecurityGroup>) => response.data);
};

const deleteSecurityGroups = async (securityGroupID: ID): Promise<void> => {
  try {
    await axios.delete(`${SECURITY_GROUPS_URL}/${securityGroupID}`);
  } catch (error) {
    console.log(`Error deleting security group ID ${securityGroupID}`);
    throw error;
  }
};

const getOneSecurityGroup = (securityGroupId: number): Promise<SecurityGroup | undefined> => {
  return axios
    .get(`${SECURITY_GROUPS_URL}/${securityGroupId}`)
    .then((response: AxiosResponse<Response<SecurityGroup>>) => response.data)
    .then((response: Response<SecurityGroup>) => response.data);
};

const updatePermissionSecurityGroups = (request: {
  securityGroupId: ID;
  policyIds: ID[];
}): Promise<SecurityGroupDetail | undefined> => {
  return axios
    .post(`${SECURITY_GROUPS_POLICY_URL}`, request)
    .then((response: AxiosResponse<Response<SecurityGroupDetail>>) => response.data)
    .then((response: Response<SecurityGroupDetail>) => response.data);
};
export {
  getSecurityGroups,
  createSecurityGroups,
  updateSecurityGroups,
  deleteSecurityGroups,
  getOneSecurityGroup,
  updatePermissionSecurityGroups,
};
