import { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { scrollToTop } from 'app/helpers';
import { useBoolean, useNumber } from 'app/hooks';

import { ReactComponent as LeadingIcon } from 'app/assets/icons/icon-sort-default.svg';

import { EmptyNotification } from './components/EmptyNotifcation';
import { NotificationGroup } from './components/NotificationGroup';
import { StatusNotification } from './core/enum';
import { getNotificationGroups, updateNotification } from './core/request';
import { hasNewUnreadNotiSelector, useNotificationStore } from './store';
import SwitchButtonDefault from 'theme/layout/components/SwitchButton/SwitchButtonDefault';

const Header = styled.div`
  padding-top: 36px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select-body {
    background: var(--white);
  }
  .title {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

const AccordionWrapper = styled.div`
  min-height: 90vh;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .accordion {
    --bs-accordion-bg: transparent !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 32px;
  background-color: var(--primary);
  svg path {
    stroke: var(--white);
  }
  left: 50%;
  padding: 10px 20px;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
`;

const ButtonLoadMore = styled.div`
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 16px;
  padding: 0px 20px;
  background: var(--white);
  border-radius: 8px;
  cursor: pointer;
  #load-more-title {
    color: var(--neutral-7);
  }
  :hover {
    background: var(--neutral-3);
    #load-more-title {
      color: var(--neutral-10);
    }
  }
`;

const BOTTOM_OFFSET = 350;

const NotificationPage = () => {
  const notificationGroup = useNotificationStore((s) => s.state.notificationGroup);
  const hasNewNoti = useNotificationStore(hasNewUnreadNotiSelector);

  const unreadCount = useNotificationStore((s) => s.state.unReadCount);

  const divMasterRef: React.RefObject<HTMLDivElement> = useOutletContext();
  const groupPage = useNumber(1);
  const unreadOnlyRef = useRef(false);
  const isReload = useBoolean();
  const isLoading = useBoolean(false);

  const isReadQuery = unreadOnlyRef.current ? false : undefined;

  const notiGropToDisplay = unreadOnlyRef.current
    ? notificationGroup.items.filter((dayNoti) =>
        dayNoti.items.some((notiItem) => notiItem.read === Boolean(!unreadOnlyRef.current)),
      )
    : notificationGroup.items;

  const loadMoreNotiGroup = () => {
    getNotificationGroups({
      page: groupPage.value + 1,
      setLoading: isLoading.setValue,
      isRead: unreadOnlyRef.current ? false : undefined,
    });
    groupPage.setValue(groupPage.value + 1);
  };

  useEffect(() => {
    getNotificationGroups({ page: 1, setLoading: isLoading.setValue });
    useNotificationStore
      .getState()
      .setState({ initUnReadCount: useNotificationStore.getState().state.unReadCount });
    return useNotificationStore.getState().clearState;
  }, []);

  const markAllRead = async () => {
    await updateNotification({ isRead: StatusNotification.Read });
    useNotificationStore.getState().onMarkAllRead();
    useNotificationStore.getState().setState({
      initUnReadCount: 0,
      unReadCount: 0,
      refreshTimestamp: +new Date(),
    });
  };

  const onViewNewUnreads = () => {
    getNotificationGroups({
      page: 1,
      isRead: isReadQuery,
      setLoading: isLoading.setValue,
    });
    useNotificationStore.getState().setState({
      initUnReadCount: useNotificationStore.getState().state.unReadCount,
    });
    scrollToTop(divMasterRef);
    groupPage.setValue(1);
  };

  useEffect(() => {
    if (notiGropToDisplay.length === 0 && notificationGroup.total > 0) {
      getNotificationGroups({
        page: 1,
        isRead: unreadOnlyRef.current ? false : undefined,
      });
      groupPage.setValue(1);
    }
  }, [notiGropToDisplay.length]);

  const toggleShowUnreadOnly = () => {
    const newUnreadOnly = !unreadOnlyRef.current;
    getNotificationGroups({
      page: 1,
      isRead: newUnreadOnly ? false : undefined,
      setLoading: isLoading.setValue,
    });
    unreadOnlyRef.current = newUnreadOnly;
    groupPage.setValue(1);
  };

  return (
    <div className="min-h-100 d-flex flex-column" style={{ position: 'relative' }}>
      {hasNewNoti && !isReload.value && (
        <Button onClick={onViewNewUnreads}>
          <LeadingIcon />
          <Typography variant="title-4" color="white" style={{ marginLeft: 6 }}>
            New unreads
          </Typography>
        </Button>
      )}

      <Header>
        <div className="d-flex align-items-center">
          <Typography variant="heading-6" style={{ marginRight: 24 }}>
            Notification
          </Typography>
          {unreadCount > 0 && (
            <Typography variant="body-1" color="primary" className="title" onClick={markAllRead}>
              Mark all as read
            </Typography>
          )}
        </div>
        <div className="d-flex flex-col gap-10">
          <div className="d-flex align-items-center">
            <Typography variant="body-1" style={{ marginRight: 9 }}>
              Only show unread
            </Typography>
            <SwitchButtonDefault checked={unreadOnlyRef.current} onChange={toggleShowUnreadOnly} />
          </div>
        </div>
      </Header>
      {isLoading.value || notiGropToDisplay.length ? (
        <AccordionWrapper>
          <div>
            {notificationGroup.items.map((item, index) => (
              <NotificationGroup
                date={item.day}
                index={index}
                key={index}
                isRead={unreadOnlyRef.current ? false : undefined}
                notifications={item.items}
              />
            ))}
          </div>
          {notificationGroup.total > notificationGroup.items.length && (
            <div className="w-100 d-flex justify-content-center">
              <ButtonLoadMore onClick={loadMoreNotiGroup}>
                <Typography id="load-more-title" variant="title-4">
                  Load more
                </Typography>
              </ButtonLoadMore>
            </div>
          )}
        </AccordionWrapper>
      ) : (
        <EmptyNotification />
      )}
      {isLoading.value && <LoadingCard />}
    </div>
  );
};

export default NotificationPage;
