import { FC, PropsWithChildren, useMemo } from 'react';
import { HeaderProps } from 'react-table';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { useChangeParams } from 'app/modules/query-state/hooks';

import { ReactComponent as IconSortASC } from 'app/assets/icons/icon-sort-asc.svg';
import { ReactComponent as IconSortDefault } from 'app/assets/icons/icon-sort-default.svg';
import { ReactComponent as IconSortDESC } from 'app/assets/icons/icon-sort-desc.svg';

import { useQueryRequest } from '../core/QueryRequestProvider';
import { getHeaderTableStyle } from '../core/helpers';
import clsx from 'clsx';

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<any> & { syncParamUrl?: boolean; index?: number }>;
  styleTh?: React.CSSProperties;
};
const TableTh = styled.th`
  cursor: pointer;
  padding-top: 0px;
  white-space: nowrap;
`;
const CustomHeader: FC<Props> = ({ className, title, tableProps, styleTh }) => {
  const id = tableProps.column.id;
  const { state, updateState } = useQueryRequest();
  const { onChangeParams } = useChangeParams();

  const isSelectedForSorting = useMemo(() => {
    return state.orderBy && state.orderBy === id;
  }, [state, id]);
  const orderDirection: 'ASC' | 'DESC' | undefined = useMemo(() => state.orderDirection, [state]);

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return;
    }

    const orderBy = id;

    const getNewOrderDirection = () => {
      if (!isSelectedForSorting) return 'ASC';
      if (orderDirection === undefined) return undefined;
      return orderDirection === 'ASC' ? 'DESC' : 'ASC';
    };

    const newOrderDirection = getNewOrderDirection();

    if (newOrderDirection === undefined) {
      return;
    }

    updateState({
      page: 1,
      orderBy,
      orderDirection: newOrderDirection,
      limit: state.limit,
    });

    if (tableProps.syncParamUrl) {
      onChangeParams({
        orderBy,
        orderDirection: newOrderDirection,
      });
    }
  };

  const renderIconSort = () => {
    if (id === 'actions' || id === 'selection') {
      return null;
    }
    if (!isSelectedForSorting) {
      return (
        <IconSortDefault
          style={{
            marginLeft: 3,
            marginBottom: 2,
          }}
        />
      );
    }
    if (orderDirection === undefined) {
      return null;
    }
    if (orderDirection === 'ASC') {
      return <IconSortASC style={{ marginLeft: 3, marginBottom: 2 }} />;
    }
    return <IconSortDESC style={{ marginLeft: 3, marginBottom: 2 }} />;
  };

  return (
    <TableTh
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      onClick={sortColumn}
      style={{
        width: tableProps.column.width,
        ...getHeaderTableStyle(Number(tableProps.index), tableProps.columns.length),
        ...styleTh,
      }}
    >
      <div className="d-flex align-items-center">
        <Typography variant="body-7">{title}</Typography>

        {renderIconSort()}
      </div>
    </TableTh>
  );
};

export { CustomHeader };
