import React, { useEffect } from 'react';
import styled from 'styled-components';

import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { injectDragToScroll } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { orderBy } from 'lodash';

import { ActionBin } from '../racks/core/enum';
import BinDetailCard from './components/BinDetailCard';
import { Bin } from './core/type';

const Main = styled.div`
  width: 100%;
  padding-top: 55px;
`;
export const GridView = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding: 28px;
  overflow-x: auto;
  user-select: none;
`;
const BinNoItem = styled.div`
  padding: 8px 6px 6px;
  height: 126px;
  border: 1px solid transparent;
  border-radius: 8px;
`;

interface Props {
  bins: Bin[];
  onClickModal: (key: ActionBin, originBin: Bin) => void;
  inSession?: boolean;
}
const ViewListBin: React.FC<Props> = ({ bins, onClickModal, inSession }) => {
  injectDragToScroll('bin-layout');
  const isLoading = useBoolean(true);

  useEffect(() => {
    setTimeout(() => {
      isLoading.setValue(false);
    }, 500);
  }, []);

  return (
    <Main className="d-flex flex-column">
      <GridView id="bin-layout">
        {new Array(7).fill(null).map((_el, index) => {
          const newBinsFilterRow: Bin[] = bins.filter((el) => el.row === index + 1);
          return (
            <React.Fragment key={index}>
              {orderBy(newBinsFilterRow, 'col').map((bin, binIndex) => {
                return (
                  <BinDetailCard
                    bin={bin}
                    key={binIndex}
                    onClickModal={onClickModal}
                    inSession={inSession}
                  />
                );
              })}
              {newBinsFilterRow.length < 5 && <BinNoItem></BinNoItem>}
            </React.Fragment>
          );
        })}
        {isLoading.value && <LoadingCard />}
      </GridView>
    </Main>
  );
};
export default ViewListBin;
