import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useBoolean, useString } from 'app/hooks';
import { compact, includes, isUndefined } from 'lodash';

import { ReactComponent as IconArrow } from 'app/assets/icons/icon-select.svg';

import { TippyDefault } from '.';
import Typography from '../Typography';
import { MenuAction, MenuItem } from './TippyMenu';

export interface SelectTippyProps {
  defaultOpen?: boolean;
  optionsSelect: any;
  fieldSelect?: string;
  fieldActive?: string;
  activeSelect: any;
  handleChange?: (newValue: any) => void;
  placementCustom?:
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  offsetCustom?: [number, number];
  styleSelectCard?: React.CSSProperties;
  isDisable?: boolean;
  type?: 'multiple' | 'default';
  inputPlaceholder?: string;
  title?: string;
  showOptionNone?: boolean;
}
const SelectBody = styled.div<{ disabled?: boolean }>`
  display: flex;
  height: 43px;
  background: var(--neutral-2);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  cursor: pointer;
  ${({ disabled }) =>
    `${
      disabled
        ? `background:var(--neutral-2-5);svg path[stroke] {
    stroke: var(--neutral-6);
  };`
        : `background:var(--neutral-2);`
    }`};
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-10);
  :focus-visible {
    outline: none !important;
  }
  ::placeholder {
    color: var(--neutral-6);
  }
`;

const SelectTippy = (props: SelectTippyProps) => {
  const {
    defaultOpen,
    optionsSelect,
    fieldSelect = 'label',
    fieldActive = 'value',
    activeSelect,
    placementCustom = 'bottom-start',
    offsetCustom = [0, 5],
    styleSelectCard,
    handleChange,
    isDisable,
    type = 'default',
    title,
    inputPlaceholder,
    showOptionNone = false,
  } = props;
  const showSelect = useBoolean(false);
  const input = useString('');
  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    if (isUndefined(defaultOpen)) {
      return;
    }
    showSelect.setValue(defaultOpen);
  }, [defaultOpen]);

  const openSelect = () => {
    if (isDisable) {
      return;
    }
    showSelect.setValue(true);
  };
  const onChange = (newOption: any) => () => {
    if (type === 'default') {
      showSelect.setValue(!showSelect.value);
    }
    if (isUndefined(handleChange)) {
      return;
    }
    handleChange(newOption);
  };
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    input.setValue(event.target.value);
    showSelect.setValue(true);
    const regex = new RegExp(event.target.value ?? '', 'gi');
    const dataSearch = optionsSelect.filter((e: any) => e[fieldSelect].search(regex)! > -1);
    setOptions(dataSearch);
  };
  const newOptionsSelect = compact([
    showOptionNone && { title: 'None', value: 0 },
    ...optionsSelect,
  ]);

  const valueShow = newOptionsSelect.find((el: any) => el[fieldActive] === activeSelect);

  const newOptions = type === 'multiple' && input.value ? options : newOptionsSelect;

  const preSelectedValue = valueShow ? valueShow[fieldSelect] : '';

  const optionColor = isDisable ? 'neutral-6' : 'neutral-10';
  return (
    <div>
      <TippyDefault
        containerClass="action-menu"
        placement={placementCustom}
        interactive
        arrow={false}
        animation="scale"
        visible={showSelect.value}
        onClickOutside={() => {
          showSelect.setValue(false);
          input.setValue('');
        }}
        offset={offsetCustom}
        content={
          <MenuAction
            style={{
              maxHeight: 200,
              overflow: 'auto',
              ...styleSelectCard,
            }}
          >
            {newOptions.map((option: any, index: number) => {
              const isActive =
                type === 'multiple'
                  ? includes(Object.values(activeSelect), option[fieldActive])
                  : option[fieldActive] === activeSelect;
              return (
                <MenuItem
                  key={index}
                  onClick={onChange(option)}
                  style={{
                    background: isActive ? '#f1faff' : '',
                    color: isActive ? 'var(--primary)' : '',
                    marginBottom: index === newOptions.length - 1 ? 0 : 5,
                  }}
                >
                  {option[fieldSelect]}
                </MenuItem>
              );
            })}
          </MenuAction>
        }
        allowHTML
      >
        <SelectBody
          className="select-body"
          style={{
            cursor: isDisable ? 'default' : 'pointer',
          }}
          disabled={isDisable}
          onClick={openSelect}
        >
          {type === 'default' ? (
            <React.Fragment>
              <div
                className="d-flex"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {title && (
                  <Typography variant="body-5" color="neutral-7" style={{ marginRight: 8 }}>
                    {title}
                  </Typography>
                )}
                {!valueShow && inputPlaceholder && (
                  <Typography variant="body-5" color="neutral-7" style={{ marginRight: 8 }}>
                    {inputPlaceholder}
                  </Typography>
                )}
                <Typography
                  variant="body-5"
                  color={optionColor}
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {preSelectedValue}
                </Typography>
              </div>

              <IconArrow />
            </React.Fragment>
          ) : (
            <Input value={input.value} onChange={onChangeInput} placeholder={inputPlaceholder} />
          )}
        </SelectBody>
      </TippyDefault>
    </div>
  );
};
export default SelectTippy;
