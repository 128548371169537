import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { ReactComponent as TickIcon } from 'app/assets/icons/icon-tick.svg';

import { ForecastCurve, MlQuantity } from '../core/type';

const Qty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  quantity: MlQuantity;
  approved: ForecastCurve | null;
}

const QuantityByML: React.FC<Props> = ({ quantity, approved }) => {
  const renderQty = (qty: number | undefined, curve: ForecastCurve) => {
    const checked = curve === approved;
    if (qty !== undefined) {
      return (
        <Qty>
          <Typography
            variant="body-5"
            color={checked ? 'primary' : 'neutral-10'}
            style={{ marginRight: 4 }}
          >
            {qty}
          </Typography>
          {checked && <TickIcon style={{ color: 'var(--primary)' }} width={20} height={20} />}
        </Qty>
      );
    }
    return null;
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {renderQty(quantity.highQty, ForecastCurve.upper)}
      <Qty>
        <Typography
          variant="body-5"
          color={approved === ForecastCurve.normal ? 'primary' : 'neutral-10'}
          style={{ marginRight: 4 }}
        >
          {quantity.nominalQty}
        </Typography>
        {approved === ForecastCurve.normal && (
          <TickIcon style={{ color: 'var(--primary)' }} width={20} height={20} />
        )}
      </Qty>

      {renderQty(quantity.lowQty, ForecastCurve.lower)}
    </div>
  );
};
export default QuantityByML;
