export enum NameSetting {
  completedScreen = 'completedScreen',
  afterLoggingInMMD = 'afterLoggingInMMD',
  endCartScreen = 'endCartScreen',
  afterLoggingIn = 'afterLoggingIn',
  mmdUserAt = 'mmdSession',
  nurseUserAt = 'nurseSession',
  holiday = 'Stock run - Complete date',
  workingTime = 'working-time',
  workShift = 'shift_work',
  stockRun = 'stock_run',
  stockLevel = 'stock_level',
  zeroWeight = 'zero-weight',
}

export enum TypeHoliday {
  on = 'on',
  off = 'off',
}
