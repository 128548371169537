import Typography, { EmptyTableCell } from 'app/components/Typography';

interface Props {
  title: string;
  value?: string | number;
  styleRoot?: React.CSSProperties;
  fontSizeValue?: number;
  icon?: any;
}
const InfoItemCard: React.FC<Props> = ({ title, value, styleRoot, icon, fontSizeValue = 14 }) => {
  const renderValue = () =>
    typeof value === 'string'
      ? value.split('\n').map((line) => (
          <>
            {line}
            <br />
          </>
        ))
      : value;

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginBottom: 32,
        ...styleRoot,
      }}
    >
      <Typography variant="title-5">{title}</Typography>
      <Typography
        fontSize={fontSizeValue}
        lineHeight={26}
        fontWeight={400}
        color="neutral-10"
      ></Typography>
      {value == null || value === '' ? (
        EmptyTableCell
      ) : (
        <div className="d-flex flex-row align-items-center">
          <Typography
            fontSize={fontSizeValue}
            lineHeight={26}
            fontWeight={400}
            color="neutral-10"
            style={{ width: 'fit-content', marginRight: icon ? '10px' : '0' }}
          >
            {renderValue()}
          </Typography>
          {icon}
        </div>
      )}
    </div>
  );
};
export default InfoItemCard;
