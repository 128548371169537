import { useEffect, useState } from 'react';
import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES, SORT_BY_NAME_TABLE_QUERY } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useSyncQueryToState } from 'app/modules/query-state/hooks';
import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { useNavigateWithSendPath } from 'app/helpers/utils';
import { SocketClient } from 'app/hooks/socket';

import { HIDDEN_BUTTON_ADD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { racksColumns } from './core/columns';
import { deleteRack, getRacks2 } from './core/requests';
import { Rack } from './core/type';
import FormRackModal from './modal/FormRack.modal';
import { useRackStore } from './store';

const RackManagementPage = () => {
  useSyncQueryToState(window.location.pathname, SORT_BY_NAME_TABLE_QUERY);

  const navigate = useNavigateWithSendPath();
  const [wards, setWards] = useState<Ward[]>([]);

  useEffect(() => {
    getData();
    SocketClient.getAllTablets();

    SocketClient.onTabletInAllWards((tablets) => {
      useRackStore.getState().setState({ tablets });
    });

    return () => {
      SocketClient.offTablets();
      useRackStore.getState().clearState();
    };
  }, []);

  const getData = async () => {
    const dataResWards = await getWards(
      stringifyRequestQuery({
        page: 1,
        limit: 100,
      }),
    );
    const newResWards = dataResWards.data?.items || [];
    newResWards.unshift({
      createdAt: '',
      id: 0,
      name: 'None',
      updatedAt: '',
    });
    setWards(newResWards);
  };

  const renderModalForm = (originalData: Rack, _typeModal: TYPE_MODAL, _isView?: boolean) => {
    return <FormRackModal wards={wards} itemRack={originalData} />;
  };
  const onClickBodyTable = (row: Row) => () => {
    const newRack = row.original as Rack;
    if (newRack.id) {
      navigate(PATH.RACK_DETAIL.replace(':rackId', String(newRack.id)));
    }
  };
  return (
    <RootContentPage title="Racks">
      <TableComponent
        getList={getRacks2}
        nameQuery={QUERIES.RACK_LIST}
        originColumns={racksColumns()}
        titleModal="rack"
        placeholderSearch="Search with rack name and ward"
        nameBtnAdd={!HIDDEN_BUTTON_ADD ? 'Add rack' : ''}
        isBorder={false}
        deleteById={deleteRack}
        ModalForm={renderModalForm}
        onClickBodyTable={onClickBodyTable}
        syncParamUrl
        spacing="xxl"
      />
    </RootContentPage>
  );
};

export default RackManagementPage;
