import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormInput } from 'app/components/Input/FormInput';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean, useString } from 'app/hooks';

import { resetPassword } from '../core/requests';
import { useFormik } from 'formik';
import queryString from 'query-string';
import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

export function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useString();
  const passwordShown = useBoolean(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    token.setValue(String(parsed?.token));
  }, [location]);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!token) {
          return;
        }
        await resetPassword(values.password, token.value);
        toast.success(`Create new password successfully!`);
        setSubmitting(true);
        navigate('/login');
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center">
        <Typography
          fontSize={26}
          fontWeight={700}
          lineHeight={41}
          color="neutral-10"
          style={{
            marginBottom: 32,
          }}
        >
          Create new password
        </Typography>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-10 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <FormInput
        label="New password"
        placeholder="Enter your new password"
        formik={formik}
        field="password"
        rootClass="w-100"
        typeInput={passwordShown.value ? 'text' : 'password'}
        passwordShown={passwordShown.value}
        setPasswordShown={() => passwordShown.setValue(!passwordShown.value)}
      />
      <div
        className="text-center"
        style={{
          paddingTop: 40,
        }}
      >
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary"
          style={{
            width: 200,
          }}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && <Typography variant="body-6">Create and sign in</Typography>}
          {formik.isSubmitting && (
            <Typography variant="body-6">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </Typography>
          )}
        </button>
      </div>
    </form>
  );
}
