import { FC } from 'react';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import { FormSelect } from 'app/components/Select/FormSelect';

import { blockInvalidCharacters } from 'app/helpers';

import { COUNTRIES } from '../core/constants';

const GridView = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;
const Main = styled.div`
  padding: 28px;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const LabelAddress = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: var(--gray-700);
  margin-bottom: 32px;
`;

export const CompanyInformationItem: FC<{
  formik: any;
  listIsError: any;
  lisMessageError: any;
  index: number;
  isEdit: boolean;
  onChangeCountry: (newValue: string, index: number) => void;
}> = ({ formik, listIsError, index, lisMessageError, isEdit, onChangeCountry }) => {
  return (
    <Main>
      <LabelAddress>{`Address ${index + 1}`}</LabelAddress>
      <FormInput
        label="Address"
        placeholder="Address"
        field={`listAddress.${index}.address`}
        formik={formik}
        messageError={lisMessageError && lisMessageError[index].address}
        touched={listIsError && listIsError[index].address}
        isRequired
        isDisable={!isEdit}
        marginBottom={32}
        valueShowIconInput={formik.values.listAddress[index].address}
      />
      <FormInput
        label="Phone number"
        placeholder="Phone number"
        field={`listAddress.${index}.phone`}
        formik={formik}
        touched={listIsError && listIsError[index].phone}
        messageError={lisMessageError && lisMessageError[index].phone}
        isRequired
        isDisable={!isEdit}
        marginBottom={32}
        typeInput="number"
        onKeyDown={(e) => blockInvalidCharacters(e, 'phoneNumber')}
        valueShowIconInput={formik.values.listAddress[index].phone}
      />
      <GridView>
        <FormInput
          label="Town/City"
          placeholder="Town/City"
          field={`listAddress.${index}.city`}
          formik={formik}
          isRequired
          touched={listIsError && listIsError[index].city}
          messageError={lisMessageError && lisMessageError[index].city}
          isDisable={!isEdit}
          marginBottom={0}
          valueShowIconInput={formik.values.listAddress[index].city}
        />
        <FormInput
          label="Zip Code"
          placeholder="Zip Code"
          field={`listAddress.${index}.zipCode`}
          formik={formik}
          isRequired
          touched={listIsError && listIsError[index].zipCode}
          messageError={lisMessageError && lisMessageError[index].zipCode}
          valueShowIconInput={formik.values.listAddress[index].zipCode}
          isDisable={!isEdit}
          marginBottom={0}
          typeInput="number"
          onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
        />
        <FormSelect
          label="Country"
          isRequired
          optionsSelect={COUNTRIES}
          activeSelect={
            formik.values.listAddress && formik.values.listAddress[index].country
              ? formik.values.listAddress[index].country
              : COUNTRIES[0].value
          }
          handleChange={(select) => onChangeCountry(select.value, index)}
          fieldActive="value"
          fieldSelect="title"
          styleSelectCard={{
            width: 277,
          }}
          styleRoot={{
            marginBottom: 0,
          }}
          isDisable={!isEdit}
        />
      </GridView>
    </Main>
  );
};
