import { FC, memo } from 'react';
import styled from 'styled-components';

import { useString } from 'app/hooks';
import { uniqueId } from 'lodash';

import { ReactComponent as IconTrash } from 'app/assets/icons/icon-trash.svg';
import { ReactComponent as ImageDefaultEdit } from 'app/assets/icons/image-default-edit.svg';
import { ReactComponent as ImageDefault } from 'app/assets/icons/image-default.svg';

const ShowImage = styled.div`
  min-width: 160px;
  min-height: 160px;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  position: relative;
`;
const UploadImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
const UploadImageLabel = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const ButtonIconTrash = styled.div`
  background-color: var(--danger);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`;
const imageStyle = {
  minWidth: 160,
  width: 160,
  minHeight: 160,
  height: 160,
};
interface Props {
  file: File | null;
  image: string;
  setFile: (newFile: File | null) => void;
  isEdit: boolean;
  isRemoveLogo: boolean;
  setIsRemoveLogo: (value: boolean) => void;
}
const AddLogoCompanyCard: FC<Props> = ({
  file,
  image,
  isEdit,
  isRemoveLogo,
  setFile,
  setIsRemoveLogo,
}) => {
  const keyInputFile = useString();

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentFiles: any = [];
    if (event.target.files) {
      for (const [, tfile] of Object.entries(event.target.files)) {
        currentFiles.push(tfile);
      }
    }
    if (currentFiles.length) {
      setFile(currentFiles[0]);
    }
    keyInputFile.setValue(uniqueId('item_'));
  };
  const renderImage = () => {
    if (file) {
      return `url(${URL.createObjectURL(file)})`;
    }
    if (isRemoveLogo) {
      return 'inherit';
    }
    if (image) {
      return `url(${image})`;
    }

    return 'inherit';
  };

  const renderImageAdd = () => {
    if (file) {
      return null;
    }
    if (!image && !isEdit) {
      return <ImageDefault style={imageStyle} />;
    }
    if (isRemoveLogo || !image) {
      return <ImageDefaultEdit style={imageStyle} />;
    }
    return null;
  };

  const showIconRemove = () => {
    if (file) {
      return true;
    }
    if (isEdit && image && !isRemoveLogo) {
      return true;
    }
    return false;
  };

  return (
    <ShowImage
      style={{
        backgroundImage: renderImage(),
      }}
    >
      <UploadImage>
        <input
          id="container-update-image"
          onChange={fileSelectedHandler}
          accept={'image/*'}
          type="file"
          style={{ display: 'none' }}
          key={keyInputFile.value || ''}
        />
        <UploadImageLabel
          htmlFor={!isEdit ? '' : 'container-update-image'}
          style={{
            cursor: !isEdit ? 'inherit' : 'pointer',
          }}
        >
          {renderImageAdd()}
        </UploadImageLabel>
      </UploadImage>

      {showIconRemove() ? (
        <ButtonIconTrash
          onClick={() => {
            setFile(null);
            if (image) {
              setIsRemoveLogo(true);
            }
          }}
        >
          <IconTrash />
        </ButtonIconTrash>
      ) : null}
    </ShowImage>
  );
};
export default memo(AddLogoCompanyCard);
