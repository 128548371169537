import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import { NavigationDetail } from 'app/components/Navigation/core/types';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getRacks } from 'app/modules/inventory/racks/core/requests';

import { coverPath, replacePath } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { orderBy } from 'lodash';

import { DEFAULT_WARD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { NameSetting } from '../configuration/core/enum';
import { getSetting } from '../configuration/core/request';
import { SettingType } from '../configuration/core/type';
import RackZeroWeightEditCard from './components/RackZeroWeightEditCard';
import { RackZeroWeightSetting } from './core/types';

export const EMPTY_SETTING = { rackId: 0, settings: [] };

const ZeroWeightSettingPage = () => {
  const [racks, setRacks] = useState<
    {
      rackName: string;
      rackId: ID;
    }[]
  >([
    { rackName: '', rackId: -1 },
    { rackName: '', rackId: -1 },
  ]);

  const getSettingRackPath = (rackId: ID) => coverPath(PATH.ZERO_WEIGHT_SETTING_RACK, { rackId });

  const [setting, setSetting] = useState<RackZeroWeightSetting[]>([]);

  const navigationLocation: NavigationDetail[] = racks.map((rack) => ({
    title: rack.rackName,
    path: getSettingRackPath(rack.rackId),
  }));

  const hasPath = !racks.filter((rack) => rack.rackId === -1).length;

  const isLoading = useBoolean(true);

  const fetchRacks = async () => {
    const dataResRacks = await getRacks(
      `${stringifyRequestQuery({
        page: 1,
        limit: 100,
      })}&wardId=${DEFAULT_WARD}`,
    );

    if (dataResRacks.data?.items.length) {
      setRacks(
        orderBy(dataResRacks.data?.items, 'name').map((el) => {
          return { rackName: el.name, rackId: el.id };
        }),
      );
    }

    isLoading.setValue(false);
  };

  const fetchSetting = async () => {
    const dataResSetting = (await getSetting(SettingType.binSensor, {
      name: NameSetting.zeroWeight,
      location_id: DEFAULT_WARD as string,
    })) as any;

    if (dataResSetting.length) {
      setSetting(orderBy(dataResSetting as RackZeroWeightSetting[], 'rackId'));
    }

    isLoading.setValue(false);
  };

  useEffect(() => {
    fetchRacks();
    fetchSetting();
  }, []);

  if (isLoading.value || !hasPath) {
    return <LoadingCard />;
  }

  return (
    <Routes>
      <Route
        element={
          <RootContentPage title="W0 settings">
            {hasPath && <NavigationDefault navigation={navigationLocation} />}
            <Outlet />
          </RootContentPage>
        }
      >
        {racks.map((rack) => {
          const index = setting.findIndex((el) => el.rackId === rack.rackId);
          if (index === -1) return null;
          return (
            <Route
              key={rack.rackId}
              path={replacePath(getSettingRackPath(rack.rackId), PATH.ZERO_WEIGHT_SETTING, '')}
              element={
                <RackZeroWeightEditCard
                  index={index}
                  originalData={setting}
                  refetch={fetchSetting}
                />
              }
            />
          );
        })}

        <Route index element={<Navigate to={getSettingRackPath(racks[0].rackId)} />} />
      </Route>
    </Routes>
  );
};

export default ZeroWeightSettingPage;
