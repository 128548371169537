import { Permission, PermissionResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const PERMISSION_URL = `${API_URL}/api/policy`;

const getPermissions = (query: string): Promise<PermissionResponse> => {
  return axios.get(`${PERMISSION_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: Permission;
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

export { getPermissions };
