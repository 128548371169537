import { useMemo, useState } from 'react';
import { Row } from 'react-table';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { isUndefined } from 'lodash';

import { getItems } from '../../items/core/requests';
import { Item } from '../../items/core/type';
import { itemColumns } from '../core/columns';
import { updateBin } from '../core/requests';
import { Bin } from '../core/type';

const MIN_NONCHARGEABLE_INDEX = 5;
const MAX_NONCHARGEABLE_INDEX = 29;

export const getStockType = (index: number) => {
  return index <= MAX_NONCHARGEABLE_INDEX && index >= MIN_NONCHARGEABLE_INDEX ? 2 : 1;
};

interface Props {
  onClickCloseModal?: (updateBin?: Bin) => void;
  onUpdateRack?: () => void;
  selectBin: Bin | null;
}

const AssignItemModal: React.FC<Props> = ({ selectBin, onUpdateRack, onClickCloseModal }) => {
  const { refetch } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const [selectItem, setSelectItem] = useState<Item | null>(null);
  const isLoading = useBoolean();
  const isLoadingSubmit = useBoolean();

  const onAssignItem = async () => {
    if (!selectBin?.id || !selectItem?.id) {
      return;
    }
    isLoadingSubmit.setValue(true);
    try {
      await updateBin(selectBin?.id, {
        itemId: Number(selectItem?.id),
      });
      toast.success(`Assign item successfully!`);
      if (!isUndefined(onClickCloseModal)) {
        onClickCloseModal({
          ...selectBin,
          item: selectItem,
        });
        return;
      }
      if (onUpdateRack) {
        onUpdateRack();
      }
      cancel(true);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoadingSubmit.setValue(false);
    }
  };
  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onClickCloseModal)) {
      onClickCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const onClickBodyTable = (row: Row) => () => {
    const newItem = row.original as Item;
    setSelectItem(newItem);
  };

  return (
    <ModalDefault
      title="Assign item"
      onClickCloseModal={() => cancel()}
      childrenAction={
        <ActionButton
          onCancel={() => cancel()}
          disableButton={isLoadingSubmit.value || !selectItem}
          isSubmitting={isLoadingSubmit.value}
          labelButtonSubmit="Assign"
          onSubmit={onAssignItem}
        />
      }
      modalContentStyle={{ height: 'calc(100vh - 112px)' }}
      styleBodyModalDialog={{
        padding: 0,
        flexGrow: 1,
        height: 0,
        maxHeight: 'none',
        overflow: 'hidden',
      }}
    >
      <TableComponent
        originColumns={useMemo(() => itemColumns(), [])}
        moreQuery={`&status=1`}
        nameQuery={''}
        getList={getItems}
        isBorder={false}
        onClickBodyTable={onClickBodyTable}
        placeholderSearch="Search with item name or SKU"
        bodyStyle={{ flexGrow: 1, height: 0, overflow: 'auto' }}
        isLoadMore={true}
        tableContainerStyle={{ flexGrow: 1, height: 0 }}
        tableStyle={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
        spacing={'sm'}
        cardStyle={{ height: '100%' }}
        choseRowId={Number(selectItem?.id)}
        tableContentStyle={{ height: '100%' }}
      />

      {isLoading.value || isLoadingSubmit.value ? <LoadingCard /> : null}
    </ModalDefault>
  );
};
export default AssignItemModal;
