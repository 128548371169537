import { CSSProperties, FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import { merge, sum } from 'lodash';

import { getBarChartOptions } from '../core/helpers';
import { EmptyChart } from './EmptyChart';
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface BarChartProps {
  options?: ChartOptions<'bar'>;
  data: ChartData<'bar'>;
  containerStyle?: CSSProperties;
  type?: string;
}

export const BarChart: FC<BarChartProps> = ({ data, options, containerStyle, type }) => {
  const isEmpty = useMemo(
    () => data.datasets.every((el) => el.data.every((value) => Number(value) === 0)),
    [data.datasets],
  );
  const singleBar = data.datasets.length === 1;

  const barChartTooltip = singleBar
    ? {
        plugins: {
          tooltip: {
            boxWidth: 0,
            boxHeight: 0,
            boxPadding: 0,
          },
        },
      }
    : {};

  return (
    <div style={{ height: '460px', position: 'relative', ...containerStyle }}>
      {isEmpty ? (
        <EmptyChart type={type} />
      ) : (
        <Bar
          options={getBarChartOptions(
            merge(
              barChartTooltip,
              {
                elements: {
                  bar: {
                    borderRadius: 4,
                    hoverBorderRadius: 4,
                  },
                },
              } as ChartOptions<'bar'>,
              options,
            ),
          )}
          data={data}
        />
      )}
    </div>
  );
};
