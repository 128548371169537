import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { FormSelect } from 'app/components/Select/FormSelect';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useChangeParams } from 'app/modules/query-state/hooks';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean, useNumber } from 'app/hooks';

import { DeviceType } from '../core/enum';
import { createDevice, updateDevice } from '../core/requests';
import { Device, DeviceRequest, initialDeviceRequest } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  device: Device;
  typeSub: DeviceType;
  titleNotification: string;
  wards: Ward[];
  originIsView?: boolean;
  onShowCodeWhenAddDevice?: (newDevice: Device) => void;
};

const FormDeviceTableCard: FC<Props> = ({
  device,
  typeSub,
  titleNotification,
  wards,
  originIsView = false,
  onShowCodeWhenAddDevice,
}) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const { onChangeParams } = useChangeParams();

  const [formRequest] = useState<{
    name: string;
  }>({
    name: device ? device.name : initialDeviceRequest.name,
  });
  const wardId = useNumber(0);
  const isView = useBoolean(originIsView);

  useEffect(() => {
    if (!wards.length) {
      return;
    }
    wardId.setValue(
      device && device.inventoryLocation && device.inventoryLocation.location
        ? Number(device.inventoryLocation.location.id)
        : wardId.value,
    );
  }, [JSON.stringify(wards), device]);

  useEffect(() => {
    isView.setValue(originIsView);
  }, [originIsView]);

  const getSchema = () => {
    return {
      name: Yup.string().trim().required(`${titleNotification} name is required`),
    };
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape(getSchema()),
    onSubmit: async (values, { setSubmitting }) => {
      if (isView.value) {
        isView.setValue(false);
        if (itemForUpdate) {
          setItemForUpdate({
            ...itemForUpdate,
            isView: false,
          });
        }
        return;
      }
      setSubmitting(true);
      const request: DeviceRequest = {
        ...values,
        wardId: wardId.value,
      };
      try {
        if (device) {
          await updateDevice(device.id, request);
          toast.success(`Update ${titleNotification} successfully!`);
          cancel(true);
          return;
        }

        const dataCreateDevice = await createDevice({
          ...request,
          typeSub,
        });
        toast.success(`Add ${titleNotification} successfully!`);
        if (onShowCodeWhenAddDevice && dataCreateDevice) {
          onShowCodeWhenAddDevice(dataCreateDevice);
        }
        if (Number(state.page) !== 1) {
          cancel();
          onChangeParams({ page: 1 });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const renderLabelButtonSubmit = () => {
    if (device) {
      return isView.value ? 'Edit' : 'Save';
    }
    return 'Add';
  };

  return (
    <>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <FormInput
            label={`${titleNotification} name`}
            placeholder={`${titleNotification} name`}
            formik={formik}
            field="name"
            isRequired
            isDisable={isView.value}
          />
          <FormSelect
            label="Belong to ward"
            handleChange={(select) => wardId.setValue(select.value)}
            optionsSelect={wards.map((el) => {
              return {
                title: el.name,
                value: el.id ?? ' ',
              };
            })}
            activeSelect={wardId.value}
            isRequired
            fieldActive="value"
            fieldSelect="title"
            styleSelectCard={{
              width: 580,
            }}
            isDisable={isView.value}
            placementCustom="bottom-start"
            inputPlaceholder="Select ward"
          />
        </div>
        <ActionButton
          onCancel={() => cancel()}
          disableButton={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            !wardId.value ||
            !formik.values.name
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={renderLabelButtonSubmit()}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormDeviceTableCard };
