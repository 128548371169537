import { addMinutes, format, subMinutes } from 'date-fns';

import { TimeFrame } from './type';

export const getTimeFromDate = (timeString: string | Date): Date => {
  if (typeof timeString === 'string') {
    const [hour, minute] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hour, 10));
    date.setMinutes(parseInt(minute || '0', 10));
    return date;
  }
  return timeString;
};

export const stringifyTimeFrame = (data: TimeFrame[]) =>
  JSON.stringify(
    data.map((frame) => ({
      from: format(frame.from, 'HH:mm'),
      to: format(frame.to, 'HH:mm'),
    })),
  );

const MIN_TIME_STEP = 30; // (0-60) mins
export const getTimeRange = (startPoint: Date, endPoint: Date) => {
  const startHrs = startPoint.getHours();
  const startMins = startPoint.getMinutes();
  const startTime = startPoint.getTime();

  const endHrs = endPoint.getHours();
  const endMins = endPoint.getMinutes();
  const endTime = endPoint.getTime();

  const times: Date[] = [];
  const haveEndPivot = Math.abs(endTime - startTime) >= MIN_TIME_STEP * 60000;
  const steps =
    (endHrs - startHrs + (endHrs < startHrs ? 24 : 0)) * (60 / MIN_TIME_STEP) +
    Math.round((endMins - startMins) / MIN_TIME_STEP) +
    (haveEndPivot ? 1 : 0);
  const arr = new Array(steps).fill(null);
  arr.forEach((_v, index) => {
    times.push(new Date(startTime + MIN_TIME_STEP * 60000 * index));
  });
  return times;
};

export const getTimeShifts = (timeFrame: TimeFrame[], index: number) => {
  if (timeFrame.length === 0) {
    return {
      from: [],
      to: [],
    };
  }

  const prevIndex = index - 1 < 0 ? timeFrame.length - 1 : index - 1;
  const nextIndex = index + 1 > timeFrame.length - 1 ? 0 : index + 1;

  if (timeFrame.length === 1) {
    return {
      from: getTimeRange(
        addMinutes(timeFrame[index].to, MIN_TIME_STEP),
        subMinutes(timeFrame[index].to, MIN_TIME_STEP),
      ),
      to: getTimeRange(
        addMinutes(timeFrame[index].from, MIN_TIME_STEP),
        subMinutes(timeFrame[index].from, MIN_TIME_STEP),
      ),
    };
  }
  return {
    from: getTimeRange(timeFrame[prevIndex].to, subMinutes(timeFrame[index].to, MIN_TIME_STEP)),
    to: getTimeRange(addMinutes(timeFrame[index].from, MIN_TIME_STEP), timeFrame[nextIndex].from),
  };
};
