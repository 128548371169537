import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { createSetting, updateSetting } from '../core/request';
import { Setting, SettingRequest, SettingType } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  setting: Setting;
  originIsView?: boolean;
};

const FormQuantityUnitTableCard: FC<Props> = ({ setting, originIsView = false }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { updateState, state } = useQueryRequest();

  const [formRequest] = useState<{
    name: string;
  }>({
    name: setting ? setting.name : '',
  });
  const isView = useBoolean(originIsView);

  useEffect(() => {
    isView.setValue(originIsView);
  }, [originIsView]);

  const getSchema = () => {
    return {
      name: Yup.string().trim().required(`Unit name is required`),
    };
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape(getSchema()),
    onSubmit: async (values, { setSubmitting }) => {
      if (isView.value) {
        isView.setValue(false);
        if (itemForUpdate) {
          setItemForUpdate({
            ...itemForUpdate,
            isView: false,
          });
        }
        return;
      }
      setSubmitting(true);
      const request: SettingRequest = {
        ...values,
        type: SettingType.quantityUnit,
        value: values.name,
      };
      try {
        if (setting) {
          await updateSetting(setting.id, request);
          toast.success(`Update Unit successfully!`);
          cancel(true);
          return;
        }

        await createSetting({
          ...request,
        });
        toast.success(`Add Unit successfully!`);
        if (state.page !== 1) {
          cancel();
          updateState({
            page: 1,
          });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const renderLabelButtonSubmit = () => {
    if (setting) {
      return isView.value ? 'Edit' : 'Save';
    }
    return 'Add';
  };

  return (
    <>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <FormInput
            label="Unit name"
            placeholder="Unit name"
            formik={formik}
            field="name"
            isRequired
            isDisable={isView.value}
          />
        </div>
        <ActionButton
          onCancel={() => cancel()}
          disableButton={
            formik.isSubmitting || !formik.isValid || !formik.values.name || !formik.touched
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={renderLabelButtonSubmit()}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormQuantityUnitTableCard };
