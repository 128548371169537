import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { QueryState } from 'app/components/Table/core/types';

import { stringifyObject } from 'app/helpers/utils';
import { camelCase, isEmpty, omit, omitBy } from 'lodash';

import { useQueryParamStore } from './store';
import { parse, stringify } from 'qs';

export const useSyncQueryToState = <T = any>(
  locationPath: string,
  defaultParams?: Partial<QueryState> & T,
  options?: {
    skipFields?: string[];
    requiredFields?: string[];
  },
) => {
  const clearState = useQueryParamStore((s) => s.clearState);

  const pathname = camelCase(locationPath);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const paramState = searchParams.toString();

  useEffect(() => {
    if (options?.skipFields) {
      useQueryParamStore.getState().setState({ skipFields: options.skipFields });
    }
    return clearState;
  }, []);

  useEffect(() => {
    const synced = useQueryParamStore.getState().state.synced;
    const intial = !checkParamSynced(synced) && defaultParams && !paramState;
    const newParams: any = intial ? defaultParams : parse(paramState);

    if (intial) {
      setSearchParams(stringify(defaultParams), { replace: true, state: location.state });
    }

    if (isEmpty(newParams)) {
      return;
    }

    useQueryParamStore.getState().setState({
      curParams: stringifyObject(newParams),
      synced: {
        ...synced,
        [pathname]: true,
      },
    });
  }, [paramState, location]);

  return null;
};

export const useGetQueryParams = <T = any>() => {
  const curParams = useQueryParamStore((s) => s.state.curParams);
  const synced = useQueryParamStore((s) => s.state.synced);
  const skipFields = useQueryParamStore((s) => s.state.skipFields);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return { curParams: curParams as QueryState & T, synced, skipFields };
};

export const useChangeParams = <T = any>() => {
  const location = useLocation();
  const [_, setSearchParams] = useSearchParams();

  const onChangeParams = useCallback(
    (
      newParams: Partial<QueryState> & T,
      options?: {
        removeParams?: string | string[];
        navigateOptions?: {
          replace?: boolean;
          state?: any;
        };
      },
    ) => {
      const combinedParams = { ...useQueryParamStore.getState().state.curParams, ...newParams };
      setSearchParams(
        stringify(
          omitBy(
            options?.removeParams ? omit(combinedParams, options.removeParams) : combinedParams,
            (v) => v === '' || v === undefined || v === null,
          ),
        ),
        {
          ...options?.navigateOptions,
          state: { ...options?.navigateOptions?.state, ...(location.state as any) },
        },
      );
    },
    [],
  );

  const removeParams = (param: string | string[]) =>
    setSearchParams(omit(useQueryParamStore.getState().state.curParams, param));

  return {
    onChangeParams,
    removeParams,
  };
};

export const checkParamSynced = (synced: Record<string, boolean>) => {
  return synced[camelCase(window.location.pathname)] ? true : false;
};
