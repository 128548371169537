import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';

import { ReactComponent as EditICon } from 'app/assets/icons/edit-icon.svg';

const NameButtonEdit = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: var(--white);
  margin-left: 8px;
`;
interface Props {
  isEdit: {
    value: boolean;
    setValue: (newValue: boolean) => void;
  };
  onCancel: () => void;
  isSubmitting: boolean;
  disableButton: boolean;
}

const Action: React.FC<Props> = ({ isEdit, disableButton, isSubmitting, onCancel }) => {
  if (!isEdit.value) {
    return (
      <div className="d-flex justify-content-end" style={{ marginBottom: 32 }}>
        <button
          className="btn btn-primary d-flex justify-content-center align-items-center"
          style={{
            width: 140,
            height: 43,
            borderRadius: 8,
          }}
          onClick={() => isEdit.setValue(true)}
        >
          <EditICon />
          <NameButtonEdit>Edit</NameButtonEdit>
        </button>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end" style={{ marginBottom: 32 }}>
      <ActionButton
        onCancel={onCancel}
        disableButton={disableButton}
        isSubmitting={isSubmitting}
        labelButtonSubmit="Save"
        customClass="pt-0 mt-0"
        labelButtonReset="Cancel"
      />
    </div>
  );
};
export default Action;
