export const SUMMARY_CONFIG_1 = [
  {
    title: 'Total pick-up sessions',
    key: 'pickUp.totalSessions',
  },
  {
    title: 'Total number of pick-up items',
    key: 'pickUp.totalItems',
  },
  {
    title: 'Total qty of pick-up items',
    key: 'pickUp.totalQty',
  },
];

export const SUMMARY_CONFIG_2 = [
  {
    title: 'Total stock-runs completion',
    key: 'stockUp.totalCompletions',
  },
  {
    title: 'Total number of stock-up items',
    key: 'stockUp.totalItems',
  },
  {
    title: 'Total qty of stock-up items',
    key: 'stockUp.totalQty',
  },
  {
    title: 'Average stock-run IDs completion',
    key: 'stockUp.averageCompletion',
  },
];
