import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { useAuth } from 'app/modules/auth';
import { LOGIN_TYPE } from 'app/modules/auth/core/enum';
import { DashboardPage } from 'app/modules/dashboard/Dashboard';
import InventoriesManagementPage from 'app/modules/inventory/InventoriesManagementPage';
import NotificationPage from 'app/modules/notifications/NotificationPage';
import ProjectionPage from 'app/modules/projection';
import ReportDetailPage from 'app/modules/reporting/ReportDetailPage';
import ReportPage from 'app/modules/reporting/ReportPage';
import CompanyInformationPage from 'app/modules/system-settings/company-information/CompanyInformationPage';
import ConfigurationPage from 'app/modules/system-settings/configuration/ConfigurationPage';
import DeviceManagementPage from 'app/modules/system-settings/devices/DeviceManagementPage';
import LocationsManagementPage from 'app/modules/system-settings/locations/LocationsManagementPage';
import UsersRoleDetail from 'app/modules/system-settings/users-role/UsersRoleDetail';
import UsersRoleManagementPage from 'app/modules/system-settings/users-role/UsersRoleManagementPage';
import UsersManagementPage from 'app/modules/system-settings/users/UsersManagementPage';
import ZeroWeightSettingPage from 'app/modules/system-settings/zero-weight-setting/ZeroWeightSettingPage';

import { navigateDefaultPrivateRouter, usePolicyInUser } from 'app/hooks/policy';
import { includes } from 'lodash';

import { PATH } from 'app/constants/path';

import { getCSSVariableValue } from '../../theme/assets/ts/_utils';
import { WithChildren } from '../../theme/helpers';
import { MasterLayout } from '../../theme/layout/MasterLayout';
import { DisableSidebar } from '../../theme/layout/core';
import RoutePolicy from './RoutePolicy';

const PrivateRoutes = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);

  const PermissionPage = lazy(() => import('../modules/system-settings/permission/PermissionPage'));

  const isPathAuth = includes(
    [PATH.LOGIN, PATH.RESET_PASSWORD, PATH.FORGOT_PASSWORD],
    location.pathname,
  );
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path={PATH.DASHBOARD}
          element={
            <RoutePolicy accept={policy.dashboard}>
              <DashboardPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.REPORT}
          element={
            <RoutePolicy accept={policy.report}>
              <ReportPage />
            </RoutePolicy>
          }
        />

        <Route
          path={PATH.REPORT_DETAIL}
          element={
            <RoutePolicy accept={policy.report}>
              <ReportDetailPage />
            </RoutePolicy>
          }
        />

        <Route
          path={`${PATH.INVENTORIES}*`}
          element={
            <SuspensedView>
              <InventoriesManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${PATH.USERS}*`}
          element={
            <RoutePolicy accept={policy.userManagement || policy.userMMD || policy.userNurse}>
              <UsersManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={`${PATH.SYSTEM_SETTING_DEVICE}*`}
          element={
            <RoutePolicy accept={policy.item}>
              <DeviceManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={`${PATH.LOCATIONS}*`}
          element={
            <RoutePolicy accept={policy.settings}>
              <LocationsManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.COMPANY_INFORMATION}
          element={
            <RoutePolicy accept={policy.settings}>
              <CompanyInformationPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.PERMISSION}
          element={
            <RoutePolicy accept={policy.settings}>
              <PermissionPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.NOTIFICATIONS}
          element={
            <RoutePolicy accept={policy.notification}>
              <NotificationPage />
            </RoutePolicy>
          }
        />
        <Route
          path={`${PATH.USERS_ROLE}*`}
          element={
            <RoutePolicy accept={policy.settings}>
              <UsersRoleManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.USERS_ROLE_WEB_DETAIL}
          element={
            <RoutePolicy accept={policy.settings}>
              <UsersRoleDetail redirectPage={PATH.USERS_ROLE_WEB} type={LOGIN_TYPE.WEB} />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.USERS_ROLE_TABLET_DETAIL}
          element={
            <RoutePolicy accept={policy.settings}>
              <UsersRoleDetail redirectPage={PATH.USERS_ROLE_TABLET} type={LOGIN_TYPE.TABLET} />
            </RoutePolicy>
          }
        />
        <Route
          path={`${PATH.CONFIGURATIONS}*`}
          element={
            <RoutePolicy accept={policy.settings}>
              <ConfigurationPage />
            </RoutePolicy>
          }
        />
        <Route
          path={`${PATH.ZERO_WEIGHT_SETTING}*`}
          element={
            <RoutePolicy accept={policy.settings}>
              <ZeroWeightSettingPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.REPORT_DEMAND_PROJECTION_DETAIL}
          element={
            <RoutePolicy accept={policy.viewProjection || policy.approveProjection}>
              <ProjectionPage />
            </RoutePolicy>
          }
        />
        <Route
          path={PATH.STOCK_RUN_DEMAND_PROJECTION_DETAIL}
          element={
            <RoutePolicy accept={policy.viewProjection || policy.approveProjection}>
              <ProjectionPage />
            </RoutePolicy>
          }
        />

        <Route index element={<Navigate to={navigateDefaultPrivateRouter(policy)} />} />
        <Route
          path="*"
          element={
            <Navigate to={isPathAuth ? navigateDefaultPrivateRouter(policy) : PATH.NOT_FOUND} />
          }
        />
      </Route>
    </Routes>
  );
};

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  );
};

export { PrivateRoutes };
