import { FC } from 'react';

import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import Typography from 'app/components/Typography';

export const RemoveItemModal: FC<{
  onCancel: () => void;
  onSubmit: () => void;
}> = ({ onCancel, onSubmit }) => {
  return (
    <ModalDefault title="Remove item" onClickCloseModal={onCancel} isConfirmationModal={true}>
      <Typography
        fontWeight={400}
        lineHeight={23}
        fontSize={14}
        color="neutral-10"
        style={{ paddingBottom: 24 }}
      >
        Remove this item from the list?
      </Typography>
      <ActionButton
        onCancel={onCancel}
        labelButtonSubmit="Remove"
        themeButton="btn-danger"
        onSubmit={onSubmit}
      />
    </ModalDefault>
  );
};
