import { FC } from 'react';

import Typography from 'app/components/Typography';

import { InventoryMovementType } from '../../racks/core/enum';

interface Props {
  userName: string;
  roleName: string;
  userNameStyle?: React.CSSProperties;
  movementType?: InventoryMovementType;
}

export const UserInfo: FC<Props> = ({ userName, roleName, userNameStyle, movementType }) => {
  return (
    <div className="d-flex flex-column justify-content-between w-100">
      <Typography
        variant="body-1"
        lineHeight={26}
        color={userName === '' ? 'neutral-6' : 'neutral-10'}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...userNameStyle,
        }}
      >
        {movementType === InventoryMovementType.autoEdit
          ? 'SIMS'
          : userName === ''
          ? 'Undefined user'
          : userName}
      </Typography>
      {userName !== '' && (
        <Typography
          variant="body-3"
          color="neutral-7"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {roleName}
        </Typography>
      )}
    </div>
  );
};
