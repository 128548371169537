import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import Typography from 'app/components/Typography';

interface Props {
  onCancel: () => void;
  onCloseModal: () => void;
  onSubmit: () => void;
}

export const ConfirmLeavingModal: React.FC<Props> = ({ onCancel, onSubmit, onCloseModal }) => {
  return (
    <ModalDefault
      title="Unsaved items"
      onClickCloseModal={onCloseModal}
      isConfirmationModal
      closeOnBackdrop
      childrenAction={
        <ActionButton
          onCancel={onCancel}
          onSubmit={onSubmit}
          labelButtonSubmit="Save and leave"
          labelButtonReset="Leave page"
        />
      }
      styleBodyModalDialog={{ padding: '28px 28px 24px ' }}
    >
      <Typography variant="body-3">
        You have unsaved items in the stock run. Leaving this page without saving will discard your
        unsaved changes.
      </Typography>
    </ModalDefault>
  );
};
