import { ID, Response } from 'app/components/Table/core/types';

export enum SettingType {
  logoutTimeout = 'auto_logout_time',
  quantityUnit = 'quantity_unit',
  holiday = 'holiday',
  location = 'location',
  workShift = 'shift_work',
  stockRun = 'stock_run',
  stockLevel = 'stock_level',
  binSensor = 'bin-sensor',
}

export interface Setting {
  id: number;
  name: string;
  value: string;
  canDelete?: boolean;
  meta: boolean;
  totalItemUse?: number;
  calendar?: string[];
  wardId?: number;
  shifts?: TimeFrame[];
}

export type SettingsQueryResponse = Response<{
  items: Array<Setting>;
  total: number;
}>;

export interface SettingRequest {
  name: string;
  value: string;
  type: SettingType;
}

export interface WardHoursStockRun {
  wardId: number;
  nameWard: string;
  shifts: TimeFrame[];
}

export interface WorkShift {
  id: number;
  name: string;
  type: string;
  value: {
    from: string;
    to: string;
  }[];
}

export interface TimeFrame {
  from: Date;
  to: Date;
}

export interface StockValues {
  id: ID;
  type: SettingType;
  name: StockLevelSettingType;
  value: StockLevelSetting;
}

// Setting name
export enum StockLevelSettingType {
  restock = 'restock',
  alarm = 'alarm',
}

// Setting metadata interface (JSON stringified)
export interface StockLevelSetting {
  chargeable: number;
  nonChargeable: number;
}

export interface MQTTOption {
  title: string;
  value: QuantityUpdateMethod;
}

export enum StockRunSettingType {
  qtyUpdateMethod = 'quantity_update_method',
}

export enum QuantityUpdateMethod {
  immediately = 'immediately',
  afterSessionCompleted = 'after_session_completed',
}
