import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useListView } from 'app/components/Table/core/ListViewProvider';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { blockInvalidCharacters } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { patchStockValueItem } from '../core/requests';

const Main = styled.div`
  position: relative;
  .edit {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  height: 56px;
`;
const RestockCard = styled.div`
  width: 160px;
  padding-right: 24px;
`;
const ParValue = styled.div`
  width: 160px;
  padding-right: 24px;
`;
const Input = styled.input`
  width: 100%;
  height: 43px;
  background: var(--neutral-2-5);
  border-radius: 8px;
  border: none !important;
  outline: none !important;
  padding: 0px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-10);
  :focus {
    background: var(--neutral-3);
  }
`;
const LowStockValue = styled.div`
  width: 160px;
  padding-right: 24px;
`;

interface Props {
  restockValue: number;
  alarmValue: number;
  wardId: number;
  idItem: number;
  parValue: number;
  index: number;
}
const RestockAndParValueCard: React.FC<Props> = ({
  alarmValue,
  restockValue,
  idItem,
  wardId,
  parValue,
  index,
}) => {
  const { selected, onSelect, clearSelected } = useListView();
  const isEdit = selected.some((el) => el === index);

  const [request, setRequest] = useState({
    alarmValue: 0,
    restockValue: 0,
    parValue: 0,
  });
  const [originRequest, setOriginRequest] = useState({
    alarmValue: 0,
    restockValue: 0,
    parValue: 0,
  });
  const isLoading = useBoolean(false);
  useEffect(() => {
    setOriginRequest({
      alarmValue: (Number(parValue) * alarmValue) / 100,
      restockValue: (Number(parValue) * restockValue) / 100,
      parValue: Number(parValue),
    });
  }, [alarmValue, restockValue]);

  const onChangeInput =
    (key: 'restockValue' | 'alarmValue' | 'parValue') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      });
    };

  const onCancel = () => {
    clearSelected();
    setRequest(originRequest);
  };

  const onSuccess = () => {
    clearSelected();
    setOriginRequest({
      alarmValue: (Number(request.parValue) * alarmValue) / 100,
      restockValue: (Number(request.parValue) * restockValue) / 100,
      parValue: Number(request.parValue),
    });
    toast.success(`Update stock value setting successfully!`);
  };

  const onError = () => {
    clearSelected();
    setRequest(originRequest);
  };

  const onFinally = () => {
    isLoading.setValue(false);
  };

  const onSave = async () => {
    if (!idItem || !wardId) {
      return;
    }
    isLoading.setValue(true);
    await patchStockValueItem(
      idItem,
      {
        ...request,
        alarmValue: 0,
        restockValue: 0,
        parValue: Number(request.parValue),
        wardId: wardId,
      },
      onSuccess,
      onError,
      onFinally,
    );
  };

  const onEdit = () => {
    setRequest(originRequest);
    onSelect(index);
  };

  const renderButton = () => {
    if (!selected.length) {
      return (
        <Typography variant="body-5" color="primary" onClick={onEdit} className="edit">
          Edit
        </Typography>
      );
    }
    if (!isEdit) {
      return null;
    }

    return (
      <div className="d-flex align-items-center">
        <Typography
          variant="body-5"
          color="neutral-6"
          onClick={onCancel}
          className="edit"
          style={{
            marginRight: 10,
          }}
        >
          Cancel
        </Typography>
        <Typography variant="body-5" color="primary" onClick={onSave} className="edit">
          Save
        </Typography>
      </div>
    );
  };
  return (
    <Main className="d-flex align-items-center">
      <LowStockValue>
        {isEdit ? (
          <Input
            type="number"
            value={request.parValue}
            onChange={onChangeInput('parValue')}
            onKeyDown={(e) => blockInvalidCharacters(e, 'float')}
          ></Input>
        ) : (
          <Typography variant="body-5" color="neutral-10">
            {originRequest.parValue}
          </Typography>
        )}
      </LowStockValue>
      <RestockCard>
        <Typography variant="body-5" color="neutral-7">
          {originRequest.restockValue}
        </Typography>
      </RestockCard>
      <ParValue>
        <Typography variant="body-5" color="neutral-7">
          {originRequest.alarmValue}
        </Typography>
      </ParValue>
      <div className="d-flex flex-grow-1 justify-content-end align-items-center">
        {renderButton()}
      </div>
      {isEdit && Number(request.parValue) < 0 && (
        <Typography
          fontWeight={400}
          fontSize={12}
          lineHeight={22}
          color="danger"
          style={{
            position: 'absolute',
            bottom: -14,
          }}
        >
          Par level can not be negative!
        </Typography>
      )}

      {isLoading.value && <LoadingCard />}
    </Main>
  );
};
export default RestockAndParValueCard;
