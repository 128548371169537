import {
  ConnectStatusItem,
  InventoryConnectionStatus,
  InventoryConnectionType,
} from './wards/core/type';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  connectStatus: ConnectStatusItem[];
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
}

const initState: State = {
  connectStatus: [],
};

export const useHeartBeatStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const connectionSelector = (type: 'ward' | 'rack', targetId: number) => {
  return (store: Store) => {
    const notReady = store.state.connectStatus.every((el) =>
      type === 'ward' ? el.ward_id !== targetId : el.rack_id !== targetId,
    );

    return {
      notReady,
      offlineConnection: notReady
        ? []
        : store.state.connectStatus?.filter((el) => {
            if (type === 'ward') {
              return (
                el.ward_id === targetId &&
                el.type === InventoryConnectionType.ward &&
                el.status === InventoryConnectionStatus.offline
              );
            }
            return (
              el.rack_id === targetId &&
              el.type !== InventoryConnectionType.ward &&
              el.status === InventoryConnectionStatus.offline
            );
          }),
    };
  };
};

export const getOfflineCount = (store: Store) => {
  return {
    offlineWard: store.state.connectStatus?.filter(
      (ward) =>
        ward.type === InventoryConnectionType.ward &&
        ward.status === InventoryConnectionStatus.offline,
    ).length,
    offlineRack: store.state.connectStatus?.filter(
      (rack) =>
        rack.type !== InventoryConnectionType.ward &&
        rack.status === InventoryConnectionStatus.offline,
    ).length,
  };
};
