import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';

import AutoLogoutTimeItemCard from './components/AutoLogoutTimeItemCard';
import { NameSetting } from './core/enum';
import { getSetting } from './core/request';
import { Setting, SettingType } from './core/type';

const Body = styled.div`
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 28px;
`;

const ConfigAutoLogoutTimePage = () => {
  const isLoading = useBoolean(false);

  const [mmds, setMmds] = useState<Setting[]>([]);
  const [nurse, setNurse] = useState<Setting[]>([]);

  const [currentForm, setCurrentForm] = useState<NameSetting | null>(null);

  useEffect(() => {
    const getAutoLogoutTime = async () => {
      isLoading.setValue(true);
      const arr: Setting[] = await getSetting(SettingType.logoutTimeout);
      setMmds(
        arr.filter(
          (el) =>
            el.name === NameSetting.completedScreen || el.name === NameSetting.afterLoggingInMMD,
        ),
      );
      setNurse(
        arr.filter(
          (el) =>
            el.name !== NameSetting.completedScreen && el.name !== NameSetting.afterLoggingInMMD,
        ),
      );
      isLoading.setValue(false);
    };
    getAutoLogoutTime();
  }, []);

  return (
    <Body className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between ">
        <Typography lineHeight={26} variant="body-1">
          In the tablet app, if there is no action performed, auto log out and end present session
          after
        </Typography>
      </div>

      <div style={{ marginTop: 32 }}>
        {Boolean(mmds) && (
          <AutoLogoutTimeItemCard
            type={NameSetting.mmdUserAt}
            settings={mmds}
            currentSelected={currentForm}
            setCurrentSelected={setCurrentForm}
          />
        )}
        <div
          style={{
            marginTop: 24,
          }}
        >
          {Boolean(nurse) && (
            <AutoLogoutTimeItemCard
              type={NameSetting.nurseUserAt}
              settings={nurse}
              currentSelected={currentForm}
              setCurrentSelected={setCurrentForm}
            />
          )}
        </div>
      </div>
      {isLoading.value && <LoadingCard />}
    </Body>
  );
};

export default ConfigAutoLogoutTimePage;
