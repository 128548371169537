import { io } from 'socket.io-client';

export enum SessionEventType {
  start = 'start',
  stop = 'stop',
  transaction = 'transaction',
  endCart = 'end-cart',
}

export interface TransactionItem {
  bin: number;
  weight: number;
  qty: number;
  weight_unit: number;
  sku: string;
  ward_id: number;
  rack_id: number;
  time: string;
  item?: {
    id: number;
  };
  afterSessionCompleted?: boolean;
  episode_id: number;
}

export interface EpisodeTablet {
  wardId: number;
  episodeId: number;
  tabletId: string;
  tabletName: string;
}

export enum EpisodeRole {
  Nurse = 'Nurse',
  MMD = 'MMD',
}

interface SessionPayload {
  type: SessionEventType;
  transaction: TransactionItem[];
  role?: EpisodeRole;
}

export const SocketClient = {
  socket: io(process.env.REACT_APP_SOCKET_URL!),
  // Ward session
  onSession(wardId: number, callback: (data: SessionPayload) => void) {
    this.socket.on(`session_ward_${wardId}`, callback);
  },
  offSession(wardId: number) {
    this.socket.off(`session_ward_${wardId}`);
  },
  getSession(wardId: number) {
    this.socket.emit('get_session_ward', { wardId });
  },

  // Rack detail: Get tablets that in session with the ward
  getTabletByWard(wardId: number) {
    this.socket.emit('get_ward_tablets', { wardId });
  },
  onTabletByWard(wardId: number, callback: (data: EpisodeTablet[]) => void) {
    this.socket.on(`ward_tablets_${wardId}`, callback);
  },
  offTabletByWard(wardId: number) {
    this.socket.off(`ward_tablets_${wardId}`);
  },

  // Rack list: get all tablets that in session with all wards
  getAllTablets() {
    this.socket.emit('get_ward_tablets', { wardId: 0 });
  },
  onTabletInAllWards(callback: (data: EpisodeTablet[]) => void) {
    this.socket.on(`ward_tablets`, callback);
  },
  offTablets() {
    this.socket.off(`ward_tablets`);
  },
};
