import React from 'react';
import styled from 'styled-components';

import { Bin } from 'app/modules/inventory/bins/core/type';

import { orderBy } from 'lodash';

import { Transaction } from '../RackTestPage';
import BinTestCard from './BinTestCard';

const Main = styled.div`
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  width: 100%;
  padding: 28px;
  margin-top: 24px;
`;
const GridView = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  overflow-x: auto;
`;
const BinNoItem = styled.div`
  padding: 8px 6px 6px;
  height: 126px;
  border: 1px solid transparent;
  border-radius: 8px;
`;

interface Props {
  bins: Bin[];
  onEmitTransaction: (data: Partial<Transaction>, bin: Bin) => void;
  disabled: boolean;
}
const ViewListBin: React.FC<Props> = ({ bins, onEmitTransaction, disabled }) => {
  return (
    <Main>
      <GridView>
        {new Array(7).fill(null).map((_el, index) => {
          const newBinsFilterRow: Bin[] = bins.filter((el) => el.row === index + 1);
          return (
            <React.Fragment key={index}>
              {orderBy(newBinsFilterRow, 'col').map((bin, binIndex) => {
                return (
                  <BinTestCard
                    bin={bin}
                    key={binIndex}
                    onEmitTransaction={onEmitTransaction}
                    disabled={disabled}
                  />
                );
              })}
              {newBinsFilterRow.length < 5 && <BinNoItem></BinNoItem>}
            </React.Fragment>
          );
        })}
      </GridView>
    </Main>
  );
};
export default ViewListBin;
