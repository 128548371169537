import styled from 'styled-components';

import { ReactComponent as IconFilter } from 'app/assets/icons/icon-filter.svg';

import { TippyDefault } from '../Tippy';
import Typography from '../Typography';

const Main = styled.div<{ isFilter?: boolean }>`
  ${({ isFilter }) =>
    isFilter
      ? `.button-filter {
      background: var(--primary-3);
      svg path[stroke] {
    stroke: var(--primary);
  }}`
      : ``}
`;
const ButtonFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 43px;
  background: var(--neutral-2);
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
`;
interface Props {
  title?: string;
  content: React.ReactNode;
  visible: boolean;
  onClickFilter: (type: 'on' | 'off') => void;
  isFilter: boolean;
}
const FilterDefault: React.FC<Props> = ({
  title = 'Filter',
  content,
  visible,
  isFilter,
  onClickFilter,
}) => {
  return (
    <Main isFilter={isFilter}>
      <TippyDefault
        interactive
        arrow={false}
        containerClass="action-menu"
        placement="bottom-start"
        offset={[170, 5]}
        trigger="click"
        allowHTML
        visible={visible}
        content={content}
        onClickOutside={() => onClickFilter('off')}
      >
        <ButtonFilter className="button-filter" onClick={() => onClickFilter('on')}>
          <IconFilter />
          <Typography
            variant="title-4"
            color={isFilter ? 'primary' : 'neutral-7'}
            style={{
              marginLeft: 10,
            }}
          >
            {title}
          </Typography>
        </ButtonFilter>
      </TippyDefault>
    </Main>
  );
};
export default FilterDefault;
