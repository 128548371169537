import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import RootContentPage from 'app/components/layout/RootContentPage';

import { compileFormData, trimObject } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { cloneDeep } from 'lodash';

import Action from './components/Action';
import AddLogoCompanyCard from './components/AddLogoCompanyCard';
import { CompanyInformationItem } from './components/CompanyInformationItem';
import { COUNTRIES } from './core/constants';
import { createAddress, createCustomer, getCustomers, updateCustomer } from './core/requests';
import { AddressRequest, Customer, CustomerRequest, initialCustomer } from './core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LogoCompany = styled.div`
  width: 160px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
`;
const NameLogoCompany = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
  margin-bottom: 4px;
`;

const CompanyInformationPage = () => {
  const [customer, setCustomer] = useState<Customer>(initialCustomer);
  const [formRequest] = useState<{
    name: string;
    code: string;
    listAddress: AddressRequest[];
  }>({
    name: customer.name,
    code: '',
    listAddress: [],
  });
  const isEdit = useBoolean(false);
  const [logo, setLogo] = useState<File | null>(null);
  const isRemoveLogo = useBoolean();
  const isLoading = useBoolean(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      isLoading.setValue(true);
      const dataRes = await getCustomers();
      if (dataRes.data) {
        setCustomer(dataRes.data);
        const newAddress = dataRes.data.address ?? [];
        if (!dataRes.data.address?.length) {
          newAddress.push({
            address: '',
            city: '',
            country: COUNTRIES[0].value,
            phone: '',
            zipCode: '',
          });
        }
        formik.setValues({
          name: dataRes.data.name,
          code: dataRes.data.code,
          listAddress: newAddress,
        });
      }
    } finally {
      isLoading.setValue(false);
    }
  };
  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Company name is required'),
      // code: Yup.string().required("Code is required"),
      listAddress: Yup.array()
        .of(
          Yup.object().shape({
            address: Yup.string().trim().required('Address is required'),
            phone: Yup.number().required('Phone number is required'),
            city: Yup.string().trim().required('Town/City is required'),
            zipCode: Yup.number().required('Zip Code is required'),
            country: Yup.string().trim().required('Country is required'),
          }),
        )
        .required('Must have address'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const request: CustomerRequest = {
          name: values.name.trim(),
          code: 'code',
        };
        if (logo) {
          request.logo = logo;
        }

        if (customer.id) {
          if (isRemoveLogo.value) {
            request.removeLogo = true;
          }
          await updateCustomer(customer.id, compileFormData(request));
          await changeAddressCompany(String(customer.id), values.listAddress);
          await getData();
          toast.success(`Update company information successfully!`);
          isRemoveLogo.setValue(false);
          isEdit.setValue(false);
          setLogo(null);

          return;
        }
        const dataRes = await createCustomer(compileFormData(request));
        isEdit.setValue(false);
        toast.success(`Add company information successfully!`);
        if (dataRes) {
          await changeAddressCompany(String(dataRes.id), values.listAddress);
          await getData();
          setLogo(null);
        }
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  const changeAddressCompany = async (customerId: string, listAddress: AddressRequest[]) => {
    await createAddress({
      items: listAddress.map((el) => {
        const trimmedRequest = trimObject(el) as AddressRequest;
        return {
          ...trimmedRequest,
          customerId: customerId,
        };
      }),
    });
  };
  const onChangeCountry = (newValue: string, index: number) => {
    const newListAddress = cloneDeep(formik.values.listAddress);
    newListAddress[index].country = newValue;
    formik.setValues({
      name: customer.name,
      code: customer.code,
      listAddress: newListAddress,
    });
  };
  const onCancel = () => {
    isEdit.setValue(false);
    setLogo(null);
    isRemoveLogo.setValue(false);
    formik.setValues({
      name: customer.name,
      code: customer.code,
      listAddress: customer.address ?? [
        {
          address: '',
          city: '',
          country: '',
          phone: '',
          zipCode: '',
        },
      ],
    });
  };

  return (
    <RootContentPage title="Company information">
      {!isLoading.value ? (
        <div
          style={{
            padding: 28,
          }}
        >
          <form
            id="kt_modal_company_form"
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <Action
              isEdit={isEdit}
              onCancel={onCancel}
              disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
              isSubmitting={formik.isSubmitting}
            />
            <div className="d-flex">
              <LogoCompany>
                <NameLogoCompany>Company logo</NameLogoCompany>
                <AddLogoCompanyCard
                  file={logo}
                  setFile={setLogo}
                  image={customer.logo ?? ''}
                  isEdit={isEdit.value}
                  isRemoveLogo={isRemoveLogo.value}
                  setIsRemoveLogo={isRemoveLogo.setValue}
                />
              </LogoCompany>
              <div className="d-flex flex-column w-100">
                <div
                  className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
                  id="kt_modal_add_user_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_add_user_header"
                  data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  data-kt-scroll-offset="300px"
                >
                  <FormInput
                    label="Company name"
                    placeholder="Company name"
                    formik={formik}
                    field="name"
                    isRequired
                    isDisable={!isEdit.value}
                  />
                  {formik.values.listAddress.map((_el, index) => {
                    return (
                      <CompanyInformationItem
                        key={index}
                        formik={formik}
                        index={index}
                        listIsError={formik.touched.listAddress}
                        lisMessageError={formik.errors.listAddress}
                        isEdit={isEdit.value}
                        onChangeCountry={onChangeCountry}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <LoadingCard />
      )}
    </RootContentPage>
  );
};
export default CompanyInformationPage;
