import React, { memo } from 'react';
import { Row } from 'react-table';

import { isUndefined } from 'lodash';
import { includes } from 'lodash';

import { BodyTable } from './body/BodyTable';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { emptyListViewProvider } from './core/constants';
import { ID, QueryState, TYPE_MODAL, TableSpacing } from './core/types';
import { ListHeader } from './header/ListHeader';
import ConfirmationModal from './modal/ConfirmationModal';
import FormChangeInfoModal from './modal/FormChangeInfoModal';
import { KTCard } from 'theme/helpers';

export type CustomTableToolbar = {
  Left?: React.ReactNode;
  Right?: React.ReactNode;
};

interface Props {
  originColumns: any;
  nameQuery: string;
  nameBtnAdd?: string;
  titleModal?: string;
  getList: (query: string, id?: ID, moreParam?: string) => Promise<any>;
  getById?: (id: ID) => Promise<any>;
  deleteById?: (id: ID) => Promise<any>;
  ModalForm?: (originalData: any, typeModal: TYPE_MODAL, isView?: boolean) => JSX.Element;
  ModalFormConfirmation?: (originalData: any, typeModal: TYPE_MODAL) => JSX.Element;
  moreQuery?: string;
  styleModalDialog?: React.CSSProperties;
  contentModal?: string;
  placeholderSearch?: string;
  onRedirectPage?: () => void;
  onClickBodyTable?: (row: Row) => () => void;
  isHiddenHeader?: boolean;
  paramId?: ID;
  isBorder?: boolean;
  styleModalForm?: React.CSSProperties;
  getTotal?: (total: number) => void;
  defaultState?: QueryState;
  customListHeader?: React.ReactNode;
  ModalOutsideTable?: (originalData: any, typeModal?: TYPE_MODAL) => JSX.Element;
  isSortAndSearchFrontEnd?: boolean;
  refetchTimestamp?: number;
  query?: QueryState;
  isAutoFetch?: boolean;
  getDataItemResponse?: (data: any, total: number) => void;
  hideBody?: boolean;
  CustomToolbar?: CustomTableToolbar;
  styleHeader?: React.CSSProperties;
  fieldCheckShowCursorInRow?: string;
  syncParamUrl?: boolean;
  isRowClickable?: boolean;
  bodyStyle?: React.CSSProperties;
  isLoadMore?: boolean;
  spacing?: TableSpacing;
  tableContainerStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  cardStyle?: React.CSSProperties;
  choseRowId?: number;
  tableContentStyle?: React.CSSProperties;
  theadStyle?: React.CSSProperties;
  mappingDataMiddleware?: (data: any) => any;
  customSearchFields?: string[];
  hideSearch?: boolean;
  noDataMessage?: string;
  isSortLowerCase?: boolean;
}

const ListTable: React.FC<Props> = ({
  originColumns,
  nameQuery,
  titleModal,
  nameBtnAdd,
  deleteById,
  ModalForm,
  ModalFormConfirmation,
  getById,
  styleModalDialog,
  contentModal,
  placeholderSearch,
  onRedirectPage,
  onClickBodyTable,
  isHiddenHeader = false,
  isBorder = true,
  styleModalForm,
  customListHeader,
  ModalOutsideTable,
  hideBody,
  CustomToolbar,
  styleHeader,
  fieldCheckShowCursorInRow,
  isRowClickable,
  syncParamUrl,
  bodyStyle,
  isLoadMore = false,
  spacing = 'md',
  tableContainerStyle,
  tableStyle,
  cardStyle,
  choseRowId,
  tableContentStyle,
  isSortAndSearchFrontEnd,
  theadStyle,
  hideSearch,
  noDataMessage,
}) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const renderTitleModal = () => {
    if (itemForUpdate?.type === TYPE_MODAL.RESET_PASSWORD) {
      return 'Reset Password';
    }
    if (itemForUpdate?.type === TYPE_MODAL.EDIT) {
      return 'Edit';
    }
    return 'Add';
  };
  return (
    <React.Fragment>
      <KTCard isBorder={isBorder} contentStyle={{ ...cardStyle }}>
        {!isHiddenHeader ? (
          <ListHeader
            nameBtnAdd={nameBtnAdd}
            placeholderSearch={placeholderSearch ?? ''}
            onRedirectPage={onRedirectPage}
            isBorder={isBorder}
            customListHeader={customListHeader}
            CustomToolbar={CustomToolbar}
            containerStyle={styleHeader}
            syncParamUrl={syncParamUrl}
            hideSearch={hideSearch}
          />
        ) : (
          <div
            style={{
              height: 28,
            }}
          ></div>
        )}

        {hideBody ? null : (
          <BodyTable
            isBorder={isBorder}
            originColumns={originColumns}
            onClickBodyTable={onClickBodyTable}
            fieldCheckShowCursorInRow={fieldCheckShowCursorInRow}
            isRowClickable={isRowClickable}
            syncParamUrl={syncParamUrl}
            bodyStyle={bodyStyle}
            isLoadMore={isLoadMore}
            spacing={spacing}
            tableStyle={tableStyle}
            tableContainerStyle={tableContainerStyle}
            choseRowId={choseRowId}
            tableContentStyle={tableContentStyle}
            isSortAndSearchFrontEnd={isSortAndSearchFrontEnd}
            theadStyle={theadStyle}
            noDataMessage={noDataMessage}
          />
        )}
      </KTCard>
      {!isUndefined(itemForUpdate?.originalData) &&
        includes(
          [TYPE_MODAL.ADD, TYPE_MODAL.EDIT, TYPE_MODAL.RESET_PASSWORD],
          itemForUpdate?.type,
        ) && (
          <FormChangeInfoModal
            nameQuery={nameQuery}
            getById={getById}
            title={
              itemForUpdate?.titleModal
                ? itemForUpdate.titleModal
                : itemForUpdate?.isView
                ? `${titleModal} detail`
                : titleModal
                ? `${renderTitleModal()} ${titleModal}`
                : ''
            }
            ModalForm={ModalForm}
            styleModalDialog={styleModalDialog}
            styleModalForm={styleModalForm}
            onClickCloseModal={() => setItemForUpdate(emptyListViewProvider)}
            isView={itemForUpdate?.isView ?? false}
          />
        )}
      {itemForUpdate?.originalData &&
        includes(
          [
            TYPE_MODAL.DELETE,
            TYPE_MODAL.STATUS,
            TYPE_MODAL.INFO,
            TYPE_MODAL.UNABLE_CHANGE_ACTIVE_STATUS,
          ],
          itemForUpdate?.type,
        ) && (
          <ConfirmationModal
            title={itemForUpdate.titleModal ? itemForUpdate.titleModal : `Delete ${titleModal}`}
            typeModal={itemForUpdate.type}
            originalData={itemForUpdate?.originalData}
            ModalFormConfirmation={ModalFormConfirmation}
            label={contentModal}
            nameQuery={nameQuery}
            deleteById={deleteById}
            onClickCloseModal={() => setItemForUpdate(emptyListViewProvider)}
            messageDelete={titleModal}
            syncParamUrl={syncParamUrl}
          />
        )}

      {!isUndefined(ModalOutsideTable) &&
        ModalOutsideTable(itemForUpdate?.originalData, itemForUpdate?.type)}
    </React.Fragment>
  );
};

const TableComponent: React.FC<Props> = memo((props: Props) => {
  const {
    nameQuery,
    paramId,
    getList,
    moreQuery = '',
    getTotal,
    defaultState,
    isSortAndSearchFrontEnd,
    refetchTimestamp,
    query,
    isAutoFetch,
    getDataItemResponse,
    hideBody,
    syncParamUrl = false,
    mappingDataMiddleware,
    customSearchFields,
    isSortLowerCase,
  } = props;

  if (hideBody) {
    return <ListTable {...props} />;
  }

  return (
    <QueryRequestProvider defaultState={defaultState} query={query}>
      <QueryResponseProvider
        nameQuery={nameQuery}
        getList={getList}
        moreQuery={moreQuery}
        paramId={paramId}
        getTotal={getTotal}
        isSortAndSearchFrontEnd={isSortAndSearchFrontEnd}
        refetchTimestamp={refetchTimestamp}
        isAutoFetch={isAutoFetch}
        getDataItemResponse={getDataItemResponse}
        syncParamUrl={syncParamUrl}
        mappingDataMiddleware={mappingDataMiddleware}
        customSearchFields={customSearchFields}
        isSortLowerCase={isSortLowerCase}
      >
        <ListViewProvider>
          <ListTable {...props} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
});

export default TableComponent;
