import { toast } from 'react-toastify';
import styled from 'styled-components';

import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import { StockLevelAlertIcon } from 'app/components/Status/StockLevelAlertIcon';
import { MenuOptionsDivider } from 'app/components/Table/columns/ActionsCell';
import { TippyDefault } from 'app/components/Tippy';
import { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';
import Typography from 'app/components/Typography';

import { coverPath } from 'app/helpers';
import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconThreeDots } from 'app/assets/icons/icon-3-dots.svg';

import LabelStockTypeCard from '../../items/components/LabelStockTypeCard';
import { ActionBin } from '../../racks/core/enum';
import { curWardSelector, useRackStore } from '../../racks/store';
import { Bin } from '../core/type';

const MENU_CONTAINER_WIDTH = 160;
const TOTAL_PADDING = 32;
const CUSTOM_OPTION_CONTENT_WIDTH = MENU_CONTAINER_WIDTH - TOTAL_PADDING;

export const BinItem = styled.div`
  position: relative;
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  min-height: 126px;
  min-width: 166px;
  .action {
    opacity: 0;
  }
  &:hover .action {
    opacity: 1;
  }
  .action-menu {
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 2;
  }
  cursor: pointer;
`;

const ButtonAction = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
`;

interface Props {
  bin: Bin;
  onClickModal: (key: ActionBin, originBin: Bin) => void;
  inSession?: boolean;
}
const BinDetailCard: React.FC<Props> = ({ bin, onClickModal, inSession }) => {
  const navigate = useNavigateWithSendPath();

  const wardId = useRackStore(curWardSelector);

  const isOpenAction = useBoolean(true);

  const onChangeMenuItem = (action: ActionBin, binItem: Bin) => (event: any) => {
    if (
      [ActionBin.assignItem, ActionBin.removeItem, ActionBin.updateQuantity].includes(action) &&
      inSession
    ) {
      return toast.error(
        'This function has been momentarily suspended due to an active tablet session. Please try again later.',
      );
    }

    event.preventDefault();
    event.stopPropagation();
    isOpenAction.setValue(false);
    onClickModal(action, binItem);
  };

  const onClickBin = () => {
    if (bin.item?.id) {
      navigate(PATH.ITEMS_DETAIL.replace(':id', String(bin.item.id)));
    }
  };

  return (
    <BinItem style={bin.inSession ? { borderColor: 'var(--primary)' } : {}}>
      <div className="d-flex align-items-center justify-content-between" style={{ height: 30 }}>
        <Typography variant="body-6" color="neutral-10">
          {bin.name}
        </Typography>
        <TippyDefault
          interactive
          arrow={false}
          placement="bottom-end"
          offset={[0, 5]}
          trigger="click"
          allowHTML
          content={
            isOpenAction.value && (
              <MenuAction
                style={{
                  width: MENU_CONTAINER_WIDTH,
                  padding: 8,
                }}
              >
                {bin.item ? (
                  <>
                    <MenuItem
                      onClick={onChangeMenuItem(ActionBin.updateQuantity, bin)}
                      style={{ marginBottom: 4 }}
                    >
                      Edit quantity
                    </MenuItem>
                    <MenuItem
                      onClick={onChangeMenuItem(ActionBin.removeItem, bin)}
                      style={{ marginBottom: 4 }}
                    >
                      Remove item
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    onClick={onChangeMenuItem(ActionBin.assignItem, bin)}
                    style={{ marginBottom: 4 }}
                  >
                    Assign item
                  </MenuItem>
                )}
                {bin.item ? (
                  <>
                    <MenuItem onClick={onChangeMenuItem(ActionBin.shortFor, bin)}>
                      Add to a stock run
                    </MenuItem>
                    <MenuOptionsDivider length={CUSTOM_OPTION_CONTENT_WIDTH} />
                  </>
                ) : undefined}
                <MenuItem onClick={() => navigate(PATH.BIN_HISTORY.replace(':id', String(bin.id)))}>
                  Show bin history
                </MenuItem>
                {bin.item?.id && (
                  <MenuItem
                    onClick={() =>
                      navigate(
                        coverPath(PATH.REPORT_DETAIL, {
                          wardId: wardId,
                          itemId: bin.item?.id,
                        }),
                      )
                    }
                    style={{ marginTop: 4 }}
                  >
                    Go to item report
                  </MenuItem>
                )}
              </MenuAction>
            )
          }
        >
          <ButtonAction
            className="action"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              isOpenAction.setValue(true);
            }}
          >
            <IconThreeDots />
          </ButtonAction>
        </TippyDefault>
      </div>

      {bin.item ? (
        <div className="d-flex flex-column">
          <div className="d-flex">
            <ShowImageDefault
              image={bin.item.image ?? ''}
              styleMain={{
                minWidth: 32,
                minHeight: 32,
                width: 32,
                height: 32,
              }}
            />
            <div
              className="d-flex flex-column"
              style={{
                marginLeft: 8,
                flexGrow: 1,
              }}
            >
              <Typography variant="body-2" className="text-overflow-ellipsis" onClick={onClickBin}>
                {bin.item.name}
              </Typography>
              <LabelStockTypeCard stockType={Number(bin.item.stockType)} />
              <Typography
                variant="body-3"
                color="neutral-7"
                style={{
                  whiteSpace: 'nowrap',
                  marginTop: 1,
                }}
                onClick={onClickBin}
              >
                {bin.item.skuId}
              </Typography>
              <div className="d-flex align-items-center">
                <Typography
                  variant="title-4"
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: 4,
                  }}
                >
                  {`Qty: ${bin.item.qty ?? 0}`}
                </Typography>
                <StockLevelAlertIcon
                  stockLevel={bin.item?.stockLevel}
                  marginLeft={0}
                  isScaleSize={false}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Typography>{'No item assigned'}</Typography>
      )}
      {bin.inSession && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: 73,
            height: 23,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'var(--primary-3)',
            borderBottomLeftRadius: 7,
            zIndex: 1,
            borderTopRightRadius: 8,
          }}
        >
          <Typography fontWeight={600} fontSize={13} color="primary">
            In session
          </Typography>
        </div>
      )}
    </BinItem>
  );
};

export default BinDetailCard;
