import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import { Address, AddressRequest, Customer, CustomersQueryResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const CUSTOMERS_URL = `${API_URL}/api/customers`;
const ADDRESS_URL = `${API_URL}/api/address`;

const getCustomers = (): Promise<CustomersQueryResponse> => {
  return axios.get(`${CUSTOMERS_URL}`).then(
    (
      d: AxiosResponse<{
        data: Customer;
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const createCustomer = (request: FormData): Promise<Customer | undefined> => {
  return axios
    .post(CUSTOMERS_URL, request)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data);
};

const createAddress = (request: { items: AddressRequest[] }): Promise<Address[] | undefined> => {
  return axios
    .post(ADDRESS_URL, request)
    .then((response: AxiosResponse<Response<Address[]>>) => response.data)
    .then((response: Response<Address[]>) => response.data);
};

const updateCustomer = (idCustomer: ID, request: FormData): Promise<Customer | undefined> => {
  return axios
    .patch(`${CUSTOMERS_URL}/${idCustomer}`, request)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data);
};

export { getCustomers, createCustomer, updateCustomer, createAddress };
