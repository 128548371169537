import { Column } from 'react-table';

import HeartBeatStatus from 'app/components/Status/HeartBeatStatus';
import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { Ward } from './type';

export const wardsColumns = (): ReadonlyArray<Column<Ward>> => {
  const actions: ActionTable[] = [
    {
      name: 'Edit',
      type: TYPE_MODAL.EDIT,
    },
  ];

  return [
    {
      id: 'name',
      accessor: 'name',
      width: '40%',
      Header: (props) => <CustomHeader tableProps={props} title="Name" />,
    },
    {
      id: 'bedQuantity',
      accessor: 'countChildren',
      width: '30%',
      Header: 'Bed quantity',
      Cell: ({ ...props }) => (
        <Typography variant="body-5" color="neutral-10">
          {props.data[props.row.index].countChildren ?? 0}
        </Typography>
      ),
    },
    {
      id: 'connectionStatus',
      Header: 'Raspberry Pi connection',
      Cell: ({ ...props }) => (
        <HeartBeatStatus pageType="ward" targetId={Number(props.data[props.row.index].id)} />
      ),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => <ActionsCell item={props.data[props.row.index]} actions={actions} />,
    },
  ];
};
