export const API_URL = process.env.REACT_APP_API_URL;
export const HIDDEN_BUTTON_ADD = process.env.REACT_APP_HIDDEN_BUTTON_ADD === 'true';

export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const DASHBOARD_PANEL_ID_1 = process.env.REACT_APP_DASHBOARD_PANEL_ID_1;
export const DASHBOARD_PANEL_ID_2 = process.env.REACT_APP_DASHBOARD_PANEL_ID_2;
export const DASHBOARD_PANEL_ID_3 = process.env.REACT_APP_DASHBOARD_PANEL_ID_3;
export const DASHBOARD_PANEL_ID_4 = process.env.REACT_APP_DASHBOARD_PANEL_ID_4;
export const DASHBOARD_PANEL_ID_5 = process.env.REACT_APP_DASHBOARD_PANEL_ID_5;

export const DEFAULT_WARD = process.env.REACT_APP_DEFAULT_WARD || 1;

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION || '';

export const MMD_ACTIVE_BY_RACK = process.env.REACT_APP_MMD_ACTIVE_BY_RACK === 'true';

export const HIDE_RACK_TEST = process.env.REACT_APP_HIDE_RACK_TEST === 'true';
