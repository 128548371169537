import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ButtonDefault from 'app/components/Button/ButtonDefault';
import { ViewMode } from 'app/components/Button/ButtonSwitchView';
import CheckboxDefault from 'app/components/Checkbox/CheckboxDefault';
import InputDateFilter from 'app/components/Filter/InputDateFilter';
import SelectMulti from 'app/components/Select/SelectMulti';
import Typography from 'app/components/Typography';

import { useChangeParams } from 'app/modules/query-state/hooks';
import { useQueryParamStore } from 'app/modules/query-state/store';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { useBoolean } from 'app/hooks';
import { endOfDay, startOfDay } from 'date-fns';
import { cloneDeep, isEqual, xor } from 'lodash';

import { emptyStockRunFilter } from '../core/constants';
import { StatusStockUp } from '../core/enum';
import { labelFilterDate } from '../core/helpers';
import { StockRunFilterDetail } from '../core/type';
import StatusStockRunCard from './StatusStockRunCard';

const Main = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  height: 80px;
  padding: 0px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-3);

  .clear_all:hover {
    text-decoration: underline;
  }
`;
const Body = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

interface Props {
  openMenu: boolean;
  originWards: Ward[];
  onUpdateFilter: () => void;
}
const StockRunFilter: React.FC<Props> = ({ originWards, openMenu, onUpdateFilter }) => {
  const curParams = useQueryParamStore((s) => s.state.curParams);
  const { onChangeParams } = useChangeParams();
  const [filter, setFilter] = useState<StockRunFilterDetail>(emptyStockRunFilter);
  const [wards, setWards] = useState<Ward[]>([]);
  const menuIsOpen = useBoolean(false);

  const hideDateFilter = String(curParams.mode) === String(ViewMode.nonList); // Calendar mode

  const wardOptions = filter.wardIds.map((el) => ({
    label: wards.find((w) => String(w.id) === el)?.name,
    value: el,
  }));

  useEffect(() => {
    if (!openMenu || !originWards.length) {
      return;
    }
    setWards(originWards);
  }, [JSON.stringify(originWards), openMenu]);

  useEffect(() => {
    if (!openMenu || isEqual(curParams, filter)) {
      return;
    }
    setFilter({ ...filter, ...curParams } as any);
  }, [curParams, openMenu]);

  const onChangeStatus = (newStatus: StatusStockUp) => () => {
    setFilter((prev) => ({ ...prev, status: xor(prev.status, [newStatus.toString()]) }));
  };

  const applyFilter = () => {
    onChangeParams({
      ...filter,
      page: 1,
    });
    onUpdateFilter();
  };

  const onChangeDate = (
    newDate: {
      startDate: Date;
      endDate: Date;
      key: string;
    }[],
    type: 'commence' | 'complete',
  ) => {
    if (!newDate.length) {
      return;
    }
    const newFilter = cloneDeep(filter);
    if (type === 'commence') {
      newFilter.from_commence_date = startOfDay(newDate[0].startDate).toUTCString();
      newFilter.to_commence_date = endOfDay(newDate[0].endDate).toUTCString();
    } else {
      newFilter.from_complete_date = startOfDay(newDate[0].startDate).toUTCString();
      newFilter.to_complete_date = endOfDay(newDate[0].endDate).toUTCString();
    }
    setFilter(newFilter);
  };

  const clearAll = () => {
    setFilter(emptyStockRunFilter);
  };

  const onClearDate = (type: 'commence' | 'complete') => {
    const newFilter = cloneDeep(filter);
    if (type === 'commence') {
      newFilter.from_commence_date = null;
      newFilter.to_commence_date = null;
    } else {
      newFilter.from_complete_date = null;
      newFilter.to_complete_date = null;
    }
    setFilter(newFilter);
  };

  const renderFilter = () => (
    <InputDateFilter
      label="Start date"
      value={labelFilterDate(
        filter.from_commence_date ? new Date(filter.from_commence_date) : null,
        filter.to_commence_date ? new Date(filter.to_commence_date) : null,
      )}
      onChange={(newDate) => onChangeDate(newDate, 'commence')}
      state={[
        {
          endDate: filter.to_commence_date ? new Date(filter.to_commence_date) : new Date(),
          startDate: filter.from_commence_date ? new Date(filter.from_commence_date) : new Date(),
          key: 'selection',
        },
      ]}
      onClear={() => onClearDate('commence')}
    />
  );

  return (
    <Main>
      <Header>
        <Typography variant="heading-6">Filter</Typography>
        <Typography
          className="clear_all"
          variant="body-5"
          onClick={clearAll}
          color="primary"
          style={{
            cursor: 'pointer',
          }}
        >
          Clear all
        </Typography>
      </Header>
      <Body>
        <Typography
          variant="body-7"
          color="gray-700"
          style={{
            marginBottom: 8,
          }}
        >
          Ward
        </Typography>
        <SelectMulti
          options={wards.map((el) => {
            return {
              ...el,
              label: el.name,
              value: String(el.id),
            };
          })}
          selectedOption={wardOptions}
          handleChange={(newValue) => {
            setFilter((prev) => ({
              ...prev,
              wardIds: newValue.map((el) => String(el.value)),
            }));
            menuIsOpen.setValue(true);
          }}
          placeholder="Select ward to filter"
          menuIsOpen={menuIsOpen.value}
          onMenuClose={() => menuIsOpen.setValue(false)}
          openMenuOnClick={() => menuIsOpen.setValue(true)}
        />
        <div
          style={{
            marginTop: 15,
          }}
        >
          {hideDateFilter ? null : renderFilter()}

          <InputDateFilter
            label="Complete date"
            value={labelFilterDate(
              filter.from_complete_date ? new Date(filter.from_complete_date) : null,
              filter.to_complete_date ? new Date(filter.to_complete_date) : null,
            )}
            onChange={(newDate) => onChangeDate(newDate, 'complete')}
            state={[
              {
                endDate: filter.to_complete_date ? new Date(filter.to_complete_date) : new Date(),
                startDate: filter.from_complete_date
                  ? new Date(filter.from_complete_date)
                  : new Date(),
                key: 'selection',
              },
            ]}
            onClear={() => onClearDate('complete')}
          />
        </div>

        <Typography variant="body-7" color="gray-700" style={{ marginBottom: 8 }}>
          Status
        </Typography>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          {[
            StatusStockUp.Draft,
            StatusStockUp.Posted,
            StatusStockUp.WIP,
            StatusStockUp.Completed,
            StatusStockUp['Past-due'],
          ].map((status, index) => {
            return (
              <div
                key={index}
                style={{
                  marginBottom: 14,
                }}
              >
                <CheckboxDefault
                  onChange={onChangeStatus(status)}
                  checked={filter.status.includes(status.toString())}
                  label={
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <StatusStockRunCard key={index} status={status} />
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{
            marginTop: 10,
          }}
        >
          <ButtonDefault
            onClick={applyFilter}
            style={{
              width: 160,
              borderRadius: 8,
            }}
          >
            Apply
          </ButtonDefault>
        </div>
      </Body>
    </Main>
  );
};
export default StockRunFilter;
