import { convertToCsvFormatData, exportFile, formatDate } from 'app/helpers';

import { FilterReportDetail } from './type';

export const coverReportFilterToQuery = (filter: FilterReportDetail) => {
  let query = '';
  const queryStatus = Object.values(filter.action)
    .map((action) => {
      return `&action[]=${action}`;
    })
    .join('');

  if (filter.from_expriry_date && filter.to_expriry_date) {
    query = query.concat(
      `&from_expriry_date=${filter.from_expriry_date.toISOString()}&to_expriry_date=${filter.to_expriry_date.toISOString()}`,
    );
  }

  return query.concat(queryStatus);
};

export const getFormatedDate = (date: Date | null) =>
  date ? formatDate(date, 'dd-MMM-yyyy') : date;

export const exportCsv = (header?: any[], items?: any, fileName?: string) => {
  const tableData = convertToCsvFormatData(header, items);
  exportFile(
    tableData.join('\n'),
    'text/csv',
    `${fileName} report-${getFormatedDate(new Date())}.csv`,
  );
};
