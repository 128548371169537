import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Typography, { EmptyTableCell } from 'app/components/Typography';

import { get } from 'lodash';

import { getSummary } from './core/requests';
import { DashboardSummary } from './core/types';

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const useSummaryInfo = (from: Date, to: Date) => {
  const [summaries, setSummaries] = useState<DashboardSummary>();

  const fetchSummary = async () => {
    const res = await getSummary(`from=${from.toISOString()}&to=${to.toISOString()}`);
    setSummaries(res);
  };

  useEffect(() => {
    fetchSummary();
  }, [from, to]);

  const renderSummaries = (
    configs: {
      title: string;
      key: string;
    }[],
    options?: { firstColPadding?: number; rowGap?: number },
  ) => {
    return configs.map((el, idx) => {
      const value = get(summaries, el.key);
      return (
        <InfoGroup
          style={{
            paddingLeft: idx % 2 === 0 ? options?.firstColPadding ?? 24 : 0,
            marginBottom: options?.rowGap ?? 0,
          }}
          key={el.key}
        >
          <Typography variant="title-5">{el.title}</Typography>
          {value ? <Typography variant="body-1">{value}</Typography> : EmptyTableCell}
        </InfoGroup>
      );
    });
  };

  return { renderSummaries };
};
