import React, { FC, useEffect } from 'react';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { editBed } from '../core/requests';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditBed: FC<{ disableOrigin?: boolean }> = ({ disableOrigin = false }) => {
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();

  const isDisable = useBoolean(disableOrigin);

  useEffect(() => {
    isDisable.setValue(disableOrigin);
  }, [disableOrigin]);

  const formik = useFormik({
    initialValues: {
      code: itemForUpdate?.originalData.code || '',
      codeId: itemForUpdate?.originalData.codeId || '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().trim().required('Bed name is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (isDisable.value) {
        isDisable.setValue(false);
        if (itemForUpdate) {
          setItemForUpdate({ ...itemForUpdate, isView: false });
        }
        return;
      }
      try {
        setSubmitting(true);
        await editBed(itemForUpdate?.originalData.id, values.code?.trim(), values.codeId?.trim());
        refetch();
        setItemForUpdate(emptyListViewProvider);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  const onCancel = () => {
    setItemForUpdate(emptyListViewProvider);
  };

  return (
    <React.Fragment>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <FormInput
          label="Bed name"
          placeholder="Bed name"
          formik={formik}
          field="code"
          rootClass="w-100"
          isRequired
          isDisable={isDisable.value}
        />
        <FormInput
          label="ID"
          placeholder="ID"
          formik={formik}
          field="codeId"
          rootClass="w-100"
          isDisable={isDisable.value}
        />

        <ActionButton
          onCancel={onCancel}
          disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={isDisable.value ? 'Edit' : 'Save'}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </React.Fragment>
  );
};
export default EditBed;
