import { formatCustomDate, formatDurationInHuman } from 'app/helpers';
import { EpisodeTablet, TransactionItem } from 'app/hooks/socket';
import { orderBy, sumBy, uniqBy } from 'lodash';

import { StockLevel } from '../../stock-run/core/enum';
import { getStockLevel } from '../../stock-run/core/helpers';

export const showUpdateAtRack = (updatedAt: string) => {
  const getTimeNewDate = new Date().getTime();
  const getTimeUpdateRack = new Date(updatedAt).getTime() + 24 * 60 * 60 * 1000; //1 date
  if (getTimeNewDate < getTimeUpdateRack) {
    return `${formatDurationInHuman(updatedAt)} ago`;
  }
  return `${formatCustomDate(updatedAt)}`;
};

export const getListTabletName = (tabletInWard: EpisodeTablet[]) => {
  return uniqBy(orderBy(tabletInWard, 'episodeId', 'desc'), 'tabletId').map(
    (tablet) => tablet.tabletName,
  );
};

export const mergeQtyItems =
  (
    changeItems: TransactionItem[],
    saveItems: TransactionItem[],
    changeGroupByItems: {
      itemId: string | number;
      totalChangeQty: number;
    }[],
  ) =>
  (el: any) => {
    const item = el.item;
    const existed = changeItems.find((e) => e.bin === el.index);
    const totalQty =
      (item?.qty || 0) +
      sumBy(
        changeItems
          .concat(saveItems)
          .filter((itm) => itm.bin === el.index && !itm.afterSessionCompleted),
        'qty',
      );
    const changeQtyByItem =
      (item &&
        changeGroupByItems.find((itemEl) => item.id && itemEl.itemId === item.id)
          ?.totalChangeQty) ||
      0;
    const newQtyBalance = (item?.qtyBalance || 0) + changeQtyByItem;
    const stockLevel = getStockLevel({
      ...item,
      qtyBalance: newQtyBalance,
    });
    const stockLevelOrder = Object.values(StockLevel).indexOf(stockLevel);
    return {
      ...el,
      item: item
        ? {
            ...item,
            qty: totalQty,
            qtyBalance: newQtyBalance,
            stockLevel,
            stockLevelOrder,
          }
        : undefined,
      inSession: !!existed,
    };
  };
