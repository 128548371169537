import { QueryState } from 'app/components/Table/core/types';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  curParams: Partial<QueryState> & Record<string, string | number>;
  synced: Record<string, boolean>;
  skipFields?: string[];
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
}

const initState: State = {
  curParams: {},
  synced: {},
};

export const useQueryParamStore = create(
  devtools(
    immer<Store>((set) => ({
      state: initState,
      setState: (payload) => {
        set((store) => {
          store.state = { ...store.state, ...payload };
        });
      },
      clearState: () => {
        set((store) => {
          store.state = initState;
        });
      },
    })),
    {
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);
