import { useEffect, useState } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import styled from 'styled-components';

import ButtonDefault from 'app/components/Button/ButtonDefault';
import CheckboxDefault from 'app/components/Checkbox/CheckboxDefault';
import { ModalDatePicker } from 'app/components/Filter/InputDateFilter';
import Typography from 'app/components/Typography';

import { staticDateRanges } from 'app/modules/system-settings/configuration/core/constants';

import { addMonths, endOfDay, isBefore, isSameDay, startOfDay, subMonths } from 'date-fns';

import { FilterReportDetail } from '../core/type';

const FooterContainer = styled.div`
  position: absolute;
  width: 548px;
  bottom: 10px;
  right: 10px;
  transform: translate(10px, 0px);
  border-top: 1px solid var(--neutral-3);
  display: flex;
  flex-direction: column;
`;

const ExportSectionWrapper = styled.div`
  padding: 16px 28px 18px 28px;
  display: flex;
  justify-content: flex-end;
`;

const CheckboxWrapper = styled.div`
  padding: 18px 28px 0;
  svg path[stroke] {
    stroke: var(--white) !important;
  }
`;

const INITIAL_FILTER_VALUE = Object.freeze({
  action: {},
  from_expriry_date: startOfDay(new Date()),
  to_expriry_date: endOfDay(new Date()),
  mqtt_log_included: false,
});

interface Props {
  onExport?: (filter: FilterReportDetail) => void;
  onClose?: () => void;
  isSubmitting: boolean;
}

export const ReportTimeframe: React.FC<Props> = ({ onExport, onClose, isSubmitting }) => {
  const [filter, setFilter] = useState<FilterReportDetail>(INITIAL_FILTER_VALUE);

  const [firstSelectedDay, setFirstSelectedDay] = useState<Date | null>(null);

  useEffect(() => {
    return () => {
      setFilter(INITIAL_FILTER_VALUE);
    };
  }, []);

  const onChangeDate = (
    newDate: {
      startDate: Date;
      endDate: Date;
      key: string;
    }[],
  ) => {
    if (!newDate.length) {
      return;
    }

    setFilter({
      ...filter,
      from_expriry_date: startOfDay(newDate[0].startDate),
      to_expriry_date: endOfDay(newDate[0].endDate),
    });
  };

  const today = startOfDay(new Date());
  const fromDate = filter.from_expriry_date as Date;
  const toDate = filter.to_expriry_date as Date;
  const isFromEqualsTo = isSameDay(fromDate, toDate);

  const maxDayWithMqttLog = endOfDay(
    addMonths(firstSelectedDay && isBefore(firstSelectedDay, today) ? firstSelectedDay : today, 3),
  );

  const minDayWithMqttLog = startOfDay(subMonths(maxDayWithMqttLog, 6));

  //Set the first date selected by user. If mqtt export is enabled, retain this first selected date
  useEffect(() => {
    if (isFromEqualsTo) {
      setFirstSelectedDay(fromDate);
    }

    if (
      firstSelectedDay &&
      !isFromEqualsTo &&
      ![startOfDay(fromDate).toISOString(), startOfDay(toDate).toISOString()].includes(
        startOfDay(firstSelectedDay).toISOString(),
      )
    ) {
      setFirstSelectedDay(null);
    }
  }, [filter]);

  //When mqtt export is enabled, check if the range is exceeding 3 months. If it does, update the range in a number of scenarios
  useEffect(() => {
    if (filter.mqtt_log_included) {
      if (isBefore(fromDate, minDayWithMqttLog)) {
        setFilter({
          ...filter,
          from_expriry_date: minDayWithMqttLog,
        });
      }

      if (isBefore(toDate, minDayWithMqttLog)) {
        setFilter({
          ...filter,
          from_expriry_date: minDayWithMqttLog,
          to_expriry_date: firstSelectedDay || endOfDay(today),
        });
      }

      if (isBefore(maxDayWithMqttLog, toDate)) {
        setFilter({
          ...filter,
          to_expriry_date: maxDayWithMqttLog,
        });
      }
    }
  }, [filter.mqtt_log_included]);

  const onSelectMqttCheckbox = () => {
    setFilter({
      ...filter,
      mqtt_log_included: !filter.mqtt_log_included,
    });
  };

  const handleExport = () => {
    if (onExport) {
      onExport(filter);
    }
  };

  const onClickCancel = async () => {
    setFilter(INITIAL_FILTER_VALUE);
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalDatePicker>
      <DateRangePicker
        minDate={filter.mqtt_log_included ? minDayWithMqttLog : undefined}
        maxDate={
          filter.mqtt_log_included && isBefore(maxDayWithMqttLog, today)
            ? maxDayWithMqttLog
            : undefined
        }
        onChange={(item) => {
          onChangeDate([item.selection as any]);
        }}
        showPreview={true}
        months={2}
        staticRanges={[...createStaticRanges(staticDateRanges(filter.mqtt_log_included) as any)]}
        ranges={[
          {
            endDate: filter.to_expriry_date ?? new Date(),
            startDate: filter.from_expriry_date ?? new Date(),
            key: 'selection',
          },
        ]}
        direction="horizontal"
        rangeColors={['var(--primary)']}
        footerContent={
          <FooterContainer>
            <CheckboxWrapper>
              <CheckboxDefault
                onChange={onSelectMqttCheckbox}
                checked={filter.mqtt_log_included || false}
                label={
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <Typography variant="body-3">
                      Include MQTT log (Data retrieval limited to 3 months only)
                    </Typography>
                  </div>
                }
              />
            </CheckboxWrapper>
            <ExportSectionWrapper>
              <ButtonDefault
                theme="btn-light"
                onClick={onClickCancel}
                style={{ width: 160, marginRight: 12 }}
                className="d-flex align-items-center justify-content-center"
              >
                Cancel
              </ButtonDefault>
              <ButtonDefault
                onClick={handleExport}
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                style={{
                  width: 160,
                  borderRadius: 8,
                }}
              >
                Export
              </ButtonDefault>
            </ExportSectionWrapper>
          </FooterContainer>
        }
      />
    </ModalDatePicker>
  );
};
