/* eslint-disable react/jsx-no-target-blank */
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Badge } from 'app/components/Badge';
import Typography from 'app/components/Typography';

import { useAuth } from 'app/modules/auth';
import { getHeartBeat, getTotalUnreadNoti } from 'app/modules/notifications/core/request';
import { useNotificationStore } from 'app/modules/notifications/store';
import { getOfflineCount, useHeartBeatStore } from 'app/modules/system-settings/locations/store';

import { useWindowSize } from 'app/helpers/screen';
import { useBoolean } from 'app/hooks';
import { usePolicyInUser } from 'app/hooks/policy';

import { PATH } from 'app/constants/path';

import { ReactComponent as DangerIcon } from 'app/assets/icons/icon-danger.svg';

import { useLayout } from '../../core';
import { BANNER_HEIGHT, useCheckDashboardLocation } from '../header/ForecastBanner';
import { Topbar } from '../header/Topbar';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from 'theme/helpers';

const PointMenu = styled.div`
  width: 4px;
  height: 4px;
  background: var(--gray-700);
  border-radius: 50%;
  margin-right: 12px;
`;
const MenuSub = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 6px;
  margin-bottom: 4px;
  ${({ active }) =>
    active
      ? `  background: var(--primary-2);
    p {
      color: var(--primary);
      font-weight: 600;
    };.point {
      background: var(--primary);
    }`
      : ''}

  :hover {
    background: var(--primary-2);
    p {
      color: var(--primary);
      font-weight: 600;
    }
    .point {
      background: var(--primary);
    }
  }
`;
const GroupTitle = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-left: 12px;
`;

const ImageLogo = styled.img`
  height: 36px;
`;

type MenuItem = {
  title: string;
  path: string;
  icon?: string;
  pathActive?: string[];
};

const MAC_AIR_SCREEN = 900;
export const ASIDE_MENU_WIDTH = 244;

const AsideDefault: FC = () => {
  const location = useLocation();
  const checkScreen = useWindowSize();
  const { config, classes } = useLayout();
  const { currentUser } = useAuth();
  const permission = usePolicyInUser(currentUser?.policy ?? []);

  const refreshTimestamp = useNotificationStore((s) => s.state.refreshTimestamp);
  const unReadCount = useNotificationStore((s) => s.state.unReadCount);
  const { isDashboardPage } = useCheckDashboardLocation();

  const { offlineWard, offlineRack } = useHeartBeatStore(getOfflineCount);

  const intervalRef = useRef<any>();

  const [menu, setMenu] = useState<{ [key: string]: MenuItem[] }>({});
  const isFirstMenu = useBoolean(false);

  useEffect(() => {
    if (permission.notification) {
      getTotalUnreadNoti();
      intervalRef.current = setInterval(getTotalUnreadNoti, 10000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [refreshTimestamp]);

  useEffect(() => {
    if (permission.rack || permission.settings) {
      getHeartBeat();
      intervalRef.current = setInterval(getHeartBeat, 60000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!permission || isFirstMenu.value) {
      return;
    }
    const newMenu: {
      [key: string]: MenuItem[];
    } = {
      OVERVIEW: [],
      INVENTORY: [],
      'SYSTEM SETTINGS': [],
    };
    if (permission.dashboard) {
      newMenu['OVERVIEW'].push({
        title: 'Dashboard',
        path: PATH.DASHBOARD,
      });
    }
    if (permission.report) {
      newMenu['OVERVIEW'].push({
        title: 'Report',
        path: PATH.REPORT,
        pathActive: [PATH.REPORT, PATH.REPORT_DEMAND_PROJECTION_DETAIL],
      });
    }
    if (permission.notification) {
      newMenu['OVERVIEW'].push({
        title: 'Notifications',
        path: PATH.NOTIFICATIONS,
      });
    }

    if (permission.item) {
      newMenu['INVENTORY'].push({
        title: 'Items',
        path: PATH.ITEMS,
      });

      newMenu['SYSTEM SETTINGS'].push({
        title: 'Devices',
        path: PATH.SYSTEM_SETTING_DEVICE_TABLET,
        pathActive: [PATH.SYSTEM_SETTING_DEVICE_TABLET, PATH.SYSTEM_SETTING_DEVICE_GATEWAY],
      });
    }
    if (permission.rack) {
      newMenu['INVENTORY'].push({
        title: 'Racks',
        path: PATH.RACKS,
      });
    }
    if (permission.item) {
      newMenu['INVENTORY'].push({
        title: 'Stock Run',
        path: PATH.INVENTORY_STOCK_RUN,
        pathActive: [PATH.INVENTORY_STOCK_RUN, PATH.STOCK_RUN_DEMAND_PROJECTION_DETAIL],
      });
    }
    if (permission.settings) {
      newMenu['SYSTEM SETTINGS'].push({
        title: 'Locations',
        path: PATH.WARDS,
        pathActive: [PATH.WARDS, PATH.ROOMS, PATH.BEDS],
      });
    }
    if (permission.userManagement || permission.userMMD || permission.userNurse) {
      newMenu['SYSTEM SETTINGS'].push({
        title: 'User accounts',
        path: permission.userManagement ? PATH.WEB : PATH.TABLET,
        pathActive: [PATH.WEB, PATH.TABLET],
      });
    }
    if (permission.settings) {
      newMenu['SYSTEM SETTINGS'].push({
        title: 'User roles',
        path: PATH.USERS_ROLE_WEB,
        pathActive: [PATH.USERS_ROLE_WEB, PATH.USERS_ROLE_TABLET],
      });
      newMenu['SYSTEM SETTINGS'].push({
        title: 'View permission',
        path: PATH.PERMISSION,
      });
      newMenu['SYSTEM SETTINGS'].push({
        title: 'Company information',
        path: PATH.COMPANY_INFORMATION,
      });
      newMenu['SYSTEM SETTINGS'].push({
        title: 'Configuration settings',
        path: PATH.CONFIGURATIONS,
        pathActive: [
          PATH.AUTO_LOGOUT_TIME,
          PATH.ITEM_UNIT,
          PATH.CONFIGURATION_STOCK_RUN,
          PATH.CONFIGURATION_WORK_SHIFT,
          PATH.CONFIGURATION_STOCK_VALUES,
        ],
      });
      newMenu['SYSTEM SETTINGS'].push({
        title: 'W0 settings',
        path: PATH.ZERO_WEIGHT_SETTING,
      });
    }
    setMenu(newMenu);
    isFirstMenu.setValue(true);
  }, [permission]);
  const { aside } = config;

  const isShowDangerIcon = (title: string) => {
    if (title === 'Locations') {
      return offlineWard > 0;
    }
    if (title === 'Racks') {
      return offlineRack > 0;
    }
    return false;
  };

  return (
    <div
      id="kt_aside"
      className={clsx('aside', classes.aside.join(' '), {
        'd-none': !aside.display,
      })}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '225px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggle"
    >
      <div
        id="kt_aside_menu_wrapper"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        // data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_header"
        data-kt-scroll-wrappers="#kt_aside"
        style={{
          paddingTop: 32,
          paddingLeft: 28,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'var(--white)',
          width: ASIDE_MENU_WIDTH,
          boxSizing: 'border-box',
          height: `calc(100vh${isDashboardPage ? ' - ' + BANNER_HEIGHT + 'px' : ''})`,
        }}
      >
        <div style={{ paddingRight: 28 }}>
          <Link to="/">
            <ImageLogo alt="Logo" src={toAbsoluteUrl('/media/logos/logo-sims-small.svg')} />
          </Link>
        </div>
        <div
          className="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100"
          id="#kt_aside_menu"
          data-kt-menu="true"
          style={{ flexGrow: 1, overflow: 'auto', paddingRight: 28 }}
        >
          {Object.entries(menu).map(([groupTitle, menuItems]) => (
            <Fragment key={groupTitle}>
              {groupTitle && Boolean(menuItems.length) && (
                <GroupTitle>
                  <Typography variant="body-8" color="neutral-6" className="text-uppercase">
                    {groupTitle}
                  </Typography>
                </GroupTitle>
              )}
              {menuItems.map((item, i) => {
                const isPathActive =
                  item.pathActive && item.pathActive.length
                    ? Boolean(item.pathActive.find((el) => location.pathname.startsWith(el)))
                    : location.pathname.startsWith(item.path);
                return (
                  <MenuSub active={isPathActive} key={i}>
                    <NavLink
                      to={item.path}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: checkScreen.height <= MAC_AIR_SCREEN ? 30 : 38,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.icon ? (
                          <span className="menu-icon" data-kt-element="icon">
                            <KTSVG path={'/media/icons' + item.icon} className="svg-icon-3" />
                          </span>
                        ) : null}
                        <PointMenu className="point"></PointMenu>
                        <Typography variant="body-3">{item.title}</Typography>
                      </div>

                      {item.title === 'Notifications' && unReadCount > 0 && (
                        <Badge value={unReadCount} />
                      )}
                      {isShowDangerIcon(item.title) && (
                        <DangerIcon width={20} height={20} style={{ marginRight: 12 }} />
                      )}
                    </NavLink>
                  </MenuSub>
                );
              })}
            </Fragment>
          ))}
        </div>
        <Topbar />
      </div>
    </div>
  );
};

export { AsideDefault };
