import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import { Ward, WardRequest, WardsQueryResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const WARD_URL = `${API_URL}/api/wards`;

const getWards = (query: string): Promise<WardsQueryResponse> => {
  return axios.get(`${WARD_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Ward>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const getWard = (idWard: ID): Promise<Ward | undefined> => {
  return axios
    .get(`${WARD_URL}/${idWard}`)
    .then((response: AxiosResponse<Response<Ward>>) => response.data)
    .then((response: Response<Ward>) => response.data);
};

const createWard = (request: WardRequest): Promise<Ward | undefined> => {
  return axios
    .post(WARD_URL, trimObject(request))
    .then((response: AxiosResponse<Response<Ward>>) => response.data)
    .then((response: Response<Ward>) => response.data);
};

const updateWard = (idWard: ID, request: WardRequest): Promise<Ward | undefined> => {
  return axios
    .patch(`${WARD_URL}/${idWard}`, trimObject(request))
    .then((response: AxiosResponse<Response<Ward>>) => response.data)
    .then((response: Response<Ward>) => response.data);
};

const deleteWard = async (idWard: ID): Promise<void> => {
  try {
    await axios.delete(`${WARD_URL}/${idWard}`);
  } catch (error) {
    console.log(`Error deleting ward ID ${idWard}`);
    throw error;
  }
};

export { getWards, getWard, createWard, updateWard, deleteWard };
