import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import RootContentPage from 'app/components/layout/RootContentPage';

import { usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { getHistory } from '../racks/core/requests';
import InformationBinCard from './components/InformationBinCard';
import { historyBinColumns } from './core/columns';
import { getBin } from './core/requests';
import { Bin } from './core/type';

const BinHistory = () => {
  const params = useParams();
  const goBack = usePageGoBack(PATH.RACKS);

  const [bin, setBin] = useState<Bin | null>(null);
  const isLoading = useBoolean(false);

  useEffect(() => {
    if (!params?.id) {
      return;
    }

    getDataRack(Number(params.id));
  }, [params.id]);

  const getDataRack = async (binId: number) => {
    try {
      isLoading.setValue(true);
      const dataRes = await getBin(binId);
      if (dataRes) {
        setBin(dataRes);
        return;
      }
      goBack();
    } catch (error) {
      goBack();
    } finally {
      isLoading.setValue(false);
    }
  };
  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };

  if (!bin) {
    return <div />;
  }
  return (
    <RootContentPage
      title=""
      header={
        <InformationBinCard
          binName={bin.name}
          rackId={bin.warehouse ? bin.warehouse.id : ''}
          binColor="neutral-10"
          item={bin.inventoryLocation?.inventory}
        />
      }
    >
      <TableComponent
        getList={getHistory}
        nameQuery={QUERIES.HISTORY_BIN_LIST}
        originColumns={historyBinColumns()}
        onClickBodyTable={onClickBodyTable}
        paramId={bin.warehouse ? bin.warehouse.id : ''}
        moreQuery={`&bin=${bin.id}`}
        placeholderSearch="Search with item, SKU"
        defaultState={{
          limit: 10,
          page: 1,
          orderBy: 'expriryDate',
          orderDirection: 'DESC',
        }}
        isBorder={false}
        isRowClickable={false}
      />
    </RootContentPage>
  );
};
export default BinHistory;
