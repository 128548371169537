import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  requiredEpisode: boolean;
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
}

const initState: State = {
  requiredEpisode: true,
};

export const useRackTestStore = create<Store>()(
  immer((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);
