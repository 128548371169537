import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { format, getYear } from 'date-fns';

import { ReactComponent as Arrow } from 'app/assets/icons/icon-redirect.svg';

const MonthNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .reverse {
    transform: scaleX(-1);
  }
  user-select: none;
`;

const StyledArrow = styled(Arrow)`
  width: 38px;
  height: 38px;
  cursor: pointer;
`;

interface Props {
  date: Date;
  onChange: (type: 'prev' | 'next') => void;
}

const CalendarNavigator: React.FC<Props> = ({ date, onChange }) => {
  return (
    <MonthNavigation>
      <StyledArrow onClick={() => onChange('prev')} style={{}} />
      <Typography variant="title-1" style={{ padding: '0 8px' }}>
        {format(date, 'LLL')} {getYear(date)}
      </Typography>
      <StyledArrow className="reverse" onClick={() => onChange('next')} />
    </MonthNavigation>
  );
};

export { CalendarNavigator };
