import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { PATH } from 'app/constants/path';

import { deleteSecurityGroups, getSecurityGroups } from '../security-groups/core/requests';
import { SecurityGroup } from '../security-groups/core/type';
import { usersRoleColumns } from './core/columns';
import { FormUsersRole } from './modal/FormUsersRole';

const UsersRoleTabletManagement = () => {
  const navigate = useNavigate();
  const renderModalForm = (originalData: any, _typeModal: TYPE_MODAL) => {
    return <FormUsersRole usersRole={originalData} loginType={LOGIN_TYPE.TABLET} />;
  };

  const onClickBodyTable = (row: Row) => () => {
    const newUsersRole = row.original as SecurityGroup;
    if (newUsersRole.id) {
      navigate(PATH.USERS_ROLE_TABLET_DETAIL.replace(':id', String(newUsersRole.id)));
    }
  };

  return (
    <TableComponent
      originColumns={usersRoleColumns()}
      nameQuery={QUERIES.USERS_ROLE_TABLET_LIST}
      getList={getSecurityGroups}
      ModalForm={renderModalForm}
      deleteById={deleteSecurityGroups}
      moreQuery={`&type=2`}
      titleModal="role"
      contentModal="Deleting this role will also remove this role from all the accounts."
      onClickBodyTable={onClickBodyTable}
      placeholderSearch="Search with role"
      isBorder={false}
      spacing="lg"
    />
  );
};

export default UsersRoleTabletManagement;
