import { ID, Response } from 'app/components/Table/core/types';

import { Customer } from 'app/modules/system-settings/company-information/core/type';

export type Ward = {
  id: ID;
  name: string;
  customer?: Customer;
  countChildren?: number;
  createdAt: string;
  updatedAt: string;
  item?: number;
};

export type WardsQueryResponse = Response<{
  items: Array<Ward>;
  total: number;
}>;

export type WardRequest = {
  name: string;
  customerId: string;
  bedQuantity?: number;
};

export const initialWard: Ward = {
  id: '',
  name: '',
  createdAt: '',
  updatedAt: '',
};

export enum InventoryConnectionStatus {
  offline,
  online,
  notReady,
}

export enum InventoryConnectionType {
  ward,
  rack_top,
  rack_bottom,
}

export type ConnectStatusItem = {
  ward_id: number;
  rack_id?: number;
  status: InventoryConnectionStatus;
  type: InventoryConnectionType;
};

export interface InventoryConnectionSummary {
  connectStatus: ConnectStatusItem[];
}
