import { PropsWithChildren } from 'react';
import { Column, HeaderProps } from 'react-table';

import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { formatCustomDate } from 'app/helpers';
import { compact } from 'lodash';

import StatusUserCard from '../components/StatusUserCard';
import { STATUS_USER } from './constants';
import { User } from './type';

export const usersColumns = (type: LOGIN_TYPE): ReadonlyArray<Column<User>> => {
  const actions: ActionTable[] = compact([
    {
      name: 'Edit',
      type: TYPE_MODAL.EDIT,
    },
    type === LOGIN_TYPE.TABLET && {
      name: 'Reset password',
      type: TYPE_MODAL.RESET_PASSWORD,
    },
  ]);

  const RFIDColumn = {
    id: 'provider',
    accessor: 'provider',
    Header: (props) => <CustomHeader tableProps={props} title="RFID" />,
    width: '15%',
  } as Column<User>;

  const statusAndActionColumn = [
    {
      id: 'status',
      accessor: 'status',
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => <StatusUserCard status={props.data[props.row.index].status} />,
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props: PropsWithChildren<HeaderProps<any>>) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={
            props.data[props.row.index].status === STATUS_USER.ACTIVATE
              ? actions.concat({
                  name: 'Inactivate',
                  type: TYPE_MODAL.STATUS,
                  titleModal: 'Inactivate account',
                } as ActionTable)
              : [
                  {
                    name: 'Activate',
                    type: TYPE_MODAL.STATUS,
                    titleModal: 'Activate account',
                  } as ActionTable,
                ]
          }
        />
      ),
    },
  ] as Column<User>[];

  const userColumns = [
    {
      id: 'createdAt',
      accessor: 'createdAt',
      Header: (props) => <CustomHeader tableProps={props} title="Created at" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].createdAt)}
        </Typography>
      ),
    },
    {
      id: 'username',
      accessor: 'username',
      Header: (props) => (
        <CustomHeader
          tableProps={props}
          title={type === LOGIN_TYPE.TABLET ? 'Username' : 'Email'}
        />
      ),
      Cell: ({ ...props }) => <p className="mb-0">{props.data[props.row.index].username}</p>,
      width: '25%',
    },
    {
      id: 'firstName',
      Header: (props) => <CustomHeader tableProps={props} title="First name" />,
      accessor: 'firstName',
      width: '15%',
    },
    {
      id: 'lastName',
      accessor: 'lastName',
      Header: (props) => <CustomHeader tableProps={props} title="Last name" />,
      width: '15%',
    },
    {
      id: 'role',
      accessor: 'role',
      Header: (props) => <CustomHeader tableProps={props} title="Role" />,
      Cell: ({ ...props }) => (
        <div
          style={{
            minWidth: 200,
          }}
        >
          <p className="mb-0">{props.data[props.row.index].role?.name || ''}</p>
        </div>
      ),
      width: type === LOGIN_TYPE.WEB ? '20%' : '15%',
    },
  ] as Column<User>[];

  if (type === LOGIN_TYPE.TABLET) {
    return userColumns.concat(RFIDColumn, statusAndActionColumn);
  }
  return userColumns.concat(statusAndActionColumn);
};
