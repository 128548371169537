import Tippy, { tippy, useSingleton } from '@tippyjs/react';
import { cloneElement, useEffect } from 'react';
import { Calendar, Event, EventPropGetter, Views, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';

import {
  LEVEL_SIZE,
  STOCK_LEVEL_DESCRIPTION,
  hasStockLevelAlert,
} from 'app/components/Status/StockLevelAlertIcon';
import { ID } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { useNavigateWithSendPath } from 'app/helpers/utils';
import {
  addMonths,
  format,
  getDay,
  getWeeksInMonth,
  parse,
  setDate,
  startOfDay,
  startOfWeek,
  subMonths,
} from 'date-fns';

import { PATH } from 'app/constants/path';

import LowStockIcon from 'app/assets/icons/stock-level/alert-gray.svg';
import alarmIcon from 'app/assets/icons/stock-level/alert-red.svg';
import restockIcon from 'app/assets/icons/stock-level/alert-yellow.svg';

import { StatusStockUp, StockLevel } from '../../core/enum';
import { getCalendarDateValue, onCellDayClick } from './helpers';

const locales = {
  'en-US': require('date-fns'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Popup style customize in app/styes/index.scss since it don't nested in this component
const CalendarWrapper = styled.div`
  .rbc-month-view {
    border-radius: 8px;
  }
  .rbc-month-header {
    height: 30px;
  }
  .rbc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral-7);
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
  .rbc-row-content {
    padding: 6px 0px;
    pointer-events: none;
  }
  .rbc-date-cell {
    text-align: left;
    padding: 0px 12px;
    padding-bottom: 6px;
  }
  .rbc-off-range .rbc-button-link {
    color: var(--neutral-6);
  }
  .rbc-button-link {
    color: var(--neutral-10);
    font-family: 'Archivo', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .rbc-off-range-bg {
    background: #00000000;
  }
  .rbc-row-segment {
    height: 34px;
    padding: 0px 12px;
    padding-bottom: 6px;
  }
  .rbc-event {
    pointer-events: auto;
    border-radius: 6px;
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
  }
  .rbc-selected {
    border: none;
  }
  .rbc-show-more {
    pointer-events: auto;
    color: var(--neutral-7) !important;
    font-size: 13px;
    font-weight: 400;
    background-color: transparent;
  }
  .rbc-header,
  .rbc-day-bg {
    border-color: var(--neutral-3);
    :not([disabled]) {
      cursor: pointer;
      :hover {
        background-color: var(--neutral-2-5);
      }
    }
  }
  .rbc-month-row:last-child {
    .rbc-day-bg:first-child {
      border-bottom-left-radius: 8px;
    }
    .rbc-day-bg:last-child {
      border-bottom-right-radius: 8px;
    }
  }
  .rbc-date-cell {
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;

      .rbc-button-link {
        text-decoration: line-through;
        color: var(--neutral-4);
      }
    }
  }
  .rbc-today {
    background: none;
  }
  .rbc-date-cell.rbc-now.rbc-current .rbc-button-link {
    color: var(--primary);
  }

  .rbc-button-link.rbc-show-more {
    border-radius: 6px;
    padding: 3px 10px;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: var(--neutral-2-5);
    }
  }
`;

const STOCK_LEVEL_IMAGES = [LowStockIcon, restockIcon, alarmIcon];
const CALENDAR_HEADER_HEIGHT = 30;
const CALENDAR_DATE_CELL_HEIGHT = 172;
const PADDING = 28;

export interface CustomEvent extends Event {
  tagColor: string;
  status: StatusStockUp;
  stockLevel: StockLevel;
  id: ID;
}

interface Props {
  date: Date;
  events: CustomEvent[] | null;
  holidays?: Date[];
  forecastingWardLoaded: boolean;
  onSimInputClick: (startDate?: number) => void;
}

export const StockRunCalendar: React.FC<Props> = ({ date, events, holidays, onSimInputClick }) => {
  const [source, target] = useSingleton();

  const navigate = useNavigateWithSendPath();

  useEffect(() => {
    setTimeout(() => {
      let cellEls = document.querySelectorAll('.rbc-date-cell:not([value])');
      if (cellEls.length === 0) {
        cellEls = document.querySelectorAll('.rbc-date-cell:not(.hol-ched)');
      }

      cellEls.forEach((el, elIndex) => {
        const day = Number(el.children?.[0].innerHTML || '1');
        const tempCurrentDate = day < elIndex - 6 ? addMonths(date, 1) : date;
        const currentDate = startOfDay(
          setDate(day > elIndex + 6 ? subMonths(date, 1) : tempCurrentDate, day),
        );
        el.setAttribute('value', getCalendarDateValue(currentDate));

        // Append anchor for View more Tippy popup
        if (!el.querySelector('.rbc-event-anchor')) {
          const anchorEl = document.createElement('div');
          anchorEl.className = 'rbc-event-anchor';
          el.appendChild(anchorEl);
        }

        if (holidays) {
          el.classList.add('hol-ched');
          if (holidays.findIndex((hol) => currentDate.getTime() === hol.getTime()) !== -1) {
            el.setAttribute('disabled', 'true');
            return;
          }
        }
        el.removeAttribute('disabled');
      });
    }, 200);
  }, [holidays, date.getTime()]);

  const eventStyleGetter: EventPropGetter<CustomEvent> = (event) => ({
    className: `rbc-event-tag-${event.status} ${
      hasStockLevelAlert(event.status, event.stockLevel)
        ? `stock-level-${event.stockLevel}`
        : 'normal'
    }`,
  });

  const onSelectEvent = (event: CustomEvent) => {
    navigate(PATH.INVENTORY_STOCK_RUN_DETAIL.replace(':id', String(event.id)));
  };

  return (
    <CalendarWrapper>
      <Tippy
        singleton={source}
        theme="dark"
        moveTransition="transform 0.2s ease-out"
        delay={200}
        arrow={false}
      />
      <div
        style={{
          padding: '0px 28px 20px 28px',
          display: 'flex',
          flexDirection: 'row',
          width: 'fit-content',
        }}
      >
        {Object.values(STOCK_LEVEL_DESCRIPTION).map((description, index) => (
          <div className="d-flex flex-row align-items-center" key={description}>
            <img
              src={STOCK_LEVEL_IMAGES[index]}
              alt={description}
              style={{ width: `${Object.values(LEVEL_SIZE)[index]}px` }}
            />
            <Typography
              fontWeight={400}
              lineHeight={23}
              fontSize={14}
              color="neutral-6"
              className="me-3 ms-1 text-nowrap"
            >
              {description}
            </Typography>
          </div>
        ))}
      </div>
      <Calendar
        localizer={localizer}
        events={events || []}
        startAccessor="start"
        endAccessor="end"
        view={Views.MONTH}
        popup={false}
        doShowMoreDrillDown={false}
        toolbar={false}
        style={{
          width: '100%',
          height:
            CALENDAR_HEADER_HEIGHT +
            CALENDAR_DATE_CELL_HEIGHT * getWeeksInMonth(date) +
            PADDING +
            1,
          padding: `0 ${PADDING}px ${PADDING}px`,
        }}
        onSelectEvent={onSelectEvent}
        date={date}
        eventPropGetter={eventStyleGetter}
        formats={{
          weekdayFormat: (wd, culture, loc) => (loc ?? localizer).format(wd, 'EEE', culture),
        }}
        onShowMore={(evts, dt) => {
          const dateEl = document.querySelector(
            `.rbc-date-cell[value='${getCalendarDateValue(dt)}'] .rbc-event-anchor`,
          );
          if (!dateEl) {
            return;
          }

          const dateNum = new Date(dt).getDate();
          const content = document.createElement('div');
          const dateTitle = document.createElement('div');

          dateTitle.className = 'rbc-popup-title';
          dateTitle.innerHTML = String(dateNum < 10 ? `0${dateNum}` : dateNum);

          content.appendChild(dateTitle);
          evts.forEach((ev) => {
            const eventEl = document.createElement('div');
            eventEl.className = `rbc-event view-more rbc-event-tag-${ev.status} ${
              hasStockLevelAlert(ev.status, ev.stockLevel)
                ? `stock-level-${ev.stockLevel}`
                : 'normal'
            } d-flex justity-items-center`;

            const eventContentEl = document.createElement('div');
            eventContentEl.className = 'rbc-event-content';
            eventContentEl.title = String(ev.title) || '';
            eventContentEl.innerHTML = String(ev.title) || '';
            eventEl.appendChild(eventContentEl);

            eventEl.onclick = () => {
              onSelectEvent(ev);
              tippyInstance.unmount();
            };
            content.appendChild(eventEl);
          });
          const isSaturday = dt.getDay() === 6;
          const tippyInstance = tippy(dateEl, {
            content,
            placement: isSaturday ? 'bottom-end' : 'bottom-start',
            arrow: false,
            interactive: true,
            theme: 'light',
            appendTo: () => document.body,
            trigger: 'click',
            offset: [isSaturday ? 6 : -4, -22],
            onClickOutside: () => tippyInstance.destroy(),
          });
          tippyInstance.show();
        }}
        components={{
          dateCellWrapper: ({ children, value }) => {
            const isHoliday = holidays
              ? holidays.findIndex((hol) => value.getTime() === hol.getTime()) !== -1
              : false;
            const childrenEl = cloneElement(children, {
              value: getCalendarDateValue(value),
              disabled: isHoliday,
              onClick: isHoliday
                ? undefined
                : onCellDayClick(navigate, value.getTime(), onSimInputClick),
            });

            if (isHoliday || events === null) {
              return childrenEl;
            }

            return (
              <Tippy content={'Add stock run'} theme="dark" singleton={target}>
                {childrenEl}
              </Tippy>
            );
          },
        }}
      />
    </CalendarWrapper>
  );
};
