import { useEffect, useState } from 'react';

import { throttle } from 'lodash';

export const useWindowSize = () => {
  const [size, setSize] = useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const updateSize = throttle(
    () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    },
    500,
    {
      leading: false,
      trailing: true,
    },
  );

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('orientationchange', updateSize);
    };
    // eslint-disable-next-line
  }, []);

  return size;
};
