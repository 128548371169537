import styled from 'styled-components';

import StatusDefault from 'app/components/Status/StatusDefault';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { Device } from '../core/type';

const Main = styled.div`
  display: flex;
  align-items: center;
  :hover {
    span {
      text-decoration: underline;
    }
  }
`;
interface Props {
  device: Device;
}
const StatusDeviceCard: React.FC<Props> = ({ device }) => {
  const { setItemForUpdate } = useListView();
  const onConnectTablet = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setItemForUpdate({
      isView: false,
      originalData: device,
      titleModal: 'Connect tablet',
      type: TYPE_MODAL.CONNECT_TABLET,
    });
  };
  return (
    <Main>
      <StatusDefault
        styleMain={{
          width: device.linked ? 86 : 108,
        }}
        isActive={device.linked}
        label={device.linked ? 'Connected' : 'Not connected'}
      />
      {!device.linked && (
        <span
          onClick={onConnectTablet}
          style={{
            marginLeft: 12,
            whiteSpace: 'nowrap',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '23px',
            color: 'var(--primary)',
          }}
        >
          Connect tablet
        </span>
      )}
    </Main>
  );
};
export default StatusDeviceCard;
