import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { getEnumKeyByValue } from 'app/helpers';

import { ActionReport } from '../core/enum';

const MainStatus = styled.div<{ height?: number; bg?: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 14px;
  border-radius: 4px;
  ${({ height, bg }) => `height:${height}px;background-color:${bg}`}
`;

interface Props {
  status: ActionReport;
  height?: number;
  styleLabel?: React.CSSProperties;
}

const bgAction = {
  [ActionReport['Pick up']]: 'var(--danger-light-1-5)',
  [ActionReport['Stock up']]: 'var(--success-light-1-5)',
  [ActionReport['Manual edit']]: 'var(--neutral-3)',
  [ActionReport['Auto edit']]: 'var(--primary-3)',
};
const colorAction = {
  [ActionReport['Pick up']]: 'danger',
  [ActionReport['Stock up']]: 'success',
  [ActionReport['Manual edit']]: 'neutral-7',
  [ActionReport['Auto edit']]: 'primary',
};

export const ActionReportCard: React.FC<Props> = ({ status, height = 28, styleLabel }) => {
  return (
    <MainStatus height={height} bg={bgAction[status]}>
      <Typography
        variant="title-5"
        color={colorAction[status]}
        style={{
          whiteSpace: 'nowrap',
          ...styleLabel,
        }}
      >
        {getEnumKeyByValue(ActionReport, status)}
      </Typography>
    </MainStatus>
  );
};
