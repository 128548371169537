import { API_URL } from 'app/constants/environments';

import {
  DashboardSummary,
  ItemsPickUpData,
  ParLevelData,
  PostedStockRunChartData,
  SessionChartData,
} from './types';
import axios, { AxiosResponse } from 'axios';

const DASHBOARD_SUMMARY_URL = `${API_URL}/api/dashboard`;
const CHART_STOCK_RUN_URL = `${DASHBOARD_SUMMARY_URL}/chart-stockrun`;
const CHART_SESSIONS_URL = `${DASHBOARD_SUMMARY_URL}/chart-sessions`;

export const DEFAULT_STOCK_CHART_DATA = {
  time: [],
  count: [],
};

export const getSummary = (query: string) =>
  axios.get(`${DASHBOARD_SUMMARY_URL}?${query}`).then(
    (
      res: AxiosResponse<{
        data: DashboardSummary;
      }>,
    ) => {
      return res.data.data;
    },
  );

export const getSessionChartData = (query: string) =>
  axios.get(`${CHART_SESSIONS_URL}?${query}`).then(
    (
      res: AxiosResponse<{
        data: SessionChartData;
      }>,
    ) => {
      return res.data.data;
    },
  );
// .catch(() => {
//   return DEFAULT_STOCK_CHART_DATA;
// });

export const getPostedStockRunChartData = () =>
  axios
    .get(`${CHART_STOCK_RUN_URL}`)
    .then(
      (
        res: AxiosResponse<{
          data: PostedStockRunChartData;
        }>,
      ) => {
        return res.data.data;
      },
    )
    .catch(() => {
      return DEFAULT_STOCK_CHART_DATA;
    });

export const getItemsPickUp = (fromDate: string, toDate: string) =>
  axios.get(`${DASHBOARD_SUMMARY_URL}/chart-pickup?from=${fromDate}&to=${toDate}`).then(
    (
      res: AxiosResponse<{
        data: ItemsPickUpData;
      }>,
    ) => {
      return res.data.data;
    },
  );

export const getParLevelData = (fromDate: string, toDate: string) =>
  axios
    .get(`${DASHBOARD_SUMMARY_URL}/chart-performance?from=${fromDate}&to=${toDate}`)
    .then(
      (
        res: AxiosResponse<{
          data: ParLevelData;
        }>,
      ) => {
        return res.data.data;
      },
    )
    .catch(() => {
      return {
        bottomParLevel: { items: [], values: [] },
        topParLevel: { items: [], values: [] },
      };
    });
