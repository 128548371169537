import { ID, Response } from 'app/components/Table/core/types';

import { API_URL } from 'app/constants/environments';

import { StatusBed } from './enum';
import { Bed, BedRequest, BedsQueryResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const BED_URL = `${API_URL}/api/beds`;

const getBeds = (query: string): Promise<BedsQueryResponse> => {
  return axios.get(`${BED_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Bed>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const createBed = (request: BedRequest): Promise<Bed | undefined> => {
  return axios
    .post(BED_URL, request)
    .then((response: AxiosResponse<Response<Bed>>) => response.data)
    .then((response: Response<Bed>) => response.data);
};

const updateBed = (idBed: ID, request: BedRequest): Promise<Bed | undefined> => {
  return axios
    .patch(`${BED_URL}/${idBed}`, request)
    .then((response: AxiosResponse<Response<Bed>>) => response.data)
    .then((response: Response<Bed>) => response.data);
};
const changeStatusBed = (
  idBed: ID,
  request: {
    activate: StatusBed;
  },
): Promise<Bed | undefined> => {
  return axios
    .patch(`${BED_URL}/${idBed}/activate`, request)
    .then((response: AxiosResponse<Response<Bed>>) => response.data)
    .then((response: Response<Bed>) => response.data);
};

const deleteBed = async (idBed: ID): Promise<void> => {
  try {
    await axios.delete(`${BED_URL}/${idBed}`);
  } catch (error) {
    console.log(`Error deleting bed ID ${idBed}`);
    throw error;
  }
};

const editBed = (idBed: ID, code: string, codeId: string): Promise<Bed | undefined> => {
  return axios
    .patch(`${BED_URL}/${idBed}`, {
      code,
      codeId,
    })
    .then((response: AxiosResponse<Response<Bed>>) => response.data)
    .then((response: Response<Bed>) => response.data);
};

export { getBeds, createBed, changeStatusBed, updateBed, deleteBed, editBed };
