import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import { NavigationDetail } from 'app/components/Navigation/core/types';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useAuth } from 'app/modules/auth';

import { replacePath } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { usePolicyInUser } from 'app/hooks/policy';

import { PATH } from 'app/constants/path';

import UserTabletManagement from './UserTabletManagement';
import UserWebManagementPage from './UserWebManagement';
import RoutePolicy from 'app/routing/RoutePolicy';

const UsersManagementPage = () => {
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);
  const [navigation, setNavigation] = useState<NavigationDetail[]>([]);
  const isFirstNavigation = useBoolean(false);

  useEffect(() => {
    if (!policy || isFirstNavigation.value) {
      return;
    }
    const newNavigation: NavigationDetail[] = [];
    if (policy.userManagement) {
      newNavigation.push({
        title: 'Desktop account',
        path: PATH.WEB,
      });
    }

    if (policy.userMMD || policy.userNurse || policy.userManagement) {
      newNavigation.push({
        title: 'Tablet account',
        path: PATH.TABLET,
      });
    }
    setNavigation(newNavigation);
    isFirstNavigation.setValue(true);
  }, [policy]);

  return (
    <Routes>
      <Route
        element={
          <React.Fragment>
            <RootContentPage title="User accounts">
              <NavigationDefault navigation={navigation} />
              <Outlet />
            </RootContentPage>
          </React.Fragment>
        }
      >
        <Route
          path={replacePath(PATH.WEB, PATH.USERS, '')}
          element={
            <RoutePolicy accept={policy.userManagement}>
              <UserWebManagementPage />
            </RoutePolicy>
          }
        />

        <Route
          path={replacePath(PATH.TABLET, PATH.USERS, '')}
          element={
            <RoutePolicy accept={policy.userManagement || policy.userMMD || policy.userNurse}>
              <UserTabletManagement />
            </RoutePolicy>
          }
        />
        <Route index element={<Navigate to={policy.userManagement ? PATH.WEB : PATH.TABLET} />} />
      </Route>
    </Routes>
  );
};

export default UsersManagementPage;
