import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Row } from 'react-table';
import styled from 'styled-components';

import FilterDefault from 'app/components/Filter/FilterDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { scrollToTop } from 'app/helpers';
import { usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { ExportButton } from './components/ExportButton';
import { FilterReport } from './components/FilterReport';
import { ForecastChart } from './components/ForecastChart';
import { ReportChart } from './components/ReportChart';
import { ShowInfoReportCard } from './components/ShowInfoReportCard';
import { reportDetailColumns } from './core/columns';
import { emptyFilterReportDetail } from './core/constants';
import { coverReportFilterToQuery } from './core/helpers';
import { getReportDetail, getReportDetailList } from './core/request';
import { FilterReportDetail, ReportDetail } from './core/type';
import { KTCard } from 'theme/helpers';

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  .card-toolbar {
    flex-direction: row-reverse;
    margin: 0;
  }
`;

const ReportDetailPage = () => {
  const divMasterRef: React.RefObject<HTMLDivElement> = useOutletContext();

  const goBack = usePageGoBack(PATH.REPORT);

  const params = useParams<any>();

  const openMenu = useBoolean(false);

  const [report, setReport] = useState<ReportDetail | null>(null);

  const [filter, setFilter] = useState<FilterReportDetail>(emptyFilterReportDetail);

  const isAutoFetch = useBoolean(false);
  const isLoading = useBoolean(false);

  useEffect(() => {
    if (!params.wardId || !params.itemId) {
      goBack();
      return;
    }
    getReportByWardAndItem();
  }, [params.wardId, params.itemId]);

  const getReportByWardAndItem = async () => {
    isLoading.setValue(true);
    try {
      const dataRes = await getReportDetail(
        stringifyRequestQuery({ page: 1, limit: 100 }),
        params.wardId,
        params.itemId,
      );
      setReport(dataRes ?? null);
      scrollToTop(divMasterRef);
    } catch (error) {
      console.log('error', error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const renderInfoReport = useMemo(
    () => (
      <>
        <ShowInfoReportCard report={report} />
        <KTCard contentStyle={{ padding: 28, marginTop: 24, height: 556 }}>
          <ReportChart report={report?.chart} ward={report?.ward?.name} />
        </KTCard>
        <KTCard contentStyle={{ padding: 28, marginTop: 24, height: 556 }}>
          <ForecastChart chartData={report?.chart?.forecast} />
        </KTCard>
      </>
    ),
    [report],
  );

  const onUpdateFilter = (newFilter: FilterReportDetail, newFecth: boolean) => {
    setFilter(newFilter);
    openMenu.setValue(false);
    isAutoFetch.setValue(newFecth);
  };

  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <Header>
            <IconRedirect
              style={{
                cursor: 'pointer',
              }}
              onClick={goBack}
            />
            <Typography variant="heading-6">{report?.name}</Typography>
          </Header>
          {renderInfoReport}
        </div>
      }
    >
      <Main>
        <Typography
          variant="title-1"
          lineHeight={32}
          style={{
            paddingLeft: 28,
            marginTop: 28,
            marginBottom: 16,
          }}
        >
          Detail
        </Typography>
        <TableComponent
          getList={getReportDetailList}
          paramId={params.wardId}
          originColumns={reportDetailColumns()}
          nameQuery={QUERIES.REPORT_LIST}
          placeholderSearch="Search with bin and user"
          isBorder={false}
          isRowClickable={false}
          onClickBodyTable={onClickBodyTable}
          customListHeader={
            <div className="d-flex align-items-center">
              <FilterDefault
                visible={openMenu.value}
                onClickFilter={(type) => openMenu.setValue(type === 'on')}
                isFilter={Boolean(coverReportFilterToQuery(filter))}
                content={
                  <FilterReport
                    openMenu={openMenu.value}
                    originFilter={filter}
                    onUpdateFilter={onUpdateFilter}
                  />
                }
              />
              <ExportButton wardId={params.wardId} itemId={params.itemId} actionFilter={filter} />
            </div>
          }
          defaultState={{
            page: 1,
            limit: 10,
            orderBy: 'expriryDate',
            orderDirection: 'DESC',
            param: params.itemId,
          }}
          styleHeader={{ padding: '0 28px 32px 28px' }}
          isAutoFetch={isAutoFetch.value}
          moreQuery={coverReportFilterToQuery(filter)}
          spacing="lg"
        />
        {isLoading.value ? <LoadingCard /> : null}
      </Main>
    </RootContentPage>
  );
};

export default ReportDetailPage;
