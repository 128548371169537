import { ID, Response } from 'app/components/Table/core/types';

import { Setting } from 'app/modules/system-settings/configuration/core/type';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { StatusStockUp, StockLevel } from '../../stock-run/core/enum';
import { QtyUnitsEnum, StockTypeEnum, WeightUnitsEnum } from './enum';

interface ItemInBin {
  qty: number;
  alarmValue: number;
  qtyBalance: number;
  restockValue: number;
  parValue: number;
  status: StatusStockUp;
  stockLevel: StockLevel;
}

export interface Item extends ItemInBin {
  id: ID;
  name: string;
  createdAt: string;
  updatedAt: string;
  skuId: string;
  skuSub: string;
  type: string;
  volumePerPackage?: number;
  info: string;
  weight: number;
  weightUnit: string;
  qtyUnit: Setting;
  stockType: string;
  hasBin: boolean;
  stockValue: { items: Ward; total: number };
  image: string;
  qty: number;
  status: number;
  hasStockRun: number;
  bins: string[];
}

export type StockValueSettingQueryResponse = Response<{
  items: Array<StockValueSetting>;
  total: number;
  dataItem: Item;
}>;

export type ItemsQueryResponse = Response<{
  items: Array<Item>;
  total: number;
}>;

export type StockValueSetting = {
  location: Ward;
  restockValue: number;
  alarmValue: number;
  parValue: number;
};
export type ItemRequest = {
  name: string;
  info: string;
  skuId: string;
  skuSub: string;
  qtyUnit: number;
  weight: number;
  weightUnit: number;
  volumePerPackage: number | null;
  image?: File | null;
  stockType: number;
  removeImage?: boolean;
};

export const initialItemRequest: ItemRequest = {
  name: '',
  info: '',
  qtyUnit: QtyUnitsEnum.Piece,
  skuId: '',
  skuSub: '',
  weight: 0,
  weightUnit: WeightUnitsEnum.g,
  volumePerPackage: 1,
  stockType: StockTypeEnum['Non-Chargeable'],
};

export interface StockType {
  title: string;
  value: StockTypeEnum;
}
export interface QtyUnit {
  title: string;
  value: QtyUnitsEnum;
}

export interface WeightUnit {
  title: string;
  value: WeightUnitsEnum;
}
