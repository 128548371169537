import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'app/assets/icons/stock-level/alert-default.svg';

import { StatusStockUp, StockLevel } from '../../modules/inventory/stock-run/core/enum';
import { TippyDefault } from '../Tippy';
import Typography from '../Typography';

export const LEVEL_COLOR = {
  [StockLevel.par]: 'var(--success-3)',
  [StockLevel.restock]: 'var(--warning)',
  [StockLevel.alarm]: 'var(--danger)',
};

const DEFAULT_SIZE = 24;

export const LEVEL_SIZE = {
  [StockLevel.par]: DEFAULT_SIZE,
  [StockLevel.restock]: 30,
  [StockLevel.alarm]: 36,
};

export const STOCK_LEVEL_DESCRIPTION = {
  [StockLevel.par]: 'Below Par level',
  [StockLevel.restock]: 'Below Restock level',
  [StockLevel.alarm]: 'Below Alarm level',
};

const DEFAULT_MARGIN_LEFT = 10;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getIconWidthOffset = (stockLevel: StockLevel) =>
  Math.round((LEVEL_SIZE['alarm'] - LEVEL_SIZE[stockLevel]) / 2);

export const hasStockLevelAlert = (status?: StatusStockUp, stockLevel?: StockLevel) =>
  status !== StatusStockUp.Completed && stockLevel && stockLevel !== StockLevel.normal;

interface Props {
  stockLevel?: StockLevel;
  status?: StatusStockUp;
  marginLeft?: number;
  isScaleSize?: boolean;
}

export const StockLevelAlertIcon: React.FC<Props> = ({
  status,
  stockLevel = StockLevel.normal,
  marginLeft = DEFAULT_MARGIN_LEFT + getIconWidthOffset(stockLevel),
  isScaleSize = true,
}) => {
  const iconSize = isScaleSize ? LEVEL_SIZE[stockLevel] : DEFAULT_SIZE;

  if (!hasStockLevelAlert(status, stockLevel)) {
    return null;
  }

  return (
    <div style={{ marginLeft, height: iconSize, width: iconSize }}>
      <TippyDefault
        arrow={true}
        placement="top"
        theme="dark"
        allowHTML
        content={
          <div className="d-flex flex-column align-items-center">
            <Typography variant="body-8" color="neutral-6">
              Stock health:
            </Typography>
            <div className="d-flex justify-center align-items-center">
              <InfoIcon className="me-1" color={LEVEL_COLOR[stockLevel]} />
              <Typography variant="title-4" color="white">
                {STOCK_LEVEL_DESCRIPTION[stockLevel]}
              </Typography>
            </div>
          </div>
        }
      >
        <Wrapper>
          <InfoIcon color={LEVEL_COLOR[stockLevel]} width={iconSize} height={iconSize} />
        </Wrapper>
      </TippyDefault>
    </div>
  );
};
