import { ID, QueryState, Response } from 'app/components/Table/core/types';

import { getTimezoneOffset } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';

import { API_URL } from 'app/constants/environments';

import { exportCsv, getFormatedDate } from './helpers';
import {
  ItemLineData,
  Report,
  ReportDetaiCSV,
  ReportDetail,
  ReportDetailList,
  ReportDetailQueryResponse,
  ReportV2,
  ReportsQueryResponse,
} from './type';
import axios, { AxiosResponse } from 'axios';

const REPORT_URL = `${API_URL}/api/report`;
const getReports = (query: string, idWard: ID): Promise<ReportsQueryResponse> => {
  return axios.get(`${REPORT_URL}/${idWard}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<ReportV2>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const getReportDetail = (
  query: string,
  wardId: ID,
  itemId: ID,
): Promise<ReportDetail | undefined> => {
  return axios
    .get(`${REPORT_URL}/${wardId}/items/${itemId}?${query}`)
    .then((response: AxiosResponse<Response<ReportDetail>>) => response.data)
    .then((response: Response<ReportDetail>) => response.data);
};

const getReportDetailList = (
  query: string,
  wardId: ID,
  itemId: ID,
): Promise<ReportDetailQueryResponse> => {
  return axios.get(`${REPORT_URL}/${wardId}/items/${itemId}/details?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<ReportDetailList>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const getQuantityBalanceReportCSV = (
  query: string,
  idWard: ID,
  callback?: () => void,
): Promise<void> => {
  return axios
    .get(`${REPORT_URL}/${idWard}/csv?${query}`)
    .then(
      (
        d: AxiosResponse<{
          data: {
            header: Array<{ name: string; key: keyof Report }>;
            items: Array<Report>;
          };
        }>,
      ) => {
        exportCsv(d.data.data.header, d.data.data.items, 'Quantity balance');
      },
    )
    .catch((error) => {
      getResponseError(error);
    })
    .finally(() => {
      if (callback) callback();
    });
};

const getReportDetailCSV = (
  query: string | QueryState,
  wardId: ID,
  itemId: ID,
  callback: () => void,
  includeLog?: boolean,
  actions?: string[],
): Promise<void> => {
  const url = `${REPORT_URL}/${wardId}/items/${itemId}/details/csv?${query}${
    includeLog ? '&include_log' : ''
  }`;
  const requestParam = { tz: getTimezoneOffset(), action: actions };

  if (includeLog) {
    return axios
      .get(url, {
        params: requestParam,
        responseType: 'text',
      })
      .then((response) => {
        const responseUrl = response.data.data;
        const link = document.createElement('a');
        link.href = responseUrl;

        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        getResponseError(error);
      })
      .finally(() => {
        if (callback) callback();
      });
  }
  return axios
    .get(url, { params: requestParam })
    .then(
      (
        d: AxiosResponse<{
          data: {
            header: Array<{ name: string; key: keyof ReportDetaiCSV }>;
            items: Array<ReportDetaiCSV>;
          };
        }>,
      ) => {
        exportCsv(d.data.data.header, d.data.data.items, itemId ? 'Item' : 'All item history');
      },
    )
    .catch((error) => {
      getResponseError(error);
    })
    .finally(() => {
      if (callback) callback();
    });
};

const getDynamicAnalysis = (wardId: ID, callback?: () => void): Promise<void> => {
  const URL = `${REPORT_URL}/${wardId}/dynamic-analysis`;

  return axios
    .get(URL, {
      params: { tz: getTimezoneOffset() },
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Dynamic analysis-${getFormatedDate(new Date())}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      getResponseError(error);
    })
    .finally(() => {
      if (callback) callback();
    });
};

export const getBalanceChart = (wardId: ID, itemId: ID, callback?: () => void) => {
  return axios
    .get(`${REPORT_URL}/${wardId}/items/${itemId}/chart/balance-forecast`)
    .then((response: AxiosResponse<{ data: ItemLineData }>) => response.data.data)
    .finally(() => {
      if (callback) {
        callback();
      }
    });
};

export {
  getReports,
  getReportDetail,
  getReportDetailList,
  getQuantityBalanceReportCSV,
  getReportDetailCSV,
  getDynamicAnalysis,
};
