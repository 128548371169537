import { useCallback, useEffect, useState } from 'react';

import InputSearch from 'app/components/Input/InputSearch';

import { useChangeParams } from 'app/modules/query-state/hooks';

import { trimValue } from 'app/helpers';
import { debounce } from 'lodash';

import { useQueryRequest } from '../core/QueryRequestProvider';

interface Props {
  placeholderSearch: string;
  syncParamUrl?: boolean;
}

const SearchComponent: React.FC<Props> = ({ placeholderSearch, syncParamUrl }) => {
  const { state, updateState } = useQueryRequest();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { onChangeParams } = useChangeParams();

  useEffect(() => {
    setSearchTerm(state.search || '');
  }, [state.search]);

  const debounceSearch = useCallback(
    debounce((newSearch: string) => {
      updateState({ page: 1, search: trimValue(newSearch) });

      if (syncParamUrl) {
        onChangeParams({ page: 1, search: trimValue(newSearch) });
      }
    }, 300),
    [],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);

    debounceSearch(event.target.value);
  };

  return (
    <InputSearch value={searchTerm} onChange={handleInputChange} placeholder={placeholderSearch} />
  );
};

export { SearchComponent };
