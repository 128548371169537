import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import { Device, DeviceRequest, DevicesQueryResponse } from './type';
import axios, { AxiosResponse } from 'axios';

const DEVICE_URL = `${API_URL}/api/devices`;

const getDevices = (query: string): Promise<DevicesQueryResponse> => {
  return axios.get(`${DEVICE_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Device>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const createDevice = (request: DeviceRequest): Promise<Device | undefined> => {
  return axios
    .post(DEVICE_URL, trimObject(request))
    .then((response: AxiosResponse<Response<Device>>) => response.data)
    .then((response: Response<Device>) => response.data);
};

const updateDevice = (idDevice: ID, request: DeviceRequest): Promise<Device | undefined> => {
  return axios
    .patch(`${DEVICE_URL}/${idDevice}`, trimObject(request))
    .then((response: AxiosResponse<Response<Device>>) => response.data)
    .then((response: Response<Device>) => response.data);
};

const deleteDevice = async (idDevice: ID): Promise<void> => {
  try {
    await axios.delete(`${DEVICE_URL}/${idDevice}`);
  } catch (error) {
    console.log(`Error deleting device ID ${idDevice}`);
    throw error;
  }
};

const generateCodeDevice = (idDevice: ID): Promise<Device | undefined> => {
  return axios
    .patch(`${DEVICE_URL}/${idDevice}/generate`)
    .then((response: AxiosResponse<Response<Device>>) => response.data)
    .then((response: Response<Device>) => response.data);
};

export { getDevices, createDevice, updateDevice, deleteDevice, generateCodeDevice };
