import { ID, Response } from 'app/components/Table/core/types';

export type Customer = {
  id: ID;
  name: string;
  code: string;
  logo?: string;
  address?: Address[];
};

export type Address = {
  id?: ID;
  address: string;
  phone: string;
  city: string;
  zipCode: string;
  country: string;
};

export type AddressRequest = {
  id?: ID;
  address: string;
  phone: string;
  city: string;
  zipCode: string;
  country: string;
  customerId?: string;
};

export type CustomerRequest = {
  name: string;
  code: string;
  logo?: File;
  removeLogo?: boolean;
};
export const initialCustomer: Customer = {
  name: '',
  code: '',
  id: '',
};
export type CustomersQueryResponse = Response<Customer>;
