import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { FormSelect } from 'app/components/Select/FormSelect';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useAuth } from 'app/modules/auth';
import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean, useNumber } from 'app/hooks';

import { SecurityGroup } from '../../security-groups/core/type';
import { createUser, updateUser } from '../core/requests';
import { User, UserRequest, initialUser } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  user: User;
  securityGroups: SecurityGroup[];
  loginType: LOGIN_TYPE;
  originIsView?: boolean;
};

const FormUserTableCard: FC<Props> = ({
  user,
  securityGroups,
  loginType,
  originIsView = false,
}) => {
  const { currentUser, setCurrentUser } = useAuth();
  const { itemForUpdate, setItemForUpdate } = useListView();
  const { updateState, state } = useQueryRequest();
  const { refetch } = useQueryResponse();
  const [passwordShown, setPasswordShown] = useState(false);

  const [userForEdit] = useState<{
    firstName: string;
    lastName: string;
    username: string;
    password?: string;
    rfidCode?: string;
  }>({
    firstName: user ? user.firstName : initialUser.firstName,
    lastName: user ? user.lastName : initialUser.lastName,
    username: user ? user.username : initialUser.username,
    password: '',
    rfidCode: user ? user.provider : initialUser.provider,
  });
  const roleId = useNumber(0);
  const isWebUser = loginType === LOGIN_TYPE.WEB;
  const isView = useBoolean(originIsView);

  useEffect(() => {
    if (user && user.role) {
      roleId.setValue(Number(user.role.id));
      return;
    }
    if (!securityGroups.length || user) {
      return;
    }
  }, [JSON.stringify(securityGroups), user]);

  useEffect(() => {
    isView.setValue(originIsView);
  }, [originIsView]);

  const getEditUserSchema = () => {
    const defaultSchema: any = {
      firstName: Yup.string()
        .trim()
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
      lastName: Yup.string().trim().max(50, 'Maximum 50 symbols').required('Last name is required'),
      username: !isWebUser
        ? Yup.string().trim().max(50, 'Maximum 50 symbols').required('Username is required')
        : Yup.string().trim().email('Wrong email format').required('Email is required'),
      rfidCode: Yup.string().trim().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    };
    if (!user) {
      defaultSchema.password = Yup.string()
        .trim()
        .min(6, 'Minimum 6 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password is required');
    }
    return defaultSchema;
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: Yup.object().shape(getEditUserSchema()),
    onSubmit: async ({ username, ...values }, { setSubmitting }) => {
      if (isView.value) {
        isView.setValue(false);
        if (itemForUpdate) {
          setItemForUpdate({
            ...itemForUpdate,
            isView: false,
          });
        }
        return;
      }
      if (!roleId.value) {
        toast.error(`Role is required!`);
        return;
      }
      setSubmitting(true);
      const request: UserRequest = {
        ...values,
        type: loginType,
        roleId: roleId.value,
        ...(isWebUser ? { email: username } : { username }),
      };
      try {
        if (user) {
          const dataRes = await updateUser(user.id, request);
          toast.success(`Update account successfully!`);
          cancel(true);
          if (dataRes && currentUser && dataRes.id === currentUser?.id) {
            setCurrentUser({
              ...currentUser,
              firstName: dataRes.firstName,
              lastName: dataRes.lastName,
            });
          }
          return;
        }
        await createUser(request);
        toast.success(`Add account successfully!`);

        if (state.page !== 1) {
          cancel();
          updateState({
            page: 1,
          });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const isEmptyUser = Object.values(formik.values).every((value) => value === '');

  const renderLabelButtonSubmit = () => {
    if (user) {
      return isView.value ? 'Edit' : 'Save';
    }
    return 'Add';
  };

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="d-flex justify-content-between">
            <div className="w-50 pe-3">
              <FormInput
                label="First name"
                placeholder="First name"
                formik={formik}
                field="firstName"
                isRequired
                isDisable={isView.value}
              />
            </div>
            <div className="w-50 ps-3">
              <FormInput
                label="Last name"
                placeholder="Last name"
                formik={formik}
                field="lastName"
                isRequired
                isDisable={isView.value}
              />
            </div>
          </div>
          <FormInput
            label={isWebUser ? 'Email' : 'Username'}
            placeholder={isWebUser ? 'Email' : 'Username'}
            formik={formik}
            field="username"
            isRequired
            isDisable={Boolean(user)}
          />
          {!user && (
            <FormInput
              label="Password"
              placeholder="Password"
              formik={formik}
              field="password"
              isRequired
              typeInput={passwordShown ? 'text' : 'password'}
              rootClass="w-100"
              passwordShown={passwordShown}
              setPasswordShown={() => setPasswordShown(!passwordShown)}
              isDisable={isView.value}
            />
          )}
          <div className="d-flex justify-content-between ">
            <div className={loginType === LOGIN_TYPE.TABLET ? 'w-50 pe-3' : 'w-100'}>
              <FormSelect
                label="Role"
                isRequired
                handleChange={(select) => roleId.setValue(select.value)}
                optionsSelect={securityGroups
                  .filter((el) => el.policy && el.policy.length > 0)
                  .map((el) => ({ title: el.name, value: el.id }))}
                isDisable={isView.value}
                activeSelect={roleId.value}
                fieldActive="value"
                fieldSelect="title"
                styleSelectCard={{
                  width: loginType === LOGIN_TYPE.TABLET ? 280 : 580,
                }}
                placementCustom="bottom-start"
                inputPlaceholder="Select role"
              />
            </div>
            {loginType === LOGIN_TYPE.TABLET && (
              <div className="w-50 ps-3">
                <FormInput
                  label="RFID code"
                  placeholder="RFID code"
                  formik={formik}
                  field="rfidCode"
                  isDisable={isView.value}
                />
              </div>
            )}
          </div>
        </div>
        <ActionButton
          onCancel={() => cancel()}
          disableButton={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            !roleId.value ||
            isEmptyUser
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={renderLabelButtonSubmit()}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormUserTableCard };
