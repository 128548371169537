import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import ModalDefault from 'app/components/Modal/ModalDefault';
import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { blockInvalidCharacters } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { isUndefined } from 'lodash';

import { updateQuantity } from '../../bins/core/requests';
import { Bin } from '../../bins/core/type';
import InfoItemCard from '../../items/components/InfoItemCard';
import { StockTypeEnum } from '../../items/core/enum';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Image = styled.div`
  width: fit-content;
  min-width: fit-content;
  margin-right: 32px;
`;

interface Props {
  onClickCloseModal?: () => void;
  selectBin: Bin | null;
  wardId: ID;
  wardName: string;
}

export const QuantityUpdateModal: React.FC<Props> = ({ selectBin, onClickCloseModal }) => {
  const { refetch } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const isLoading = useBoolean();
  const isLoadingSubmit = useBoolean();

  const onSubmit = async () => {
    if (selectBin) {
      formik.setSubmitting(true);
      await updateQuantity(
        selectBin.id,
        {
          newQty: Number(formik.values.quantity) || 0,
          itemId: Number(selectBin.item?.id) || 0,
        },
        () => {
          cancel(true);
        },
      );
      formik.setSubmitting(false);
    }
  };
  const formik = useFormik<{ quantity: number | null }>({
    initialValues: { quantity: null },
    validationSchema: Yup.object().shape({
      quantity: Yup.number()
        .typeError('Quantity is required')
        .required('Quantity is required')
        .min(0, 'New quantity must be positive')
        .notOneOf([selectBin?.item?.qty], 'New quantity must not be equal to current quantity'),
    }),
    onSubmit: onSubmit,
  });
  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onClickCloseModal)) {
      onClickCloseModal();
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  return (
    <ModalDefault
      title={`Edit quantity in Bin ${selectBin?.name}`}
      onClickCloseModal={() => cancel()}
      childrenAction={
        <ActionButton
          onCancel={() => cancel()}
          disableButton={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            !formik.values.quantity ||
            (selectBin?.item?.qty !== undefined &&
              Number(formik.values.quantity) === selectBin?.item?.qty)
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit="Save"
          onSubmit={onSubmit}
        />
      }
      styleModalDialog={{ width: 640, height: 'auto' }}
      modalContentStyle={{ height: 'fit-content' }}
      styleBodyModalDialog={{
        padding: 28,
        paddingBottom: 0,
        flexGrow: 1,
        height: 'fit-content',
      }}
    >
      <div
        className="d-flex align-items-start"
        style={{
          width: 'auto',
          height: 'fit-content',
        }}
      >
        <Image>
          <ShowImageDefault
            styleMain={{
              width: 120,
              height: 120,
              minWidth: 120,
              minHeight: 120,
            }}
            image={selectBin?.item?.image || ''}
          />
        </Image>
        <div
          className="d-flex flex-column"
          style={{
            width: '100%',
            height: 120,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridRow: 10,
              width: '100%',
            }}
          >
            <InfoItemCard
              title="Item name"
              value={selectBin?.item?.name || ''}
              fontSizeValue={16}
              styleRoot={{ marginBottom: 24, marginRight: 24 }}
            />
            <InfoItemCard
              title="SKU"
              value={selectBin?.item?.skuId || ''}
              fontSizeValue={16}
              styleRoot={{ marginBottom: 24 }}
            />
            <InfoItemCard
              title="Item type"
              value={StockTypeEnum[Number(selectBin?.item?.stockType) || 0]}
              fontSizeValue={16}
              styleRoot={{ marginBottom: 0, marginRight: 24 }}
            />
            <InfoItemCard
              title="Current quantity"
              value={selectBin?.item?.qty.toString() || ''}
              fontSizeValue={16}
              styleRoot={{ marginBottom: 0 }}
            />
          </div>
        </div>
      </div>
      <form
        id="kt_modal_company_form"
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        style={{ marginTop: 24 }}
      >
        <FormInput
          label="New quantity"
          placeholder="New quantity"
          formik={formik}
          field="quantity"
          typeInput="number"
          touched={true}
          rootClass="w-100"
          onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
          // messageError={formik.errors.quantity}
        />
      </form>
      {isLoading.value || isLoadingSubmit.value ? <LoadingCard /> : null}
    </ModalDefault>
  );
};
