import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import Typography from 'app/components/Typography';

import { blockInvalidCharacters } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { isArray } from 'lodash';

import { ReactComponent as EditICon } from 'app/assets/icons/edit-icon.svg';

import { ROLE_SETTING_TITLE } from '../core/constants';
import { NameSetting } from '../core/enum';
import { updateSetting } from '../core/request';
import { Setting, SettingType } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Body = styled.div`
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  padding: 28px 28px 4px 28px;
  display: flex;
  flex-direction: column;
  .btn-light {
    background: transparent;
  }
`;
const ButtonEdit = styled.div`
  width: 140px;
  height: 43px;
  border-radius: 8;
  svg path[stroke] {
    stroke: var(--primary);
  }
  :hover {
    svg path[stroke] {
      stroke: var(--white);
    }
    background: var(--primary);
    p {
      color: var(--white);
    }
  }
`;
interface Props {
  type: NameSetting;
  label?: string;
  settings: Setting[];
  currentSelected: NameSetting | null;
  setCurrentSelected: (payload: NameSetting | null) => void;
}
const AutoLogoutTimeItemCard: React.FC<Props> = ({
  type,
  label,
  settings,
  currentSelected,
  setCurrentSelected,
}) => {
  const [formRequest, setFormRequest] = useState<{
    settings: Setting[];
  }>({
    settings: settings,
  });
  const isEdit = useBoolean(false);

  useEffect(() => {
    formik.setValues({ settings });
    setFormRequest({ settings });
  }, [JSON.stringify(settings)]);

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape({
      settings: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.number().min(0, 'Minimum 0s').required('Seconds is required'),
          }),
        )
        .required(ROLE_SETTING_TITLE[type]),
    }),
    onSubmit: async ({ settings: formSettings }, { setSubmitting }) => {
      setSubmitting(true);

      const allPromises = formSettings.map(async (setting: Setting) =>
        updateSetting(Number(setting.id), {
          name: setting.name,
          type: SettingType.logoutTimeout,
          value: String(setting.value),
        }),
      );

      const res = await Promise.all(allPromises);
      if (res) {
        const filteredResponse = res.filter((setting): setting is Setting => setting !== undefined);
        formik.setValues({
          settings: filteredResponse,
        });
        setFormRequest({ settings: filteredResponse });
      }
      setSubmitting(false);
      isEdit.setValue(false);
      toast.success('Update setting successfully!');
    },
  });

  const onCancel = () => {
    isEdit.setValue(false);
    formik.setValues({ settings: formRequest.settings });
  };

  useEffect(() => {
    if (currentSelected !== type) {
      onCancel();
    }
  }, [currentSelected]);

  const renderButton = () => {
    if (!isEdit.value) {
      return (
        <div className="d-flex">
          <ButtonEdit
            className="btn d-flex justify-content-center align-items-center"
            onClick={() => {
              isEdit.setValue(true);
              setCurrentSelected(type);
            }}
          >
            <EditICon />
            <Typography
              variant="title-4"
              color="primary"
              style={{
                marginLeft: 8,
              }}
            >
              Edit
            </Typography>
          </ButtonEdit>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <ActionButton
          onCancel={onCancel}
          disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit="Save"
          customClass="pt-0 mt-0"
          labelButtonReset="Cancel"
        />
      </div>
    );
  };

  const errors: any = formik.errors;
  return (
    <Body>
      <form id="kt_modal_company_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div className="d-flex flex-column">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              marginBottom: 32,
            }}
          >
            <Typography variant="body-6" color="gray-700">
              {ROLE_SETTING_TITLE[type]}
            </Typography>
            {renderButton()}
          </div>
          <div
            style={{
              width: '85%',
            }}
          >
            {formik.values.settings.map((el: Setting, index: number) => {
              return (
                <div
                  className="d-flex"
                  key={index}
                  style={{
                    maxWidth: '615px',
                  }}
                >
                  <FormInput
                    placeholder=""
                    label={label ?? String(el.meta) ?? ''}
                    formik={formik}
                    field={`settings.${index}.value`}
                    rootClass="w-100"
                    isDisable={!isEdit.value}
                    typeInput="number"
                    touched={formik.touched?.settings && formik.touched.settings[index]?.value}
                    onKeyDown={(e) => blockInvalidCharacters(e, 'float')}
                    messageError={isArray(errors.settings) && errors.settings[index]?.value}
                  />
                  <Typography
                    variant="body-5"
                    color={!isEdit.value ? 'neutral-6' : 'neutral-10'}
                    style={{
                      marginLeft: 8,
                      marginTop: 39,
                    }}
                  >
                    Seconds
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </Body>
  );
};
export default AutoLogoutTimeItemCard;
