import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import ModalDefault from 'app/components/Modal/ModalDefault';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import TippyMenu, { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';

import { getResponseError } from 'app/helpers/errors';
import { usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { cloneDeep, differenceBy, includes, intersection, remove } from 'lodash';

import { ReactComponent as EditIcon } from 'app/assets/icons/edit-icon.svg';
import { ReactComponent as IconArrow } from 'app/assets/icons/icon-arrow-down.svg';

import { getPermissions } from '../permission/core/request';
import { PermissionDetail } from '../permission/core/type';
import {
  getOneSecurityGroup,
  updatePermissionSecurityGroups,
} from '../security-groups/core/requests';
import { SecurityGroup } from '../security-groups/core/type';
import { PermissionCheckbox } from './components/PermissionCheckbox';
import { UsersRoleHeader } from './components/UsersRoleHeader';
import { FormUsersRole } from './modal/FormUsersRole';

const Collapse = styled.div`
  .accordion-button::after {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  .accordion-button:not(.collapsed) {
    color: var(--neutral-10);
    background-color: transparent;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }
  .accordion-button {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    padding: 0;
  }
  .accordion-item {
    border: none;
  }
  .accordion-body {
    padding: 0;
  }
  .accordion-header {
    padding: 19px 28px;
  }
  .form-check-input[type='checkbox'] {
    border-radius: 6px;
  }
`;
const ButtonCollapsed = styled.button`
  ::after {
    display: none;
  }
`;
const UsersRoleDetail: FC<{ redirectPage: string; type: LOGIN_TYPE }> = ({
  redirectPage,
  type,
}) => {
  const params = useParams<any>();
  const isLoading = useBoolean(true);
  const navigate = useNavigate();
  const isEdit = useBoolean();
  const [securityGroup, setSecurityGroup] = useState<SecurityGroup | null>(null);
  const [originSecurityGroupPermission, setOriginSecurityGroupPermission] = useState<
    PermissionDetail[]
  >([]);

  const [permissions, setPermissions] = useState<PermissionDetail[]>([]);
  const [securityPolicies, setSecurityPolicies] = useState<PermissionDetail[]>([]);
  const isOpenMenu = useBoolean(false);
  const isOpenEdit = useBoolean(false);
  const goBack = usePageGoBack(redirectPage);

  useEffect(() => {
    if (!params.id) {
      navigate(redirectPage);
      return;
    }
    getSecurityGroupDetail(Number(params.id), true);
  }, [params.id]);

  const getSecurityGroupDetail = async (id: number, isLoadPermission = false) => {
    try {
      isLoading.setValue(true);
      const dataSecurity = await getOneSecurityGroup(id);
      if (isLoadPermission) {
        const dataRes = await getPermissions(
          stringifyRequestQuery({
            page: 1,
            limit: 100,
          }),
        );
        if (type === LOGIN_TYPE.TABLET) {
          setPermissions(dataSecurity?.policy as PermissionDetail[]);
        } else {
          setPermissions(dataRes.data?.items ?? []);
        }
      }

      if (dataSecurity) {
        setSecurityGroup(dataSecurity);
        setSecurityPolicies(dataSecurity.policy ?? []);
        setOriginSecurityGroupPermission(dataSecurity.policy ?? []);
      }
    } catch (error) {
      navigate(redirectPage);
    } finally {
      isLoading.setValue(false);
    }
  };

  const onSubmit = async () => {
    if (!securityGroup?.id) {
      return;
    }
    try {
      isLoading.setValue(true);
      const originSecurityGroup = cloneDeep(securityGroup);
      const originalSecurityPolicies = cloneDeep(securityPolicies);
      const originPermissions = cloneDeep(originSecurityGroupPermission);

      const listSecurityPermissionIds = originalSecurityPolicies.map((permission) => permission.id);

      const listPermissionsIds = originPermissions.map((el) => el.id);

      const newListPermissionIds = differenceBy(
        [...listPermissionsIds, ...listSecurityPermissionIds],
        intersection(listPermissionsIds, listSecurityPermissionIds),
      );
      await updatePermissionSecurityGroups({
        securityGroupId: originSecurityGroup?.id,
        policyIds: newListPermissionIds,
      });
      setOriginSecurityGroupPermission(originalSecurityPolicies);
      toast.success('Update permissions role successfully!');
      isEdit.setValue(false);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const renderActionButton = () => {
    if (type === LOGIN_TYPE.TABLET) {
      return null;
    }
    return (
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ paddingRight: 28, marginBottom: 32 }}
      >
        {isEdit.value ? (
          <button
            className="btn btn-primary h-45px d-flex justify-content-center align-items-center"
            onClick={onSubmit}
            style={{
              width: 140,
            }}
          >
            Save
          </button>
        ) : (
          <button
            className="btn btn-primary h-45px d-flex justify-content-center align-items-center"
            onClick={() => isEdit.setValue(true)}
            style={{
              width: 177,
            }}
          >
            <EditIcon />
            <span className="indicator-label ms-2">Edit permission</span>
          </button>
        )}
      </div>
    );
  };

  const onChangePermission = (newPermission: PermissionDetail, checked: boolean) => () => {
    const newSecurityPolicies = cloneDeep(securityPolicies);
    if (checked) {
      newSecurityPolicies.push(newPermission);
    } else {
      remove(newSecurityPolicies, (permission) => permission.id === newPermission.id);
    }
    setSecurityPolicies(newSecurityPolicies);
  };
  const onChangePermissionAll = (newAll: boolean) => {
    setSecurityPolicies(newAll ? permissions : []);
  };
  const renderBody = (description: string) => {
    const splitDescription = description.split(',');
    if (!splitDescription.length) {
      return <div />;
    }

    return (
      <div className="d-flex flex-column">
        {splitDescription.map((el, index) => {
          return (
            <div
              key={index}
              className="d-flex align-items-center"
              style={{
                height: 64,
                paddingLeft: 28,
                background: 'var(--neutral-1)',
                borderBottom: `1px solid var(--neutral-3)`,
              }}
            >
              <Typography variant="body-5" color="gray-700">
                {`- ${el}`}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };
  const onCloseModalEditRole = (newData?: SecurityGroup) => {
    isOpenEdit.setValue(false);
    if (newData && securityGroup) {
      setSecurityGroup({
        ...securityGroup,
        name: newData.name,
      });
    }
  };
  if (isLoading.value) {
    return <LoadingCard />;
  }
  return (
    <RootContentPage
      title=""
      header={
        <UsersRoleHeader
          securityGroup={securityGroup}
          onClickRedirect={goBack}
          action={
            <TippyMenu
              onClickIcon={() => isOpenMenu.setValue(true)}
              visible={isOpenMenu.value}
              onClickOutside={() => isOpenMenu.setValue(false)}
              content={
                <MenuAction>
                  <MenuItem
                    onClick={() => {
                      isOpenMenu.setValue(false);
                      isOpenEdit.setValue(true);
                    }}
                  >
                    Edit role name
                  </MenuItem>
                </MenuAction>
              }
            />
          }
        />
      }
    >
      <div
        className="d-flex flex-column"
        style={{
          paddingTop: '28px',
        }}
      >
        {renderActionButton()}
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            borderBottom: `1px solid var(--neutral-3)`,
            padding: '19px 0px',
          }}
        >
          <div style={{ width: 300, paddingLeft: 28 }}>
            <Typography variant="body-6" color="neutral-10">
              All permissions
            </Typography>
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{
              marginRight: 136,
            }}
          >
            <PermissionCheckbox
              onChangePermission={() =>
                onChangePermissionAll(securityPolicies.length !== permissions.length)
              }
              showIndeterminateCheckbox={Boolean(
                securityPolicies.length && securityPolicies.length !== permissions.length,
              )}
              disabled={!isEdit.value}
              checked={securityPolicies.length === permissions.length}
            />
          </div>
        </div>
        <Collapse>
          <div className="accordion" id="kt_accordion_1">
            {permissions.map((permission, index) => (
              <div className="accordion-item" key={permission.id}>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ borderBottom: `1px solid var(--neutral-3)` }}
                >
                  <div
                    className="accordion-header"
                    id={`kt_accordion_1_header_${index}`}
                    style={{ width: 300, cursor: 'default' }}
                  >
                    <ButtonCollapsed
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#kt_accordion_1_body_${index}`}
                      aria-expanded="false"
                      aria-controls={`kt_accordion_1_body_${index}`}
                      style={{ width: 'auto' }}
                    >
                      <Typography
                        variant="body-6"
                        color="neutral-10"
                        style={{
                          whiteSpace: 'nowrap',
                          marginRight: 8,
                        }}
                      >
                        {permission.title}
                      </Typography>
                      <IconArrow />
                    </ButtonCollapsed>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{
                      marginRight: 136,
                    }}
                  >
                    <PermissionCheckbox
                      showIndeterminateCheckbox={false}
                      onChangePermission={onChangePermission(
                        permission,
                        !includes(
                          securityPolicies.map((el) => el.id),
                          permission.id,
                        ),
                      )}
                      disabled={!isEdit.value}
                      checked={includes(
                        securityPolicies.map((el) => el.id),
                        permission.id,
                      )}
                    />
                  </div>
                </div>
                <div
                  id={`kt_accordion_1_body_${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`kt_accordion_1_header_${index}`}
                >
                  {renderBody(permission.description)}
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
      {isOpenEdit.value && securityGroup && (
        <ModalDefault title="Edit role name" onClickCloseModal={() => isOpenEdit.setValue(false)}>
          <FormUsersRole
            usersRole={securityGroup}
            loginType={securityGroup.type as LOGIN_TYPE}
            onCloseModal={onCloseModalEditRole}
          />
        </ModalDefault>
      )}
    </RootContentPage>
  );
};

export default UsersRoleDetail;
