import { FC, useState } from 'react';
import styled from 'styled-components';

import { CustomHeader } from 'app/components/Modal/components/CustomHeader';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';

import { getBalanceChart } from 'app/modules/reporting/core/request';
import { ForecastChartData, ItemLineData } from 'app/modules/reporting/core/type';

import { useBoolean } from 'app/hooks';

import { getForecastChart } from '../core/requests';
import { approveStatusLengthSelector, useProjectionStore } from '../core/store';
import { DemandProjectionItem } from '../core/type';
import { ForecastChartModal } from './ForecastChart.modal';
import { ViewItemInProjection } from './ViewItemInProjection';

const TableHeader = styled.div`
  display: flex;
  top: 0;
  align-items: center;
  margin-top: 0;
  padding-top: 32px;
  background-color: var(--white);
  border-bottom: 1px solid var(--neutral-3);
  padding: 16px 28px 0 28px;

  .header-column {
    white-space: normal !important;
    padding: 0 12px 12px !important;
  }
`;

const SelectAll = styled.div`
  min-width: 180px;
  display: flex;
  align-items: center;
  p {
    font-weight: 700;
    white-space: nowrap;
  }
  padding: 0 12px 16px 12px;
`;

interface Props {
  items: DemandProjectionItem[];
  isApproved: boolean;
  wardId: number;
}
export const ProjectionList: FC<Props> = ({ items, isApproved, wardId }) => {
  const openModal = useBoolean(false);
  const isLoading = useBoolean(false);

  const [chartData, setChartData] = useState<ForecastChartData | undefined>(undefined);
  const [balanceChartData, setBalanceChartData] = useState<ItemLineData | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<DemandProjectionItem | null>(null);

  const openForecastModal = async (itemId: number) => {
    isLoading.setValue(true);
    openModal.setValue(true);
    const dataRes = await getForecastChart(wardId, itemId, () => {
      isLoading.setValue(false);
    });
    setChartData(dataRes);
  };

  const openBalanceModal = async (itemId: number) => {
    isLoading.setValue(true);
    openModal.setValue(true);
    const dataRes = await getBalanceChart(wardId, itemId, () => {
      isLoading.setValue(false);
    });
    setBalanceChartData(dataRes);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ minWidth: 'max-content' }}>
        <TableHeader>
          <div style={{ minWidth: '50px', padding: '0 12px 16px 12px' }}>
            <Typography variant="title-4" fontWeight={700} color="neutral-6">
              #
            </Typography>
          </div>
          <CustomHeader
            title="Item"
            styleHeader={{ width: '100%', display: 'flex', flexGrow: 1 }}
            id="itemName"
          />
          <CustomHeader title="SKU" styleHeader={{ minWidth: '190px' }} id="skuId" />
          <CustomHeader
            title="Stock health"
            styleHeader={{ minWidth: '90px', whiteSpace: 'normal' }}
            id="stockLevelOrder"
          />
          <CustomHeader
            title="Par level"
            styleHeader={{ minWidth: '90px', whiteSpace: 'normal' }}
            id="parValue"
          />
          <CustomHeader
            title="Qty balance"
            styleHeader={{ minWidth: '130px', whiteSpace: 'normal' }}
            id="qtyBalance"
          />
          <CustomHeader
            title="ML projected demand"
            styleHeader={{ minWidth: '150px', whiteSpace: 'normal' }}
            id="projectedDemand"
            isOrder={false}
          />
          <CustomHeader
            title="Pre-planned qty"
            styleHeader={{ minWidth: '140px', whiteSpace: 'normal' }}
            id="plannedQty"
          />
          <CustomHeader
            title="ML stock run qty"
            styleHeader={{ minWidth: '120px', whiteSpace: 'normal' }}
            id="recommendedQty"
            isOrder={false}
          />

          <CustomHeader
            title="Demand's status"
            styleHeader={{ minWidth: '190px', whiteSpace: 'normal' }}
            id="approvedType"
          />
        </TableHeader>
        {items.length ? (
          <div style={{ paddingBottom: 28, minWidth: '100%' }}>
            {items.map((item, index) => (
              <ViewItemInProjection
                item={item}
                index={index}
                isApproved={isApproved}
                key={item.id}
                onViewConsumptionChart={() => {
                  setSelectedItem(item);
                  openForecastModal(Number(item.itemId));
                }}
                onViewBalanceChart={() => {
                  setSelectedItem(item);
                  openBalanceModal(Number(item.itemId));
                }}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center" style={{ padding: '16px 10px' }}>
            <Typography variant="body-3">No matching records found</Typography>
          </div>
        )}
      </div>

      {openModal.value && !isLoading.value && (
        <ForecastChartModal
          item={selectedItem}
          onCancel={() => {
            openModal.setValue(false);
            setChartData(undefined);
            setBalanceChartData(undefined);
            setSelectedItem(null);
          }}
          forecastChartData={chartData}
          balanceChartData={balanceChartData}
        />
      )}

      {isLoading.value && <LoadingCard />}
    </div>
  );
};
