export enum ActionBin {
  assignItem,
  removeItem,
  shortFor,
  updateQuantity,
}

export enum InventoryMovementType {
  pickUp = 'pick-up',
  stockUp = 'stock-up',
  exception = 'exception',
  manualEdit = 'manual-edit',
  autoEdit = 'auto-edit',
}

export enum EventTypeLogRack {
  UpdateRack = 'Update rack',
  PickUp = 'Pick up',
  StockUp = ' Stock up',
  AbnormalAction = 'Abnormal action',
  ManualEdit = 'Manual edit',
  AutoEdit = 'Auto edit',
}

export enum TypeLog {
  dates,
  months,
}
