import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

import { cloneDeep, get, has, isArray, partial, some } from 'lodash';

import { stringify } from 'qs';

export const QUERY_KEY = {
  filter: {
    ward_id: 'ward_id',
    ward_name: 'ward_name',
    from_complete_date: 'from_complete_date',
    to_complete_date: 'to_complete_date',
    status: 'status',
    to_commence_date: 'to_commence_date',
    from_commence_date: 'from_commence_date',
  },
  table: {
    page: 'page',
    limit: 'limit',
    orderBy: 'orderBy',
    orderDirection: 'orderDirection',
  },
  viewMode: 'viewMode',
};

export const injectDragToScroll = (elementId: string) => {
  const container: any = document.querySelector(`#${elementId}`);
  if (!container) return;
  let startY;
  let startX;
  let scrollLeft;
  let scrollTop;
  let isDown;

  function mouseIsDown(e) {
    isDown = true;
    startY = e.pageY - container.offsetTop;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
    scrollTop = container.scrollTop;
  }
  function mouseUp() {
    isDown = false;
  }
  function mouseLeave() {
    isDown = false;
  }
  function mouseMove(e) {
    if (isDown) {
      e.preventDefault();
      //Move vertcally
      const y = e.pageY - container.offsetTop;
      const walkY = y - startY;
      container.scrollTop = scrollTop - walkY;

      //Move Horizontally
      const x = e.pageX - container.offsetLeft;
      const walkX = x - startX;
      container.scrollLeft = scrollLeft - walkX;
    }
  }

  container.addEventListener('mousedown', (e) => mouseIsDown(e));
  container.addEventListener('mousemove', (e) => mouseMove(e));
  container.addEventListener('mouseup', mouseUp);
  container.addEventListener('mouseleave', mouseLeave);
};

export const useGoBackIfExist = (defaultPath: string) => {
  const locationPath = useLocation();
  const navigate = useNavigate();

  const doesAnyHistoryEntryExist = locationPath.key !== 'default';

  return () => {
    if (doesAnyHistoryEntryExist) {
      navigate(-1);
    } else {
      navigate(defaultPath);
    }
  };
};

export const usePageGoBack = (defaultPath?: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  return () => {
    const state = location.state as { previousPath?: string };
    navigate(state?.previousPath || defaultPath || (-1 as To));
  };
};

export const useNavigateWithSendPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (to: To, options?: NavigateOptions) => {
    navigate(to, {
      ...options,
      state: {
        ...options?.state,
        previousPath: `${location.pathname}${location.search}`,
      },
    });
  };
};

export const useNavigateWithPreviousPath = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (to: To, options?: NavigateOptions) => {
    const locationState = location.state as { previousPath?: string };
    navigate(to, {
      ...options,
      state: {
        ...options?.state,
        previousPath: locationState?.previousPath,
      },
    });
  };
};

export const filterBySearch = (items: any[], search: string, searchKeys: string[]) => {
  // Escape special characters that have meaning in regular expressions
  const test = search.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(test ?? '', 'gi');

  return items.filter((el) =>
    searchKeys.some((key) => {
      const value = get(el, key);
      return value && value.search(regex) >= 0;
    }),
  );
};

export const haveKeysIn = (obj: any, requiredKeys: string[]) =>
  some(requiredKeys, partial(has, obj));

export const stringifyObject = (obj: any) => {
  const newObj = cloneDeep(obj);
  Object.entries(obj).forEach(([key, value]) => {
    const valueStringified = typeof value === 'object' ? JSON.stringify(value) : String(value);
    newObj[key] = isArray(value) ? value : valueStringified;
  });
  return newObj;
};

export const getPathWithParams = (path: string, params: object) => {
  return `${path}?${stringify(params)}`;
};
