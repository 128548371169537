import { ID } from 'app/components/Table/core/types';

import { StockLevel } from 'app/modules/inventory/stock-run/core/enum';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

export type TimeFrame = {
  id: ID;
  timeFrame: string;
  isApproved: boolean;
  userName: string;
  createdAt: string;
  index: number;
};

export type ProjectionDetail = {
  ward: Ward;
  items: TimeFrame[];
};

export type DemandProjectionItem = {
  id: ID;
  itemName: string;
  itemId: ID;
  image: string;
  skuId: string;
  skuSub: string;
  stockLevel: StockLevel;
  parValue: number;
  qtyBalance: number;
  plannedQty: number;
  status: boolean;
  stockType: number;
  approvedType: ForecastCurve | null; // Undefined is not approved, Use for approval
  projectedDemand: MlQuantity;
  recommendedQty: MlQuantity;
};

export interface MlQuantity {
  nominalQty: number; // Required
  highQty?: number;
  lowQty?: number;
}

export enum ForecastCurve {
  normal = 'normal',
  upper = 'upper',
  lower = 'lower',
}

export interface DemandApproveItem {
  itemDemandId: number;
  approvedType: ForecastCurve;
}
