import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import './style.css';
import clsx from 'clsx';

const Label = styled.div<{ disabled?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
  ${({ disabled }) => (disabled ? `color:#B5B8C3` : `color:var(--gray-700)`)}
`;

const Textarea = styled.textarea<{ disabledInput?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-10) !important;
  ${({ disabledInput }) =>
    disabledInput
      ? `background-color:var(--neutral-2-5) !important; color: #B5B8C3  !important`
      : `background-color:var(--neutral-2) !important; color: var(--neutral-10) !important `}
`;

interface Props {
  label?: string;
  rootClass?: string;
  formik: any;
  field: string;
  placeholder: string;
  isRequired?: boolean;
  isDisable?: boolean;
  typeInput?: HTMLInputTypeAttribute | undefined;
  rows?: number;
  maxLength?: number;
  showCount?: boolean;
}
const FormTextarea: React.FC<Props> = ({
  label,
  placeholder,
  rootClass,
  field,
  formik,
  isRequired = false,
  isDisable = false,
  typeInput = 'text',
  rows = 3,
  maxLength,
  showCount,
}) => {
  //CRLF (Carriage Return Line Feed) is a newline character whith a '\r' followed by '\n'
  const crlfCount = (formik.getFieldProps(field).value.match(/\r\n/g) || []).length;

  //remove duplicated CRLF character counts
  const currentLength = formik.getFieldProps(field).value.length - crlfCount;

  return (
    <div
      className={clsx('fv-row', rootClass)}
      style={{
        marginBottom: 24,
      }}
    >
      {label && (
        <Label disabled={isDisable} className={clsx('mb-2', isRequired ? 'required' : '')}>
          {label}
        </Label>
      )}
      <Textarea
        disabledInput={isDisable}
        placeholder={placeholder}
        {...formik.getFieldProps(field)}
        type={typeInput}
        name={field}
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0 fw-normal',
          {
            'is-invalid': formik.touched[field] && formik.errors[field],
          },
          {
            'is-valid': formik.touched[field] && formik.values[field] && !formik.errors[field],
          },
        )}
        rows={rows}
        autoComplete="off"
        disabled={formik.isSubmitting || isDisable}
        maxLength={maxLength}
        style={{ color: 'var(--neutral-10)', fontSize: '16px' }}
      />
      {showCount && (
        <div
          style={{
            fontSize: '14px',
            color: 'var(--neutral-6)',
            textAlign: 'end',
            marginTop: '6px',
          }}
        >
          {currentLength}/{maxLength}
        </div>
      )}
      {formik.touched[field] && formik.errors[field] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik.errors[field]}</span>
          </div>
        </div>
      )}
      {/* end::Input */}
    </div>
  );
};

export { FormTextarea };
