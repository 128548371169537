import React, { HTMLInputTypeAttribute, KeyboardEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as HidePasswordIcon } from 'app/assets/icons/hide-password-icon.svg';
import { ReactComponent as ShowPasswordIcon } from 'app/assets/icons/show-password-icon.svg';

import Typography from '../Typography';
import './style.css';
import clsx from 'clsx';

const Label = styled.div<{ disabled?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
  ${({ disabled }) => (disabled ? `color:var(--neutral-6)` : `color:var(--gray-700)`)}
`;
const Input = styled.input<{ disabledInput?: boolean; isInvalid?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: 43px;
  color: var(--neutral-10);
  box-shadow: ${({ isInvalid }) => (isInvalid ? '0 0 0 0.25rem rgba(241, 65, 108, 0.25)' : 'none')};
  ${({ disabledInput }) =>
    disabledInput
      ? `background-color:var(--neutral-2-5) !important;`
      : `color:var(--neutral-10) !important; background-color:var(--neutral-2) !important;`}
`;

const PasswordIcon = styled.span`
  position: absolute;
  margin-right: 10px;
  cursor: pointer;
  top: 10px;
`;

const renderPasswordIcon = (passwordShown?: boolean) => {
  return passwordShown ? <ShowPasswordIcon /> : <HidePasswordIcon />;
};

interface Props {
  label?: string;
  rootClass?: string;
  formik: any;
  field: string;
  placeholder: string;
  isRequired?: boolean;
  isDisable?: boolean;
  typeInput?: HTMLInputTypeAttribute;
  isShowError?: boolean;
  touched?: boolean;
  messageError?: string;
  passwordShown?: boolean;
  setPasswordShown?: () => void;
  message?: string;
  marginBottom?: number;
  onKeyDown?: KeyboardEventHandler;
  valueShowIconInput?: string;
  isShowIcon?: boolean;
  defaultValue?: any;
}
const FormInput: React.FC<Props> = ({
  label,
  placeholder,
  rootClass,
  field,
  formik,
  isRequired = false,
  isDisable = false,
  typeInput = 'text',
  isShowError = true,
  touched,
  messageError,
  passwordShown,
  message,
  setPasswordShown,
  marginBottom = 24,
  onKeyDown,
  valueShowIconInput,
  isShowIcon = true,
  defaultValue = '',
}) => {
  const isError = touched ?? formik.touched[field];
  const newMessageError = messageError ?? formik.errors[field];
  const newValue = valueShowIconInput ?? formik.values[field];
  const [inputValue, setInputValue] = useState<string>(
    formik.getFieldProps(field).value || defaultValue,
  );

  useEffect(() => {
    setInputValue(formik.getFieldProps(field).value);
    if (typeInput === 'number' && formik.getFieldProps(field).value) {
      setInputValue(formik.getFieldProps(field).value.toString());
      if (formik.isSubmitting === true) {
        formik.setFieldValue(field, parseFloat(inputValue).toString());
      }
    }
  }, [formik.values[field], formik.isSubmitting, isDisable, field]);

  const renderMessage = () => {
    if (isShowError && isError && newMessageError) {
      return (
        <div className="fv-help-block">
          <span role="alert">{newMessageError}</span>
        </div>
      );
    }
    if (formik.values[field] && message) {
      return (
        <Typography
          variant="body-4"
          lineHeight={23}
          style={{
            marginTop: '0.3rem',
          }}
        >
          {message}
        </Typography>
      );
    }
    return null;
  };

  useEffect(() => {
    if (formik.isSubmitting && defaultValue && !inputValue) {
      setInputValue(defaultValue);
      formik.setFieldValue(field, defaultValue);
    }
  }, [formik.isSubmitting]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let latestValue = value;

    if (typeInput === 'number') {
      /*Regex explanation:
        - `^0*(?=\d)` : Matches leading zeros followed by a digit (0) at the beginning of the string.
      */
      latestValue = value.replace(/^0*(?=\d)/, '');
    }
    setInputValue(latestValue);
    formik.setFieldValue(field, latestValue || defaultValue);
  };

  return (
    <div
      className={clsx('position-relative fv-row', rootClass)}
      style={{
        marginBottom: marginBottom,
      }}
    >
      {label && (
        <Label
          disabled={isDisable}
          className={clsx(isRequired ? 'required' : '')}
          style={{ marginBottom: 4 }}
        >
          {label}
        </Label>
      )}
      <div className="d-flex align-items-center justify-content-end position-relative">
        <Input
          disabledInput={isDisable}
          placeholder={placeholder}
          value={inputValue}
          type={typeInput}
          name={field}
          className={clsx('form-control form-control-solid mb-3 mb-lg-0', {
            'is-invalid': isError && newMessageError && isShowIcon,
            'is-valid': isError && newValue && !isDisable && !newMessageError && isShowIcon,
          })}
          autoComplete="new-password"
          disabled={formik.isSubmitting || isDisable}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onBlur={formik.handleBlur}
          isInvalid={isError && newMessageError && isShowIcon}
        />
        {typeInput === 'password' || passwordShown ? (
          <PasswordIcon
            onClick={setPasswordShown}
            style={{
              marginRight: isError && isShowIcon ? '50px' : '',
            }}
          >
            {renderPasswordIcon(passwordShown)}
          </PasswordIcon>
        ) : null}
      </div>
      <div
        className="position-absolute  fv-plugins-message-container"
        style={{
          bottom: -22,
        }}
      >
        {renderMessage()}
      </div>
      {/* end::Input */}
    </div>
  );
};

export { FormInput };
