import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const Main = styled.div<{ disabled?: boolean; isShowSelectDate?: boolean }>`
  input {
    height: 43px;
    width: 100px;

    border-radius: 8px;
    border: none !important;
    outline: none !important;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--neutral-10);
    ${({ disabled }) =>
      disabled
        ? `background:var(--neutral-2-5); color: var(--neutral-6);`
        : `background: var(--neutral-2); color: var(--neutral-10); cursor: pointer;`}
  }
  .react-datepicker {
    background: var(--white);
    box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
      4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
    border-radius: 8px;
    border: none;
    padding: 8px 0px 8px 8px;
    width: 100px;
    ${({ isShowSelectDate }) => (isShowSelectDate ? `display:flex` : `display:none`)}
  }
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__time-container {
    width: 92px;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    margin: 0px;
    width: 90px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 35px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--neutral-10);
    margin-bottom: 4px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: #f7fcff;
    color: var(--primary);
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: #f7fcff;
    color: var(--primary);
    font-weight: 600;
  }
`;
export interface FormSelectTimeOnlyProps {
  time: Date | null;
  setTime: (newDate: Date | null) => void;
  disabled?: boolean;
  minTime?: Date;
  maxTime?: Date;
  injectTimes?: Date[];
  isShowSelectDate?: boolean;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
}
const FormSelectTimeOnly: React.FC<FormSelectTimeOnlyProps> = ({
  time,
  setTime,
  disabled,
  maxTime,
  minTime,
  injectTimes,
  isShowSelectDate = true,
  onCalendarClose,
  onCalendarOpen,
}) => {
  return (
    <Main disabled={disabled} isShowSelectDate={isShowSelectDate}>
      <DatePicker
        readOnly
        selected={time}
        onChange={setTime}
        showTimeSelect
        disabled={disabled}
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="h:mm aa"
        showPopperArrow={false}
        minTime={minTime}
        maxTime={maxTime}
        injectTimes={injectTimes}
        onCalendarClose={onCalendarClose}
        onCalendarOpen={onCalendarOpen}
      />
    </Main>
  );
};
export default FormSelectTimeOnly;
