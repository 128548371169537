import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { I18nProvider } from '../theme/i18n/i18nProvider';
import { MasterInit } from '../theme/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core';
import { ReactComponent as IconToastError } from './assets/icons/icon-toast-error.svg';
import { ReactComponent as IconToastInfo } from './assets/icons/icon-toast-info.svg';
import { ReactComponent as IconToastSuccess } from './assets/icons/icon-toast-success.svg';
import { ReactComponent as IconToastWarning } from './assets/icons/icon-toast-warning.svg';
import { AuthInit } from './modules/auth';

const renderIcon = (value: { theme: string; type: string }) => {
  switch (value.type) {
    case 'info':
      return <IconToastInfo />;
    case 'warning':
      return <IconToastWarning />;
    case 'error':
      return <IconToastError />;
    default:
      return <IconToastSuccess />;
  }
};

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="colored"
              className="custom-toast-body"
              icon={renderIcon}
            />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
