import styled from 'styled-components';

import Typography from '../Typography';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  height: 100%;
`;

export const Body = styled.div`
  background: var(--white);
  border-radius: 8px;
`;
interface Props {
  title: string;
  header?: React.ReactNode;
  children: React.ReactNode;
  rootBody?: React.CSSProperties;
  AdditionHeaderComponent?: React.ReactNode;
  noWrap?: boolean;
}
const RootContentPage: React.FC<Props> = ({
  title,
  children,
  header,
  rootBody,
  AdditionHeaderComponent,
  noWrap,
}) => {
  return (
    <Main>
      <div
        style={{
          marginBottom: 24,
          display: header ? undefined : 'flex',
          justifyContent: header ? undefined : 'space-between',
        }}
      >
        {header ?? (
          <>
            <Typography variant="heading-6">{title}</Typography>
            {/* Space between */}
            {AdditionHeaderComponent}
          </>
        )}
      </div>

      {noWrap ? (
        children
      ) : (
        <Body
          style={{
            ...rootBody,
          }}
        >
          {children}
        </Body>
      )}
    </Main>
  );
};
export default RootContentPage;
