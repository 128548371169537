import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import styled from 'styled-components';

import ButtonDefault from 'app/components/Button/ButtonDefault';
import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getTimeRange } from 'app/modules/projection/core/helper';

import { formatDate, getFullName } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';
import { getPathWithParams, useNavigateWithPreviousPath, usePageGoBack } from 'app/helpers/utils';
import { useBoolean, useNumber } from 'app/hooks';
import { cloneDeep, includes } from 'lodash';

import { PATH } from 'app/constants/path';

import { ReactComponent as EditICon } from 'app/assets/icons/edit-icon.svg';
import { ReactComponent as IconEditPrimary } from 'app/assets/icons/icon-edit-primary.svg';
import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import BodyFormTableStockRunItemCard from './components/BodyFormTableStockRunItemCard';
import InfoItemStockRunCard from './components/InfoItemStockRunCard';
import StatusStockRunCard from './components/StatusStockRunCard';
import { historyStockRunColumns } from './core/columns';
import { STOCK_RUN_STATUS_BG_DETAIL, STOCK_TYPE_TITLE } from './core/constants';
import { StatusStockUp, StockRunType, TAB_STOCK_RUN_DETAIL } from './core/enum';
import { useUpdateStockRunStatus } from './core/helpers';
import { itemsStockRunColumns } from './core/itemColumns';
import {
  getHistoryStockRunItems,
  getStockRun,
  getStockRunItems,
  updateStatusStockRun,
  updateStockRun,
} from './core/requests';
import { StockRun, StockRunRequest } from './core/type';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const BodyDetailStockRun = styled.div`
  padding: 28px;
  background: var(--white);
  border-radius: 8px;
`;
const ListItemBody = styled.div``;
const ButtonEdit = styled.div<{ isDraft?: boolean }>`
  height: 43px;
  border-radius: 8px;
  width: 130px;
  ${({ isDraft }) =>
    isDraft
      ? ` background: var(--white) !important;
          p{
            color:var(--neutral-7);
          };
          svg path[stroke] {
            stroke: var(--neutral-7);
          };
        :hover {
          background: var(--neutral-3) !important;
          p {
            color: var(--neutral-10);
          }
          svg path {
              stroke: var(--neutral-10);
          }
        }`
      : `background: var(--primary) !important;
        :hover {
          background: var(--primary-8) !important;
        }`}
`;

const getValue = (value?: number) => {
  return value ?? 0;
};

const StockRunDetailPage = () => {
  const navigate = useNavigateWithPreviousPath();
  const onBack = usePageGoBack(PATH.INVENTORY_STOCK_RUN);
  const { getStatusKey, setStatusKey, removeStatusKey } = useUpdateStockRunStatus();

  const params = useParams<any>();
  const [stockRun, setStockRun] = useState<StockRun | null>(null);
  const itemsTotal = useNumber(0);
  const historyTotal = useNumber(0);

  const isLoading = useBoolean(false);
  const [tab, setTab] = useState<TAB_STOCK_RUN_DETAIL>(TAB_STOCK_RUN_DETAIL.ITEM);
  useEffect(() => {
    if (!params.id) {
      navigate(PATH.INVENTORY_STOCK_RUN);
      return;
    }
    if (getStatusKey()) {
      changeStatusStockRun(Number(getStatusKey()));
    } else {
      getStockRunItem(Number(params.id));
    }
  }, [params.id]);

  const changeStatusStockRun = async (status: StatusStockUp) => {
    try {
      await updateStatusStockRun(Number(params.id), { status });
    } catch (error) {
      console.log(error);
    }
    await getStockRunItem(Number(params.id));
    removeStatusKey();
  };

  const getStockRunItem = async (idStockRun: number) => {
    try {
      isLoading.setValue(true);
      const dataStockRunRes = await getStockRun(idStockRun);
      if (dataStockRunRes) {
        setStockRun(dataStockRunRes);
      }
    } catch (error) {
      navigate(PATH.INVENTORY_STOCK_RUN);
    } finally {
      isLoading.setValue(false);
    }
  };
  const onSubmitPostStockRun = async () => {
    if (!stockRun?.id) {
      return;
    }
    try {
      isLoading.setValue(true);
      const requestStockRun: StockRunRequest = {
        index: stockRun?.index,
        endTime: stockRun?.endTime,
        startTime: stockRun?.startTime,
        wardId: Number(stockRun.location?.id),
        status: StatusStockUp.Posted,
      };
      await updateStockRun(stockRun.id, requestStockRun);
      const newStockRun = cloneDeep(stockRun);
      newStockRun.status = StatusStockUp.Posted;
      setStockRun(newStockRun);
      isLoading.setValue(false);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoading.setValue(false);
    }
  };

  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };
  const isDraft = stockRun?.status === StatusStockUp.Draft;
  const onChangeNavigation = (newValue: number) => () => {
    setTab(newValue);
  };
  if (!stockRun?.id) {
    return <LoadingCard />;
  }

  const renderTable = () => {
    return (
      <div
        className="d-flex flex-column"
        style={{
          width: '100%',
        }}
      >
        <BodyFormTableStockRunItemCard
          isShowCard={tab === TAB_STOCK_RUN_DETAIL.HISTORY}
          total={historyTotal.value}
          label="No item was stocked up yet"
        >
          <TableComponent
            originColumns={historyStockRunColumns()}
            nameQuery={QUERIES.HISTORY__STOCK_RUN_LIST}
            getList={getHistoryStockRunItems}
            paramId={params.id}
            isBorder={false}
            isHiddenHeader
            onClickBodyTable={onClickBodyTable}
            defaultState={{
              page: 1,
              limit: 10,
              orderBy: 'dateModified',
              orderDirection: 'DESC',
            }}
            getTotal={(total: number) => historyTotal.setValue(total)}
            isRowClickable={false}
          />
        </BodyFormTableStockRunItemCard>
        <BodyFormTableStockRunItemCard
          isShowCard={tab === TAB_STOCK_RUN_DETAIL.ITEM}
          total={itemsTotal.value}
          label="No item selected"
        >
          <TableComponent
            originColumns={itemsStockRunColumns()}
            nameQuery={QUERIES.ITEM__STOCK_RUN_LIST}
            getList={getStockRunItems}
            paramId={params.id}
            isBorder={false}
            isHiddenHeader
            onClickBodyTable={onClickBodyTable}
            isRowClickable={false}
            defaultState={{
              page: 1,
              limit: 10,
              orderBy: 'order',
              orderDirection: 'DESC',
            }}
            getTotal={(total: number) => itemsTotal.setValue(total)}
          />
        </BodyFormTableStockRunItemCard>
      </div>
    );
  };
  const showEditButton =
    includes([StatusStockUp.Draft, StatusStockUp.Posted], stockRun?.status) ||
    (stockRun.status === StatusStockUp['Past-due'] && stockRun.activate === 0);
  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <Header>
            <div className="d-flex align-items-center">
              <IconRedirect
                style={{
                  cursor: 'pointer',
                }}
                onClick={onBack}
              />
              <Typography
                variant="heading-6"
                style={{
                  marginRight: 24,
                }}
              >
                {stockRun?.index}
              </Typography>
              <StatusStockRunCard
                status={stockRun?.status ?? StatusStockUp.Draft}
                height={38}
                styleLabel={{
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '26px',
                }}
                bg={STOCK_RUN_STATUS_BG_DETAIL}
              />
            </div>
            <div className="d-flex align-items-center">
              {showEditButton && (
                <ButtonEdit
                  isDraft={isDraft}
                  onClick={() => {
                    const time = getTimeRange(stockRun.demand);
                    if (
                      [StatusStockUp.Posted, StatusStockUp['Past-due']].includes(stockRun?.status)
                    ) {
                      setStatusKey(String(stockRun?.status));
                    }
                    navigate(
                      getPathWithParams(
                        PATH.INVENTORY_STOCK_RUN_EDIT.replace(':id', String(stockRun?.id)),
                        {
                          forecastingWard: stockRun.location?.id,
                          startDate: time.startDate ?? stockRun.actualStartTime,
                          demandId: stockRun?.demand?.id,
                          timeFrame: stockRun?.demand?.timeFrame,
                          endDate: time.endDate ?? stockRun.actualEndTime,
                        },
                      ),
                    );
                  }}
                  className="btn d-flex justify-content-center align-items-center"
                >
                  {isDraft ? <IconEditPrimary /> : <EditICon />}
                  <Typography
                    variant="title-4"
                    color={isDraft ? 'primary' : 'white'}
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    Edit
                  </Typography>
                </ButtonEdit>
              )}
              {stockRun?.status === StatusStockUp.Draft && (
                <ButtonDefault
                  style={{
                    marginLeft: 12,
                  }}
                  onClick={onSubmitPostStockRun}
                  disabled={!stockRun.noOfBins}
                >
                  Post
                </ButtonDefault>
              )}
            </div>
          </Header>
          <BodyDetailStockRun>
            <Typography
              fontSize={18}
              lineHeight={32}
              fontWeight={600}
              color="neutral-10"
              style={{
                marginBottom: 32,
              }}
            >
              Detail
            </Typography>
            <div className="d-flex">
              <InfoItemStockRunCard title="Ward" value={stockRun?.location?.name} />
              <InfoItemStockRunCard
                title="Start date"
                value={formatDate(stockRun?.actualStartTime ?? stockRun?.startTime)}
              />
              <InfoItemStockRunCard
                title="Complete date"
                value={formatDate(stockRun?.actualEndTime ?? stockRun?.endTime)}
              />
            </div>

            <div
              className="d-flex"
              style={{
                marginTop: 32,
              }}
            >
              <InfoItemStockRunCard
                title="Completed racks"
                value={`${getValue(stockRun?.noOfRacksCompeted)} /
           ${getValue(stockRun?.noOfRacks)}`}
              />
              <InfoItemStockRunCard
                title="Completed bins"
                value={`${getValue(stockRun?.noOfBinsCompeted)} /
           ${getValue(stockRun?.noOfBins)}`}
              />
              <InfoItemStockRunCard title="Created date" value={formatDate(stockRun?.createdAt)} />
            </div>

            <div className="d-flex" style={{ marginTop: 32 }}>
              <InfoItemStockRunCard
                title="Stock run type"
                value={stockRun.type != null ? STOCK_TYPE_TITLE[stockRun.type] : ''}
              />
              <InfoItemStockRunCard
                title="Created by"
                value={getFullName(stockRun.addedBy?.firstName, stockRun.addedBy?.lastName)}
              />
              <InfoItemStockRunCard
                title="Last updated by"
                value={`${
                  stockRun.updatedBy
                    ? getFullName(stockRun.updatedBy?.firstName, stockRun.updatedBy?.lastName)
                    : getFullName(stockRun.addedBy?.firstName, stockRun.addedBy?.lastName)
                }`}
              />
            </div>
          </BodyDetailStockRun>
        </div>
      }
    >
      <ListItemBody>
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <NavigationDefault
            valueActive={tab}
            navigation={[
              {
                title: `Items (${itemsTotal.value})`,
                value: TAB_STOCK_RUN_DETAIL.ITEM,
              },
              {
                title: 'Stock run history',
                value: TAB_STOCK_RUN_DETAIL.HISTORY,
              },
            ]}
            onChangeNavigation={onChangeNavigation}
          />
        </div>
        {renderTable()}
      </ListItemBody>
      {isLoading.value && <LoadingCard />}
    </RootContentPage>
  );
};
export default StockRunDetailPage;
