import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import Typography from 'app/components/Typography';

import { ReactComponent as EditICon } from 'app/assets/icons/edit-icon.svg';

const ButtonEdit = styled.div`
  width: 140px;
  height: 43px;
  border-radius: 8;
  svg path[stroke] {
    stroke: var(--primary);
  }
  :hover {
    svg path[stroke] {
      stroke: var(--white);
    }
    background: var(--primary);
    p {
      color: var(--white);
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  isEdit: boolean;
  disableButton: boolean;
  isSubmitting: boolean;
  onClickEdit: () => void;
  onCancel: () => void;
  onSubmit?: () => void;
}
const ActionButtonCard: React.FC<Props> = ({
  isEdit,
  onClickEdit,
  onCancel,
  disableButton,
  isSubmitting,
  onSubmit,
}) => {
  if (!isEdit) {
    return (
      <div className="d-flex">
        <ButtonEdit
          className="btn d-flex justify-content-center align-items-center"
          onClick={onClickEdit}
        >
          <EditICon />
          <Typography
            variant="title-4"
            color="primary"
            style={{
              marginLeft: 8,
            }}
          >
            Edit
          </Typography>
        </ButtonEdit>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <ActionButton
        onCancel={onCancel}
        disableButton={disableButton}
        isSubmitting={isSubmitting}
        labelButtonSubmit="Save"
        customClass="pt-0 mt-0"
        labelButtonReset="Cancel"
        onSubmit={onSubmit}
      />
    </div>
  );
};
export default ActionButtonCard;
