import styled from 'styled-components';

import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import { StockLevelAlertIcon } from 'app/components/Status/StockLevelAlertIcon';
import Typography from 'app/components/Typography';

import InfoItemCard from 'app/modules/inventory/items/components/InfoItemCard';
import { StockTypeEnum } from 'app/modules/inventory/items/core/enum';

import { ReportDetail } from '../core/type';
import { ReportChart } from './ReportChart';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px;
  background: var(--white);
  border-radius: 8px;
  margin-top: 24px;
`;

const AttributeColumn: React.FC<{
  leftTitle: string;
  leftValue?: string | number;
  rightTitle: string;
  rightValue?: string | number;
  leftContent?: any;
  rightContent?: any;
}> = ({ leftTitle, leftValue, rightTitle, rightValue, leftContent, rightContent }) => {
  return (
    <div className="d-flex flex-row" style={{ marginBottom: 24 }}>
      <div
        style={{
          width: '50%',
          marginRight: 24,
          minWidth: '167px',
          display: 'flex',
        }}
      >
        <InfoItemCard
          title={leftTitle}
          value={leftValue}
          styleRoot={{ marginBottom: 0, width: 'fit-content' }}
          fontSizeValue={16}
          icon={leftContent}
        />
      </div>

      <div style={{ width: '50%', minWidth: '167px' }}>
        <InfoItemCard
          title={rightTitle}
          value={rightValue}
          styleRoot={{ marginBottom: 0 }}
          fontSizeValue={16}
        />
        {rightContent}
      </div>
    </div>
  );
};

export const ShowInfoReportCard: React.FC<{ report: ReportDetail | null }> = ({ report }) => {
  return (
    <Main>
      <Typography
        fontWeight={600}
        fontSize={18}
        lineHeight={32}
        color="neutral-10"
        style={{
          marginBottom: 32,
        }}
      >
        Item info
      </Typography>
      <div className="d-flex" style={{ width: '100%' }}>
        <div
          style={{
            paddingRight: 32,
          }}
        >
          <ShowImageDefault
            image={report?.image ?? ''}
            styleMain={{ width: 160, height: 160, marginBottom: 24 }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridRow: 10,
            width: '100%',
          }}
        >
          <AttributeColumn
            leftTitle="Item name"
            leftValue={report?.name}
            rightTitle="Item type"
            rightValue={StockTypeEnum[Number(report?.stockType)]}
          />
          <AttributeColumn
            leftTitle="SKU"
            leftValue={report?.skuId}
            rightTitle="Sub SKU"
            rightValue={report?.skuSub}
          />
          <AttributeColumn
            leftTitle="Qty balance"
            leftValue={report?.qtyBalance}
            rightTitle="Par level performance"
            rightValue={report?.parLevelPerformance?.toFixed(2)}
            leftContent={
              report && <StockLevelAlertIcon stockLevel={report.stockLevel} marginLeft={0} />
            }
          />
          <AttributeColumn
            leftTitle="Total pickup (last 30 days)"
            leftValue={report?.totalPickup}
            rightTitle="Total stock up (last 30 days)"
            rightValue={report?.totalStockup}
          />
        </div>
      </div>
    </Main>
  );
};
