import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { DEFAULT_TABLE_QUERY, QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';

import { LOGIN_TYPE } from 'app/modules/auth/core/enum';
import { useSyncQueryToState } from 'app/modules/query-state/hooks';

import { useNavigateWithSendPath } from 'app/helpers/utils';

import { PATH } from 'app/constants/path';

import { deleteSecurityGroups, getSecurityGroups } from '../security-groups/core/requests';
import { SecurityGroup } from '../security-groups/core/type';
import { usersRoleColumns } from './core/columns';
import { FormUsersRole } from './modal/FormUsersRole';

const UsersRoleWebManagement = () => {
  useSyncQueryToState(window.location.pathname, DEFAULT_TABLE_QUERY);

  const navigate = useNavigateWithSendPath();
  const renderModalForm = (originalData: any, _typeModal: TYPE_MODAL) => {
    return <FormUsersRole usersRole={originalData} loginType={LOGIN_TYPE.WEB} />;
  };

  const onClickBodyTable = (row: Row) => () => {
    const newUsersRole = row.original as SecurityGroup;
    if (newUsersRole.id) {
      navigate(PATH.USERS_ROLE_WEB_DETAIL.replace(':id', String(newUsersRole.id)));
    }
  };
  return (
    <TableComponent
      originColumns={usersRoleColumns()}
      nameQuery={QUERIES.USERS_ROLE_WEB_LIST}
      getList={getSecurityGroups}
      ModalForm={renderModalForm}
      deleteById={deleteSecurityGroups}
      moreQuery={`&type=1`}
      titleModal="role"
      contentModal="Deleting this role will also remove this role from all the accounts."
      onClickBodyTable={onClickBodyTable}
      placeholderSearch="Search with role"
      isBorder={false}
      syncParamUrl
      spacing="lg"
    />
  );
};

export default UsersRoleWebManagement;
