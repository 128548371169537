import { AddButton } from 'app/components/Button/AddButton';

import { isUndefined } from 'lodash';

import { CustomTableToolbar } from '../TableComponent';
import { useListView } from '../core/ListViewProvider';
import { TYPE_MODAL } from '../core/types';
import { SearchComponent } from './SearchComponent';

interface Props {
  nameBtnAdd?: string;
  placeholderSearch: string;
  onRedirectPage?: () => void;
  isBorder?: boolean;
  customListHeader?: React.ReactNode;
  CustomToolbar?: CustomTableToolbar;
  containerStyle?: React.CSSProperties;
  syncParamUrl?: boolean;
  hideSearch?: boolean;
}
const ListHeader: React.FC<Props> = ({
  nameBtnAdd,
  placeholderSearch,
  onRedirectPage,
  customListHeader,
  CustomToolbar,
  containerStyle,
  syncParamUrl,
  hideSearch,
}) => {
  const { setItemForUpdate } = useListView();
  const onClickBtnAdd = () => {
    if (!isUndefined(onRedirectPage)) {
      onRedirectPage();
      return;
    }
    setItemForUpdate({
      originalData: null,
      type: TYPE_MODAL.ADD,
      isView: false,
      titleModal: '',
    });
  };

  return (
    <div
      className="card-header border-0 d-flex align-items-center"
      style={{
        // padding: isBorder ? "28px 28px 32px 28px" : "0px 0px 32px 0px",
        padding:
          CustomToolbar?.Left || CustomToolbar?.Right || !hideSearch
            ? '28px 28px 32px 28px'
            : undefined,
        ...containerStyle,
      }}
    >
      {CustomToolbar?.Left ||
        (hideSearch ? null : (
          <SearchComponent placeholderSearch={placeholderSearch} syncParamUrl={syncParamUrl} />
        ))}

      {CustomToolbar?.Right || (
        <div className="card-toolbar">
          {nameBtnAdd && <AddButton title={nameBtnAdd} onClick={onClickBtnAdd} />}
          {customListHeader}
        </div>
      )}
    </div>
  );
};

export { ListHeader };
