import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { FormSelect } from 'app/components/Select/FormSelect';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { getResponseError } from 'app/helpers/errors';
import { useNumber } from 'app/hooks';
import { isEqual, isUndefined } from 'lodash';

import { createRack, updateRack } from '../core/requests';
import { Rack, RackRequest, initialRackRequest } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const WardCard = styled.div`
  width: 100%;
  /* padding-left: 8px; */
`;

interface Props {
  onClickCloseModal?: (newRack?: Rack) => void;
  itemRack?: Rack;
  wards: Ward[];
}
const FormRackModal: React.FC<Props> = ({ itemRack, wards, onClickCloseModal }) => {
  const { updateState, state } = useQueryRequest();
  const { setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [formRequest] = useState<RackRequest>({
    name: itemRack ? itemRack.name : initialRackRequest.name,
    code: itemRack ? itemRack.code : initialRackRequest.code,
    info: itemRack ? itemRack.info : initialRackRequest.info,
  });
  const wardId = useNumber(0);
  const hasItemAssigned = itemRack?.items ? itemRack.items > 0 : false;
  useEffect(() => {
    if (itemRack?.location) {
      wardId.setValue(Number(itemRack.location.id));
    }
  }, [itemRack]);

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Name is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const request: RackRequest = {
          ...values,
          wardId: wardId.value,
          info: 'info',
        };

        if (
          isEqual(itemRack?.name, request.name) &&
          isEqual(itemRack?.location?.id, request.wardId)
        ) {
          cancel(true);
          return;
        }

        if (itemRack) {
          const dataRes = await updateRack(itemRack.id, request);
          toast.success(`Update rack successfully!`);
          if (dataRes) {
            if (onClickCloseModal) {
              onClickCloseModal(dataRes);
              return;
            }
            cancel(true);
          }
          return;
        }
        await createRack(request);
        toast.success(`Add rack successfully!`);
        if (state.page !== 1) {
          cancel();
          updateState({
            page: 1,
          });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });
  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onClickCloseModal)) {
      onClickCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };
  return (
    <React.Fragment>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <FormInput
          label="Rack name"
          placeholder="Rack name"
          formik={formik}
          field="name"
          isRequired
          rootClass="w-100"
        />
        <div className="d-flex justify-content-between">
          <WardCard>
            <FormSelect
              label="Assign to ward"
              handleChange={(select) => wardId.setValue(select.value)}
              isDisable={hasItemAssigned}
              optionsSelect={wards.map((el) => {
                return {
                  value: Number(el.id),
                  title: el.name,
                };
              })}
              activeSelect={wardId.value}
              fieldActive="value"
              fieldSelect="title"
              placementCustom="bottom-start"
              styleSelectCard={{
                width: 584,
              }}
            />
          </WardCard>
        </div>

        <ActionButton
          onCancel={() => cancel()}
          disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={itemRack ? 'Save' : 'Add'}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </React.Fragment>
  );
};
export default FormRackModal;
