import styled from 'styled-components';

import Typography from 'app/components/Typography';

import {
  STOCK_RUN_STATUS_BG,
  STOCK_RUN_STATUS_BG_DETAIL,
  STOCK_RUN_STATUS_COLOR,
} from '../core/constants';
import { StatusStockUp } from '../core/enum';

interface Props {
  status: StatusStockUp;
  height?: number;
  styleLabel?: React.CSSProperties;
  bg?: { [key in StatusStockUp]: string };
}
const MainStatus = styled.div<{ height?: number; bg?: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px 14px;
  border-radius: 4px;
  ${({ height, bg }) => `height:${height}px;background-color:${bg}`}
`;
const StatusStockRunCard: React.FC<Props> = ({ status, height = 28, styleLabel, bg }) => {
  return (
    <MainStatus
      height={height}
      bg={bg ? STOCK_RUN_STATUS_BG_DETAIL[status] : STOCK_RUN_STATUS_BG[status]}
    >
      <Typography
        variant="title-5"
        color={STOCK_RUN_STATUS_COLOR[status]}
        style={{
          ...styleLabel,
        }}
      >
        {StatusStockUp[status]}
      </Typography>
    </MainStatus>
  );
};
export default StatusStockRunCard;
