import { PATH } from 'app/constants/path';

import { getAuth, removeAuth } from '../modules/auth';
import axios, { AxiosError } from 'axios';

export default function () {
  axios.defaults.headers['Accept'] = 'application/json';

  axios.interceptors.request.use((config) => {
    const auth = getAuth();
    if (config.headers && auth?.access_token) {
      config.headers.Authorization = `Bearer ${auth.access_token}`;
    }

    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (window.location.pathname !== PATH.LOGIN && error.response?.status === 401) {
        removeAuth();
        window.location.href = PATH.LOGIN;
      }

      return Promise.reject(error);
    },
  );
}
