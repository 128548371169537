import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ModalDefault from 'app/components/Modal/ModalDefault';
import HeartBeatStatus from 'app/components/Status/HeartBeatStatus';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import SelectAction from 'app/components/Tippy/SelectAction';
import Typography from 'app/components/Typography';
import { COLOR } from 'app/components/Typography/core/enum';
import RootContentPage from 'app/components/layout/RootContentPage';

import { usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { cloneDeep } from 'lodash';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { initialCustomer } from '../../company-information/core/type';
import { getWard } from '../wards/core/requests';
import { Ward, initialWard } from '../wards/core/type';
import { FormWardTableCard } from '../wards/modal/FormWardTableCard';
import { bedsColumns } from './core/columns';
import { deleteBed, getBeds } from './core/requests';
import { Bed } from './core/type';
import EditBed from './modal/EditBed';
import { FormBedTableCard } from './modal/FormBedTableCard';
import FormChangeStatusCard from './modal/FormChangeStatusCard';

const BedManagementPage = () => {
  const goBack = usePageGoBack(PATH.WARDS);

  const [ward, setWard] = useState<Ward>(initialWard);
  const params = useParams<any>();
  const isLoading = useBoolean();
  const isOpenMenu = useBoolean();
  const isOpenEditWard = useBoolean();

  useEffect(() => {
    if (!params.id) {
      return;
    }
    getDataWard(Number(params.id));
  }, [params]);

  const getDataWard = async (idWard: number) => {
    try {
      isLoading.setValue(true);
      const dataRes = await getWard(idWard);
      if (dataRes) {
        setWard(dataRes);
      }
    } catch (error) {
      goBack();
    } finally {
      isLoading.setValue(false);
    }
  };
  const onUpdateWhenAddBed = (newCountChildren: number) => {
    const newWard = cloneDeep(ward);
    newWard.countChildren = Number(newWard.countChildren) + newCountChildren;
    setWard(newWard);
  };

  const renderModalForm = (originalData: Bed, _typeModal: TYPE_MODAL, isView?: boolean) => {
    if (_typeModal === TYPE_MODAL.EDIT) {
      return <EditBed disableOrigin={isView} />;
    }

    return (
      <FormBedTableCard
        bed={originalData}
        ward={ward}
        onUpdateWhenAddBed={onUpdateWhenAddBed}
        originIsView={isView}
      />
    );
  };

  const renderFormConfirmation = (originalData: any, _typeModal: TYPE_MODAL) => {
    return <FormChangeStatusCard originalData={originalData} nameQuery={QUERIES.BEDS_LIST} />;
  };
  const updateWhenEdit = (newWard?: Ward) => {
    if (!newWard) {
      return;
    }
    const originWard = cloneDeep(ward);
    originWard.name = newWard.name;
    setWard(originWard);
    isOpenEditWard.setValue(false);
  };

  return (
    <RootContentPage
      rootBody={{
        padding: 0,
        border: 'none',
      }}
      title=""
      header={
        ward.name && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex  align-items-center">
              <div
                className="d-flex align-items-center"
                style={{
                  borderRight: `1px solid var(--neutral-4)`,
                }}
              >
                <IconRedirect
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={goBack}
                />
                <Typography
                  variant="heading-6"
                  style={{
                    marginRight: 24,
                  }}
                >
                  {ward.name}
                </Typography>
              </div>
              <div
                className="d-flex flex-column align-items-start justify-content-between"
                style={{ marginLeft: 24 }}
              >
                <Typography variant="title-5" style={{ marginBottom: 4 }}>
                  {'Raspberry Pi connection'}
                </Typography>
                <HeartBeatStatus pageType="ward" targetId={Number(ward.id)} />
              </div>
            </div>
            <SelectAction
              open={isOpenMenu}
              menuActions={[
                {
                  label: 'Edit',
                  onClick: () => {
                    isOpenEditWard.setValue(true);
                    isOpenMenu.setValue(false);
                  },
                },
              ]}
            />
          </div>
        )
      }
    >
      <div className="d-flex flex-column">
        {ward.id && (
          <TableComponent
            getList={getBeds}
            nameQuery={QUERIES.BEDS_LIST}
            originColumns={bedsColumns()}
            titleModal="Bed"
            ModalForm={renderModalForm}
            ModalFormConfirmation={renderFormConfirmation}
            placeholderSearch="Search with bed"
            nameBtnAdd="Add Bed"
            deleteById={deleteBed}
            contentModal="After deleting, nurses can no longer select this bed in their cart session."
            moreQuery={`&wardId=${ward.id}`}
            isBorder={false}
            defaultState={{
              limit: 10,
              page: 1,
              orderBy: 'name',
              orderDirection: 'DESC',
            }}
            spacing="xxl"
          />
        )}
        {isOpenEditWard.value && (
          <ModalDefault title="Edit Ward" onClickCloseModal={() => isOpenEditWard.setValue(false)}>
            <FormWardTableCard
              ward={ward}
              customer={ward.customer ?? initialCustomer}
              updateWhenEdit={updateWhenEdit}
              onClickCloseModal={() => isOpenEditWard.setValue(false)}
            />
          </ModalDefault>
        )}
        {isLoading.value && <LoadingCard />}
      </div>
    </RootContentPage>
  );
};

export default BedManagementPage;
