import { Dispatch, SetStateAction, createContext } from 'react';
import { Row } from 'react-table';

import { omit, omitBy } from 'lodash';

import { ID, QueryResponseContextProps, QueryState } from './types';
import qs from 'qs';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState);
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== '';
}

// Example: page=1&limit=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page', 'limit', state.type ? 'type' : '', state.param ? 'param' : ''],
    skipNulls: true,
  });
  const sort = qs.stringify(state, {
    filter: ['orderBy', 'orderDirection'],
    skipNulls: true,
  });
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : '';

  const filter = state.filter
    ? Object.entries(state.filter)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`;
        })
        .join('&')
    : '';
  const extraQuery = qs.stringify(
    omitBy(
      omit(state, 'page', 'limit', 'param', 'orderBy', 'orderDirection', 'search', 'filter'),
      (v: any) => v === '' || v === undefined || v === null,
    ),
  );

  return `${[pagination, sort, search, filter].filter((f) => f).join('&')}${
    extraQuery ? `&${extraQuery}` : ''
  }`;
  // .toLowerCase();
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query);
  return cache as QueryState;
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true;
  }

  return !data || !data.length;
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false;
  }

  return data.length > 0 && data.length === selected.length;
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
) {
  if (!id) {
    return;
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id));
  } else {
    const updatedSelected = [...selected];
    updatedSelected.push(id);
    setSelected(updatedSelected);
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>,
) {
  if (isAllSelected) {
    setSelected([]);
    return;
  }

  if (!data || !data.length) {
    return;
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id));
}

function checkIsRowHoverable(
  row: Row,
  isRowClickable: boolean,
  fieldCheckShowCursorInRow?: string,
) {
  if (isRowClickable !== undefined) {
    const isRowMeta = row.original[fieldCheckShowCursorInRow as string];
    return isRowClickable && !isRowMeta;
  }
  return isRowClickable;
}

const getHeaderTableStyle = (index: number, total: number) => {
  return {
    paddingTop: 0,
    paddingBottom: 16,
    paddingLeft: index === 0 ? 28 : 12,
    paddingRight: index === total - 1 ? 28 : 12,
  };
};

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  isNotEmpty,
  checkIsRowHoverable,
  getHeaderTableStyle,
};
