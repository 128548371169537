import { Row } from 'react-table';

import TableComponent from 'app/components/Table/TableComponent';
import { DEFAULT_TABLE_QUERY, QUERIES } from 'app/components/Table/core/constants';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useSyncQueryToState } from 'app/modules/query-state/hooks';

import { useNavigateWithSendPath } from 'app/helpers/utils';

import { PATH } from 'app/constants/path';

import { itemsColumns } from './core/columns';
import { deleteItem, getItems } from './core/requests';
import { Item } from './core/type';
import { FormChangeStatusItem } from './modal/FormChangeStatusItem';
import { InfoCard } from './modal/InfoCard';

const ItemManagementPage = () => {
  useSyncQueryToState(window.location.pathname, DEFAULT_TABLE_QUERY);

  const navigate = useNavigateWithSendPath();

  const renderFormConfirmation = (originalData: Item, _typeModal: TYPE_MODAL) => {
    if (_typeModal === TYPE_MODAL.INFO) {
      return (
        <InfoCard content="To delete this item, it needs to not exist in any stock run (except for completed stock run)." />
      );
    }
    if (_typeModal === TYPE_MODAL.UNABLE_CHANGE_ACTIVE_STATUS) {
      return (
        <InfoCard content="To inactivate this item, it has to be removed from all the bins." />
      );
    }

    return <FormChangeStatusItem originalData={originalData} nameQuery={QUERIES.ITEMS_LIST} />;
  };
  const onRedirectPage = () => {
    navigate(PATH.ITEMS_ADD);
  };
  const onClickBodyTable = (row: Row) => () => {
    const newItem = row.original as Item;
    if (newItem.id) {
      navigate(PATH.ITEMS_DETAIL.replace(':id', String(newItem.id)));
    }
  };

  return (
    <RootContentPage
      title="Items"
      rootBody={{
        padding: 0,
        border: 'none',
      }}
    >
      <TableComponent
        getList={getItems}
        nameQuery={QUERIES.ITEMS_LIST}
        originColumns={itemsColumns()}
        onRedirectPage={onRedirectPage}
        titleModal="Item"
        ModalFormConfirmation={renderFormConfirmation}
        onClickBodyTable={onClickBodyTable}
        nameBtnAdd="Add item"
        deleteById={deleteItem}
        contentModal="Deleting this item will also remove it from the assigned bins."
        placeholderSearch="Search with item name, SKU"
        styleModalForm={{
          paddingRight: 0,
        }}
        isBorder={false}
        syncParamUrl
      />
    </RootContentPage>
  );
};

export default ItemManagementPage;
