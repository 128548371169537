import { auto } from '@popperjs/core';

import { forwardRef } from 'react';

import { formatDate, isChrome } from 'app/helpers';
import { useBoolean } from 'app/hooks';
import { addMinutes, isAfter } from 'date-fns';

import FormSelectTimeOnly, { FormSelectTimeOnlyProps } from '../Select/FormSelectTimeOnly';
import { TippyCustomzieProps, TippyDefault } from '../Tippy';
import { MenuAction, MenuItem } from '../Tippy/TippyMenu';
import Typography from '../Typography';

interface Props extends FormSelectTimeOnlyProps {
  popupProps?: TippyCustomzieProps;
  timeFrame: Date[];
  durationStartTime?: Date;
}

const getPaddingByBrowser = () => {
  return isChrome() ? 0 : 8;
};

const getRightPadding = (timeFrameLength: number) => {
  return timeFrameLength > 5 ? getPaddingByBrowser() : 8; //Why 5? Because the scrollbar appears when the number of options is greater than 5
};

const renderMenuSelectTime = (timeItem: Date, durationStartTime?: Date) => {
  if (!durationStartTime) {
    return formatDate(timeItem, 'h:mm aa');
  }
  let deltaTime = timeItem.getHours() - durationStartTime.getHours();

  if (
    timeItem.getHours() <= durationStartTime.getHours() &&
    isAfter(timeItem, addMinutes(durationStartTime, 30))
  ) {
    deltaTime += 24;
  }

  /*in case of start time is 11:30 PM, the duration to 12:00 AM will be -23.5 hours. If that happens, add 24 to delta time */
  if (deltaTime < 0) {
    deltaTime += 24;
  }
  const minutes = timeItem.getMinutes() - durationStartTime.getMinutes();
  const aboutMinutes = minutes / 60;

  return `${formatDate(timeItem, 'h:mm aa')} (${(deltaTime + aboutMinutes).toFixed(1)} hours)`;
};

const getOptionStyle = (active: boolean) => {
  return active
    ? {
        background: '#f7fcff',
        color: 'var(--primary)',
        fontWeight: 600,
      }
    : {
        background: '',
        color: '',
        fontWeight: 400,
      };
};

export const TimePickerInput: React.FC<Props> = forwardRef(
  ({ popupProps, timeFrame, durationStartTime, ...props }, ref) => {
    const isOpen = useBoolean(false);

    return (
      <TippyDefault
        interactive
        arrow={false}
        containerClass="action-menu"
        offset={[0, 10]}
        trigger="click"
        onClickOutside={() => isOpen.setValue(false)}
        visible={isOpen.value}
        allowHTML
        {...popupProps}
        content={
          <MenuAction
            style={{
              width: auto,
              overflow: 'auto',
              padding: `8px ${getRightPadding(timeFrame.length)}px 8px 8px`,
            }}
          >
            <div
              ref={ref as any}
              style={{
                maxHeight: 206,
              }}
            >
              {timeFrame.length ? (
                timeFrame.map((el, index) => {
                  const active =
                    el.getHours() === props.time?.getHours() &&
                    el.getMinutes() === props.time.getMinutes();

                  const { background, color, fontWeight } = getOptionStyle(active);

                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        props.setTime(el);
                        isOpen.setValue(false);
                      }}
                      style={{
                        marginBottom: index === timeFrame.length - 1 ? 0 : 4,
                        whiteSpace: 'nowrap',
                        background,
                        color,
                        fontWeight,
                      }}
                    >
                      {renderMenuSelectTime(el, durationStartTime)}
                    </MenuItem>
                  );
                })
              ) : (
                <Typography>No option available</Typography>
              )}
            </div>
          </MenuAction>
        }
      >
        <div onClick={() => isOpen.setValue(true)}>
          <FormSelectTimeOnly {...props} />
        </div>
      </TippyDefault>
    );
  },
);
