import { ID, Response } from 'app/components/Table/core/types';

import { getTimezoneOffset, trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import { HistoryBin, HistoryBinQueryResponse } from '../../bins/core/type';
import { StatusStockUp } from '../../stock-run/core/enum';
import { StockRun, StockRunsQueryResponse } from '../../stock-run/core/type';
import {
  ActivityLog,
  ActivityLogDetailQueryResponse,
  LogItemDetail,
  Rack,
  RackRequest,
  RacksActivityLogResponse,
  RacksQueryResponse,
} from './type';
import axios, { AxiosResponse } from 'axios';

const RACK_URL = `${API_URL}/api/racks`;
const RACK_URL_V2 = `${API_URL}/api/racks-v2`;
const getRacks = (query: string): Promise<RacksQueryResponse> => {
  return axios.get(`${RACK_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Rack>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const getRacks2 = (query: string): Promise<RacksQueryResponse> => {
  return axios.get(`${RACK_URL_V2}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Rack>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};
const getHistory = (query: string, rackId: ID): Promise<HistoryBinQueryResponse> => {
  return axios.get(`${RACK_URL}/${rackId}/history?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<HistoryBin>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const getRack = (rackId: ID): Promise<Rack | undefined> => {
  return axios
    .get(`${RACK_URL}/${rackId}`)
    .then((response: AxiosResponse<Response<Rack>>) => response.data)
    .then((response: Response<Rack>) => response.data);
};

const createRack = (request: RackRequest): Promise<Rack | undefined> => {
  return axios
    .post(RACK_URL, trimObject(request))
    .then((response: AxiosResponse<Response<Rack>>) => response.data)
    .then((response: Response<Rack>) => response.data);
};
const deleteRack = (rackId: ID): Promise<void> => {
  return axios.delete(`${RACK_URL}/${rackId}`);
};

const updateRack = (rackId: ID, request: RackRequest): Promise<Rack | undefined> => {
  return axios
    .patch(`${RACK_URL}/${rackId}`, trimObject(request))
    .then((response: AxiosResponse<Response<Rack>>) => response.data)
    .then((response: Response<Rack>) => response.data);
};
const synchronizedRack = (
  rackId: ID,
  request: {
    isSync: boolean;
  },
): Promise<Rack | undefined> => {
  return axios
    .patch(`${RACK_URL}/${rackId}/synchronized`, request)
    .then((response: AxiosResponse<Response<Rack>>) => response.data)
    .then((response: Response<Rack>) => response.data);
};

export const getRackActivityLogs = (rackId: ID): Promise<ActivityLog | undefined> => {
  return axios
    .get(`${RACK_URL}/${rackId}/activity-logs`, {
      params: {
        page: 1,
        limit: 999,
        tz: getTimezoneOffset(),
      },
    })
    .then((response: AxiosResponse<Response<ActivityLog>>) => response.data)
    .then((response: Response<ActivityLog>) => response.data);
};
const getRackActivityLogsList = (
  rackId: ID,
  day?: string,
  month?: string,
  page?: number,
): Promise<RacksActivityLogResponse> => {
  return axios
    .get(`${RACK_URL}/${rackId}/activity-logs/list`, {
      params: {
        page: page || 1,
        limit: 10,
        day,
        month,
        tz: getTimezoneOffset(),
      },
    })
    .then(
      (
        d: AxiosResponse<{
          data: {
            items: Array<LogItemDetail>;
            total: number;
          };
        }>,
      ) => {
        return {
          data: d.data.data,
        };
      },
    );
};
const getActivityLogDetail =
  (callback: () => void) =>
  (query: string, rackId: ID, moreParam?: string): Promise<ActivityLogDetailQueryResponse> => {
    return axios
      .get(`${RACK_URL}/${rackId}/activity-logs/${moreParam}/details?${query}`)
      .then(
        (
          d: AxiosResponse<{
            data: {
              items: Array<LogItemDetail>;
              total: number;
            };
          }>,
        ) => {
          return {
            data: d.data.data,
          };
        },
      )
      .finally(callback);
  };

export {
  getRacks,
  getRacks2,
  getRack,
  getHistory,
  createRack,
  updateRack,
  deleteRack,
  synchronizedRack,
  getRackActivityLogsList,
  getActivityLogDetail,
};

export const getShortForStockRuns = (
  query: string,
  _id: ID,
  moreParam?: string,
): Promise<StockRunsQueryResponse> =>
  axios
    .get(`${API_URL}/api/stock-run/short-for?${query}${moreParam || ''}`)
    .then((res: AxiosResponse<{ data: StockRun[] }>) => ({
      data: {
        items: res.data.data.map((el) => ({ ...el, statusName: StatusStockUp[el.status] })),
        total: res.data.data.length,
      },
    }));

export const addItemToStockRun = (stockRunId: ID, body: { itemId: ID; binId: ID }) =>
  axios
    .post(`${API_URL}/api/stock-run/${stockRunId}/short-for`, body)
    .then((res: AxiosResponse<{ data: { items: StockRun[]; total: number } }>) => ({
      data: res.data.data,
    }));
