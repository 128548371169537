import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';

import ModalDefault from 'app/components/Modal/ModalDefault';
import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { useChangeParams, useSyncQueryToState } from 'app/modules/query-state/hooks';
import { useQueryParamStore } from 'app/modules/query-state/store';
import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';
import { Ward } from 'app/modules/system-settings/locations/wards/core/type';

import { getResponseError } from 'app/helpers/errors';
import { useNavigateWithSendPath } from 'app/helpers/utils';
import { useBoolean, useNumber } from 'app/hooks';
import { cloneDeep } from 'lodash';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconGrid } from 'app/assets/icons/icon-view-grid-rack.svg';
import { ReactComponent as IconList } from 'app/assets/icons/icon-view-list-rack.svg';

import ButtonSwitchView, { ViewMode } from '../../../components/Button/ButtonSwitchView';
import ViewListBin from '../bins/ViewListBin';
import { getBinInRack } from '../bins/core/requests';
import { Bin } from '../bins/core/type';
import AssignItemModal from '../bins/modal/AssignItem.modal';
import { RemoveItemModal } from '../bins/modal/RemoveItem.modal';
import { UnableToRemoveItemModal } from '../bins/modal/UnableToRemoveItem.modal';
import InformationRackCard from './components/InformationRackCard';
import LabelSyncDataRack from './components/LabelSyncDataRack';
import { binInRackColumns } from './core/columns';
import { ActionBin } from './core/enum';
import { getRack, synchronizedRack } from './core/requests';
import { useRackSocket } from './core/socket';
import { Rack } from './core/type';
import { getListTabletName, mergeQtyItems, showUpdateAtRack } from './core/utils';
import UpdateRackModal from './modal/FormRack.modal';
import { QuantityUpdateModal } from './modal/QuantityUpdateModal';
import { ShortForModal } from './modal/ShortFor.modal';
import { changeGroupByItemsSelector, useRackStore } from './store';

const CHANGE_VIEW_MESSAGES = ['Switch to Grid view', 'Switch to Table view'];

const RackDetailManagement = () => {
  useSyncQueryToState(
    window.location.pathname,
    {
      mode: ViewMode.list,
      limit: 50,
      page: 1,
      orderBy: 'name',
      orderDirection: 'ASC',
    },
    { skipFields: ['mode'] },
  );

  const mode = useQueryParamStore((s) => s.state.curParams.mode);
  const { onChangeParams } = useChangeParams();

  const params = useParams<{ rackId: string }>();

  const customNavigate = useNavigateWithSendPath();

  const setRackState = useRackStore((s) => s.setState);
  const { rack, inSession, tablets, bins, changeItems, savedItems } = useRackStore((s) => s.state);
  const changeGroupByItems = useRackStore(changeGroupByItemsSelector);

  const [wards, setWards] = useState<Ward[]>([]);
  const isEditRack = useBoolean(false);
  const [selectBin, setSelectBin] = useState<Bin | null>(null);
  const isAssignItemInBin = useBoolean(false);
  const isRemoveItemInBin = useBoolean(false);
  const unableToRemoveItem = useBoolean(false);
  const showShortForModal = useBoolean(false);
  const showQuantityUpdateModal = useBoolean(false);
  const isLoadingPage = useBoolean(false);
  const totalBin = useNumber(0);
  const refetchTimestamp = useNumber(0);
  const isGridView = String(mode) === String(ViewMode.nonList);

  const onClearSession = (gridView: boolean, immediateUpdate: boolean, callback: () => void) => {
    if (immediateUpdate) {
      useRackStore.getState().saveCompletedTransaction();
    } else {
      if (gridView) {
        getBinInRackViewGrid(false, callback);
      } else {
        refetchTimestamp.setValue(+new Date());
        callback();
      }
    }
  };

  const getDataRack = async (oldRackId: number, isGetWards = false, showLoading = true) => {
    if (showLoading) {
      isLoadingPage.setValue(true);
    }

    const dataRes = await getRack(oldRackId);
    if (dataRes) {
      useRackStore.getState().setState({ rack: dataRes });
    }
    if (isGetWards) {
      const dataResWards = await getWards(
        stringifyRequestQuery({
          page: 1,
          limit: 100,
        }),
      );
      const newResWards = dataResWards.data?.items || [];
      newResWards.unshift({
        createdAt: '',
        id: 0,
        name: 'None',
        updatedAt: '',
      });
      setWards(newResWards);
    }

    if (showLoading) {
      isLoadingPage.setValue(false);
    }
  };

  useRackSocket(isGridView, onClearSession);

  useEffect(() => {
    if (!params?.rackId) {
      customNavigate(PATH.RACKS);
      return;
    }
    getBinInRackViewGrid();
    getDataRack(Number(params.rackId), true);
  }, [params.rackId]);

  useEffect(() => {
    return () => {
      useRackStore.getState().clearState();
    };
  }, []);

  const onClickCloseModalUpdate = async (newRack?: Rack) => {
    isEditRack.setValue(false);
    if (newRack) {
      setRackState({ rack: newRack });
    }
  };
  const onClickModal = (key: ActionBin, originBin: Bin) => {
    setSelectBin(originBin);
    switch (key) {
      case ActionBin.assignItem:
        isAssignItemInBin.setValue(true);
        break;
      case ActionBin.removeItem:
        isRemoveItemInBin.setValue(true);
        break;
      case ActionBin.shortFor:
        showShortForModal.setValue(true);
        break;
      case ActionBin.updateQuantity:
        showQuantityUpdateModal.setValue(true);
        break;
      default:
        break;
    }
  };
  const onClickCloseModalAssignItemInBin = async (updateBin?: Bin) => {
    isAssignItemInBin.setValue(false);
    if (updateBin) {
      await getBinInRackViewGrid();
      getDataRack(Number(rack.id));
    }
  };

  const onSubmitSyncRack = async () => {
    isLoadingPage.setValue(true);
    try {
      await synchronizedRack(rack.id, {
        isSync: true,
      });
      getDataRack(Number(rack.id));
    } catch (error: any) {
      getResponseError(error);
    } finally {
      isLoadingPage.setValue(true);
    }
  };

  const onNavigate = (itemId: string) => {
    customNavigate(PATH.ITEMS_DETAIL.replace(':id', String(itemId)));
  };

  const fetchBins = async () => {
    const dataRes = await getBinInRack(`page=1&limit=50&rack=${params.rackId}`);
    if (dataRes?.data?.items) {
      setRackState({ bins: dataRes?.data?.items });
    }
    if (dataRes?.data?.total) {
      totalBin.setValue(dataRes?.data?.total);
    }
  };

  const onChangeViewRack = (newView: ViewMode) => async () => {
    onChangeParams({ mode: newView });
    if (newView == ViewMode.nonList) {
      fetchBins();
    }
  };

  const getBinInRackViewGrid = async (load = true, callback?: () => void) => {
    load && isLoadingPage.setValue(true);
    await fetchBins();
    callback?.();
    load && isLoadingPage.setValue(false);
  };

  const renderModalForm = (originalData: Bin, typeModal?: TYPE_MODAL) => {
    switch (typeModal) {
      case TYPE_MODAL.REMOVE_ITEM:
        return (
          <RemoveItemModal
            binId={originalData.id}
            itemId={originalData.item?.id}
            onUpdateRack={() => {
              getDataRack(Number(rack.id));
            }}
            showUnableToRemoveModal={() => unableToRemoveItem.setValue(true)}
          />
        );
      case TYPE_MODAL.ASSIGN_ITEM:
        return (
          <AssignItemModal
            selectBin={originalData}
            onUpdateRack={() => {
              getDataRack(Number(rack.id));
            }}
          />
        );
      case TYPE_MODAL.SHORT_FOR:
        return (
          <ShortForModal
            selectBin={originalData}
            wardId={rack.location?.id}
            wardName={rack.location?.name || ''}
          />
        );
      case TYPE_MODAL.EDIT_QUANTITY:
        return (
          <QuantityUpdateModal
            selectBin={originalData}
            wardId={rack.location?.id}
            wardName={rack.location?.name || ''}
          />
        );
      default:
        return <div />;
    }
  };
  const onCloseModalRemoveItem = (updateBin?: boolean) => {
    if (updateBin) {
      getBinInRackViewGrid();
      getDataRack(Number(rack.id));
    }
    isRemoveItemInBin.setValue(false);
  };

  if (!rack.id || isLoadingPage.value) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <LoadingCard />
      </div>
    );
  }

  const mappingWithSocketDataMiddleware = (data: any) => {
    if (!data) {
      return undefined;
    }

    const rows = cloneDeep(data);

    // Mapping qty and stock level
    rows.data.items = rows.data.items.map(
      mergeQtyItems(changeItems, savedItems, changeGroupByItems),
    );

    return rows;
  };

  const renderViewRack = () => {
    if (isGridView) {
      const newBins = cloneDeep(bins).map(
        mergeQtyItems(changeItems, savedItems, changeGroupByItems),
      );
      return <ViewListBin bins={newBins} onClickModal={onClickModal} inSession={inSession} />;
    }
    return (
      <TableComponent
        getList={getBinInRack as any}
        nameQuery={QUERIES.BIN_IN_RACK_LIST}
        originColumns={binInRackColumns(inSession, onNavigate, rack.location?.id)}
        titleModal="bin"
        placeholderSearch="Search with bin, item, and SKU"
        isBorder={false}
        isRowClickable={false}
        onClickBodyTable={(_row: Row) => () => {
          //do nothing
        }}
        ModalOutsideTable={renderModalForm}
        moreQuery={`&rack=${params.rackId || ''}`}
        isSortAndSearchFrontEnd
        getTotal={totalBin.setValue}
        refetchTimestamp={refetchTimestamp.value}
        mappingDataMiddleware={mappingWithSocketDataMiddleware}
        syncParamUrl
      />
    );
  };

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <InformationRackCard
            rackItem={rack}
            onChangeEditRack={() => isEditRack.setValue(true)}
            totalBin={totalBin.value}
            onSyncRack={onSubmitSyncRack}
            isRedirect
            inSession={inSession}
            activeTabletName={tablets.length ? getListTabletName(tablets) : undefined}
          />
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: 32 }}
          >
            <Typography variant="body-3" color="neutral-7" style={{ marginRight: 12 }}>
              {rack.updatedAt ? `Last update: ${showUpdateAtRack(rack.updatedAt)}` : ''}
            </Typography>
            <LabelSyncDataRack isSync={rack.isSync} />
          </div>
        </div>
      }
    >
      <div className="position-relative">
        {renderViewRack()}
        <div className="position-absolute" style={{ top: 28, right: 28 }}>
          <ButtonSwitchView
            view={Number(mode) as ViewMode}
            onClickButton={onChangeViewRack}
            messages={CHANGE_VIEW_MESSAGES}
            iconList={[IconList, IconGrid]}
          />
        </div>

        {isEditRack.value && (
          <ModalDefault
            title="Edit rack"
            onClickCloseModal={() => isEditRack.setValue(false)}
            styleBodyModalDialog={{ overflowY: 'unset' }}
          >
            <UpdateRackModal
              itemRack={rack}
              wards={wards}
              onClickCloseModal={onClickCloseModalUpdate}
            />
          </ModalDefault>
        )}
        {isAssignItemInBin.value && (
          <AssignItemModal
            selectBin={selectBin}
            onClickCloseModal={onClickCloseModalAssignItemInBin}
          />
        )}
        {isRemoveItemInBin.value && (
          <RemoveItemModal
            onCloseModal={onCloseModalRemoveItem}
            binId={selectBin?.id}
            itemId={selectBin?.item?.id}
            showUnableToRemoveModal={() => unableToRemoveItem.setValue(true)}
          />
        )}
        {unableToRemoveItem.value && (
          <UnableToRemoveItemModal
            onClose={() => unableToRemoveItem.setValue(false)}
            show={unableToRemoveItem.value}
          />
        )}
        {showShortForModal.value && (
          <ShortForModal
            selectBin={selectBin}
            wardId={rack.location?.id}
            wardName={rack.location?.name || ''}
            onClickCloseModal={() => showShortForModal.setValue(false)}
          />
        )}
        {showQuantityUpdateModal.value && (
          <QuantityUpdateModal
            selectBin={selectBin}
            wardId={rack.location?.id}
            wardName={rack.location?.name || ''}
            onClickCloseModal={() => {
              showQuantityUpdateModal.setValue(false);
              fetchBins();
            }}
          />
        )}
        {isLoadingPage.value && isGridView && <LoadingCard />}
      </div>
    </RootContentPage>
  );
};

export default RackDetailManagement;
