import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { useBoolean } from 'app/hooks';

import { ReactComponent as IconDate } from 'app/assets/icons/icon-date.svg';

import clsx from 'clsx';

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--gray-700);
`;
const Main = styled.div`
  margin-bottom: 24px;
  position: relative;
  .form-control.form-control-solid:focus {
    background: var(--neutral-2);
  }
  .form-control {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    height: 43px;
    color: var(--neutral-10) !important;
    background: var(--neutral-2) !important;
  }
  .react-datepicker-popper {
    background: var(--white);
    box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
      4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
    border-radius: 8px;
    padding-top: 0px !important;
  }
  .react-datepicker {
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .react-datepicker__day {
    width: 33px;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--neutral-10);
    padding: 5px 0px;
    margin: 0px;
  }
  .react-datepicker__day--selected {
    color: var(--white) !important;
    font-weight: 600;
    background-color: var(--primary) !important;
    outline: none !important;
    border-radius: 8px;
  }
  .react-datepicker__day--keyboard-selected {
    color: var(--neutral-10);
    font-weight: 400;
    background-color: var(--white);
    outline: none !important;
    border-radius: 8px;
  }
  .react-datepicker__day--today {
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
  }
  .react-datepicker__header {
    border: none;
    background-color: var(--white);
    padding: 0px;
  }
  .react-datepicker__current-month {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #121212;
  }
  .react-datepicker__day-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--neutral-7);
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    margin-bottom: 10px;
    margin-top: 12px;
  }
  .react-datepicker__month {
    margin: 0px;
  }
  .react-datepicker__navigation {
    width: 32px;
    height: 32px;
    background: var(--neutral-2-5);
    border-radius: 8px;
    top: 14px;
  }
  .react-datepicker__navigation--previous {
    left: 20px;
  }
  .react-datepicker__navigation--next {
    right: 20px;
  }
  .react-datepicker__navigation-icon--previous {
    right: 0px;
    top: 5px;
  }
  .react-datepicker__navigation-icon--next {
    left: 0px;
    top: 5px;
  }
  .react-datepicker__navigation-icon::before {
    border-width: 1.5px 1.5px 0 0;
    border-color: var(--neutral-7);
    height: 7px;
    top: 7px;
    width: 7px;
  }
  .react-datepicker__navigation-icon--previous::before {
    right: -6px;
  }
  .react-datepicker__navigation-icon--next::before {
    left: -6px;
  }
  .react-datepicker__day--disabled {
    color: var(--neutral-6) !important;
  }
  .react-datepicker__day:hover {
    background: var(--neutral-2);
    border-radius: 8px;
  }
`;
const Icon = styled.div`
  position: absolute;
  right: 13px;
  top: 38px;
  cursor: pointer;
`;
interface Props {
  label?: string;
  rootClass?: string;
  placeholder: string;
  isRequired?: boolean;
  isDisable?: boolean;
  selectedDate: Date | null;
  onChange: (newDate: Date | null) => void;
  messageError?: string;
  excludeDates?: Date[];
  minDate?: Date | null;
  readOnly?: boolean;
  maxDate?: Date | null;
}
const FormInputDate: React.FC<Props> = ({
  label,
  rootClass,
  isRequired = false,
  selectedDate,
  onChange,
  messageError,
  excludeDates,
  minDate,
  readOnly,
  maxDate,
}) => {
  const isOpenDatePicker = useBoolean(false);
  const onChangeInput = (newDate: Date | null) => {
    onChange(newDate);
    isOpenDatePicker.setValue(false);
  };
  return (
    <Main className={clsx('fv-row', rootClass)}>
      {label && <Label className={clsx('mb-2', isRequired ? 'required' : '')}>{label}</Label>}
      <div>
        <DatePicker
          className="form-control form-control-solid mb-3 mb-lg-0 h6 cursor-pointer"
          selected={selectedDate}
          onChange={onChangeInput}
          onInputClick={() => isOpenDatePicker.setValue(!isOpenDatePicker.value)}
          excludeDates={excludeDates}
          dateFormat="dd MMM yyyy"
          open={isOpenDatePicker.value}
          showPopperArrow={false}
          onClickOutside={() => isOpenDatePicker.setValue(false)}
          minDate={minDate}
          readOnly={readOnly}
          maxDate={maxDate}
        ></DatePicker>
      </div>
      <Icon onClick={() => isOpenDatePicker.setValue(!isOpenDatePicker.value)}>
        <IconDate />
      </Icon>
      {messageError ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{messageError}</span>
          </div>
        </div>
      ) : null}
    </Main>
  );
};

export { FormInputDate };
