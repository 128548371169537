import { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { resetPasswordUser } from '../core/requests';
import { User } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  user: User;
};

const defaultSchema = {
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .required('Please your confirm password.')
    .oneOf([Yup.ref('password')], 'The password confirmation does not match!'),
};

const FormResetPasswordCard: FC<Props> = ({ user }) => {
  const { setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const iconNewPassword = useBoolean(false);
  const iconConfirmPassword = useBoolean(false);

  const [formResetPassword] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: formResetPassword,
    validationSchema: Yup.object().shape(defaultSchema),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (!user) {
          return;
        }
        await resetPasswordUser(user.id, values);
        toast.success(`Reset password User successfully!`);

        cancel();
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  return (
    <>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <FormInput
            label="New Password"
            placeholder="New Password"
            formik={formik}
            field="password"
            isRequired
            typeInput={iconNewPassword.value ? 'text' : 'password'}
            passwordShown={iconNewPassword.value}
            setPasswordShown={() => iconNewPassword.setValue(!iconNewPassword.value)}
          />
          <FormInput
            label="Confirm Password"
            placeholder="Confirm Password"
            formik={formik}
            field="confirmPassword"
            isRequired
            typeInput={iconConfirmPassword.value ? 'text' : 'password'}
            passwordShown={iconConfirmPassword.value}
            setPasswordShown={() => iconConfirmPassword.setValue(!iconConfirmPassword.value)}
          />
        </div>
        <ActionButton
          onCancel={cancel}
          disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
          isSubmitting={formik.isSubmitting}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormResetPasswordCard };
