import Tippy, { TippyProps } from '@tippyjs/react';
import { CSSProperties, Ref, forwardRef } from 'react';
import styled from 'styled-components';

import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
// optional
import 'tippy.js/themes/light.css';

const Main = styled.div`
  height: 100%;
  .tippy-box {
    box-shadow: 23.4866px 32.3787px 94px rgba(0, 0, 0, 0.1),
      4.69732px 6.47574px 15.275px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    max-width: unset !important;
    background: var(--white);
  }

  .tippy-content {
    padding: 0;
  }
  div[data-tippy-root] {
    max-width: unset !important;
  }
`;
export interface TippyCustomzieProps extends TippyProps {
  containerClass?: string;
  content?: any;
  containerStyle?: CSSProperties;
}

export const TippyDefault = forwardRef((props: TippyCustomzieProps, ref: Ref<Element>) => {
  const { children, containerClass = '', containerStyle, popperOptions, ...otherProps } = props;

  return (
    <Main className={containerClass}>
      {props.disabled ? (
        children
      ) : (
        <Tippy
          ref={ref}
          popperOptions={{
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true,
                  tether: false,
                },
              },
            ],
            ...popperOptions,
          }}
          {...otherProps}
        >
          <div className="h-100 w-100" style={containerStyle}>
            {children}
          </div>
        </Tippy>
      )}
    </Main>
  );
});
