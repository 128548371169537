import { QtyUnitsEnum, StockTypeEnum, WeightUnitsEnum } from './enum';
import { QtyUnit, StockType, WeightUnit } from './type';

export const STOCK_TYPE: StockType[] = [
  {
    value: StockTypeEnum['Non-Chargeable'],
    title: StockTypeEnum[0],
  },
  {
    value: StockTypeEnum.Chargeable,
    title: StockTypeEnum[1],
  },
];

export const QTY_UNITS: QtyUnit[] = [
  {
    value: QtyUnitsEnum.Piece,
    title: QtyUnitsEnum[0],
  },
  {
    value: QtyUnitsEnum.Set,
    title: QtyUnitsEnum[1],
  },
  {
    value: QtyUnitsEnum.Box,
    title: QtyUnitsEnum[2],
  },
];
export const WEIGHT_UNITS: WeightUnit[] = [
  {
    value: WeightUnitsEnum.g,
    title: WeightUnitsEnum[0],
  },
];

export enum StatusItem {
  Active = 1,
  Inactive = 2,
}
