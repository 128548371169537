import { FC } from 'react';

interface Props {
  value: number;
  style?: React.CSSProperties;
}

export const Badge: FC<Props> = ({ value, style }) => {
  return (
    <div
      style={{
        background: 'var(--danger)',
        borderRadius: '50%',
        width: value.toString().length * 8 + (value > 10 ? 8 : 12),
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        lineHeight: '20px',
        ...style,
      }}
    >
      {value}
    </div>
  );
};
