import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getSetting } from 'app/modules/system-settings/configuration/core/request';
import {
  QuantityUpdateMethod,
  SettingType,
  StockRunSettingType,
} from 'app/modules/system-settings/configuration/core/type';

import { EpisodeRole, SessionEventType, SocketClient } from 'app/hooks/socket';

import { curWardSelector, useRackStore } from '../store';

export const useRackSocket = (
  isGridView: boolean,
  onClearSession: (isGridView: boolean, immediateUpdate: boolean, allback: () => void) => void,
) => {
  const wardId = useRackStore(curWardSelector);
  const [updateQuantityMethod, setUpdateQuantityMethod] = useState<QuantityUpdateMethod>(
    QuantityUpdateMethod.afterSessionCompleted,
  );
  const afterSessionCompleted = updateQuantityMethod === QuantityUpdateMethod.afterSessionCompleted;

  const fetchqtyUpdateMethodSetting = async () => {
    const settings = await getSetting(SettingType.stockRun, {
      name: StockRunSettingType.qtyUpdateMethod,
    });
    if (settings.length) {
      setUpdateQuantityMethod(settings[0].value as QuantityUpdateMethod);
    }
  };

  useEffect(() => {
    fetchqtyUpdateMethodSetting();
  }, []);

  useEffect(() => {
    if (!wardId) {
      return;
    }

    SocketClient.getSession(wardId);
    SocketClient.getTabletByWard(wardId);

    const setState = useRackStore.getState().setState;
    SocketClient.onSession(wardId, (data) => {
      const { type, transaction, role } = data;
      switch (type) {
        case SessionEventType.start: {
          setState({ inSession: true });
          return;
        }
        case SessionEventType.transaction: {
          const currentRack = useRackStore.getState().state.rack;
          setState({
            changeItems: transaction
              .filter((item) => item.rack_id === currentRack.id)
              .map((el) => ({
                ...el,
                afterSessionCompleted: role === EpisodeRole.Nurse ? false : afterSessionCompleted,
              })),
          });
          return;
        }
        case SessionEventType.endCart: {
          onClearSession(
            isGridView,
            role === EpisodeRole.Nurse ? true : afterSessionCompleted === false,
            () => {
              setState({ inSession: false, changeItems: [] });
            },
          );
          return;
        }
        case SessionEventType.stop: {
          setState({ inSession: false });
          toast.success('This rack is no longer in any session. Change is possible starting now.');
          return;
        }
      }
    });

    SocketClient.onTabletByWard(wardId, (data) => {
      setState({ tablets: data });
    });

    return () => {
      SocketClient.offSession(wardId);
      SocketClient.offTabletByWard(wardId);
    };
  }, [wardId, updateQuantityMethod, isGridView]);

  return null;
};
