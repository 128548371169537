import styled from 'styled-components';

import { Rack } from '../core/type';

const MainNavigation = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
`;
const NavigationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
  height: 100%;
  position: relative;
  cursor: pointer;
`;
const NavigationItem = styled.p<{ active?: boolean }>`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  ${({ active }) => (active ? `color: var(--primary);` : `color: var(--neutral-6);`)}
`;
const LineNavigation = styled.div<{ active?: boolean }>`
  position: absolute;
  bottom: -1px;
  height: 2px;
  width: 100%;
  border-radius: 3px;
  ${({ active }) => (active ? `background-color:var(--primary);` : `background-color:transparent;`)}
`;
interface Props {
  racks: Rack[];
  selectRack: Rack;
  onChangeNavigation: (newSelectRack: Rack) => () => void;
}
const NavigationRackCard: React.FC<Props> = ({ racks, selectRack, onChangeNavigation }) => {
  return (
    <MainNavigation>
      {racks.map((rack, index) => {
        const isActive = selectRack.id === rack.id;
        return (
          <NavigationBody onClick={onChangeNavigation(rack)} key={index}>
            <NavigationItem active={isActive}>{rack.name}</NavigationItem>
            <LineNavigation active={isActive} />
          </NavigationBody>
        );
      })}
    </MainNavigation>
  );
};
export default NavigationRackCard;
