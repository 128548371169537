import { FC } from 'react';
import styled from 'styled-components';

import CheckboxDefault from 'app/components/Checkbox/CheckboxDefault';
import Typography from 'app/components/Typography';

import { ForecastCurve, MlQuantity } from '../core/type';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
  .check-box {
    width: 20px !important;
    height: 20px !important;
  }
`;

interface Props {
  recommendQty: MlQuantity;
  approved: ForecastCurve | null;
  onChooseValue: (curve: ForecastCurve) => void;
}

const QtyCheckBox: FC<Props> = ({ recommendQty, approved, onChooseValue }) => {
  const isNominalOnly = recommendQty.highQty === undefined && recommendQty.lowQty === undefined;
  return (
    <CheckboxWrapper>
      {recommendQty.highQty !== undefined && (
        <CheckboxDefault
          checked={approved === ForecastCurve.upper}
          onChange={() => {
            onChooseValue(ForecastCurve.upper);
          }}
          label={
            <Typography variant="body-5" color="neutral-10" style={{ paddingLeft: 9 }}>
              {`${recommendQty.highQty} - High`}
            </Typography>
          }
        />
      )}
      <CheckboxDefault
        checked={approved === ForecastCurve.normal}
        onChange={() => {
          onChooseValue(ForecastCurve.normal);
        }}
        label={
          <Typography variant="body-5" color="neutral-10" style={{ paddingLeft: 9 }}>
            {`${recommendQty.nominalQty} ${isNominalOnly ? '' : '- Nominal'}`}
          </Typography>
        }
      />

      {recommendQty.lowQty !== undefined && (
        <CheckboxDefault
          checked={approved === ForecastCurve.lower}
          onChange={() => {
            onChooseValue(ForecastCurve.lower);
          }}
          label={
            <Typography variant="body-5" color="neutral-10" style={{ paddingLeft: 9 }}>
              {`${recommendQty.lowQty} - Low`}
            </Typography>
          }
        />
      )}
    </CheckboxWrapper>
  );
};

export default QtyCheckBox;
