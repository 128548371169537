import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { StatusItem } from '../core/constants';
import { changeStatusItem } from '../core/requests';
import { Item } from '../core/type';

export const FormChangeStatusItem: FC<{
  originalData: Item;
  nameQuery: string;
}> = ({ originalData, nameQuery }) => {
  const isUnableInActivateItem = Boolean(originalData.hasStockRun) || originalData.bins.length;

  const itemStatus = originalData.status === StatusItem.Active ? 'Inactivate' : 'Activate';

  const labelStatus = isUnableInActivateItem ? 'Cancel' : itemStatus;
  const loading = useBoolean();
  const { setItemForUpdate } = useListView();
  const queryClient = useQueryClient();
  const { refetch, query } = useQueryResponse();

  const onCancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const onSubmit = async () => {
    if (isUnableInActivateItem) {
      onCancel();
      return;
    }
    loading.setValue(true);
    try {
      await changeStatusItem(originalData?.id, {
        activate:
          originalData.status === StatusItem.Active ? StatusItem.Inactive : StatusItem.Active,
      });
      refetch();
      toast.success(`${labelStatus} Item successfully!`);
      queryClient.invalidateQueries([`${nameQuery}-${query}`]);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      loading.setValue(false);
      onCancel();
    }
  };
  const renderMessage = () => {
    if (isUnableInActivateItem) {
      return 'To inactivate this item, it has to be removed from all the bins.';
    }
    if (originalData.status === StatusItem.Active) {
      return 'After inactivating this item, you won’t be able to assign it to a bin. You can activate it back later.';
    }
    return `Are you sure to Activate this item?`;
  };

  return (
    <div className="d-flex flex-column">
      <Typography variant="body-3">{renderMessage()}</Typography>
      <ActionButton
        onCancel={onCancel}
        labelButtonSubmit={labelStatus}
        isSubmitting={loading.value}
        onSubmit={onSubmit}
        themeButton={
          !isUnableInActivateItem && originalData.status === StatusItem.Active
            ? 'btn-danger'
            : 'btn-primary'
        }
        isShowButtonReset={!isUnableInActivateItem}
      />
    </div>
  );
};
