import React from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { History, Transition } from 'history';

export function useBoolean(defaultValue = false) {
  return useDefault(defaultValue);
}

export function useNumber(defaultValue = 0) {
  return useDefault(defaultValue);
}

export function useString(defaultValue = '') {
  return useDefault(defaultValue);
}

export function useDefault<T>(defaultValue: T) {
  const [value, setValue] = React.useState(defaultValue);
  return { value, setValue };
}

export const useTiming = (callback: () => void, defaultTime = 5) => {
  const time = useNumber(defaultTime);

  const timingRedirect = () => {
    let timeString = time.value;
    const interval = setInterval(() => {
      const currentTime = timeString - 1;
      if (currentTime === 0) {
        time.setValue(0);
        clearInterval(interval);
        callback();
        return;
      }
      timeString = currentTime;
      time.setValue(timeString);
    }, 1000);
  };
  return {
    timingRedirect,
    time,
  };
};

type ExtendNavigator = Navigator & Pick<History, 'block'>;
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
