import styled from 'styled-components';

import { CustomSpinner } from '../Spinner';
import clsx from 'clsx';

const MainButton = styled.button`
  height: 43px;
  width: 140px;
  padding: 0px !important;
  .indicator-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
  }
`;
export interface ButtonDefaultProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?:
    | 'btn-primary'
    | 'btn-light'
    | 'btn-danger'
    | 'btn-light-primary'
    | 'btn-light-success'
    | 'btn-none'
    | 'btn-white';
  isSubmitting?: boolean;
}
const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  theme = 'btn-primary',
  className,
  children,
  isSubmitting = false,
  ...propsButton
}) => {
  return (
    <MainButton className={clsx('btn', theme, className)} {...propsButton}>
      {!isSubmitting && <span className="indicator-label">{children}</span>}
      {isSubmitting && <CustomSpinner />}
    </MainButton>
  );
};
export default ButtonDefault;

export const OutlineButton = styled.button`
  border-radius: 8px;
  border: 1px solid var(--white);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  background-color: transparent;
  color: var(--white);
  width: 129px;
  height: 50px;
  transition: background-color 0.2s;
  :hover {
    background-color: var(--neutral-6);
  }
`;
