import { toast } from 'react-toastify';
import styled from 'styled-components';

import HeartBeatStatus from 'app/components/Status/HeartBeatStatus';
import TippyMenu, { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';
import Typography from 'app/components/Typography';

import { useNavigateWithSendPath, usePageGoBack } from 'app/helpers/utils';
import { useBoolean } from 'app/hooks';
import { isUndefined } from 'lodash';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';
import { ReactComponent as IconSync } from 'app/assets/icons/icon-sync-white.svg';

import { Rack } from '../core/type';
import InfoRackItemCard from './InfoRackItemCard';
import clsx from 'clsx';

const Main = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
`;

const RackNameWrapper = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`;

interface Props {
  rackItem: Rack;
  onChangeEditRack?: () => void;
  totalBin: number;
  isRedirect?: boolean;
  styleMain?: React.CSSProperties;
  onSyncRack?: () => void;
  inSession?: boolean;
  activeTabletName?: string[];
}
const InformationRackCard: React.FC<Props> = ({
  rackItem,
  totalBin,
  styleMain,
  isRedirect = false,
  onChangeEditRack,
  onSyncRack,
  inSession,
  activeTabletName,
}) => {
  const goBack = usePageGoBack(PATH.RACKS);
  const navigateWithSendPath = useNavigateWithSendPath();
  const isOpenMenu = useBoolean(false);

  return (
    <Main style={styleMain}>
      <div className="d-flex">
        <div
          className="d-flex"
          style={{
            borderRight: `1px solid var(--neutral-4)`,
            paddingRight: 24,
          }}
        >
          <RackNameWrapper>
            {isRedirect && <IconRedirect style={{ cursor: 'pointer' }} onClick={goBack} />}
            <Typography variant="heading-6">{rackItem.name}</Typography>
          </RackNameWrapper>
        </div>
        <InfoRackItemCard label="Ward" value={rackItem.location?.name} />
        <InfoRackItemCard label="Number of bin" value={String(totalBin)} />
        <InfoRackItemCard label="Version" value={String(rackItem.version ?? 0)} />
        <InfoRackItemCard
          label="Active tablet"
          valueList={activeTabletName}
          colorValue={activeTabletName ? 'primary' : undefined}
        />
        <InfoRackItemCard
          label="Raspberry Pi connection"
          content={
            <HeartBeatStatus
              pageType="rack"
              targetId={Number(rackItem.id)}
              styleMain={{ marginTop: 4, marginRight: 8 }}
              isRackDetailPage
            />
          }
        />
      </div>
      {!isUndefined(onChangeEditRack) && (
        <div className="d-flex align-items-center ">
          <TippyMenu
            onClickIcon={() => isOpenMenu.setValue(true)}
            visible={isOpenMenu.value}
            onClickOutside={() => isOpenMenu.setValue(false)}
            content={
              <MenuAction>
                {!isUndefined(onChangeEditRack) && (
                  <MenuItem
                    onClick={() => {
                      if (inSession) {
                        return toast.error(
                          'This function has been momentarily suspended due to an active tablet session. Please try again later.',
                        );
                      }
                      isOpenMenu.setValue(false);
                      onChangeEditRack();
                    }}
                  >
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    navigateWithSendPath(PATH.RACK_HISTORY.replace(':rackId', String(rackItem.id)))
                  }
                >
                  Show item history
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigateWithSendPath(PATH.RACK_LOG.replace(':rackId', String(rackItem.id)))
                  }
                >
                  Show activity log
                </MenuItem>
                {/* <MenuItem>Delete</MenuItem> */}
              </MenuAction>
            }
          />

          <button
            className={clsx(
              'btn d-flex justify-content-center align-items-center',
              !rackItem.isSync ? 'btn-primary' : ' ',
            )}
            style={{
              width: 136,
              height: 43,
              borderRadius: 8,
              background: rackItem.isSync ? 'var(--neutral-3)' : '',
            }}
            onClick={onSyncRack}
            disabled={rackItem.isSync}
          >
            <IconSync />
            <Typography
              variant="title-4"
              color="white"
              style={{
                marginLeft: 8,
              }}
            >
              Sync
            </Typography>
          </button>
        </div>
      )}
    </Main>
  );
};
export default InformationRackCard;
