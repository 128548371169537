import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { ID, Response } from 'app/components/Table/core/types';

import { useHeartBeatStore } from 'app/modules/system-settings/locations/store';
import { ConnectStatusItem } from 'app/modules/system-settings/locations/wards/core/type';

import { isBoolean, uniqBy } from 'lodash';

import { API_URL } from 'app/constants/environments';

import { useNotificationStore } from '../store';
import { Notification, NotificationGroup, NotificationRequest } from './type';
import axios, { AxiosResponse } from 'axios';

const NOTIFICATIONS_URL = `${API_URL}/api/notifications`;
const NOTIFICATIONS_V2_URL = `${API_URL}/api/notifications/v2`;

const getIsReadParam = (isRead?: boolean) => {
  return isBoolean(isRead) ? `&isRead=${isRead}` : '';
};

export const getNotificationGroups = (options: {
  page: number;
  onError?: () => void;
  isRead?: boolean;
  loadMore?: boolean;
  newNoti?: boolean;
  setLoading?: (loading: boolean) => void;
}) => {
  const { isRead, page, setLoading, onError } = options;
  if (page === 1) {
    useNotificationStore.getState().clearState();
  }

  setLoading?.(true);

  axios
    .get(
      `${NOTIFICATIONS_V2_URL}?${stringifyRequestQuery({
        page,
        limit: 1,
      })}${getIsReadParam(isRead)}`,
    )
    .then(
      (
        res: AxiosResponse<{
          data: NotificationGroup;
        }>,
      ) => {
        const { items, total } = res.data.data;
        const curGroup = useNotificationStore.getState().state.notificationGroup;
        const newNotiGroups = uniqBy(curGroup.items.concat(items), 'day');
        useNotificationStore.getState().setState({
          notificationGroup: { items: newNotiGroups, total },
        });
      },
    )
    .catch(onError)
    .finally(() => {
      setLoading?.(false);
    });
};

export const changeStatusNotification = (
  idNoti: ID,
  request: NotificationRequest,
): Promise<Notification | undefined> => {
  return axios
    .patch(`${NOTIFICATIONS_URL}/${idNoti}`, request)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data);
};

export const updateNotification = (request: NotificationRequest) => {
  return axios
    .put(`${NOTIFICATIONS_URL}`, request)
    .then((response: AxiosResponse<Response<Notification[]>>) => response.data)
    .then((response: Response<Notification[]>) => response.data);
};

export const getTotalUnreadNoti = async () => {
  const dataRes = await axios
    .get(`${NOTIFICATIONS_URL}/count`)
    .then((response: AxiosResponse<Response<{ count: number }>>) => response.data.data);

  const fetched = useNotificationStore.getState().state.fetched;
  const initUnReadCount = useNotificationStore.getState().state.initUnReadCount;

  if (!dataRes) {
    return;
  }

  useNotificationStore.getState().setState({
    unReadCount: dataRes.count,
    fetched: true,
    initUnReadCount: fetched ? initUnReadCount : dataRes.count,
  });
};

//Get heart beat
export const getHeartBeat = async () => {
  axios
    .get(`${NOTIFICATIONS_URL}/inventory-connection`)
    .then(
      (
        res: AxiosResponse<{
          data: ConnectStatusItem[];
        }>,
      ) => {
        const store = useHeartBeatStore;
        store.getState().clearState();

        store.getState().setState({ connectStatus: res.data.data });
      },
    )
    .catch(() => {
      return [];
    });
};
