import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useBoolean } from 'app/hooks';

import WorkShiftSettingCard from './components/WorkShiftSettingCard';
import { DEFAULT_SHIFTS } from './core/constants';
import { getWorkShift } from './core/request';
import { TimeFrame, WorkShift } from './core/type';
import { getTimeFromDate } from './core/utils';

const Body = styled.div`
  margin: 0;
`;

const ConfigWorkShiftPage = () => {
  const isLoading = useBoolean(false);

  const [workShift, setWorkShift] = useState<WorkShift | null>(null);

  const [shifts, setShifts] = useState<TimeFrame[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    isLoading.setValue(true);
    try {
      await getShiftSchedule();
      isLoading.setValue(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getShiftSchedule = async () => {
    const resData = await getWorkShift();
    setWorkShift(resData);

    const shiftSettings = resData?.value || DEFAULT_SHIFTS;
    setShifts(
      shiftSettings.map((el) => ({
        from: getTimeFromDate(el.from),
        to: getTimeFromDate(el.to),
      })),
    );
  };

  return (
    <Body>
      <WorkShiftSettingCard workShift={workShift} shifts={shifts} setShifts={setShifts} />
      {isLoading.value && <LoadingCard />}
    </Body>
  );
};

export default ConfigWorkShiftPage;
