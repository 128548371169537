import { TippyProps } from '@tippyjs/react';
import styled from 'styled-components';

import { ReactComponent as IconThreeDots } from 'app/assets/icons/icon-3-dots.svg';

import { TippyDefault } from '.';

const Icon = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 8px;
  background: var(--white);
  cursor: pointer;
`;
export const MenuAction = styled.div`
  width: 204px;
  padding: 16px 8px;
`;
export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: var(--neutral-10);
  cursor: pointer;
  &:hover {
    background-color: #f7fcff;
    color: var(--primary);
  }
`;
const MainMenu = styled.div`
  cursor: pointer;
`;

interface Props extends TippyProps {
  styleIcon?: React.CSSProperties;
  content: React.ReactNode;
  onClickIcon: () => void;
}
const TippyMenu: React.FC<Props> = ({ content, styleIcon, onClickIcon, ...otherProps }) => {
  return (
    <MainMenu>
      <TippyDefault
        placement="bottom-end"
        interactive
        arrow={false}
        containerClass="action-menu"
        offset={[0, 10]}
        trigger="click"
        allowHTML
        content={content}
        {...otherProps}
      >
        <Icon
          onClick={onClickIcon}
          className="d-flex justify-content-center align-items-center"
          style={{
            marginRight: 12,
            ...styleIcon,
          }}
        >
          <IconThreeDots />
        </Icon>
      </TippyDefault>
    </MainMenu>
  );
};
export default TippyMenu;
