import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import { NavigationDetail } from 'app/components/Navigation/core/types';
import RootContentPage from 'app/components/layout/RootContentPage';

import { replacePath } from 'app/helpers';

import { PATH } from 'app/constants/path';

import UsersRoleTabletManagement from './UsersRoleTabletManagement';
import UsersRoleWebManagement from './UsersRoleWebManagement';

const UsersRoleManagementPage = () => {
  const navigationLocation: NavigationDetail[] = [
    {
      title: 'Desktop',
      path: PATH.USERS_ROLE_WEB,
    },
    {
      title: 'Tablet App',
      path: PATH.USERS_ROLE_TABLET,
    },
  ];
  return (
    <Routes>
      <Route
        element={
          <React.Fragment>
            <RootContentPage title="User roles">
              <NavigationDefault navigation={navigationLocation} />
              <Outlet />
            </RootContentPage>
          </React.Fragment>
        }
      >
        <Route
          path={replacePath(PATH.USERS_ROLE_WEB, PATH.USERS_ROLE, '')}
          element={<UsersRoleWebManagement />}
        />
        <Route
          path={replacePath(PATH.USERS_ROLE_TABLET, PATH.USERS_ROLE, '')}
          element={<UsersRoleTabletManagement />}
        />

        <Route index element={<Navigate to={PATH.USERS_ROLE_WEB} />} />
      </Route>
    </Routes>
  );
};
export default UsersRoleManagementPage;
