import Typography from 'app/components/Typography';

import { usePageGoBack } from 'app/helpers/utils';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { Rack } from '../core/type';
import InfoRackItemCard from './InfoRackItemCard';

interface Props {
  rack: Rack;
}
const HeaderRackActivityLogCard: React.FC<Props> = ({ rack }) => {
  const goBack = usePageGoBack(PATH.RACK_DETAIL.replace(':rackId', String(rack.id)));
  return (
    <div className="d-flex align-items-center">
      <IconRedirect
        style={{
          cursor: 'pointer',
        }}
        onClick={goBack}
      />
      <div
        className="h-100 d-flex align-items-center"
        style={{
          borderRight: `1px solid var(--neutral-4)`,
          paddingRight: 24,
        }}
      >
        <Typography variant="heading-6">Activity log</Typography>
      </div>
      <InfoRackItemCard label="Rack" value={rack.name} />
    </div>
  );
};
export default HeaderRackActivityLogCard;
