import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import styled from 'styled-components';

import TableComponent from 'app/components/Table/TableComponent';
import { QUERIES } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import Typography from 'app/components/Typography';
import RootContentPage from 'app/components/layout/RootContentPage';

import { getSetting } from 'app/modules/system-settings/configuration/core/request';
import {
  SettingType,
  StockLevelSettingType,
} from 'app/modules/system-settings/configuration/core/type';

import { usePageGoBack } from 'app/helpers/utils';
import { useNumber } from 'app/hooks';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import ShowInfoItemCard from './components/ShowInfoItemCard';
import { stockValueSettingColumns } from './core/columns';
import { StockTypeEnum } from './core/enum';
import { getItemTable } from './core/requests';
import { Item } from './core/type';

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;
const Main = styled.div`
  padding-top: 28px;
`;

interface StockLevelPercentage {
  restockLevel: number;
  alarmLevel: number;
}

const ItemDetailPage = () => {
  const goBack = usePageGoBack(PATH.ITEMS);
  const params = useParams<any>();

  const [item, setItem] = useState<Item | null>(null);
  const totalStockValueSetting = useNumber(0);
  const [stockPercentages, setStockPercentages] = useState<StockLevelPercentage>({
    restockLevel: 0,
    alarmLevel: 0,
  });

  useEffect(() => {
    if (!params.id) {
      goBack();
    }
  }, [params.id]);

  const onClickBodyTable = (_row: Row) => () => {
    //do nothing
  };

  const getDataItemResponse = useCallback(async (newData: Item, total: number) => {
    const dataRes = (await getSetting(SettingType.stockLevel)) || [];
    const itemType =
      Number(newData?.stockType) === StockTypeEnum.Chargeable ? 'chargeable' : 'nonChargeable';
    const restockValue = dataRes.find((el) => el.name === StockLevelSettingType.restock);
    const alarmValue = dataRes.find((el) => el.name === StockLevelSettingType.alarm);
    setStockPercentages({
      restockLevel: restockValue ? restockValue.value[itemType] : 0,
      alarmLevel: alarmValue ? alarmValue.value[itemType] : 0,
    });
    setItem(newData);
    totalStockValueSetting.setValue(total);
  }, []);

  return (
    <RootContentPage
      title=""
      header={
        <div className="d-flex flex-column">
          <Header>
            <IconRedirect style={{ cursor: 'pointer' }} onClick={goBack} />
            <Typography variant="heading-6">Item detail</Typography>
          </Header>
          <ShowInfoItemCard item={item} />
        </div>
      }
    >
      <Main>
        <Typography
          variant="title-1"
          lineHeight={32}
          style={{
            paddingLeft: 28,
            marginBottom: 32,
          }}
        >
          Stock value setting
        </Typography>

        <div
          style={{
            display: totalStockValueSetting.value ? 'flex' : 'none',
          }}
        >
          <TableComponent
            isHiddenHeader
            getList={getItemTable}
            paramId={params.id}
            nameQuery={QUERIES.ITEMS_LIST}
            originColumns={stockValueSettingColumns(
              Number(params.id),
              stockPercentages.restockLevel,
              stockPercentages.alarmLevel,
            )}
            onClickBodyTable={onClickBodyTable}
            defaultState={{
              page: 1,
              limit: 10,
              orderBy: 'ward',
              orderDirection: 'ASC',
            }}
            isBorder={false}
            getDataItemResponse={getDataItemResponse}
            isRowClickable={false}
            spacing="md"
          />
        </div>

        <div
          className="align-items-center justify-content-center"
          style={{
            height: 200,
            display: totalStockValueSetting.value ? 'none' : 'flex',
          }}
        >
          <Typography>This item hasn’t been assigned to any ward yet.</Typography>
        </div>
      </Main>
      {!item?.id && <LoadingCard />}
    </RootContentPage>
  );
};
export default ItemDetailPage;
