import Select from 'react-select';
import styled from 'styled-components';

import Typography from '../Typography';

interface SelectMultiProps {
  options: any[];
  selectedOption: any;
  handleChange: (selectedOption: any) => void;
  placeholder?: string;
  onMenuClose?: () => void;
  menuIsOpen?: boolean;
  openMenuOnClick?: () => void;
}
const Main = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-10);
  .css-tj5bde-Svg {
    color: rgb(126 130 153 / 52%);
    cursor: pointer;
  }
  .css-1u9des2-indicatorSeparator {
    background-color: rgb(126 130 153 / 52%);
    cursor: pointer;
  }
  .css-qbdosj-Input {
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }
  .css-15ki4p9-ValueContainer {
    min-height: 36px;
  }
`;
const MenuItem = styled.div<{ isActive?: boolean }>`
  min-height: 35px;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  padding: 0px 8px;
  cursor: pointer;
  margin-right: 8px;
  ${({ isActive }) =>
    isActive
      ? `background-color: rgb(247, 252, 255);
    .label {
      color: rgb(0, 128, 240);
    }`
      : ``}

  :hover {
    background-color: rgb(247, 252, 255);
    .label {
      color: rgb(0, 128, 240);
    }
  }
`;

const SelectMulti = (props: SelectMultiProps) => {
  const {
    options,
    selectedOption,
    handleChange,
    onMenuClose,
    placeholder,
    menuIsOpen,
    openMenuOnClick,
  } = props;
  const customStyles = {
    control: () => {
      return {
        ...{
          borderRadius: 8,
          backgroundColor: 'rgb(247, 249, 252)',
          display: 'flex',
          padding: '3px 12px',
        },
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow:
        'rgb(0 0 0 / 10%) 23.4866px 32.3787px 94px, rgb(0 0 0 / 5%) 4.69732px 6.47574px 15.275px',
      border: 'none',
      borderRadius: 6,
      padding: 8,
      backgroundColor: 'var(--white)',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: 200,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: 4,
      padding: '8px 8px',
      margin: 0,
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      padding: '0px !important',
      color: 'var(--primary)',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: '#B5B8C3',
      marginLeft: 4,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      color: 'red',
    }),
    indicatorContainer: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: 'rgb(161, 165, 183)',
      fontSize: 16,
      marginLeft: 0,
      fontWeight: 400,
    }),
  };

  return (
    <Main>
      <Select
        value={selectedOption}
        styles={customStyles}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti
        menuIsOpen={menuIsOpen}
        options={options}
        onMenuClose={onMenuClose}
        onMenuOpen={openMenuOnClick}
        menuPlacement="auto"
        components={{
          Option: (optionProps: any) => {
            const data = optionProps.data;
            return (
              <MenuItem {...optionProps.innerProps}>
                <Typography className="label" variant="body-3">
                  {data.label}
                </Typography>
              </MenuItem>
            );
          },
          NoOptionsMessage: (optionProps: any) => {
            return (
              <div {...optionProps.innerProps} className="d-flex justify-content-center">
                <Typography className="label" variant="body-3">
                  No matching records found
                </Typography>
              </div>
            );
          },
        }}
      />
    </Main>
  );
};
export default SelectMulti;
