// theme css file
import { CSSProperties } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

import { staticDateRanges } from 'app/modules/system-settings/configuration/core/constants';

import { ReactComponent as IconDate } from 'app/assets/icons/icon-date.svg';

const InputDate = styled.div`
  height: 43px;
  background: var(--neutral-2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  cursor: pointer;
`;
export const ModalDatePicker = styled.div`
  box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
    4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
  border-radius: 8px;
  padding: 0px 20px;
  height: 470px;
  .rdrStaticRanges {
    padding: 20px 20px 20px 0px;
    height: 470px;
  }
  .rdrDefinedRangesWrapper {
    width: 140px;
    border-right: 1px solid var(--neutral-3);
  }
  .rdrStaticRange {
    border: none;
    margin-bottom: 4px;
  }
  .rdrStaticRangeLabel {
    height: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--neutral-10);
  }
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    background: var(--primary-2);
    border-radius: 8px;
    .rdrStaticRangeLabel {
      color: var(--primary) !important;
    }
  }
  .rdrStaticRange:hover .rdrStaticRangeLabel {
    background: var(--primary-2);
    color: var(--primary);
  }
  .rdrInputRanges {
    display: none;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrWeekDay {
    width: 34px;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--neutral-7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdrDayNumber {
    height: 32px;
    color: var(--neutral-10);
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
  .rdrInRange {
    height: 32px;
  }
  .rdrStartEdge {
    height: 32px;
  }
  .rdrEndEdge {
    height: 32px;
  }
  .rdrDay {
    height: 40px;
  }
  .rdrMonth {
    width: 238px;
    padding: 0px;
  }
  .rdrWeekDays {
    width: 238px;
  }
  .rdrMonths {
    width: 508px;
    justify-content: space-between;
  }
  .rdrMonthAndYearPickers {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    position: absolute;
    padding: 0px;
    width: 508px;
    top: 5px;
  }
  .rdrMonthName {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #121212;
    padding: 3px 0px;
    margin-bottom: 12px;
  }
  .rdrMonth .rdrMonthName:first-child {
    margin-left: 61px;
  }
  .rdrMonth .rdrMonthName:last-child {
    margin-left: 0px;
  }
  .rdrCalendarWrapper {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .rdrPprevButton i {
    border-color: transparent var(--neutral-7) transparent transparent;
  }
  .rdrNextPrevButton {
    margin: 0px;
    width: 32px;
    height: 32px;
    background: var(--neutral-2-5) !important;
    border-radius: 8px;
  }
  .rdrNextButton i {
    margin: 0px 0px 0px 10px;
    border-color: transparent transparent transparent var(--neutral-7);
  }
  .rdrDayStartOfMonth {
    color: var(--primary);
  }
  .rdrInRange {
    color: var(--primary-2) !important;
  }
  .rdrStartEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    left: 0px;
  }
  .rdrEndEdge {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    right: 0px;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: var(--primary);
    font-weight: 400;
  }
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
    color: var(--white);
    font-weight: 400;
  }
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: var(--white);
    font-weight: 400;
  }
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .rdrDayStartOfMonth .rdrEndEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .rdrDayInPreview {
    background: rgb(240 249 255 / 50%);
    border: none !important;
  }
  .rdrDayStartPreview {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: none !important;
    color: transparent !important;
  }
  .rdrDayEndOfMonth .rdrStartEdge {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .rdrDayEndPreview {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: none !important;
  }

  .rdrDayToday > .rdrDayNumber span {
    font-weight: bold;
    color: var(--primary);
  }

  .rdrDayToday > .rdrDayNumber span::after {
    content: none !important;
  }
`;

interface Props {
  label?: string;
  value: string;
  onChange: (
    newDate: {
      startDate: Date;
      endDate: Date;
      key: string;
    }[],
  ) => void;
  state: {
    startDate: Date;
    endDate: Date;
    key: string;
  }[];
  onClear?: () => void;
  containerStyle?: CSSProperties;
  inputDateStyle?: CSSProperties;
}
const InputDateFilter: React.FC<Props> = ({
  label,
  value,
  onChange,
  state,
  onClear,
  containerStyle,
  inputDateStyle,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...containerStyle,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          marginBottom: 4,
        }}
      >
        <Typography fontWeight={700} fontSize={14} lineHeight={23} color="gray-700">
          {label}
        </Typography>
        {onClear && (
          <Typography
            variant="body-3"
            color="neutral-6"
            style={{
              cursor: 'pointer',
            }}
            onClick={onClear}
          >
            Clear
          </Typography>
        )}
      </div>

      <TippyDefault
        interactive
        arrow={false}
        containerClass="action-menu"
        placement="top-end"
        offset={[27, -5]}
        trigger="click"
        allowHTML
        content={
          <ModalDatePicker>
            <DateRangePicker
              onChange={(item) => {
                onChange([item.selection as any]);
              }}
              showPreview={true}
              months={2}
              staticRanges={[...createStaticRanges(staticDateRanges() as any)]}
              ranges={state}
              direction="horizontal"
              rangeColors={['var(--primary)']}
            />
          </ModalDatePicker>
        }
      >
        <InputDate
          style={{
            marginBottom: 15,
            ...inputDateStyle,
          }}
        >
          <Typography variant="body-5" color={value ? 'neutral-10' : 'neutral-6'}>
            {value ? value : label}
          </Typography>
          <IconDate />
        </InputDate>
      </TippyDefault>
    </div>
  );
};
export default InputDateFilter;
