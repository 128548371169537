import { Column } from 'react-table';
import styled from 'styled-components';

import StatusDefault from 'app/components/Status/StatusDefault';
import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, TYPE_MODAL } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import {
  SettingType,
  StockLevelSettingType,
  StockValues,
} from 'app/modules/system-settings/configuration/core/type';

import { coverPath, formatCustomDate } from 'app/helpers';

import { DEFAULT_WARD } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import ItemInfoCard from '../components/ItemInfoCard';
import RestockAndParValueCard from '../components/RestockAndParValueCard';
import SKUCard from '../components/SKUCard';
import { StatusItem } from './constants';
import { Item, StockValueSetting } from './type';

const WeightCell = styled.div`
  min-width: 100px;
`;

export const itemsColumns = (): ReadonlyArray<Column<Item>> => {
  const actions = (data: Item) => {
    const canOperate = !data.bins;
    const itemStatus = data.status === StatusItem.Active ? 'Inactivate item' : 'Activate item';
    const listActions: ActionTable[] = [
      {
        name: 'Edit',
        type: TYPE_MODAL.EDIT,
        redirect: PATH.ITEMS_EDIT,
      },
      {
        name: data.status === StatusItem.Active ? 'Inactivate' : 'Activate',
        type: !canOperate ? TYPE_MODAL.UNABLE_CHANGE_ACTIVE_STATUS : TYPE_MODAL.STATUS,
        titleModal: !canOperate ? `Unable to inactivate item` : itemStatus,
      },
      {
        name: 'Delete',
        type: !canOperate ? TYPE_MODAL.INFO : TYPE_MODAL.DELETE,
        titleModal: !canOperate ? 'Unable to delete item' : 'Delete item',
      },
    ];
    if (data.bins) {
      listActions.push({
        name: 'Go to item report',
        type: TYPE_MODAL.EDIT,
        redirect: coverPath(PATH.REPORT_DETAIL, {
          wardId: DEFAULT_WARD,
          itemId: data.id,
        }),
      });
    }

    return listActions;
  };

  return [
    {
      id: 'createdAt',
      accessor: 'createdAt',
      Header: (props) => <CustomHeader tableProps={props} title="Created at" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].createdAt)}
        </Typography>
      ),
    },
    {
      id: 'name',
      accessor: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].image}
          name={props.data[props.row.index].name}
          stockType={props.data[props.row.index].stockType}
        />
      ),
      width: '26%',
    },

    {
      id: 'skuId',
      accessor: 'skuId',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].skuId}
          skuSub={props.data[props.row.index].skuSub}
        />
      ),

      width: '15%',
    },
    {
      id: 'quantity',
      accessor: 'qty',
      Header: (props) => <CustomHeader tableProps={props} title="Qty" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].qty} `}
        </Typography>
      ),
    },

    {
      id: 'qtyUnit',
      accessor: 'qtyUnit',
      Header: (props) => <CustomHeader tableProps={props} title="Unit" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].qtyUnit?.name || ''} `}
        </Typography>
      ),
      width: '12%',
    },
    {
      id: 'weight',
      accessor: 'weight',
      Header: (props) => <CustomHeader tableProps={props} title="Weight" />,
      Cell: ({ ...props }) => (
        <WeightCell className="d-flex flex-column">
          <Typography lineHeight={26} variant="body-1">
            {`${props.data[props.row.index].weight} ${props.data[props.row.index].weightUnit}`}
          </Typography>
        </WeightCell>
      ),
      width: '10%',
    },

    {
      id: 'info',
      accessor: 'info',
      Header: 'Description',
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
          }}
        >
          {`${props.data[props.row.index].info ?? ''} `}
        </Typography>
      ),
      width: '20%',
    },
    {
      id: 'status',
      accessor: 'status',
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => (
        <StatusDefault
          label={StatusItem[Number(props.data[props.row.index].status)]}
          isActive={props.data[props.row.index].status === StatusItem.Active}
        />
      ),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={actions(props.data[props.row.index])}
          dividerIndexes={[2]}
        />
      ),
    },
  ];
};

export const stockValueSettingColumns = (
  idItem: number,
  restockPercentage: number,
  alarmPercentage: number,
): ReadonlyArray<Column<StockValueSetting>> => {
  return [
    {
      id: 'ward',
      Header: (props) => <CustomHeader tableProps={props} title="Ward" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {`${props.data[props.row.index].location?.name} `}
        </Typography>
      ),
    },
    {
      id: 'value',
      width: '1%',
      Header: (_props) => (
        <th
          className="d-flex align-items-center"
          style={{
            paddingTop: 0,
            paddingBottom: 15,
            paddingLeft: 12,
            paddingRight: 12,
            border: 'none',
            width: 650,
          }}
        >
          <Typography
            variant="body-7"
            style={{
              width: 160,
              paddingRight: 24,
            }}
          >
            Par level
          </Typography>
          <Typography
            variant="body-7"
            style={{
              width: 160,
              paddingRight: 24,
            }}
          >
            Restock level
          </Typography>
          <Typography
            variant="body-7"
            style={{
              width: 160,
              paddingRight: 24,
            }}
          >
            Alarm level
          </Typography>
        </th>
      ),
      Cell: ({ ...props }) => (
        <RestockAndParValueCard
          restockValue={restockPercentage}
          alarmValue={alarmPercentage}
          wardId={Number(props.data[props.row.index].location?.id) || 0}
          idItem={idItem}
          parValue={props.data[props.row.index].parValue ?? 0}
          index={props.row.index + 1}
        />
      ),
    },
  ];
};
