import styled from 'styled-components';

import {
  ReactComponent as ActiveCheckbox,
  ReactComponent as IconActiveCheckbox,
} from 'app/assets/icons/icon-active-checkbox.svg';
import { ReactComponent as DefaultCheckbox } from 'app/assets/icons/icon-default-checkbox.svg';
import { ReactComponent as IndeterminateCheckbox } from 'app/assets/icons/indeterminate-checkbox.svg';

import Typography from '../Typography';

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  background: var(--neutral-2-5);
  border-radius: 6px;
  cursor: pointer;
`;
const CheckboxContainer = styled.span`
  .disabled {
    rect {
      fill: var(--primary-5);
    }
  }
  .disabled-default {
    rect {
      fill: var(--neutral-4);
    }
  }
`;

interface Props {
  checked: boolean;
  onChange: () => void;
  label?: string | React.ReactNode;
  styleLabel?: React.CSSProperties;
  indeterminate?: boolean;
  disabled?: boolean;
}
const CheckboxDefault: React.FC<Props> = ({
  checked,
  label,
  styleLabel,
  onChange,
  indeterminate,
}) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{
        cursor: 'pointer',
      }}
      onClick={onChange}
    >
      {checked ? <IconActiveCheckbox /> : <Checkbox className="check-box"></Checkbox>}
      {typeof label === 'string' ? (
        <Typography
          variant="body-3"
          color="neutral-6"
          style={{
            marginLeft: 10,
            ...styleLabel,
          }}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
    </div>
  );
};
export default CheckboxDefault;

export const CustomCheckbox: React.FC<Props> = ({
  onChange,
  indeterminate,
  checked,
  disabled,
  label,
}) => {
  const renderCheckbox = () => {
    if (indeterminate) {
      return <IndeterminateCheckbox className={`${disabled ? 'disabled' : ''}`} />;
    }
    if (checked) {
      return <ActiveCheckbox className={`${disabled ? 'disabled' : ''}`} />;
    }
    return <DefaultCheckbox className={`${disabled ? 'disabled-default' : ''}`} />;
  };
  return (
    <div
      className="form-check form-check-custom form-check-solid"
      onClick={disabled ? undefined : onChange}
      style={{
        cursor: disabled ? 'inherit' : 'pointer',
      }}
    >
      <CheckboxContainer className={`${disabled ? 'disabled' : ''}`}>
        {renderCheckbox()}
      </CheckboxContainer>
      <Typography
        variant="body-3"
        color="neutral-6"
        style={{
          marginLeft: 10,
        }}
      >
        {label}
      </Typography>
    </div>
  );
};
