export const PATH = {
  //Auth
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  NOT_FOUND: '/error/404',

  DASHBOARD: '/dashboard',

  REPORT: '/report',
  REPORT_DETAIL: '/report/:wardId/items/:itemId',

  INVENTORIES: '/inventory/',
  ITEMS: '/inventory/items',
  ITEMS_ADD: '/inventory/items/add',
  ITEMS_EDIT: '/inventory/items/edit/:id',
  ITEMS_DETAIL: '/inventory/items/:id',

  RACKS: '/inventory/racks',
  RACK_DETAIL: '/inventory/racks/:rackId',
  RACK_HISTORY: '/inventory/racks/:rackId/history',
  RACK_LOG: '/inventory/racks/:rackId/logs',
  BIN_HISTORY: '/inventory/racks/bin/:id/history',
  RACK_TEST: '/inventory/racks/test/:wardId',

  SYSTEM_SETTING_DEVICE: '/system-settings/devices/',
  SYSTEM_SETTING_DEVICE_TABLET: '/system-settings/devices/tablet',
  SYSTEM_SETTING_DEVICE_GATEWAY: '/system-settings/devices/gateway',
  SYSTEM_SETTING_DEVICE_SENSOR: '/system-settings/devices/sensor',
  INVENTORY_STOCK_RUN: '/inventory/stock-run',
  INVENTORY_STOCK_RUN_ADD: '/inventory/stock-run/add',
  INVENTORY_STOCK_RUN_EDIT: '/inventory/stock-run/edit/:id',
  INVENTORY_STOCK_RUN_DETAIL: '/inventory/stock-run/:id',

  LOCATIONS: '/system-settings/locations/',
  WARDS: '/system-settings/locations/wards',
  ROOMS: '/system-settings/locations/rooms',
  BEDS: '/system-settings/locations/wards/:id/beds',

  USERS: '/system-settings/users/',
  WEB: '/system-settings/users/desktop',
  TABLET: '/system-settings/users/tablet',

  SYSTEM_SETTINGS: '/system-settings/',
  ROLES: '/system-settings/roles',
  PERMISSION: '/system-settings/permission',

  NOTIFICATIONS: '/system-settings/notifications',
  COMPANY_INFORMATION: '/system-settings/company-information',
  USERS_ROLE: '/system-settings/users-role',
  USERS_ROLE_WEB: '/system-settings/users-role/desktop',
  USERS_ROLE_TABLET: '/system-settings/users-role/tablet',
  USERS_ROLE_WEB_DETAIL: '/system-settings/users-role/desktop/:id',
  USERS_ROLE_TABLET_DETAIL: '/system-settings/users-role/tablet/:id',
  CONFIGURATIONS: '/system-settings/configurations/',
  AUTO_LOGOUT_TIME: '/system-settings/configurations/session-timeout',
  ITEM_UNIT: '/system-settings/configurations/item-unit',
  CONFIGURATION_STOCK_RUN: '/system-settings/configurations/stock-run',
  CONFIGURATION_WORK_SHIFT: '/system-settings/configurations/work-shift',
  CONFIGURATION_STOCK_VALUES: '/system-settings/configurations/stock-levels',
  REPORT_DEMAND_PROJECTION_DETAIL: '/report/projection',
  STOCK_RUN_DEMAND_PROJECTION_DETAIL: '/inventory/stock-run/projection',
  ZERO_WEIGHT_SETTING: '/system-settings/zero-weight',
  ZERO_WEIGHT_SETTING_RACK: '/system-settings/zero-weight/:rackId',
};
