import { FC, createContext, useContext, useMemo, useState } from 'react';

import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider';
import { ItemForUpdateListView, emptyListViewProvider } from './constants';
import {
  calculateIsAllDataSelected,
  calculatedGroupingIsDisabled,
  groupingOnSelect,
  groupingOnSelectAll,
} from './helpers';
import { ID, ListViewContextProps, WithChildren, initialListView } from './types';

const ListViewContext = createContext<ListViewContextProps>(initialListView);

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected);
  const [itemForUpdate, setItemForUpdate] = useState<ItemForUpdateListView>(emptyListViewProvider);
  const { isLoading } = useQueryResponse();
  const data = useQueryResponseData();
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data]);
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected]);
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemForUpdate,
        setItemForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected);
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data);
        },
        clearSelected: () => {
          setSelected([]);
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };
