import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { PATH } from 'app/constants/path';

import { toAbsoluteUrl } from '../../../theme/helpers';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { ResetPassword } from './components/ResetPassword';

const BodyAuth = styled.div`
  width: 480px;
  border: 1px solid #f4f4f4;
  border-radius: 16px !important;
  padding: 40px 60px !important;
`;
const AuthLayout = () => {
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center "
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/login.png')})`,
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className="theme-dark-show h-45px"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/logo-sims.svg')}
            className="theme-light-show"
          ></img>
        </a>

        <BodyAuth className="bg-body rounded p-10 p-lg-15 mx-auto">
          <Outlet />
        </BodyAuth>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={PATH.RESET_PASSWORD} element={<ResetPassword />} />
      <Route index element={<Navigate to={PATH.LOGIN} />} />
      <Route path="*" element={<Navigate to={PATH.LOGIN} />} />
    </Route>
  </Routes>
);

export { AuthPage };
