import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import NavigationDefault from 'app/components/Navigation/NavigationDefault';
import { NavigationDetail } from 'app/components/Navigation/core/types';
import RootContentPage from 'app/components/layout/RootContentPage';

import { replacePath } from 'app/helpers';

import { PATH } from 'app/constants/path';

import ConfigAutoLogoutTimePage from './ConfigAutoLogoutTimePage';
import { ConfigQuantityUnitPage } from './ConfigQuantityUnitPage';
import ConfigStockRunPage from './ConfigStockRunPage';
import ConfigWorkShiftPage from './ConfigWorkShiftPage';
import StockValuesPage from './StockValuesPage';

const ConfigurationPage = () => {
  const navigationLocation: NavigationDetail[] = [
    {
      title: 'Session timeout',
      path: PATH.AUTO_LOGOUT_TIME,
    },
    {
      title: 'Item unit',
      path: PATH.ITEM_UNIT,
    },
    {
      title: 'Stock run',
      path: PATH.CONFIGURATION_STOCK_RUN,
    },
    {
      title: 'Stock levels',
      path: PATH.CONFIGURATION_STOCK_VALUES,
    },
    {
      title: 'Work shift',
      path: PATH.CONFIGURATION_WORK_SHIFT,
    },
  ];
  return (
    <Routes>
      <Route
        element={
          <RootContentPage title="Configuration settings">
            <div className="d-flex flex-column">
              <NavigationDefault navigation={navigationLocation} />
              <Outlet />
            </div>
          </RootContentPage>
        }
      >
        <Route
          path={replacePath(PATH.AUTO_LOGOUT_TIME, PATH.CONFIGURATIONS, '')}
          element={<ConfigAutoLogoutTimePage />}
        />

        <Route
          path={replacePath(PATH.ITEM_UNIT, PATH.CONFIGURATIONS, '')}
          element={<ConfigQuantityUnitPage />}
        />
        <Route
          path={replacePath(PATH.CONFIGURATION_STOCK_RUN, PATH.CONFIGURATIONS, '')}
          element={<ConfigStockRunPage />}
        />
        <Route
          path={replacePath(PATH.CONFIGURATION_WORK_SHIFT, PATH.CONFIGURATIONS, '')}
          element={<ConfigWorkShiftPage />}
        />
        <Route
          path={replacePath(PATH.CONFIGURATION_STOCK_VALUES, PATH.CONFIGURATIONS, '')}
          element={<StockValuesPage />}
        />
        <Route index element={<Navigate to={PATH.AUTO_LOGOUT_TIME} />} />
      </Route>
    </Routes>
  );
};

export default ConfigurationPage;
