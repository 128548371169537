import Typography from 'app/components/Typography';

import { ReactComponent as EmptyNotificationIcon } from 'app/assets/icons/empty-notification.svg';

export const EmptyNotification = () => {
  return (
    <div className="d-flex flex-column flex-center" style={{ flexGrow: 1 }}>
      <EmptyNotificationIcon />
      <Typography variant="body-1" color="neutral-6">
        Notification feed is empty.
      </Typography>
      <Typography variant="body-1" color="neutral-6">
        Check back later for new notifications.
      </Typography>
    </div>
  );
};
