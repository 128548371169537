import { ToastContainerProps, toast } from 'react-toastify';

import { isArray, isString } from 'lodash';

export const getResponseError = (errors: any, props?: ToastContainerProps) => {
  if (errors?.response?.data?.data?.errors) {
    const responseErrors = errors?.response?.data?.data?.errors;

    toast.error(isArray(responseErrors) ? responseErrors[0] : responseErrors, props);
    return;
  }

  if (errors?.response?.data?.message) {
    toast.error(errors.response.data.message, props);
    return;
  }

  if (errors.message) {
    toast.error(errors.message, props);
    return;
  }

  if (errors.errors && isArray(errors.errors) && errors.errors.length) {
    errors.errors.forEach((error: any) => {
      toast.error(error, props);
    });
    return;
  }

  if (isString(errors.errors)) {
    toast.error(errors.errors, props);
    return;
  }

  toast.error('Something went wrong. Please try again later', props);
};
