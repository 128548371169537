import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { getWards } from 'app/modules/system-settings/locations/wards/core/requests';

import { useBoolean } from 'app/hooks';
import { cloneDeep, inRange, orderBy } from 'lodash';

import { MMD_ACTIVE_BY_RACK } from 'app/constants/environments';

import { ReactComponent as IconLoading } from 'app/assets/icons/icon-loading-rack.svg';

import { Bin } from '../inventory/bins/core/type';
import NavigationRackCard from '../inventory/racks/components/NavigationRackCard';
import { getRacks } from '../inventory/racks/core/requests';
import { Rack, emptyRack } from '../inventory/racks/core/type';
import InformationRackCard from './components/InformationRackCard';
import ViewListBin from './components/ViewListBin';
import { useRackTestStore } from './store';
import { io } from 'socket.io-client';

export interface Transaction {
  bin: ID;
  weight: string;
  qty: string;
  weight_unit: string;
  sku: string;
  ward_id: string;
  episode_id: string;
  rack_id: ID;
  stockType?: StockTypeEnum;
}

type EpisodePayload = {
  ward_id: string;
  episode_id: string;
  type: EpisodeEventType;
  role: Role;
  rack_id?: string;
};

enum StockTypeEnum {
  'NON-CHARGEABLE' = 0,
  'CHARGEABLE' = 1,
}

export enum EpisodeEventType {
  start = 'start',
  stop = 'stop',
  endCart = 'end-cart',
  timeout = 'timeout',
}

enum Role {
  mmd = 'MMD',
  nurse = 'Nurse',
}

const TIMEOUT = 10 * 1000; //milliseconds

const RACK_LAYOUT = [
  [1, 4],
  [5, 14],
  [15, 29],
  [30, 33],
];

// MMD active/standby by all racks
const isActiveByWard = MMD_ACTIVE_BY_RACK === false;

const RackTestPage = () => {
  const requiredEpisode = useRackTestStore((s) => s.state.requiredEpisode);

  const params = useParams<{ wardId: string }>();

  const [racks, setRacks] = useState<Rack[]>([]);
  const [selectRack, setSelectRack] = useState<Rack>(emptyRack);
  const [bins, setBins] = useState<Bin[]>([]);
  const isLoadingPage = useBoolean(false);

  const [nurseEpisode, setNurseEpisode] = useState('');
  const [mmdEpisode, setMmdEpisode] = useState('');
  const [activeRack, setActiveRack] = useState<Rack | null>(null);
  const [items, setItems] = useState<Transaction[]>([]);

  const socket = useRef<any>(null);
  const timeout = useRef<any>(null);
  const timeout1 = useRef<any>(null);
  const wardId = selectRack?.location?.id;
  const isActive = nurseEpisode || (mmdEpisode && !!activeRack?.id) ? true : false;

  const onEpisodeStart = useCallback(
    (data: EpisodePayload) => {
      const { episode_id, role, rack_id, ward_id } = data;
      if (role === Role.nurse) {
        setNurseEpisode(episode_id);
      } else {
        setMmdEpisode(episode_id);
      }

      if (rack_id) {
        const existed = racks.find((el) => el.id?.toString() === rack_id.toString());
        if (existed) {
          setSelectRack(existed);
          setActiveRack(existed);
        }
      } else if (role === Role.mmd) {
        setActiveRack(null);
      }
    },
    [racks],
  );

  const onEpisodeEnd = useCallback((data: EpisodePayload) => {
    const { episode_id } = data;
    setItems((prevItems) => prevItems.filter((el) => Number(el.episode_id) !== Number(episode_id)));
    setNurseEpisode((prev) => {
      const isNurse = prev === episode_id;
      if (isNurse) {
        // Clear episode
        return '';
      }

      return prev;
    });
    setMmdEpisode((prev) => {
      const isMMD = prev === episode_id;
      if (isMMD) {
        setActiveRack(null);
        // Clear episode
        return '';
      }

      return prev;
    });
  }, []);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL!);

    socket.current.on('episode_test', (data: EpisodePayload) => {
      const { type, ward_id } = data;
      console.log('socket', data);
      if (ward_id !== params.wardId) {
        return;
      }

      if (type === EpisodeEventType.start) {
        onEpisodeStart(data);
      }

      if (type === EpisodeEventType.endCart || type === EpisodeEventType.stop) {
        onEpisodeEnd(data);
      }
    });

    return () => {
      if (socket.current) {
        socket.current.off('episode_test');
      }
    };
  }, [onEpisodeStart, onEpisodeEnd, params.wardId]);

  useEffect(() => {
    if (wardId) {
      socket.current?.emit('get_episode_test', {
        ward_id: wardId,
      });
    }
  }, [wardId]);

  useEffect(() => {
    if (selectRack) {
      const existed = racks.find((el) => el.id === selectRack.id);
      setBins(existed ? existed.bins : []);
    }
  }, [racks, selectRack]);

  const onSend = (
    transactions: Transaction[],
    type: StockTypeEnum,
    bin: Bin,
    isStackingChangeBin: boolean,
    delayed?: boolean,
  ) => {
    const binZone = RACK_LAYOUT.find(([start, end]) => bin.index >= start && bin.index <= end);
    if (binZone === undefined) {
      return;
    }
    const filteredItems = transactions.filter(
      (el) => el.bin && inRange(Number(el.bin), binZone[0], binZone[1] + 1),
    );

    socket.current?.emit(
      'transaction_test',
      filteredItems.map((el) => ({
        ...el,
        time: +new Date() - (delayed ? TIMEOUT : 0),
      })),
    );

    if (!isActive) {
      return setItems([]);
    }

    if (isStackingChangeBin === false) {
      setItems((prev) =>
        prev.filter(
          (el) => el.bin && inRange(Number(el.bin), binZone[0], binZone[1] + 1) === false,
        ),
      );
    }
  };

  const onEmitTransaction = (data: Partial<Transaction>, bin: Bin) => {
    const qty = data.qty ? Number(data.qty) : 0;
    const isMMDProcess = mmdEpisode && !!activeRack;
    console.log('mmdEpisode -->', mmdEpisode);
    console.log('nurseEpisode -->', nurseEpisode);
    const newRacks = cloneDeep(racks);
    const rackIndex = racks.findIndex((el) => el.id === selectRack.id);

    const newBins = rackIndex !== -1 ? newRacks[rackIndex].bins : [];
    const binIndex = newBins.findIndex((el) => el.index === data.bin);

    if (binIndex !== -1) {
      newBins[binIndex] = {
        ...newBins[binIndex],
        item: {
          ...newBins[binIndex].item,
          qty: (newBins[binIndex].item?.qty || 0) + qty,
        } as any,
      };

      newRacks[rackIndex] = {
        ...newRacks[rackIndex],
        bins: newBins,
      };
      setRacks(newRacks);
    }
    const type = parseInt(bin.item?.stockType || '0');
    const obj: any = {
      ...data,
      episode_id: isMMDProcess ? mmdEpisode : nurseEpisode,
      rack_id: selectRack.id,
      time: new Date().getTime(),
      stockType: type,
      qty,
    };
    console.log('obj -->', obj);
    const newItems = cloneDeep(items);
    const itemIndex = newItems.findIndex(
      (el) => el.bin === bin.index && selectRack?.id?.toString() === el.rack_id?.toString(),
    );

    if (itemIndex === -1) {
      newItems.push(obj);
    } else {
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        episode_id: obj.episode_id,
        qty: newItems[itemIndex].qty + Number(obj.qty),
        stockType: type,
      };
    }

    setItems(newItems.filter((el) => Number(el.qty)));

    const rackItems = newItems.filter(
      (el) =>
        el.rack_id?.toString() === selectRack?.id?.toString() && el.episode_id === obj.episode_id,
    );
    const isStackingChangeBin =
      inRange(bin.index, RACK_LAYOUT[0][0], RACK_LAYOUT[0][1] + 1) ||
      inRange(bin.index, RACK_LAYOUT[3][0], RACK_LAYOUT[3][1] + 1);

    if (isStackingChangeBin) {
      onSend(rackItems, type, bin, isStackingChangeBin);
      return;
    }
    if (Number(bin.index) > 14) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        onSend(rackItems, type, bin, isStackingChangeBin, true);
      }, TIMEOUT);
    } else {
      if (timeout1.current) {
        clearTimeout(timeout1.current);
      }

      timeout1.current = setTimeout(() => {
        onSend(rackItems, type, bin, isStackingChangeBin, true);
      }, TIMEOUT);
    }
  };

  useEffect(() => {
    const element: any = document.querySelector('#kt_body');
    if (element) {
      element.style.paddingTop = 0;
      const body: any = element.querySelector('#kt_content_container');
      if (body) {
        body.style.paddingLeft = '0px';
        body.style.paddingTop = '20px';
      }
      const content = element.querySelector('#kt_post');
      if (content) {
        content.style.backgroundColor = '#fff';
      }
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const dataResWards = await getWards(
      stringifyRequestQuery({
        page: 1,
        limit: 1,
      }),
    );
    const newResWards = dataResWards.data?.items || [];
    newResWards.unshift({
      createdAt: '',
      id: 0,
      name: 'None',
      updatedAt: '',
    });

    await getDataRack();
  };

  const getDataRack = async (oldRackId?: number) => {
    const dataResRacks = await getRacks(
      stringifyRequestQuery({
        page: 1,
        limit: 10,
      }) + `&wardId=${params.wardId || ''}`,
    );
    isLoadingPage.setValue(false);
    if (dataResRacks.data?.items.length) {
      if (oldRackId) {
        const newSelectRack = dataResRacks.data?.items.find((el) => el.id === oldRackId);
        if (newSelectRack) {
          setSelectRack(newSelectRack);
        }
      } else {
        setSelectRack(dataResRacks.data?.items[0]);
      }
      setRacks(orderBy(dataResRacks.data?.items, 'id'));
    }
  };

  const onChangeNavigation = (originRack: Rack) => () => {
    setSelectRack(originRack);
  };

  if (!racks.length) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <IconLoading />
      </div>
    );
  }

  return (
    <div className="rack-test">
      <NavigationRackCard
        racks={racks}
        selectRack={selectRack}
        onChangeNavigation={onChangeNavigation}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flexGrow: 1 }}>
          <InformationRackCard
            rackItem={selectRack}
            totalBin={bins.length}
            nurseEpisode={nurseEpisode ? nurseEpisode.toString() : '_'}
            mmdEpisode={mmdEpisode ? mmdEpisode?.toString() : '_'}
            rackStatus={
              mmdEpisode
                ? (isActiveByWard ? !!activeRack : activeRack?.id === selectRack?.id)
                  ? 'Active'
                  : 'Standby'
                : '_'
            }
          />
        </div>
      </div>
      <ViewListBin
        bins={bins}
        onEmitTransaction={onEmitTransaction}
        disabled={requiredEpisode && !isActive}
      />
      {isLoadingPage.value && <LoadingCard />}
    </div>
  );
};

export default RackTestPage;
