import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { formatDate } from 'app/helpers';
import { isToday } from 'date-fns';

import { ReactComponent as IconArrow } from 'app/assets/icons/icon-arrow-up-activity.svg';

import { LogDetail, LogItemDetail } from '../core/type';
import EventTypeLogCard from './EventTypeLogCard';
import clsx from 'clsx';
import { toAbsoluteUrl } from 'theme/helpers';

const MainLog = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;
const LogItemCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  padding: 12px 28px;
  cursor: pointer;
`;
const HeaderLog = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
`;

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 5px;
  width: fit-content;
  padding: 2px 10px;
  background: var(--white);
  border-radius: 8px;
  cursor: pointer;
  #load-more-title {
    color: var(--neutral-7);
  }
  :hover {
    background: var(--neutral-3);
    #load-more-title {
      color: var(--neutral-10);
    }
  }
`;

interface Props {
  logs: LogDetail[];
  logListItems: {
    [key: string]: LogItemDetail[];
  };
  selectLogItem: LogItemDetail;
  openMenu: {
    [key: string]: boolean;
  };
  onClickViewTableLog: (newItem: LogItemDetail) => () => void;
  onClickAccordion: (
    newLog: LogDetail,
    isSaveSelectLogItem?: boolean,
    newOpenMenu?: boolean,
  ) => () => void;
  onLoadMore: (log: LogDetail) => () => void;
}
const ListActivityLogCard: React.FC<Props> = ({
  logs,
  logListItems,
  selectLogItem,
  openMenu,
  onClickViewTableLog,
  onClickAccordion,
  onLoadMore,
}) => {
  const renderTitleLog = (logToDay?: string, logToMonth?: string) => {
    if (logToDay) {
      if (isToday(new Date(logToDay))) {
        return 'Today';
      }
      return formatDate(logToDay, 'dd MMM YYY');
    }
    if (logToMonth) {
      return formatDate(logToMonth, 'MMM YYY');
    }
    return '';
  };
  return (
    <div
      style={{
        width: 220,
        minWidth: 220,
        borderRight: `1px solid var(--neutral-3)`,
        minHeight: 'calc(100vh - 147px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: '20px 0px 20px 28px',
          borderBottom: `1px solid var(--neutral-3)`,
        }}
      >
        <Typography variant="body-6" color="neutral-10">
          Activity log
        </Typography>
      </div>
      <div
        className="accordion"
        id="kt_accordion_1"
        style={{ overflow: 'auto', flexGrow: 1, height: 0 }}
      >
        {logs.map((el, idx) => {
          const logTitle = el.log_to_day ?? el.log_to_month;
          return (
            <MainLog
              className="accordion-item"
              style={{
                borderRadius: 0,
                border: 'none',
              }}
              key={idx}
            >
              <HeaderLog
                className="accordion-header "
                id={`kt_accordion_1_header_${idx}`}
                style={{
                  borderTop: idx ? `1px solid var(--neutral-3)` : '',
                  borderBottom: idx + 1 === logs.length ? `1px solid var(--neutral-3)` : '',
                  width: '100%',
                }}
                onClick={onClickAccordion(el, false, !openMenu[logTitle])}
              >
                <div
                  className="collapsed d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target={`#kt_accordion_1_body_${idx}`}
                  aria-expanded="false"
                  aria-controls={`kt_accordion_1_body_${idx}`}
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: '0px 28px',
                  }}
                >
                  <Typography fontSize={14} fontWeight={600} color="neutral-7">
                    {renderTitleLog(el.log_to_day, el.log_to_month)} ({el.count})
                  </Typography>
                  <IconArrow
                    style={{
                      transform: `rotate(${openMenu[logTitle] ? '0deg' : '180deg'})`,
                    }}
                  />
                </div>
              </HeaderLog>
              <div
                id={`kt_accordion_1_body_${idx}`}
                className={clsx('accordion-collapse collapse', idx === 0 ? 'show' : '')}
                style={{
                  borderTop: idx + 1 !== logs.length ? `1px solid var(--neutral-3)` : '',
                }}
                aria-labelledby={`kt_accordion_1_header_${idx}`}
                data-bs-parent="#kt_accordion_1"
              >
                {logListItems[logTitle] &&
                  Boolean(logListItems[logTitle].length) &&
                  logListItems[logTitle].map((item, index) => {
                    const isActive = item.id === selectLogItem.id;
                    return (
                      <LogItemCard
                        key={index}
                        onClick={onClickViewTableLog(item)}
                        style={{
                          backgroundColor: isActive ? 'var(--primary-2)' : 'var(--white)',
                        }}
                      >
                        <Typography variant="body-3">
                          {formatDate(item.updatedAt, 'dd MMM yyyy, H:mm:ss')}
                        </Typography>
                        {item.user?.firstName || item.user?.lastName ? (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              marginTop: 3,
                              width: '-webkit-fill-available',
                            }}
                          >
                            <img
                              src={toAbsoluteUrl('/media/avatars/icon-avatar-default.svg')}
                              style={{
                                width: 16,
                                height: 16,
                              }}
                              alt="icon"
                            />
                            <Typography
                              className="text-overflow-ellipsis"
                              variant="body-4"
                              style={{
                                marginLeft: 4,
                              }}
                            >
                              {`${item.user.firstName ?? ''} ${item.user.lastName ?? ''}`}
                            </Typography>
                          </div>
                        ) : null}

                        <EventTypeLogCard type={item.eventType} />
                      </LogItemCard>
                    );
                  })}
                {logListItems[logTitle] && logListItems[logTitle].length < Number(el.count) ? (
                  <div className="d-flex justify-content-center w-100">
                    <LoadMoreButton>
                      <Typography
                        id="load-more-title"
                        variant="title-4"
                        fontSize={12}
                        onClick={onLoadMore(el)}
                      >
                        Load more
                      </Typography>
                    </LoadMoreButton>
                  </div>
                ) : null}
              </div>
            </MainLog>
          );
        })}
      </div>
    </div>
  );
};
export default ListActivityLogCard;
