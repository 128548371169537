import { FC } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { isUndefined } from 'lodash';

import { useListView } from '../core/ListViewProvider';
import { useQueryResponsePagination } from '../core/QueryResponseProvider';
import { checkIsRowHoverable } from '../core/helpers';
import { TYPE_MODAL, TableSpacing } from '../core/types';
import clsx from 'clsx';

type Props = {
  row: Row;
  onClickBodyTable?: (row: Row) => () => void;
  isRowClickable?: boolean;
  fieldCheckShowCursorInRow?: string;
  spacing?: TableSpacing;
  choseRowId?: number;
};
const TableTr = styled.tr<{
  background?: string;
  isRowClickable: boolean;
  isRowChosen?: boolean;
}>`
  border-bottom: 1px solid var(--neutral-3) !important;
  background-color: ${(props) => props.background};
  cursor: ${(props) => (props.isRowClickable ? 'pointer' : '')};
  :hover {
    background-color: ${(props) => (props.isRowClickable ? 'var(--neutral-2)' : '')};
  }
`;

const SPACING_CONFIG = {
  xxxl: {
    paddingTop: 31,
    paddingBottom: 31,
  },
  xxl: {
    paddingTop: 25,
    paddingBottom: 25,
  },
  xl: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  lg: {
    paddingTop: 18,
    paddingBottom: 18,
  },
  md: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  sm: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  none: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

export const getSpacingConfig = (
  spacing: TableSpacing,
  options?: { left?: boolean; right?: boolean },
) => {
  return {
    paddingLeft: options?.left ? 28 : 12,
    paddingRight: options?.right ? 28 : 12,
    ...SPACING_CONFIG[spacing],
  };
};

const InfoRow: FC<Props> = ({
  row,
  onClickBodyTable,
  fieldCheckShowCursorInRow,
  isRowClickable = true,
  spacing,
  choseRowId,
}) => {
  const pagination = useQueryResponsePagination();
  const { setItemForUpdate } = useListView();
  const renderCell = (cell: any) => {
    if (cell.render('id') === '#') {
      const newIndex = (pagination.page - 1) * pagination.limit + row.index + 1;
      return (
        <Typography color="neutral-10" variant="body-5">
          {newIndex}
        </Typography>
      );
    }
    if (cell.render('id') === 'radio') {
      return (cell.column as any).Cell({}, choseRowId === rowOriginal.id);
    }
    return cell.render('Cell');
  };
  const onClickBody = () => {
    if (!isUndefined(onClickBodyTable)) {
      onClickBodyTable(row)();
      return;
    }
    if (rowOriginal[fieldCheckShowCursorInRow as string]) {
      return;
    }
    setItemForUpdate({
      originalData: row.original,
      type: TYPE_MODAL.EDIT,
      isView: true,
      titleModal: '',
    });
  };
  const rowOriginal = row.original as any;

  const getRowBackgroundColor = () => {
    if (Number(choseRowId) === rowOriginal.id) {
      return 'var(--primary-2)';
    }
    if (row.index % 2 === 0) {
      return 'var(--neutral-1)';
    }
    return 'var(--white)';
  };

  return (
    <TableTr
      background={getRowBackgroundColor()}
      isRowClickable={checkIsRowHoverable(row, isRowClickable, fieldCheckShowCursorInRow)}
      {...row.getRowProps()}
    >
      {row.cells.map((cell, cellIndex) => {
        return (
          <td
            onClick={cell.render('id') !== 'actions' ? onClickBody : undefined}
            {...cell.getCellProps()}
            className={clsx({
              'text-end min-w-100px': cell.column.id === 'actions',
            })}
            style={{
              width: cell.render('width') ? (cell.render('width') as any) : 'auto',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '26px',
              color: 'var(--neutral-10)',
              ...getSpacingConfig(spacing as TableSpacing, {
                left: cellIndex === 0,
                right: cellIndex === row.cells.length - 1,
              }),
              cursor: cell.render('id') !== 'actions' ? '' : 'default',
            }}
          >
            {renderCell(cell)}
          </td>
        );
      })}
    </TableTr>
  );
};

export { InfoRow };
