import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { useChangeParams } from 'app/modules/query-state/hooks';

import { useQueryRequest } from '../core/QueryRequestProvider';
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider';

const Container = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 32px;
  padding-right: 28px;
  margin-bottom: 28px;
  .page-item {
    margin-right: 0px;
    margin-left: 12px;
    :hover a {
      background: var(--primary);
      color: var(--white) !important;
    }
  }
  .active a {
    background: var(--primary) !important;
    color: var(--white) !important;
  }

  .page-item a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--neutral-6);
    cursor: pointer;
    height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    background: var(--neutral-2);
    border-radius: 6px;
    :hover {
      color: var(--neutral-6);
    }
  }
  .break {
    margin-left: 20px;
    margin-right: 8px;
  }
  .break a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--neutral-6);
    :hover {
      color: var(--neutral-6);
    }
  }
`;

const Pagination: React.FC<{
  syncParamUrl?: boolean;
}> = ({ syncParamUrl }) => {
  const pagination = useQueryResponsePagination();
  const isLoading = useQueryResponseLoading();
  const { updateState } = useQueryRequest();
  const originData = useQueryResponseData();
  const { onChangeParams } = useChangeParams();

  const handlePageClick = (event: { selected: number }) => {
    const newPage = event.selected + 1;
    if (!newPage || isLoading) {
      return;
    }
    updateState({ page: newPage, limit: pagination.limit || 10 });

    if (syncParamUrl) {
      onChangeParams({ page: newPage, limit: pagination.limit || 10 });
    }
  };

  if (!pagination.links?.length || !originData.length) {
    return <div />;
  }

  return (
    <Container>
      <ReactPaginate
        nextLabel=""
        onPageChange={handlePageClick}
        pageCount={pagination.links.length}
        forcePage={pagination.page - 1}
        previousLabel=""
        pageClassName="page-item"
        previousClassName="page-item-next"
        nextClassName="page-item-next"
        breakLabel="..."
        containerClassName="pagination"
        activeClassName="active"
      />
    </Container>
  );
};

export { Pagination };
