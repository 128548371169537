import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { getListTabletName } from '../core/utils';
import { tabletBycurWardSelector, useRackStore } from '../store';

interface Props {
  wardId: number;
}

const CardWrapper = styled.div`
  background-color: var(--primary-3);
  padding: 3px 10px;
  border-radius: 4px;
  width: fit-content;
  min-width: 103px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ActiveTabletCard: React.FC<Props> = ({ wardId }) => {
  const tablets = useRackStore(tabletBycurWardSelector(wardId));

  return tablets.length ? (
    <CardWrapper>
      <Typography variant="body-8" color="#0056C6">
        Active tablet
      </Typography>
      {getListTabletName(tablets).map((tablet) => (
        <Typography
          className="text-overflow-ellipsis"
          variant="title-5"
          color="primary"
          style={{
            maxWidth: '103px',
            width: 'fit-content',
          }}
        >
          {tablet}
        </Typography>
      ))}
    </CardWrapper>
  ) : null;
};
export default ActiveTabletCard;
