import { COLOR } from 'app/components/Typography/core/enum';

import { merge } from 'lodash';

import { ChartOptions } from 'chart.js';

const getDefaultChartOptions = (): ChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        color: COLOR.neutral_7,
        usePointStyle: true,
        padding: 24,
        font: {
          size: 14,
        },
        textAlign: 'left',
        boxWidth: 10,
        boxHeight: 10,
      },
      onClick: () => {
        //do nothing
      },
    },
    tooltip: {
      mode: 'index',
      position: 'nearest',
      bodyAlign: 'left',
      bodyFont: {
        family: 'Archivo',
        size: 14,
        lineHeight: '23px',
      },
      padding: 16,
      boxWidth: 10,
      titleMarginBottom: 20,
      bodySpacing: 12,
      boxPadding: 6,
      titleFont: {
        family: 'Archivo',
        size: 16,
      },
      footerMarginTop: 16,
      footerFont: {
        family: 'Archivo',
        size: 14,
        weight: '600',
      },
      usePointStyle: true,
      callbacks: {
        labelColor: function (context) {
          return {
            borderColor: context.element.options.backgroundColor,
            backgroundColor: context.element.options.backgroundColor,
            borderWidth: 1,
          };
        },
      },
    },
    title: {
      align: 'start',
      font: {
        size: 18,
        weight: '600',
        family: 'Archivo',
      },
      color: COLOR.neutral_10,
    },
  },
  scales: {
    x: {
      grid: { display: false },
      ticks: {
        color: COLOR.neutral_7,
        font: {
          size: 14,
        },
      },
      border: { display: false },
    },
    y: {
      ticks: {
        color: COLOR.neutral_7,
        font: {
          size: 14,
        },
      },
      grid: {
        drawTicks: true,
        color: COLOR.neutral_6,
        tickWidth: 0,
      },
      border: {
        display: false,
        dash: [3, 4],
        width: 2,
      },
    },
  },
});

export const getLineChartOptions = (options: ChartOptions<'line'>): ChartOptions<'line'> =>
  merge(
    getDefaultChartOptions(),
    {
      interaction: {
        mode: 'index',
        intersect: false,
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0.2,
        },
      },
      plugins: {
        legend: {
          labels: {
            boxWidth: 20,
            boxPadding: 16,
            pointStyle: 'line',
          },
        },
        tooltip: {
          callbacks: {
            labelPointStyle: () => ({
              pointStyle: 'circle',
              rotation: 0,
            }),
          },
        },
      },
    } as ChartOptions<'line'>,
    options,
  );

export const getBarChartOptions = (options?: ChartOptions<'bar'>): ChartOptions<'bar'> =>
  merge(
    getDefaultChartOptions(),
    {
      elements: {
        bar: {
          backgroundColor: COLOR.primary_5,
          hoverBackgroundColor: COLOR.primary_7,
        },
      },
      plugins: {
        legend: {
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          yAlign: 'bottom',
        },
      },
    } as ChartOptions<'bar'>,
    options,
  );
