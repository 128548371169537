import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
} from 'date-fns';

import { NameSetting } from './enum';
import { MQTTOption, QuantityUpdateMethod, StockLevelSettingType, TimeFrame } from './type';

export const definedDateRanges = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfYesterday: startOfYesterday(),
  endOfYesterday: endOfYesterday(),

  startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),

  startOfLastWeek: startOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }),
  endOfLastWeek: endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }),

  startOfPrevMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfPrevMonth: endOfMonth(addMonths(new Date(), -1)),

  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),

  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),

  startOfPrevQuarter: startOfQuarter(addQuarters(new Date(), -1)),
  endOfPrevQuarter: endOfQuarter(addQuarters(new Date(), -1)),

  startOfPrevYear: startOfYear(addYears(new Date(), -1)),
  endOfPrevYear: endOfYear(addYears(new Date(), -1)),

  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),

  startOfNextYear: startOfYear(addYears(new Date(), 1)),
  endOfNextYear: endOfYear(addYears(new Date(), 1)),
};

const options = (includeLog?: boolean) => [
  {
    //today
    start: definedDateRanges.startOfToday,
    end: definedDateRanges.endOfToday,
  },
  {
    //yesterday
    start: definedDateRanges.startOfYesterday,
    end: definedDateRanges.endOfYesterday,
  },
  {
    //this week
    start: definedDateRanges.startOfWeek,
    end: definedDateRanges.endOfWeek,
  },
  {
    //last week
    start: definedDateRanges.startOfLastWeek,
    end: definedDateRanges.endOfLastWeek,
  },
  {
    //last month
    start: definedDateRanges.startOfPrevMonth,
    end: definedDateRanges.endOfPrevMonth,
  },
  {
    //this month
    start: definedDateRanges.startOfMonth,
    end: definedDateRanges.endOfMonth,
  },
  {
    //last year
    start: definedDateRanges.startOfPrevYear,
    end: definedDateRanges.endOfPrevYear,
  },
  includeLog
    ? {
        //last 3 months
        start: subMonths(definedDateRanges.startOfToday, 3),
        end: definedDateRanges.startOfToday,
      }
    : {
        //this year
        start: definedDateRanges.startOfYear,
        end: definedDateRanges.endOfYear,
      },
  {
    //last 30 days
    start: subDays(definedDateRanges.startOfToday, 29),
    end: definedDateRanges.startOfToday,
  },
  {
    //Last 7 days
    start: subDays(definedDateRanges.startOfToday, 6),
    end: definedDateRanges.startOfToday,
  },
];

const currentDate = new Date();

export const staticDateRanges = (includeLog?: boolean) =>
  [
    {
      label: 'Today',
      range: () => ({
        startDate: definedDateRanges.startOfToday,
        endDate: definedDateRanges.endOfToday,
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: definedDateRanges.startOfYesterday,
        endDate: definedDateRanges.endOfYesterday,
      }),
    },
    {
      label: 'This week',
      range: () => ({
        startDate: definedDateRanges.startOfWeek,
        endDate: definedDateRanges.endOfWeek,
      }),
    },
    {
      label: 'Last week',
      range: () => ({
        startDate: definedDateRanges.startOfLastWeek,
        endDate: definedDateRanges.endOfLastWeek,
      }),
    },
    {
      label: 'This month',
      range: () => ({
        startDate: definedDateRanges.startOfMonth,
        endDate: definedDateRanges.endOfMonth,
      }),
    },
    {
      label: 'Last month',
      range: () => ({
        startDate: definedDateRanges.startOfPrevMonth,
        endDate: definedDateRanges.endOfPrevMonth,
      }),
    },
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: subDays(definedDateRanges.startOfToday, 6),
        endDate: definedDateRanges.startOfToday,
      }),
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: subDays(definedDateRanges.startOfToday, 29),
        endDate: definedDateRanges.startOfToday,
      }),
    },
    includeLog
      ? {
          label: 'Last 3 months',
          range: () => ({
            startDate: subMonths(definedDateRanges.startOfToday, 3),
            endDate: definedDateRanges.startOfToday,
          }),
        }
      : {
          label: 'This year',
          range: () => ({
            startDate: definedDateRanges.startOfYear,
            endDate: definedDateRanges.endOfYear,
          }),
        },
    !includeLog
      ? {
          label: 'Last year',
          range: () => ({
            startDate: definedDateRanges.startOfPrevYear,
            endDate: definedDateRanges.endOfPrevYear,
          }),
        }
      : undefined,

    {
      label: 'Custom',
      range: () => ({
        startDate: currentDate,
        endDate: addDays(currentDate, 1),
      }),
      isSelected: ({ startDate, endDate }: any) => {
        const isExisted = options(includeLog).some(
          (el) =>
            new Date(el.start).toDateString() === new Date(startDate).toDateString() &&
            new Date(el.end).toDateString() === new Date(endDate).toDateString(),
        );
        return !isExisted;
      },
    },
  ].filter((el) => el !== undefined);

export const DEFAULT_SHIFTS: TimeFrame[] = [
  {
    from: setMinutes(setHours(new Date(), 7), 0),
    to: setMinutes(setHours(new Date(), 15), 0),
  },
  {
    from: setMinutes(setHours(new Date(), 15), 0),
    to: setMinutes(setHours(new Date(), 22), 0),
  },
  {
    from: setMinutes(setHours(new Date(), 22), 0),
    to: setMinutes(setHours(new Date(), 7), 0),
  },
];

const MQTT_TITLE = {
  [QuantityUpdateMethod.afterSessionCompleted]:
    'Update the quantity balance from planned stock run index',
  [QuantityUpdateMethod.immediately]: 'Update the quantity balance from MQTT message',
};

export const MQTT_SETTING: MQTTOption[] = [
  {
    title: MQTT_TITLE[QuantityUpdateMethod.afterSessionCompleted],
    value: QuantityUpdateMethod.afterSessionCompleted,
  },
  {
    title: MQTT_TITLE[QuantityUpdateMethod.immediately],
    value: QuantityUpdateMethod.immediately,
  },
];

export const STOCK_LEVEL_TITLE = {
  [StockLevelSettingType.restock]: 'Restock level',
  [StockLevelSettingType.alarm]: 'Alarm level',
};

export const ROLE_SETTING_TITLE = Object.freeze({
  [NameSetting.mmdUserAt]: "MMD's session",
  [NameSetting.nurseUserAt]: "Nurse's session",
});
