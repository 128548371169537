import { FC } from 'react';

import { CustomSpinner } from '../Spinner';
import clsx from 'clsx';

export interface ActionButtonProps {
  onCancel: () => void;
  labelButtonSubmit?: string;
  labelButtonReset?: string;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  disableButton?: boolean;
  themeButton?: 'btn-primary' | 'btn-light' | 'btn-danger';
  customClass?: string;
  isShowButtonReset?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({
  onCancel,
  labelButtonSubmit = 'Submit',
  labelButtonReset = 'Cancel',
  onSubmit,
  isSubmitting,
  disableButton,
  themeButton = 'btn-primary',
  customClass,
  isShowButtonReset = true,
}) => {
  return (
    <div
      className={customClass ?? 'text-end'}
      style={{
        marginTop: 24,
      }}
    >
      {isShowButtonReset && (
        <button
          type="reset"
          onClick={onCancel}
          className="btn btn-light"
          data-kt-users-modal-action="cancel"
          disabled={isSubmitting}
          style={{
            height: 43,
            width: 140,
            marginRight: 12,
          }}
        >
          {labelButtonReset}
        </button>
      )}

      <button
        onClick={onSubmit}
        type="submit"
        className={clsx('btn', themeButton)}
        style={{
          height: 43,
          width: 140,
          padding: 0,
        }}
        data-kt-users-modal-action="submit"
        disabled={disableButton}
      >
        {!isSubmitting && <span className="indicator-label">{labelButtonSubmit}</span>}
        {isSubmitting && <CustomSpinner />}
      </button>
    </div>
  );
};
