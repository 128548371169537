import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';

import { useAuth } from '../core/Auth';
import { LOGIN_TYPE } from '../core/enum';
import { login } from '../core/requests';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});
const TitleForgotPassword = styled.p`
  margin: 0px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--primary);
  text-align: center;
  :hover {
    text-decoration: underline;
  }
`;

const initialValues = {
  email: '', // Default for testing, remove when release
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const passwordShown = useBoolean(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login({
          email: values.email,
          password: values.password,
          loginType: LOGIN_TYPE.WEB,
        });
        const { statusCode, data, message } = auth;
        if (statusCode >= 400) {
          saveAuth(undefined);
          setStatus(data.errors || message || 'Loglin faied');
          setSubmitting(false);
          setLoading(false);
        } else {
          saveAuth(data);
        }
      } catch (error) {
        saveAuth(undefined);
        setStatus(
          axios.isAxiosError(error)
            ? error.response?.data.data.errors
            : 'The login detail is incorrect',
        );
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <Typography fontSize={26} fontWeight={700} lineHeight={41} color="neutral-10">
          Sign In
        </Typography>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-10 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <FormInput
        label="Email"
        placeholder="Enter your email"
        formik={formik}
        field="email"
        rootClass="w-100"
        typeInput="text"
        isShowIcon={false}
      />
      <FormInput
        label="Password"
        placeholder="Enter password"
        formik={formik}
        field="password"
        rootClass="w-100"
        typeInput={passwordShown.value ? 'text' : 'password'}
        passwordShown={passwordShown.value}
        setPasswordShown={() => passwordShown.setValue(!passwordShown.value)}
        isShowIcon={false}
      />
      <div
        className="text-center"
        style={{
          paddingTop: 16,
        }}
      >
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary"
          style={{
            width: 180,
          }}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <Typography variant="body-6">Sign in</Typography>}
          {loading && (
            <Typography variant="body-6">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </Typography>
          )}
        </button>
      </div>
      <Link to="/forgot-password">
        <TitleForgotPassword>Forgot password</TitleForgotPassword>
      </Link>
    </form>
  );
}
