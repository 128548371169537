import { CSSProperties } from 'react';

import Typography from 'app/components/Typography';

import { getItemAttribute } from '../../stock-run/core/helpers';

interface Props {
  skuId: string;
  skuSub: string;
  stockRunQtyCompleted?: number;
  isStockRunCompleted?: boolean;
  subSkuStyle?: React.CSSProperties;
  skuIdStyle?: React.CSSProperties;
  containerStyle?: CSSProperties;
}
const SKUCard: React.FC<Props> = ({
  skuId,
  skuSub,
  stockRunQtyCompleted,
  isStockRunCompleted,
  subSkuStyle,
  skuIdStyle,
  containerStyle,
}) => {
  const textDecoration = getItemAttribute('textDecoration', {
    isCompleted: isStockRunCompleted || false,
    qtyCompleted: stockRunQtyCompleted || 0,
  });
  return (
    <div className="d-flex flex-column justify-content-between" style={{ ...containerStyle }}>
      <Typography
        variant="body-6"
        fontWeight={400}
        color={getItemAttribute('color', {
          isCompleted: isStockRunCompleted || false,
          qtyCompleted: stockRunQtyCompleted || 0,
        })}
        style={{
          whiteSpace: 'nowrap',
          textDecoration,
          ...skuIdStyle,
        }}
      >
        {skuId}
      </Typography>
      {skuSub && (
        <Typography
          variant="body-4"
          color="neutral-6"
          style={{
            marginTop: 8,
            whiteSpace: 'nowrap',
            textDecoration,
            ...subSkuStyle,
          }}
        >
          {skuSub}
        </Typography>
      )}
    </div>
  );
};
export default SKUCard;
