import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as ActiveCheckbox } from 'app/assets/icons/icon-active-checkbox.svg';
import { ReactComponent as DefaultCheckbox } from 'app/assets/icons/icon-default-checkbox.svg';
import { ReactComponent as IndeterminateCheckbox } from 'app/assets/icons/indeterminate-checkbox.svg';

const Checkbox = styled.span`
  .disabled {
    rect {
      fill: var(--primary-5);
    }
  }

  .disabled-default {
    rect {
      fill: var(--neutral-4);
    }
  }
`;

interface Props {
  onChangePermission?: () => void;
  showIndeterminateCheckbox: boolean;
  disabled: boolean;
  checked: boolean;
}

export const PermissionCheckbox: FC<Props> = ({
  onChangePermission,
  showIndeterminateCheckbox,
  checked,
  disabled,
}) => {
  const renderCheckbox = () => {
    if (showIndeterminateCheckbox) {
      return <IndeterminateCheckbox className={`${disabled ? 'disabled' : ''}`} />;
    }
    if (checked) {
      return <ActiveCheckbox className={`${disabled ? 'disabled' : ''}`} />;
    }
    return <DefaultCheckbox className={`${disabled ? 'disabled-default' : ''}`} />;
  };
  return (
    <div
      className="form-check form-check-custom form-check-solid"
      onClick={disabled ? undefined : onChangePermission}
      style={{
        cursor: disabled ? 'inherit' : 'pointer',
      }}
    >
      <Checkbox className={`${disabled ? 'disabled' : ''}`}>{renderCheckbox()}</Checkbox>
    </div>
  );
};
