import React, { MouseEventHandler, useEffect } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';

import { useChangeParams } from 'app/modules/query-state/hooks';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';

import { useListView } from '../core/ListViewProvider';
import { useQueryRequest } from '../core/QueryRequestProvider';
import { useQueryResponse, useQueryResponsePagination } from '../core/QueryResponseProvider';
import { emptyListViewProvider } from '../core/constants';
import { ID, TYPE_MODAL } from '../core/types';
import { ModalHeader } from './HeaderModal';

interface Props {
  title: string;
  originalData: any;
  label?: string;
  nameQuery: string;
  onClickCloseModal?: MouseEventHandler<HTMLDivElement>;
  deleteById?: (id: ID) => Promise<void>;
  ModalFormConfirmation?: <T>(originalData: T, typeModal: TYPE_MODAL) => JSX.Element;
  typeModal: TYPE_MODAL;
  messageDelete?: string;
  syncParamUrl?: boolean;
}
const ConfirmationModal: React.FC<Props> = ({
  title,
  label,
  originalData,
  typeModal,
  deleteById,
  ModalFormConfirmation,
  onClickCloseModal,
  messageDelete,
  syncParamUrl,
}) => {
  const { refetch, response } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const { updateState } = useQueryRequest();
  const { onChangeParams } = useChangeParams();
  const pagination = useQueryResponsePagination();
  const loading = useBoolean(false);
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const onSubmitDelete = async () => {
    loading.setValue(true);
    if (!deleteById) {
      return;
    }
    try {
      await deleteById(originalData?.id);
      toast.success(`Delete ${messageDelete} successfully!`);
      if (response?.data.items.length === 1) {
        cancel(true);
        updateState({
          page: pagination.page - 1,
        });
        if (syncParamUrl && pagination.page > 1) {
          onChangeParams({ page: pagination.page - 1 });
        }
        return;
      }
      cancel(true);
    } catch (error: any) {
      getResponseError(error);
    } finally {
      loading.setValue(false);
    }
  };

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered" style={{ width: 440 }}>
          <div className="modal-content">
            <ModalHeader onClickCloseModal={onClickCloseModal} title={title} />
            <div className="modal-body d-flex flex-column scroll-y">
              {ModalFormConfirmation && typeModal !== TYPE_MODAL.DELETE ? (
                ModalFormConfirmation(originalData, typeModal)
              ) : (
                <React.Fragment>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '23px',
                      marginBottom: '24px',
                    }}
                  >
                    {label} This action can not undone.
                  </span>
                  <ActionButton
                    onCancel={cancel}
                    onSubmit={onSubmitDelete}
                    isSubmitting={loading.value}
                    labelButtonSubmit="Delete"
                    themeButton="btn-danger"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
export default ConfirmationModal;
