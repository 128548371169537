import { Column } from 'react-table';

import StatusDefault from 'app/components/Status/StatusDefault';
import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { TYPE_MODAL } from 'app/components/Table/core/types';
import Typography, { EmptyTableCell } from 'app/components/Typography';

import { StatusBed } from './enum';
import { Bed } from './type';

export const bedsColumns = (): ReadonlyArray<Column<Bed>> => {
  return [
    {
      id: 'name',
      accessor: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="No" />,
    },
    {
      id: 'code',
      accessor: 'code',
      Header: (props) => <CustomHeader tableProps={props} title="Name" />,
      Cell: ({ ...props }) =>
        props.data[props.row.index].code ? (
          <Typography color="neutral-10" variant="body-5">
            {props.data[props.row.index].code || ''}
          </Typography>
        ) : (
          EmptyTableCell
        ),
    },
    {
      id: 'codeId',
      accessor: 'codeId',
      Header: (props) => <CustomHeader tableProps={props} title="ID" />,
      Cell: ({ ...props }) =>
        props.data[props.row.index].codeId ? (
          <Typography color="neutral-10" variant="body-5">
            {props.data[props.row.index].codeId || ''}
          </Typography>
        ) : (
          EmptyTableCell
        ),
    },
    {
      id: 'status',
      accessor: 'status',
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => (
        <StatusDefault
          isActive={String(props.data[props.row.index].status) === String(StatusBed[1])}
          label={String(props.data[props.row.index].status)}
        />
      ),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={[
            {
              name: 'Edit',
              type: TYPE_MODAL.EDIT,
              titleModal: 'Edit bed',
            },
            {
              name:
                String(props.data[props.row.index].status) === String(StatusBed[1])
                  ? 'Inactivate'
                  : 'Activate',
              type: TYPE_MODAL.STATUS,
              titleModal:
                String(props.data[props.row.index].status) === String(StatusBed[1])
                  ? 'Inactivate bed'
                  : 'Activate bed',
            },
          ]}
        />
      ),
    },
  ];
};
