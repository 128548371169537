import styled from 'styled-components';

import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import Typography from 'app/components/Typography';

import { useNavigateWithPreviousPath } from 'app/helpers/utils';

import { PATH } from 'app/constants/path';

import { ReactComponent as EditICon } from 'app/assets/icons/edit-icon.svg';

import { StockTypeEnum } from '../core/enum';
import { hasWhiteSpace } from '../core/helpers';
import { Item } from '../core/type';
import InfoItemCard from './InfoItemCard';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px;
  background: var(--white);
  border-radius: 8px;
  margin-top: 24px;
`;
const Image = styled.div`
  width: 192px;
  min-width: 192px;
`;
const ButtonEdit = styled.div`
  width: 140px;
  height: 43px;
  border-radius: 8;
  svg path[stroke] {
    stroke: var(--primary);
  }
  :hover {
    svg path[stroke] {
      stroke: var(--white);
    }
    background: var(--primary);
    p {
      color: var(--white);
    }
  }
`;

interface Props {
  item: Item | null;
}
const ShowInfoItemCard: React.FC<Props> = ({ item }) => {
  const navigate = useNavigateWithPreviousPath();

  const onClickEditItem = () => {
    if (!item?.id) {
      return;
    }
    navigate(PATH.ITEMS_EDIT.replace(':id', String(item.id)));
  };

  return (
    <Main>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          width: '100%',
        }}
      >
        <Typography
          variant="title-1"
          lineHeight={32}
          style={{
            marginRight: 24,
          }}
        >
          Detail
        </Typography>
        <ButtonEdit
          className="btn d-flex justify-content-center align-items-center"
          onClick={onClickEditItem}
        >
          <EditICon />
          <Typography
            variant="title-4"
            color="primary"
            style={{
              marginLeft: 8,
            }}
          >
            Edit
          </Typography>
        </ButtonEdit>
      </div>
      <div
        className="d-flex align-items-start"
        style={{
          width: '100%',
          marginTop: 32,
        }}
      >
        <Image>
          <ShowImageDefault
            styleMain={{
              width: 160,
              height: 160,
              minWidth: 160,
              minHeight: 160,
            }}
            image={item?.image ?? ''}
          />
        </Image>
        <div
          className="d-flex flex-column"
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridRow: 10,
              width: '100%',
            }}
          >
            <InfoItemCard title="Item name" value={item?.name} fontSizeValue={16} />
            <InfoItemCard
              title="Item type"
              value={StockTypeEnum[Number(item?.stockType)]}
              fontSizeValue={16}
            />
            <InfoItemCard title="SKU" value={item?.skuId} fontSizeValue={16} />
            <InfoItemCard title="Sub SKU" value={item?.skuSub} fontSizeValue={16} />
            <InfoItemCard title="Unit" value={item?.qtyUnit?.name} fontSizeValue={16} />
            <InfoItemCard title="Weight (g)" value={item?.weight.toString()} fontSizeValue={16} />
            <InfoItemCard
              title="Volume per package"
              value={item?.volumePerPackage}
              fontSizeValue={16}
            />
          </div>
          <InfoItemCard
            title="Description"
            value={item?.info}
            styleRoot={{
              marginBottom: 0,
              wordBreak: item && hasWhiteSpace(item.info) ? 'normal' : 'break-all',
            }}
            fontSizeValue={16}
          />
        </div>
      </div>
    </Main>
  );
};
export default ShowInfoItemCard;
