import { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { useTiming } from 'app/hooks';

import { forgotPassword } from '../core/requests';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

const TitleBackToLogin = styled.p`
  margin: 0px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--primary);
  text-align: center;
  :hover {
    text-decoration: underline;
  }
`;

export function ForgotPassword() {
  const isSuccess = useBoolean(false);
  const isResend = useRef(false);
  const onResending = useBoolean(false);

  const { timingRedirect, time } = useTiming(() => {
    time.setValue(60);
    onResending.setValue(false);
    isResend.current = false;
  }, 60);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isResend.current) {
          timingRedirect();
          onResending.setValue(true);
        }
        setSubmitting(true);
        await forgotPassword(values.email);
        isSuccess.setValue(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onResend = () => {
    if (!isResend.current) {
      isResend.current = true;
      formik.handleSubmit();
    }
  };

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center">
        <Typography
          fontSize={26}
          fontWeight={700}
          lineHeight={41}
          color="neutral-10"
          style={{
            marginBottom: isSuccess.value ? 12 : 40,
          }}
        >
          {isSuccess.value ? 'Check your email inbox' : 'Forgot your password?'}
        </Typography>
      </div>
      {/* begin::Heading */}

      {isSuccess.value ? (
        <div>
          <Typography variant="body-5" color="neutral-6" style={{ textAlign: 'center' }}>
            We sent a link to create a new password for your account. Please check your email inbox.
          </Typography>

          <Typography
            variant="body-5"
            color="neutral-6"
            style={{ textAlign: 'center', marginTop: 20 }}
          >
            Didn't receive an email?{' '}
            <span style={{ color: 'var(--primary)', cursor: 'pointer' }} onClick={onResend}>
              Resend
            </span>
            {onResending.value && ` in (${time.value}s)`}
          </Typography>
        </div>
      ) : (
        <>
          {formik.status && (
            <div className="mb-lg-10 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Form group */}
          <FormInput
            label="Email"
            placeholder="Enter the email you use for SIMS"
            formik={formik}
            field="email"
            rootClass="w-100"
            typeInput="text"
          />
          <div
            className="text-center"
            style={{
              paddingTop: 16,
            }}
          >
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary"
              style={{
                width: 180,
              }}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!formik.isSubmitting && <Typography variant="body-6">Continue</Typography>}
              {formik.isSubmitting && (
                <Typography variant="body-6">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </Typography>
              )}
            </button>
          </div>
          <Link to="/login">
            <TitleBackToLogin>Back to Log in</TitleBackToLogin>
          </Link>
        </>
      )}
    </form>
  );
}
