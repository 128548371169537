import { useRef } from 'react';
import { ColumnInstance, Row, useTable } from 'react-table';

import { HeaderColumn } from '../columns/HeaderColumn';
import { InfoRow } from '../columns/InfoRow';
import { useQueryRequest } from '../core/QueryRequestProvider';
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider';
import { TableSpacing } from '../core/types';
import { LoadingCard } from '../loading/LoadingCard';
import { Pagination } from '../pagination/Pagination';

const BOTTOM_OFFSET = 200;
interface Props {
  originColumns: any;
  onClickBodyTable?: (row: Row) => () => void;
  isBorder?: boolean;
  fieldCheckShowCursorInRow?: string;
  isRowClickable?: boolean;
  syncParamUrl?: boolean;
  bodyStyle?: React.CSSProperties;
  isLoadMore?: boolean;
  spacing?: TableSpacing;
  tableContainerStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  choseRowId?: number;
  tableContentStyle?: React.CSSProperties;
  theadStyle?: React.CSSProperties;
  isSortAndSearchFrontEnd?: boolean;
  noDataMessage?: string;
}
const BodyTable: React.FC<Props> = ({
  originColumns,
  isBorder,
  onClickBodyTable,
  fieldCheckShowCursorInRow,
  isRowClickable,
  syncParamUrl,
  bodyStyle,
  isLoadMore,
  spacing,
  tableContainerStyle,
  tableStyle,
  choseRowId,
  tableContentStyle,
  theadStyle,
  noDataMessage = 'No matching records found',
}) => {
  const originData = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const { updateState, state } = useQueryRequest();
  const bodyRef = useRef<any>();
  const { response } = useQueryResponse();

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns: originColumns,
    data: originData,
  });

  const handleInfiniteScroll = async () => {
    const offsetHeight = bodyRef.current?.offsetHeight;
    const scrollHeight = bodyRef.current?.scrollHeight;
    const scrollTop = bodyRef.current?.scrollTop;
    if (isLoading || response?.data.total < state.limit) {
      return;
    }
    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - BOTTOM_OFFSET) {
      updateState({ limit: (Number(state.limit) + 10) as 1 | 10 | 30 | 50 | 100 | 9999999 });
    }
  };

  return (
    <div
      style={{
        padding: isBorder ? '0px 28px 28px 28px' : '0px',
        ...tableContainerStyle,
      }}
    >
      <div className="table-responsive" style={{ ...tableContentStyle }}>
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
          style={{ ...tableStyle }}
        >
          <thead style={theadStyle}>
            <tr
              className="text-start text-muted fw-bolder gs-0"
              style={{
                borderBottom: `1px solid var(--neutral-3)`,
              }}
            >
              {headers.map((column: ColumnInstance, index: number) => (
                <HeaderColumn
                  key={column.id}
                  column={column}
                  extraProps={{ syncParamUrl, index, headers }}
                />
              ))}
            </tr>
          </thead>
          <tbody
            onScroll={isLoadMore ? handleInfiniteScroll : undefined}
            className="fw-normal"
            {...getTableBodyProps()}
            style={{ ...bodyStyle }}
            ref={bodyRef}
          >
            {rows.length > 0 ? (
              rows.map((row: Row, i) => {
                prepareRow(row);
                return (
                  <InfoRow
                    onClickBodyTable={onClickBodyTable}
                    row={row}
                    key={`row-${i}-${row.id}`}
                    fieldCheckShowCursorInRow={fieldCheckShowCursorInRow}
                    isRowClickable={isRowClickable}
                    spacing={spacing}
                    choseRowId={choseRowId}
                  />
                );
              })
            ) : isLoading ? null : (
              <tr
                style={{
                  borderBottom: 'none',
                }}
              >
                <td colSpan={headers.length}>
                  <div
                    className="d-flex text-center align-content-center justify-content-center"
                    style={{ minWidth: isLoadMore ? 620 : '100%' }}
                  >
                    {noDataMessage}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoadMore && <Pagination syncParamUrl={syncParamUrl} />}

      {isLoading && <LoadingCard />}
    </div>
  );
};

export { BodyTable };
