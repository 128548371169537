import styled from 'styled-components';

import { ReactComponent as ImageDefault } from 'app/assets/icons/icon-default-item.svg';

const ShowImage = styled.div`
  min-width: 56px;
  min-height: 56px;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  position: relative;
`;
interface Props {
  image: string;
  styleMain?: React.CSSProperties;
}
const ShowImageDefault: React.FC<Props> = ({ image, styleMain }) => {
  if (!image) {
    return (
      <ImageDefault
        style={{
          width: 56,
          minWidth: 56,
          height: 56,
          minHeight: 56,
          borderRadius: 5,
          ...styleMain,
        }}
      />
    );
  }
  return (
    <ShowImage
      style={{
        backgroundImage: `url(${image})`,
        ...styleMain,
      }}
    />
  );
};
export default ShowImageDefault;
