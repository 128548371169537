import ModalDefault from 'app/components/Modal/ModalDefault';
import Typography from 'app/components/Typography';

import { CloseBtn } from '../../bins/modal/UnableToRemoveItem.modal';

interface Props {
  onClose: () => void;
}

export const NoRecommendModal: React.FC<Props> = ({ onClose }) => {
  return (
    <ModalDefault
      title="No recommended stock run"
      onClickCloseModal={onClose}
      isConfirmationModal
      closeOnBackdrop
    >
      <Typography variant="body-3">
        SIMS currently does not recommend a stock run for any ward at this time. Please check back
        later.
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 48,
        }}
      >
        <CloseBtn onClick={onClose} className="btn">
          Cancel
        </CloseBtn>
      </div>
    </ModalDefault>
  );
};
