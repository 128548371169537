import { FC } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import ModalDefault from 'app/components/Modal/ModalDefault';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { ID } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { getResponseError } from 'app/helpers/errors';
import { useBoolean } from 'app/hooks';
import { isUndefined } from 'lodash';

import { deleteBin } from '../core/requests';

export const RemoveItemModal: FC<{
  onCloseModal?: (updateBin?: boolean) => void;
  onUpdateRack?: () => void;
  showUnableToRemoveModal?: () => void;
  binId: ID;
  itemId: ID;
}> = ({ binId, itemId, onCloseModal, onUpdateRack, showUnableToRemoveModal }) => {
  const { refetch } = useQueryResponse();
  const { setItemForUpdate } = useListView();
  const loading = useBoolean(false);

  const onSubmitDelete = async () => {
    loading.setValue(true);
    if (!binId) {
      return;
    }
    try {
      await deleteBin(
        binId,
        itemId,
        () => {
          toast.success('Remove item successfully!');
          if (!isUndefined(onCloseModal)) {
            onCloseModal(true);
            return;
          }
          cancel(true);
          if (onUpdateRack) {
            onUpdateRack();
          }
        },
        () => showUnableToRemoveModal?.(),
      );
    } catch (error: any) {
      getResponseError(error);
    } finally {
      loading.setValue(false);
      cancel();
    }
  };
  const cancel = (withRefresh?: boolean) => {
    if (!isUndefined(onCloseModal)) {
      onCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  return (
    <ModalDefault title="Remove item" onClickCloseModal={() => cancel()} isConfirmationModal={true}>
      <Typography variant="body-3" style={{ paddingBottom: 24 }}>
        This action cannot be undone.
      </Typography>
      <ActionButton
        onCancel={() => cancel()}
        labelButtonSubmit="Remove"
        themeButton="btn-danger"
        onSubmit={onSubmitDelete}
        isSubmitting={loading.value}
      />
    </ModalDefault>
  );
};
