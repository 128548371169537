import { FC } from 'react';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';
import { format, isToday, isYesterday } from 'date-fns';

import { ReactComponent as IconArrow } from 'app/assets/icons/icon-arrow-down.svg';

import { Notification } from '../core/type';
import { useNotificationStore } from '../store';
import { NotiItem } from './NotiItem';

const ButtonCollapsed = styled.button`
  height: 38px;
  padding: 2px 10px 2px 8px;
  border-radius: 8px !important;
  background-color: transparent;
  ::after {
    display: none;
  }
  :hover {
    background-color: var(--neutral-3);
  }
`;

const getDateContent = (date: Date) => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  return format(date, 'dd MMM yyyy');
};

interface Props {
  date: Date;
  index: number;
  isRead?: boolean;
  notifications: Array<Notification>;
}

export const NotificationGroup: FC<Props> = ({ date, index, isRead }) => {
  const listNotiByDay = useNotificationStore((s) => s.state.notificationGroup.items[index]);
  const isOpen = useBoolean(true);

  const displayNotification =
    isRead !== undefined
      ? listNotiByDay.items.filter((el) => el.read === isRead)
      : listNotiByDay.items;

  const toggleShow = async () => {
    isOpen.setValue(!isOpen.value);
  };

  if (!displayNotification.length) return null;

  return (
    <div style={{ marginTop: index === 0 ? 0 : 16 }}>
      <div className={`accordion-item ${isOpen.value ? 'show' : ''}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="accordion-header"
            id={`kt_accordion_1_header_${index}`}
            style={{
              width: 300,
              minWidth: 300,
              left: 0,
              zIndex: 1,
              cursor: 'default',
            }}
          >
            <ButtonCollapsed
              className={`accordion-button collapsed ${isOpen.value ? 'show' : 0}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#kt_accordion_1_body_${index}`}
              aria-expanded={index === 0 ? 'true' : 'false'}
              aria-controls={`kt_accordion_1_body_${index}`}
              style={{ width: 'auto' }}
              onClick={() => {
                toggleShow();
              }}
            >
              <IconArrow
                style={{
                  transform: `rotate(${isOpen.value ? 180 : 0}deg)`,
                }}
              />
              <Typography
                fontSize={18}
                fontWeight={600}
                lineHeight={32}
                color="neutral-10"
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: 6,
                }}
              >
                {getDateContent(new Date(date))}
              </Typography>
            </ButtonCollapsed>
          </div>
        </div>
        <div
          id={`kt_accordion_1_body_${index}`}
          className={`accordion-collapse collapse ${isOpen.value ? 'show' : ''}`}
          aria-labelledby={`kt_accordion_1_header_${index}`}
          data-bs-parent="#kt_accordion_1"
          style={{
            marginTop: isOpen.value ? 12 : 0,
            height: isOpen.value ? 'auto' : 0,
            marginBottom: isOpen.value ? 56 : 0,
          }}
        >
          {displayNotification.map((notification) => (
            <div
              key={notification.id}
              style={{ marginBottom: 16 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <NotiItem notification={notification} key={notification.id}></NotiItem>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
