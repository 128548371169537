import { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionButton } from 'app/components/Button/ActionButton';
import { FormInput } from 'app/components/Input/FormInput';
import { useListView } from 'app/components/Table/core/ListViewProvider';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { useQueryResponse } from 'app/components/Table/core/QueryResponseProvider';
import { emptyListViewProvider } from 'app/components/Table/core/constants';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';

import { useChangeParams } from 'app/modules/query-state/hooks';

import { blockInvalidCharacters, coverNameBed } from 'app/helpers';
import { getResponseError } from 'app/helpers/errors';

import { Customer } from '../../../company-information/core/type';
import { createWard, updateWard } from '../core/requests';
import { Ward, WardRequest, initialWard } from '../core/type';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type Props = {
  ward: Ward;
  customer: Customer;
  updateWhenEdit?: (newWard: Ward) => void;
  onClickCloseModal?: () => void;
};

const FormWardTableCard: FC<Props> = ({ ward, customer, updateWhenEdit, onClickCloseModal }) => {
  const { setItemForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const { onChangeParams } = useChangeParams();

  const [formRequest] = useState<{
    name: string;
    bedQuantity: number;
  }>({
    name: ward ? ward.name : initialWard.name,
    bedQuantity: 1,
  });

  const getSchema = () => {
    const defaultSchema: any = {
      name: Yup.string().trim().max(50, 'Maximum 50 symbols').required('Name is required'),
    };
    if (!ward) {
      defaultSchema.bedQuantity = Yup.number().min(1, 'Minimum 1 bed').max(100, 'Maximum 100 beds');
    }
    return defaultSchema;
  };

  const cancel = (withRefresh?: boolean) => {
    if (onClickCloseModal) {
      onClickCloseModal();
      return;
    }
    if (withRefresh) {
      refetch();
    }
    setItemForUpdate(emptyListViewProvider);
  };

  const formik = useFormik({
    initialValues: formRequest,
    validationSchema: Yup.object().shape(getSchema()),
    onSubmit: async (values, { setSubmitting }) => {
      if (!customer.id) {
        return;
      }
      setSubmitting(true);
      const request: WardRequest = {
        ...values,
        customerId: String(customer.id),
      };
      try {
        if (ward) {
          delete request.bedQuantity;
          const dataRes = await updateWard(ward.id, request);
          toast.success(`Update Ward successfully!`);
          if (updateWhenEdit && dataRes) {
            updateWhenEdit(dataRes);
            return;
          }
          cancel(true);
          return;
        }

        await createWard(request);
        toast.success(`Add Ward successfully!`);
        if (Number(state.page) !== 1) {
          cancel();
          onChangeParams({ page: 1 });
          return;
        }
        cancel(true);
      } catch (error: any) {
        getResponseError(error);
      } finally {
        setSubmitting(true);
      }
    },
  });

  return (
    <>
      <form id="kt_modal_add_user_form" className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7 ps-1"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <FormInput
            label="Ward name"
            placeholder="Ward name"
            formik={formik}
            field="name"
            isRequired
          />
          {!ward && (
            <FormInput
              label="Add beds with a quantity of (Max: 100)"
              placeholder="Eg: 12"
              formik={formik}
              field="bedQuantity"
              typeInput="number"
              onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
              message={`The added beds will be named 001${
                formik.values.bedQuantity > 1 ? `-${coverNameBed(formik.values.bedQuantity)}` : ''
              }`}
            />
          )}
        </div>
        <ActionButton
          onCancel={cancel}
          disableButton={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            !customer.id ||
            (!ward && !formik.values.bedQuantity)
          }
          isSubmitting={formik.isSubmitting}
          labelButtonSubmit={ward ? 'Save' : 'Add'}
        />
      </form>
      {formik.isSubmitting && <LoadingCard />}
    </>
  );
};

export { FormWardTableCard };
