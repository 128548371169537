import { useListView } from 'app/components/Table/core/ListViewProvider';

import { isUndefined } from 'lodash';

import { ID, TYPE_MODAL } from '../core/types';

interface Props {
  nameQuery: string;
  getById?: (id: ID) => Promise<any>;
  ModalForm?: (originalData: any, typeModal: TYPE_MODAL, isView?: boolean) => JSX.Element;
  isView: boolean;
}
const ModalFormWrapper: React.FC<Props> = ({ isView, ModalForm }) => {
  const { itemForUpdate } = useListView();
  if (isUndefined(itemForUpdate?.originalData) || isUndefined(ModalForm)) {
    return null;
  }
  if (!itemForUpdate) {
    return ModalForm(null, TYPE_MODAL.ADD, isView);
  }

  return ModalForm(itemForUpdate?.originalData, itemForUpdate.type, isView);
};

export { ModalFormWrapper };
