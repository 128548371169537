import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Typography from 'app/components/Typography';

import { Rack } from 'app/modules/inventory/racks/core/type';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { useRackTestStore } from '../store';
import InfoRackItemCard from './InfoRackItemCard';
import SwitchButtonDefault from 'theme/layout/components/SwitchButton/SwitchButtonDefault';

const Main = styled.div`
  margin-top: 40px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
`;
const ButtonHistoryRack = styled.div`
  height: 43px;
  width: 43px;
  background: #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
interface Props {
  rackItem: Rack;
  totalBin: number;
  isRedirect?: boolean;
  styleMain?: React.CSSProperties;
  nurseEpisode: string;
  mmdEpisode: string;
  rackStatus: string;
}
const InformationRackCard: React.FC<Props> = ({
  rackItem,
  totalBin,
  styleMain,
  isRedirect = false,
  nurseEpisode,
  mmdEpisode,
  rackStatus,
}) => {
  const navigate = useNavigate();
  const requiredEpisode = useRackTestStore((s) => s.state.requiredEpisode);
  return (
    <Main style={styleMain}>
      <div className="d-flex">
        {isRedirect && (
          <IconRedirect
            style={{
              cursor: 'pointer',
            }}
            onClick={() => navigate(PATH.RACKS)}
          />
        )}

        <div
          className="h-100 d-flex align-items-center"
          style={{
            borderRight: '1px solid #F4F4F4',
            paddingRight: 24,
          }}
        >
          <Typography variant="heading-6">{rackItem.name}</Typography>
        </div>
        {rackItem.location && <InfoRackItemCard label="Ward" value={rackItem.location.name} />}
        <InfoRackItemCard label="Number of bin" value={String(totalBin)} />
        <InfoRackItemCard label="Version" value={String(rackItem.version ?? 0)} />
        <InfoRackItemCard label="Nurse Episode" value={nurseEpisode} />
        <InfoRackItemCard label="MMD Episode" value={mmdEpisode} />
        {/* <InfoRackItemCard
          label="Rack Status"
          value={rackStatus}
          colorValue={rackStatus === 'Active' ? 'rgb(0, 158, 246)' : 'rgb(237, 179, 43)'}
        /> */}
      </div>
      <div>
        <SwitchButtonDefault
          labelButton="Episode required"
          checked={requiredEpisode}
          onChange={() =>
            useRackTestStore.getState().setState({ requiredEpisode: !requiredEpisode })
          }
        />
      </div>
    </Main>
  );
};
export default InformationRackCard;
