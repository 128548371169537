import { FC } from 'react';

import Typography from 'app/components/Typography';

import InfoRackItemCard from 'app/modules/inventory/racks/components/InfoRackItemCard';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { SecurityGroup } from '../../security-groups/core/type';

interface Props {
  securityGroup: SecurityGroup | null;
  action?: React.ReactNode;
  onClickRedirect: () => void;
}

export const UsersRoleHeader: FC<Props> = ({ securityGroup, action, onClickRedirect }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <IconRedirect style={{ cursor: 'pointer' }} onClick={onClickRedirect} />
        <div
          style={{
            borderRight: `1px solid var(--neutral-4)`,
            paddingRight: 24,
          }}
        >
          <Typography variant="heading-6">{securityGroup?.name}</Typography>
        </div>
        <InfoRackItemCard
          label="Account type"
          value={securityGroup?.type === 'web' ? 'Desktop' : 'Tablet App'}
        />
      </div>
      {action}
    </div>
  );
};
