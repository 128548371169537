import styled from 'styled-components';

import { ReactComponent as IconSearch } from 'app/assets/icons/icon-search.svg';

import clsx from 'clsx';

const MainInputSearch = styled.div`
  display: flex;
  align-items: center;
  width: 360px;
  height: 43px;
  background: var(--neutral-2);
  border-radius: 8px;
  padding-left: 14px;
`;
const Input = styled.input<{ disabledInput?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  background-color: 'transparent';
  height: 43px;
  color: var(--neutral-10);
  border: none;
  ${({ disabledInput }) =>
    disabledInput
      ? `background-color:#F4F4F4 !important; color: #B5B8C3 !important`
      : `background-color:var(--neutral-2) !important; color: var(--neutral-10) !important `};
`;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  styleMain?: React.CSSProperties;
}
const InputSearch: React.FC<Props> = ({ styleMain, ...inputProps }) => {
  return (
    <MainInputSearch className="custom-input-search" style={styleMain}>
      <IconSearch />
      <Input className={clsx('form-control mb-3 mb-lg-0')} {...inputProps} autoComplete="off" />
    </MainInputSearch>
  );
};
export default InputSearch;
