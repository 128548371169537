import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { QueryRequestContextProps, QueryState, initialQueryRequest } from './types';

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest);

const QueryRequestProvider: FC<{
  children?: ReactNode;
  defaultState?: QueryState;
  query?: QueryState;
}> = ({ children, defaultState, query }) => {
  const [state, setState] = useState<QueryState>(
    query ?? defaultState ?? initialQueryRequest.state,
  );

  useEffect(() => {
    if (!query || query === state) {
      return;
    }
    setState(query);
  }, [query]);

  const updateState = (updates: Partial<QueryState>) =>
    setState((prev) => ({ ...prev, ...updates }));

  return (
    <QueryRequestContext.Provider value={{ state, updateState, setState }}>
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };
