import { FC } from 'react';
import styled from 'styled-components';

import { FormInput } from 'app/components/Input/FormInput';
import Typography from 'app/components/Typography';

import { blockInvalidCharacters } from 'app/helpers';

import ActionButtonCard, { ActionButtonWrapper } from '../components/ActionButtonCard';
import { STOCK_LEVEL_TITLE } from '../core/constants';
import { StockValues } from '../core/type';

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  .fv-help-block {
    white-space: nowrap;
  }
`;
interface Props {
  originValue: StockValues | null;
  formik: any;
  containerStyle?: React.CSSProperties;
  isEdit: boolean;
  setEdit: (payload: boolean) => void;
  onCancel?: () => void;
}

export const StockLevelSettingCard: FC<Props> = ({
  originValue,
  formik,
  containerStyle,
  isEdit,
  setEdit,
}) => {
  const stockValue: StockValues = formik.values.stockValue;
  const stocTypeTitle = STOCK_LEVEL_TITLE[stockValue?.name];

  const onCancel = () => {
    setEdit(false);
    formik.setValues({
      stockValue: originValue,
    });
  };
  const errors: any = formik.errors;

  if (formik.values.stockValue === null) {
    return null;
  }

  return (
    <Main style={containerStyle}>
      <form
        id="kt_modal_company_form"
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            marginBottom: 32,
          }}
        >
          <div>
            <Typography variant="heading-7">{stocTypeTitle}</Typography>
            <Typography variant="body-3" color="neutral-7">
              {`Changing the ${stocTypeTitle} percentage here will update all ${stocTypeTitle}s on Item Detail
              pages.`}
            </Typography>
          </div>
          <ActionButtonWrapper>
            <ActionButtonCard
              isEdit={isEdit}
              onCancel={onCancel}
              disableButton={formik.isSubmitting || !formik.isValid || !formik.touched}
              isSubmitting={formik.isSubmitting}
              onClickEdit={() => setEdit(true)}
            />
          </ActionButtonWrapper>
        </div>

        {formik.values.stockValue && (
          <div>
            {Object.keys(formik.values.stockValue.value).map((el, index) => (
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: index === 1 ? 4 : 8 }}
                key={el}
              >
                <FormInput
                  placeholder=""
                  label={el === 'chargeable' ? 'Chargeable items' : 'Non-Chargeable items'}
                  formik={formik}
                  field={`stockValue.value.${el}`}
                  isDisable={!isEdit}
                  typeInput="number"
                  touched={true}
                  onKeyDown={(e) => blockInvalidCharacters(e, 'float')}
                  messageError={errors.stockValue?.value[el]}
                />
                <Typography
                  variant="body-5"
                  color={!isEdit ? 'neutral-6' : 'neutral-10'}
                  style={{
                    marginLeft: 8,
                    marginTop: 7,
                  }}
                >
                  % of Par level
                </Typography>
              </div>
            ))}
          </div>
        )}
      </form>
    </Main>
  );
};

export default StockLevelSettingCard;
