import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { replacePath } from 'app/helpers';
import { usePolicyInUser } from 'app/hooks/policy';

import { HIDE_RACK_TEST } from 'app/constants/environments';
import { PATH } from 'app/constants/path';

import { useAuth } from '../auth';
import RackTestPage from '../rack-test/RackTestPage';
import BinHistory from './bins/BinHistory';
import AddItemPage from './items/AddItemPage';
import EditItemPage from './items/EditItemPage';
import ItemDetailPage from './items/ItemDetailPage';
import ItemManagementPage from './items/ItemManagementPage';
import RackDetailManagement from './racks/RackDetailManagement';
import RackHistoryPage from './racks/RackHistoryPage';
import RackLogPage from './racks/RackLogPage';
import RackManagementPage from './racks/RackManagementPage';
import AddStockRunPage from './stock-run/AddStockRunPage';
import EditStockRunPage from './stock-run/EditStockRunPage';
import StockRunDetailPage from './stock-run/StockRunDetail';
import StockRunManagementPage from './stock-run/StockRunManagementPage';
import RoutePolicy from 'app/routing/RoutePolicy';

const InventoriesManagementPage = () => {
  const { currentUser } = useAuth();
  const policy = usePolicyInUser(currentUser?.policy ?? []);

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={replacePath(PATH.ITEMS, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <ItemManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.ITEMS_ADD, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <AddItemPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.ITEMS_EDIT, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <EditItemPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.ITEMS_DETAIL, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <ItemDetailPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.INVENTORY_STOCK_RUN, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <StockRunManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.INVENTORY_STOCK_RUN_ADD, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <AddStockRunPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.INVENTORY_STOCK_RUN_DETAIL, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <StockRunDetailPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.INVENTORY_STOCK_RUN_EDIT, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.item}>
              <EditStockRunPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.RACKS, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.rack}>
              <RackManagementPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.RACK_DETAIL, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.rack}>
              <RackDetailManagement />
            </RoutePolicy>
          }
        />

        <Route
          path={replacePath(PATH.RACK_HISTORY, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.rack}>
              <RackHistoryPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.RACK_LOG, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.rack}>
              <RackLogPage />
            </RoutePolicy>
          }
        />
        <Route
          path={replacePath(PATH.BIN_HISTORY, PATH.INVENTORIES, '')}
          element={
            <RoutePolicy accept={policy.rack}>
              <BinHistory />
            </RoutePolicy>
          }
        />

        {HIDE_RACK_TEST ? null : (
          <Route
            path={replacePath(PATH.RACK_TEST, PATH.INVENTORIES, '')}
            element={<RackTestPage />}
          />
        )}

        <Route path="*" element={<Navigate to={policy.item ? PATH.ITEMS : PATH.RACKS} />} />
      </Route>
    </Routes>
  );
};

export default InventoriesManagementPage;
