import ButtonDefault, { ButtonDefaultProps } from 'app/components/Button/ButtonDefault';
import { useQueryRequest } from 'app/components/Table/core/QueryRequestProvider';
import { stringifyRequestQuery } from 'app/components/Table/core/helpers';
import { ID } from 'app/components/Table/core/types';
import { LoadingCard } from 'app/components/Table/loading/LoadingCard';
import { TippyDefault } from 'app/components/Tippy';
import { MenuAction, MenuItem } from 'app/components/Tippy/TippyMenu';

import { useBoolean } from 'app/hooks';

import { ReactComponent as IconButton } from 'app/assets/icons/icon-export.svg';

import { coverReportFilterToQuery } from '../core/helpers';
import {
  getDynamicAnalysis,
  getQuantityBalanceReportCSV,
  getReportDetailCSV,
} from '../core/request';
import { FilterReportDetail } from '../core/type';
import { ReportTimeframe } from './ReportTimeframe';

interface Props extends ButtonDefaultProps {
  wardId: ID;
  itemId?: ID;
  actionFilter?: FilterReportDetail;
}

export const ExportButton: React.FC<Props> = ({
  wardId,
  itemId,
  actionFilter,
  theme = 'btn-primary',
}) => {
  const isReportDetailPage = wardId && itemId;
  const { state } = useQueryRequest();
  const isOpenMenu = useBoolean(false);
  const isLoading = useBoolean(false);

  const onClickBtnExport = async () => {
    isOpenMenu.setValue(!isOpenMenu.value);
  };

  const onClose = () => {
    isOpenMenu.setValue(false);
  };

  const stopLoading = () => {
    isLoading.setValue(false);
  };

  const exportQuantityBalanceReport = async () => {
    isLoading.setValue(true);
    isOpenMenu.setValue(false);
    getQuantityBalanceReportCSV(stringifyRequestQuery(state), wardId, stopLoading);
  };

  const exportItemReport = async (filter: FilterReportDetail) => {
    isLoading.setValue(true);
    const url = `${itemId && stringifyRequestQuery(state)}${coverReportFilterToQuery(filter)}`;

    getReportDetailCSV(
      url,
      wardId,
      itemId || 0,
      stopLoading,
      filter.mqtt_log_included,
      // Object.values(actionFilter?.action || []),   21/09/2023: Disable exporting feature with action filter
    );
  };

  const exportDA = async () => {
    isLoading.setValue(true);
    isOpenMenu.setValue(false);
    getDynamicAnalysis(wardId, stopLoading);
  };

  return (
    <TippyDefault
      containerClass="action-menu"
      placement={isReportDetailPage ? 'left' : 'bottom-start'}
      offset={isReportDetailPage ? [213, -144] : [0, 10]}
      interactive
      arrow={false}
      trigger="click"
      visible={isOpenMenu.value}
      onClickOutside={() => isOpenMenu.setValue(false)}
      allowHTML
      content={
        wardId && !itemId ? (
          <MenuAction style={{ width: 'auto', padding: 8 }}>
            <MenuItem style={{ width: 144 }} onClick={exportQuantityBalanceReport}>
              Quantity balance
            </MenuItem>
            <MenuItem style={{ width: 144 }}>
              <TippyDefault
                containerClass="action-menu"
                offset={[-64, -132]}
                interactive
                arrow={false}
                trigger="click"
                allowHTML
                content={
                  <ReportTimeframe
                    onExport={exportItemReport}
                    onClose={onClose}
                    isSubmitting={isLoading.value}
                  />
                }
                containerStyle={{ display: 'flex', alignItems: 'center' }}
              >
                <>All items history</>
              </TippyDefault>
            </MenuItem>
            <MenuItem style={{ width: 144 }} onClick={exportDA}>
              Dynamic analysis
            </MenuItem>
          </MenuAction>
        ) : (
          <ReportTimeframe
            onExport={exportItemReport}
            onClose={onClose}
            isSubmitting={isLoading.value}
          />
        )
      }
    >
      <>
        <ButtonDefault
          theme={theme}
          onClick={() => {
            onClickBtnExport();
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <IconButton
            style={{
              marginRight: 10,
            }}
          />
          Export
        </ButtonDefault>
        {isLoading.value && <LoadingCard />}
      </>
    </TippyDefault>
  );
};
