import { ID, Response } from 'app/components/Table/core/types';

import { trimObject } from 'app/helpers';

import { API_URL } from 'app/constants/environments';

import {
  Setting,
  SettingRequest,
  SettingType,
  SettingsQueryResponse,
  StockValues,
  WorkShift,
} from './type';
import axios, { AxiosResponse } from 'axios';

const CONFIGURATION_URL = `${API_URL}/api/settings`;

const getSettings = (query: string): Promise<SettingsQueryResponse> => {
  return axios.get(`${CONFIGURATION_URL}?${query}`).then(
    (
      d: AxiosResponse<{
        data: {
          items: Array<Setting>;
          total: number;
        };
      }>,
    ) => {
      return {
        data: d.data.data,
      };
    },
  );
};

const getSetting = (
  type: SettingType,
  params?: {
    name: string;
    location_id?: string;
  },
): Promise<Setting[]> => {
  return axios
    .get(`${CONFIGURATION_URL}/${type}`, {
      params,
    })
    .then(
      (
        d: AxiosResponse<{
          data: Setting[];
        }>,
      ) => {
        return d.data.data;
      },
    );
};

const getStockValue = (): Promise<StockValues[]> => {
  return axios.get(`${CONFIGURATION_URL}/${SettingType.stockLevel}`).then(
    (
      d: AxiosResponse<{
        data: StockValues[];
      }>,
    ) => {
      return d.data.data;
    },
  );
};

const createSetting = (request: SettingRequest): Promise<Setting | undefined> => {
  return axios
    .post(CONFIGURATION_URL, trimObject(request))
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<Setting>) => response.data);
};

const updateSetting = (idSetting: ID, request: SettingRequest): Promise<Setting | undefined> => {
  return axios
    .patch(`${CONFIGURATION_URL}/${idSetting}`, trimObject(request))
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<Setting>) => response.data);
};

const deleteSetting = async (idSetting: ID): Promise<void> => {
  try {
    await axios.delete(`${CONFIGURATION_URL}/${idSetting}`, {
      data: {
        type: SettingType.quantityUnit,
      },
    });
  } catch (error) {
    console.log(`Error deleting setting ID ${idSetting}`);
    throw error;
  }
};

const getWorkShift = (): Promise<WorkShift> => {
  return axios.get(`${CONFIGURATION_URL}/shift_work`).then(
    (
      d: AxiosResponse<{
        data: WorkShift;
      }>,
    ) => {
      return d.data.data;
    },
  );
};

export {
  getSettings,
  getSetting,
  createSetting,
  updateSetting,
  deleteSetting,
  getWorkShift,
  getStockValue,
};
