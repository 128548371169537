import { EpisodeTablet, TransactionItem } from 'app/hooks/socket';

import { Bin } from '../bins/core/type';
import { getChangeQtyGroupByItem } from '../stock-run/core/helpers';
import { Rack, emptyRack } from './core/type';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  tablets: EpisodeTablet[];
  rack: Rack;
  inSession: boolean;
  changeItems: TransactionItem[];
  savedItems: TransactionItem[];
  bins: Bin[];
}

interface Store {
  state: State;
  setState: (payload: Partial<State>) => void;
  clearState: () => void;
  saveCompletedTransaction: () => void;
}

const initState: State = {
  tablets: [],
  rack: emptyRack,
  inSession: false,
  changeItems: [],
  savedItems: [],
  bins: [],
};

export const useRackStore = create(
  immer<Store>((set) => ({
    state: initState,
    setState: (payload) => {
      set((store) => {
        store.state = { ...store.state, ...payload };
      });
    },
    clearState: () => {
      set((store) => {
        store.state = initState;
      });
    },
    saveCompletedTransaction: () =>
      set((store) => {
        store.state.savedItems = store.state.savedItems.concat(store.state.changeItems);
        store.state.inSession = false;
        store.state.changeItems = [];
      }),
  })),
);

export const tabletBycurWardSelector = (wardId: number) => {
  return (s: Store) => s.state.tablets.filter((el) => el.wardId === wardId);
};

export const curWardSelector = (s: Store) =>
  s.state.rack.location?.id ? Number(s.state.rack.location?.id) : -1;

export const allChangeItemsSelector = (s: Store) => s.state.changeItems.concat(s.state.savedItems);

export const changeGroupByItemsSelector = (s: Store) => {
  const allChangeItems = allChangeItemsSelector(s).filter((el) => !el.afterSessionCompleted);
  const bins = s.state.bins;
  const rackId = s.state.rack.id;

  // Mapping item id
  const itemsWithId = allChangeItems.map((itm) => ({
    ...itm,
    item: {
      id: Number(bins.find((el) => el.index === itm.bin && rackId === itm.rack_id)?.item?.id || -1),
    },
  }));
  return getChangeQtyGroupByItem(itemsWithId);
};
