import { ASIDE_MENU_WIDTH } from 'theme/layout/components/aside/AsideDefault';

const LoadingCard = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: 'var(--white)',
    color: 'var(--neutral-7)',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    zIndex: 999,
    fontSize: '13px',
    lineHeight: '22px',
  };

  return (
    <div
      className="custom-loading"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: ASIDE_MENU_WIDTH,
        right: 0,
        bottom: 0,
        zIndex: 999,
        pointerEvents: 'auto',
      }}
    >
      <div style={{ ...styles, textAlign: 'center' }}>Processing...</div>
    </div>
  );
};

export { LoadingCard };
