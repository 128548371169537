import React from 'react';
import styled from 'styled-components';

import { ID } from 'app/components/Table/core/types';
import Typography from 'app/components/Typography';

import { useGoBackIfExist } from 'app/helpers/utils';

import { PATH } from 'app/constants/path';

import { ReactComponent as IconRedirect } from 'app/assets/icons/icon-redirect.svg';

import { Item } from '../../items/core/type';
import InfoRackItemCard from '../../racks/components/InfoRackItemCard';

const Main = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  binName: string;
  binColor: string;
  rackId: ID;
  item?: Item | null;
}
const InformationBinCard: React.FC<Props> = ({ binName, binColor, rackId, item }) => {
  const goBack = useGoBackIfExist(PATH.RACK_DETAIL.replace(':rackId', String(rackId)));
  return (
    <Main>
      <div className="d-flex">
        <IconRedirect
          style={{
            cursor: 'pointer',
          }}
          onClick={goBack}
        />
        <div
          className="h-100 d-flex align-items-center"
          style={{
            borderRight: `1px solid var(--neutral-4)`,
            paddingRight: 24,
          }}
        >
          <Typography variant="heading-6">Bin history</Typography>
        </div>
        <InfoRackItemCard label="Bin" value={binName} colorValue={binColor} fontWeightValue={600} />
        {item && (
          <React.Fragment>
            <InfoRackItemCard label="Item" value={item.name} />
            <InfoRackItemCard label="SKU" value={item.skuId} />
          </React.Fragment>
        )}
      </div>
    </Main>
  );
};
export default InformationBinCard;
