import ShowImageDefault from 'app/components/ShowImage/ShowImageDefault';
import Typography from 'app/components/Typography';

import { isNumber } from 'lodash';

import { getItemAttribute } from '../../stock-run/core/helpers';
import { StockTypeEnum } from '../core/enum';

interface Props {
  image: string;
  name: string;
  stockType?: string | number;
  stockupQuantity?: number;
  isStockUpCompleted?: boolean;
  isNotNavigatable?: boolean;
  imageStyle?: React.CSSProperties;
  leftContentStyle?: React.CSSProperties;
  nameStyle?: React.CSSProperties;
  stockTypeStyle?: React.CSSProperties;
}
const ItemInfoCard: React.FC<Props> = ({
  image,
  name,
  stockType,
  stockupQuantity,
  isStockUpCompleted,
  isNotNavigatable,
  imageStyle,
  leftContentStyle,
  nameStyle,
  stockTypeStyle,
}) => {
  return (
    <div
      className="d-flex"
      style={{
        cursor: isNotNavigatable ? 'default' : 'pointer',
      }}
    >
      <ShowImageDefault image={image} styleMain={imageStyle} />
      <div
        className="d-flex flex-column justify-content-between"
        style={{ paddingLeft: 16, ...leftContentStyle }}
      >
        <Typography
          variant="body-5"
          color={getItemAttribute('color', {
            isCompleted: isStockUpCompleted,
            qtyCompleted: stockupQuantity,
          })}
          style={{
            whiteSpace: 'nowrap',
            textDecoration: stockupQuantity === 0 && isStockUpCompleted ? 'line-through' : 'none',
            ...nameStyle,
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="body-4"
          color="neutral-6"
          style={{
            whiteSpace: 'nowrap',
            textDecoration: stockupQuantity === 0 && isStockUpCompleted ? 'line-through' : 'none',
            ...stockTypeStyle,
          }}
        >
          {isNumber(stockType) ? StockTypeEnum[Number(stockType)] : stockType}
        </Typography>
      </div>
    </div>
  );
};
export default ItemInfoCard;
