import React from 'react';
import { Column } from 'react-table';

import { RadioInput } from 'app/components/Input/RadioInput';
import HeartBeatStatus from 'app/components/Status/HeartBeatStatus';
import StatusDefault from 'app/components/Status/StatusDefault';
import { ActionsCell } from 'app/components/Table/columns/ActionsCell';
import { CustomHeader } from 'app/components/Table/columns/CustomHeader';
import { ActionTable, ID, TYPE_MODAL } from 'app/components/Table/core/types';
import { TippyDefault } from 'app/components/Tippy';
import Typography, { EmptyTableCell } from 'app/components/Typography';

import { getStockHealth } from 'app/modules/reporting/core/columns';

import {
  coverPath,
  formatCustomDate,
  formatDate,
  getLabelAction,
  getUser,
  isActiveLabelAction,
} from 'app/helpers';
import { compact, isEmpty } from 'lodash';

import { PATH } from 'app/constants/path';

import { Bin } from '../../bins/core/type';
import ItemInfoCard from '../../items/components/ItemInfoCard';
import SKUCard from '../../items/components/SKUCard';
import { UserInfo } from '../../items/components/UserInfo';
import StatusStockRunCard from '../../stock-run/components/StatusStockRunCard';
import { StockRun } from '../../stock-run/core/type';
import ActiveTabletCard from '../components/ActiveTabletCard';
import { QtyTypography } from '../components/Typography';
import { InventoryMovementType } from './enum';
import { ActivityLogRack, HistoryRack, LogItemDetail, Rack } from './type';

export const racksColumns = (): ReadonlyArray<Column<Rack>> => {
  const actions = (data: Rack) => {
    const listActions: ActionTable[] = [
      {
        name: 'Edit',
        type: TYPE_MODAL.EDIT,
        titleModal: 'Edit rack',
      },
    ];
    if (!data.location) {
      listActions.push({
        name: 'Delete',
        type: TYPE_MODAL.DELETE,
        titleModal: 'Delete rack',
      });
    }
    return listActions;
  };

  return [
    {
      id: 'name',
      Header: (props) => <CustomHeader tableProps={props} title="Rack" />,
      Cell: ({ ...props }) => (
        <div className="d-flex flex-column">
          <Typography lineHeight={26} variant="body-1">
            {props.data[props.row.index].name}
          </Typography>
        </div>
      ),
    },
    {
      id: 'activeTablet',
      width: '14%',
      Cell: ({ ...props }) => (
        <ActiveTabletCard
          wardId={parseInt(props.data[props.row.index].location?.id?.toString() || '0')}
        />
      ),
    },
    {
      id: 'connectionStatus',
      Header: 'Raspberry Pi connection',
      width: '20%',
      Cell: ({ ...props }) => (
        <HeartBeatStatus pageType="rack" targetId={Number(props.data[props.row.index].id)} />
      ),
    },
    {
      id: 'bin',
      Header: 'Bin qty',
      width: '12%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].bins}
        </Typography>
      ),
    },
    {
      id: 'ward',
      width: '12%',
      Header: (props) => <CustomHeader tableProps={props} title="Belong to ward" />,
      Cell: ({ ...props }) =>
        props.data[props.row.index].location ? (
          <Typography lineHeight={26} variant="body-1">
            {props.data[props.row.index].location?.name || ''}
          </Typography>
        ) : (
          EmptyTableCell
        ),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-50px" />
      ),
      Cell: ({ ...props }) => (
        <ActionsCell
          item={props.data[props.row.index]}
          actions={actions(props.data[props.row.index])}
        />
      ),
    },
  ];
};

const getActions = (data: Bin, wardId: ID): ActionTable[] =>
  compact([
    data.item && {
      name: 'Edit quantity',
      type: TYPE_MODAL.EDIT_QUANTITY,
    },
    data.item
      ? {
          name: 'Remove item',
          type: TYPE_MODAL.REMOVE_ITEM,
          titleModal: 'Remove item',
        }
      : {
          name: 'Assign item',
          type: TYPE_MODAL.ASSIGN_ITEM,
          titleModal: 'Assign item',
        },
    data.item && {
      name: 'Add to a stock run',
      type: TYPE_MODAL.SHORT_FOR,
    },
    {
      name: 'Show bin history',
      type: TYPE_MODAL.EDIT,
      redirect: PATH.BIN_HISTORY,
    },
    data.item && {
      name: 'Go to item report',
      type: TYPE_MODAL.EDIT,
      redirect: coverPath(PATH.REPORT_DETAIL, {
        wardId,
        itemId: data.item.id,
      }),
    },
  ]);

export const binInRackColumns = (
  inSession: boolean,
  onClickNameAndSKU: (itemId: string) => void,
  wardId: ID,
): ReadonlyArray<Column<Bin>> => {
  return [
    {
      id: 'name',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <div className="d-flex flex-column">
          <Typography variant="body-6" color="neutral-10">
            {props.data[props.row.index].name}
          </Typography>
        </div>
      ),
    },
    {
      id: 'item.name',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {props.data[props.row.index].item ? (
            <TippyDefault arrow={true} theme="dark" content={getTooltipTitle()}>
              <div
                onClick={() =>
                  onClickNameAndSKU(props.data[props.row.index].item?.id?.toString() || '')
                }
              >
                <ItemInfoCard
                  image={props.data[props.row.index].item?.image || ''}
                  name={props.data[props.row.index].item?.name || ''}
                  stockType={props.data[props.row.index].item?.stockType}
                />
              </div>
            </TippyDefault>
          ) : (
            <Typography
              lineHeight={26}
              style={{ height: '56px', display: 'flex', alignItems: 'center' }}
            >
              {'No item assigned'}
            </Typography>
          )}
          {props.data[props.row.index].inSession && (
            <div
              style={{
                width: 81,
                height: 28,
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--primary-3)',
              }}
            >
              <Typography fontSize={13} fontWeight={600} color="primary">
                In session
              </Typography>
            </div>
          )}
        </div>
      ),
    },
    {
      id: 'item.skuId',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      width: '20%',
      Cell: ({ ...props }) => (
        <div className="d-flex flex-column">
          {props.data[props.row.index].item ? (
            <div
              onClick={() =>
                onClickNameAndSKU(props.data[props.row.index].item?.id?.toString() || '')
              }
              style={{
                cursor: 'pointer',
                width: 'fit-content',
              }}
            >
              <TippyDefault arrow={true} theme="dark" content={getTooltipTitle()}>
                <SKUCard
                  skuId={props.data[props.row.index].item?.skuId || ''}
                  skuSub={props.data[props.row.index].item?.skuSub || ''}
                  containerStyle={{ width: 'fit-content' }}
                />
              </TippyDefault>
            </div>
          ) : (
            EmptyTableCell
          )}
        </div>
      ),
    },
    {
      id: 'item.qty',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Qty" />,
      Cell: ({ ...props }) => (
        <div className="d-flex flex-column">
          {props.data[props.row.index].item ? (
            <Typography
              lineHeight={26}
              variant="body-1"
              className="text-overflow-ellipsis number-width-5"
            >
              {props.data[props.row.index].item?.qty || '0'}
            </Typography>
          ) : (
            EmptyTableCell
          )}
        </div>
      ),
    },
    {
      id: 'item.stockLevelOrder',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Stock health" />,
      Cell: ({ ...props }) => getStockHealth(props.data[props.row.index].item?.stockLevel),
    },
    {
      id: 'actions',
      width: '5%',
      Header: (props) => (
        <CustomHeader tableProps={props} title="" className="text-end min-w-100px" />
      ),
      Cell: ({ ...props }) => {
        const actions = getActions(props.data[props.row.index], wardId);
        return (
          <ActionsCell
            item={props.data[props.row.index]}
            actions={actions}
            inSession={inSession}
            dividerIndexes={[actions.length - 3]}
          />
        );
      },
    },
  ];
};

export const historyRackColumns = (): ReadonlyArray<Column<HistoryRack>> => {
  return [
    {
      id: 'expriryDate',
      accessor: 'expriryDate',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].expriryDate)}
        </Typography>
      ),
    },
    {
      id: 'bin',
      accessor: 'bin',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].bin?.name}
        </Typography>
      ),
    },
    {
      id: 'item',
      accessor: 'inventory',
      Header: (props) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <ItemInfoCard
          image={props.data[props.row.index].inventory?.image || ''}
          name={props.data[props.row.index].inventory?.name || ''}
          stockType={props.data[props.row.index].inventory?.stockType}
          isNotNavigatable={true}
        />
      ),
    },
    {
      id: 'sku',
      Header: (props) => <CustomHeader tableProps={props} title="SKU" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <SKUCard
          skuId={props.data[props.row.index].inventory?.skuId || ''}
          skuSub={props.data[props.row.index].inventory?.skuSub || ''}
        />
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      Header: (props) => <CustomHeader tableProps={props} title="Action" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <StatusDefault
          isActive={isActiveLabelAction(Number(props.data[props.row.index].quantity))}
          label={getLabelAction(Number(props.data[props.row.index].quantity))}
          action={props.data[props.row.index].movementType}
        />
      ),
    },
    {
      id: 'qtyToStockUp',
      accessor: 'qtyToStockUp',
      Header: (props) => <CustomHeader tableProps={props} title="Stock on hand" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {Number(props.data[props.row.index].qtyToStockUp)}
        </Typography>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props) => <CustomHeader tableProps={props} title="Adjustment qty" />,
      width: '10%',
      Cell: ({ ...props }) => <QtyTypography qty={props.data[props.row.index].quantity ?? ''} />,
    },
    {
      id: 'qtyAvailable',
      accessor: 'qtyAvailable',
      Header: (props) => <CustomHeader tableProps={props} title="Available qty" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].qtyAvailable}
        </Typography>
      ),
    },
    {
      id: 'user',
      accessor: 'episode',
      Header: (props) => <CustomHeader tableProps={props} title="By user" />,
      width: '10%',
      Cell: ({ ...props }) => (
        <UserInfo
          userName={props.data[props.row.index].userName}
          roleName={props.data[props.row.index].roleName}
          movementType={props.data[props.row.index].movementType}
        />
      ),
    },
  ];
};

export const activityLogStockUpAndPickUpColumns = (
  selectLogItem: LogItemDetail,
): ReadonlyArray<Column<ActivityLogRack>> => {
  return [
    {
      id: 'expriryDate',
      width: '10%',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].expriryDate)}
        </Typography>
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      Header: 'Action',
      width: '10%',
      Cell: ({ ...props }) => (
        <React.Fragment>
          {props.data[props.row.index].type && (
            <StatusDefault
              isActive={isActiveLabelAction(Number(props.data[props.row.index].quantity))}
              label={getLabelAction(Number(props.data[props.row.index].quantity))}
              action={props.data[props.row.index].type as InventoryMovementType}
            />
          )}
        </React.Fragment>
      ),
    },
    {
      id: 'bin',
      accessor: 'bin',
      width: '10%',
      Header: (props: any) => <CustomHeader tableProps={props} title="Bin" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {props.data[props.row.index].bin?.name}
        </Typography>
      ),
    },
    {
      id: 'item',
      accessor: 'inventory',
      Header: (props: any) => <CustomHeader tableProps={props} title="Item" />,
      Cell: ({ ...props }) => (
        <React.Fragment>
          {props.data[props.row.index].inventory && (
            <ItemInfoCard
              image={props.data[props.row.index].inventory?.image || ''}
              name={props.data[props.row.index].inventory?.name || ''}
              stockType={props.data[props.row.index].inventory?.stockType}
              isNotNavigatable={true}
            />
          )}
        </React.Fragment>
      ),
    },
    {
      id: 'quantity',
      accessor: 'quantity',
      Header: (props: any) => <CustomHeader tableProps={props} title="Adjustment qty" />,
      width: '10%',
      Cell: ({ ...props }) => <QtyTypography qty={props.data[props.row.index].quantity ?? ''} />,
    },
    {
      id: 'byUser',
      Header: 'By user',
      width: '10%',
      Cell: ({ ...props }) => (
        <UserInfo
          userName={isEmpty(selectLogItem.user) ? '' : getUser(selectLogItem.user)}
          roleName={props.data[props.row.index].roleName}
          movementType={props.data[props.row.index].movementType}
        />
      ),
    },
  ];
};
export const activityLogUpdateRackColumns = (): ReadonlyArray<Column<ActivityLogRack>> => {
  return [
    {
      id: 'updatedAt',
      width: '1%',
      Header: (props) => <CustomHeader tableProps={props} title="Date modified" />,
      Cell: ({ ...props }) => (
        <Typography lineHeight={26} variant="body-1">
          {formatCustomDate(props.data[props.row.index].updatedAt)}
        </Typography>
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      Header: 'Action',
      width: '10%',
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {props.data[props.row.index].action ?? ''}
        </Typography>
      ),
    },

    {
      id: 'detail',
      accessor: 'detail',
      Header: 'Detail',
      Cell: ({ ...props }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: props.data[props.row.index].detail ?? '',
          }}
        />
      ),
    },
    {
      id: 'byUser',
      accessor: 'user',
      Header: 'By user',
      width: '10%',
      Cell: ({ ...props }) => (
        <UserInfo
          userName={
            isEmpty(props.data[props.row.index].user)
              ? ''
              : getUser(props.data[props.row.index].user)
          }
          roleName={props.data[props.row.index].roleName}
          movementType={props.data[props.row.index].movementType}
        />
      ),
    },
  ];
};

export const shortForColumns = (): ReadonlyArray<Column<StockRun>> => {
  return [
    {
      id: 'index',
      accessor: 'index',
      width: 190,
      Header: (props) => (
        <CustomHeader
          tableProps={props}
          title="Stock run index"
          // styleTh={{
          //   paddingRight: 12,
          // }}
        />
      ),
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {`${props.data[props.row.index].index}`}
        </Typography>
      ),
    },
    {
      id: 'startTime',
      accessor: `startTime`,
      Header: (props) => <CustomHeader tableProps={props} title="Start date" />,
      width: 120,
      Cell: ({ ...props }) =>
        props.data[props.row.index].startTime ? (
          <Typography
            variant="body-5"
            color="neutral-10"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {formatDate(
              props.data[props.row.index].actualStartTime ?? props.data[props.row.index].startTime,
            )}
          </Typography>
        ) : (
          EmptyTableCell
        ),
    },
    {
      id: 'endTime',
      accessor: `endTime`,
      Header: (props) => <CustomHeader tableProps={props} title="Complete date" />,
      width: 150,
      Cell: ({ ...props }) => (
        <Typography
          variant="body-5"
          color="neutral-10"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {formatDate(
            props.data[props.row.index].actualEndTime ?? props.data[props.row.index].endTime,
          )}
        </Typography>
      ),
    },
    {
      id: 'createdBy',
      accessor: `createdBy`,
      Header: (props) => <CustomHeader tableProps={props} title="Created by" />,
      width: 130,
      Cell: ({ ...props }) => (
        <div style={{ height: 56, display: 'flex', alignItems: 'center' }}>
          <UserInfo
            userName={props.data[props.row.index].createdBy ?? ''}
            roleName={props.data[props.row.index].roleName ?? ''}
          />
        </div>
      ),
    },
    {
      id: 'statusName',
      accessor: `status`,
      width: 100,
      Header: (props) => <CustomHeader tableProps={props} title="Status" />,
      Cell: ({ ...props }) => (
        <StatusStockRunCard
          status={props.data[props.row.index].status}
          styleLabel={{
            whiteSpace: 'nowrap',
          }}
        />
      ),
    },
    {
      Header: () => <div style={{ width: 32.5 }}></div>,
      id: 'radio',
      width: 22,
      Cell: ({ ..._props }, selected: boolean) => (
        <RadioInput
          className="form-check-input"
          type="radio"
          labelAlign="left"
          checked={selected}
        />
      ),
    },
  ];
};

const getTooltipTitle = () => (
  <Typography variant="body-4" color="white">
    Go to item detail
  </Typography>
);
