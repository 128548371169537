import styled from 'styled-components';

import { ReactComponent as IconThreeDots } from 'app/assets/icons/icon-3-dots.svg';

import { TippyDefault } from '.';

const Main = styled.div``;
const MenuAction = styled.div`
  width: 160px;
  padding: 16px 8px;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: var(--neutral-10);
  cursor: pointer;
  &:hover {
    background-color: #f1faff;
    color: var(--primary);
  }
`;
const ButtonAction = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 8px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
interface Props {
  open: {
    value: boolean;
    setValue: (newOpen: boolean) => void;
  };
  menuActions?: {
    label: string;
    onClick: () => void;
  }[];
}
const SelectAction: React.FC<Props> = ({ open, menuActions }) => {
  if (!menuActions || !menuActions.length) {
    return <div />;
  }
  return (
    <Main>
      <TippyDefault
        containerClass="action-menu"
        placement="bottom-end"
        interactive
        arrow={false}
        offset={[0, 10]}
        trigger="click"
        visible={open.value}
        onClickOutside={() => open.setValue(false)}
        allowHTML
        content={
          <MenuAction>
            {menuActions.map((el, index) => {
              return (
                <MenuItem key={index} onClick={el.onClick}>
                  {el.label}
                </MenuItem>
              );
            })}
          </MenuAction>
        }
      >
        <ButtonAction className="action" onClick={() => open.setValue(true)}>
          <IconThreeDots />
        </ButtonAction>
      </TippyDefault>
    </Main>
  );
};
export default SelectAction;
