import styled from 'styled-components';

import { TippyDefault } from 'app/components/Tippy';
import Typography from 'app/components/Typography';

import { isNil } from 'lodash';

import { ReactComponent as TickIcon } from 'app/assets/icons/icon-tick.svg';
import { ReactComponent as LogoThumbnail } from 'app/assets/icons/logo-thumbnail.svg';

import clsx from 'clsx';

const Container = styled.div`
  min-width: 76px;
  margin-left: 12px;
  margin-right: 24px;
  display: flex;
  align-items: center;

  .forecast-val {
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    padding-top: 1px;
    &.active {
      cursor: pointer;
      user-select: none;
      :hover {
        border-bottom-color: var(--primary);
      }
    }
    > * {
      margin-right: 6px;
    }
    &.active-icon {
      svg path {
        fill: var(--success-3);
      }
    }
  }
`;

interface Props {
  value?: number;
  fullfilled: boolean;
  onClick?: () => void;
  singletonTarget?: any;
  timeFrame: string;
}
export const ItemForecasting: React.FC<Props> = ({
  value,
  fullfilled,
  onClick,
  singletonTarget,
  timeFrame,
}) => {
  if (isNil(value)) return <Container />;

  return (
    <Container onClick={onClick}>
      <TippyDefault
        singleton={singletonTarget}
        disabled={isNil(value)}
        content={<ForecastTooltipContent forecastValue={value} timeFrame={timeFrame} />}
      >
        <div className={clsx('forecast-val', fullfilled ? 'active-icon' : 'active')}>
          <LogoThumbnail />
          <Typography variant="body-3" color={fullfilled ? 'success' : 'primary'}>
            {value}
          </Typography>
        </div>
      </TippyDefault>

      {fullfilled && <TickIcon style={{ color: 'var(--success)' }} />}
    </Container>
  );
};

export const ForecastTooltipContent: React.FC<{
  forecastValue: number;
  timeFrame: string;
}> = ({ forecastValue, timeFrame }) => (
  <Typography variant="body-4" color="white" style={{ textAlign: 'center', width: 260 }}>
    SIMS recommendation:
    <br />
    During <span style={{ color: 'var(--primary-6)' }}>{timeFrame}</span>, to meet the demand and
    maintain an optimal stock level, this item requires{' '}
    <span style={{ color: 'var(--primary-6)' }}>{forecastValue} or more</span> quantities.
  </Typography>
);
