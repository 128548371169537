import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { isUndefined } from 'lodash';

import Typography from '../Typography';
import { NavigationDetail } from './core/types';

interface Props {
  navigation: NavigationDetail[];
  valueActive?: number;
  onChangeNavigation?: (value: number) => () => void;
}
const Main = styled.div`
  display: flex;
  align-items: start;
  height: 40px;
  border-bottom: 1px solid #f4f4f4;
  margin: 28px 28px 0px 28px;
`;
const LineNavigation = styled.div`
  position: absolute;
  bottom: -1px;
  height: 2px;
  left: 0px;
  right: 0px;
  background-color: var(--primary);
  border-radius: 3px;
  opacity: 0;
`;
const NavigationItem = styled.div<{ active?: boolean }>`
  height: 100%;
  position: relative;
  margin-right: 40px;
  cursor: pointer;
  ${({ active }) => `.active{
      opacity: ${active ? 1 : 0}
  }`};
  :hover {
    .hover-line {
      opacity: 1;
    }
    a {
      color: var(--primary) !important;
    }
  }
`;
const NavigationDefault: React.FC<Props> = ({ navigation, valueActive, onChangeNavigation }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const onChangeLink = (path: string) => () => {
    navigate(path);
  };
  return (
    <Main>
      {Boolean(navigation.length) &&
        navigation.map((el, index) => {
          const active = !isUndefined(valueActive)
            ? el.value === valueActive
            : location.pathname === el.path;
          return (
            <NavigationItem
              active={active}
              className="h-100 position-relative"
              key={index}
              onClick={
                onChangeNavigation ? onChangeNavigation(el.value ?? 0) : onChangeLink(el.path ?? '')
              }
            >
              <Typography variant="body-6" color={active ? 'primary' : 'neutral-6'}>
                {el.title}
              </Typography>

              <LineNavigation className={active ? 'active' : 'hover-line'}></LineNavigation>
            </NavigationItem>
          );
        })}
    </Main>
  );
};
export default NavigationDefault;
