import { MouseEventHandler, useEffect } from 'react';

import { ID, TYPE_MODAL } from '../core/types';
import { ModalHeader } from './HeaderModal';
import { ModalFormWrapper } from './ModalFormWrapper';

interface Props {
  title: string;
  nameQuery: string;
  onClickCloseModal?: MouseEventHandler<HTMLDivElement>;
  getById?: <T>(id: ID) => Promise<T>;
  ModalForm?: (originalData: any, typeModal: TYPE_MODAL, isView?: boolean) => JSX.Element;
  styleModalDialog?: React.CSSProperties;
  styleModalForm?: React.CSSProperties;
  isView: boolean;
}
const FormChangeInfoModal: React.FC<Props> = ({
  title,
  nameQuery,
  ModalForm,
  onClickCloseModal,
  getById,
  styleModalDialog,
  styleModalForm,
  isView,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div
          className="modal-dialog modal-dialog-centered"
          style={{
            minWidth: 640,
            ...styleModalDialog,
          }}
        >
          {/* begin::Modal content */}
          <div className="modal-content">
            <ModalHeader onClickCloseModal={onClickCloseModal} title={title} />
            {/* begin::Modal body */}
            <div
              className="modal-body"
              style={{
                padding: 28,
                ...styleModalForm,
              }}
            >
              <ModalFormWrapper
                nameQuery={nameQuery}
                ModalForm={ModalForm}
                getById={getById}
                isView={isView}
              />
            </div>

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};
export default FormChangeInfoModal;
