import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { RadioInput } from 'app/components/Input/RadioInput';
import Typography from 'app/components/Typography';

import { useBoolean } from 'app/hooks';

import { MQTT_SETTING } from '../core/constants';
import { updateSetting } from '../core/request';
import { QuantityUpdateMethod, Setting, SettingType } from '../core/type';
import ActionButtonCard, { ActionButtonWrapper } from './ActionButtonCard';

const Main = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--neutral-3);
  border-radius: 8px;
  margin-bottom: 24px;
`;
interface Props {
  mqttSetting: Setting[] | null;
  setMqttSetting: (newSetting: Setting[]) => void;
  currentSelectedSetting: SettingType | null;
  setCurrentSelectedSetting: (payload: SettingType | null) => void;
}

const DEFAULT_OPTION_VALUE: QuantityUpdateMethod = QuantityUpdateMethod.afterSessionCompleted;

const MQTTSettingCard: React.FC<Props> = ({
  mqttSetting,
  setMqttSetting,
  currentSelectedSetting,
  setCurrentSelectedSetting,
}) => {
  const defaultOption =
    mqttSetting === null
      ? null
      : (mqttSetting[0].value as QuantityUpdateMethod) || DEFAULT_OPTION_VALUE;

  const [option, setOption] = useState<QuantityUpdateMethod | null>(defaultOption);
  const isSubmitting = useBoolean(false);

  useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  const isEdit = useBoolean(false);
  const onCancel = () => {
    isEdit.setValue(false);
    setOption(defaultOption);
  };

  useEffect(() => {
    if (currentSelectedSetting !== SettingType.stockRun) {
      onCancel();
    }
  }, [currentSelectedSetting]);

  const onSubmit = async () => {
    isSubmitting.setValue(true);
    if (mqttSetting) {
      const response = await updateSetting(Number(mqttSetting[0].id), {
        name: mqttSetting[0].name,
        type: SettingType.stockRun,
        value: String(option),
      });
      console.log(mqttSetting);
      console.log([response]);
      if (response) setMqttSetting([response]);
      setOption(defaultOption);
      toast.success('Update quantity balance setting successfully!');
    } else {
      toast.error('Something went wrong, please try again.');
    }
    isSubmitting.setValue(false);
    isEdit.setValue(false);
  };
  return (
    <Main>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{
          marginBottom: 32,
          width: '100%',
        }}
      >
        <div className="d-flex flex-column">
          <Typography variant="heading-7" color="gray-700">
            Quantity balance update
          </Typography>
          <Typography variant="body-3" color="neutral-7">
            When a MMD User stock up for a stock run, the quantity balance of the bins will be
            updated following the setting option below.
          </Typography>
        </div>
        <ActionButtonWrapper>
          <ActionButtonCard
            isEdit={isEdit.value}
            onCancel={onCancel}
            disableButton={isSubmitting.value}
            isSubmitting={isSubmitting.value}
            onClickEdit={() => {
              isEdit.setValue(true);
              setCurrentSelectedSetting(SettingType.stockRun);
            }}
            onSubmit={onSubmit}
          />
        </ActionButtonWrapper>
      </div>
      <div className="d-flex flex-column gap-3">
        {MQTT_SETTING.map((opt) => (
          <RadioInput
            disabled={!isEdit.value}
            key={opt.value}
            className="form-check-input me-1"
            type="radio"
            value={opt.value}
            checked={opt.value === option}
            onChange={() => {
              if (isEdit.value) {
                setOption(opt.value);
              }
            }}
            label={opt.title}
          />
        ))}
      </div>
    </Main>
  );
};
export default MQTTSettingCard;
