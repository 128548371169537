import { PermissionDetail } from 'app/modules/system-settings/permission/core/type';

import { includes } from 'lodash';

import { PATH } from 'app/constants/path';

export enum KeyPolicyUser {
  dashboard = 'data.visualization',
  report = 'data.reporting',
  item = 'inventory.management',
  rack = 'rack.management',
  settings = 'system.settings',
  pickUp = 'pickup',
  stockUp = 'stockup',
  userManagement = 'user.management',
  userMMD = 'user.manage.mmd',
  userNurse = 'user.manage.nurse',
  notification = 'notification',
  viewProjection = 'view.projection',
  approveProjection = 'approve.projection',
}
export interface PolicyUser {
  dashboard: boolean;
  report: boolean;
  item: boolean;
  rack: boolean;
  settings: boolean;
  pickUp: boolean;
  stockUp: boolean;
  userManagement: boolean;
  userMMD: boolean;
  userNurse: boolean;
  notification: boolean;
  viewProjection: boolean;
  approveProjection: boolean;
}
export const usePolicyInUser = (policies: PermissionDetail[]) => {
  const policyKeys = policies.length ? policies.map((el) => el.key) : [];
  const policy = {
    dashboard: includes(policyKeys, KeyPolicyUser.dashboard),
    report: includes(policyKeys, KeyPolicyUser.report),
    item: includes(policyKeys, KeyPolicyUser.item),
    rack: includes(policyKeys, KeyPolicyUser.rack),
    settings: includes(policyKeys, KeyPolicyUser.settings),
    pickUp: includes(policyKeys, KeyPolicyUser.pickUp),
    stockUp: includes(policyKeys, KeyPolicyUser.stockUp),
    userManagement: includes(policyKeys, KeyPolicyUser.userManagement),
    userMMD: includes(policyKeys, KeyPolicyUser.userMMD),
    userNurse: includes(policyKeys, KeyPolicyUser.userNurse),
    notification: includes(policyKeys, KeyPolicyUser.notification),
    viewProjection: includes(policyKeys, KeyPolicyUser.viewProjection),
    approveProjection: includes(policyKeys, KeyPolicyUser.approveProjection),
  };

  return policy;
};

export const navigateDefaultPrivateRouter = (policy: PolicyUser) => {
  const {
    dashboard,
    report,
    item,
    rack,
    userManagement,
    userMMD,
    userNurse,
    settings,
    notification,
  } = policy;

  switch (true) {
    case dashboard:
      return PATH.DASHBOARD;
    case report:
      return PATH.REPORT;
    case item:
      return PATH.ITEMS;
    case rack:
      return PATH.RACKS;
    case userManagement:
      return PATH.WEB;
    case settings:
      return PATH.WARDS;
    case userMMD:
    case userNurse:
      return PATH.TABLET;
    case notification:
      return PATH.NOTIFICATIONS;
    default:
      return PATH.NOT_FOUND;
  }
};
