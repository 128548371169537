import { useLocation } from 'react-router-dom';

import { format, formatDistanceToNow, isValid, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';

import { QUERY_KEY } from './utils';

export const compileFormData = (body: any) => {
  const formData = new FormData();
  Object.keys(trimObject(body)).forEach((key) => {
    formData.append(key, body[key]);
  });
  return formData;
};

export const replacePath = (path: string, searchValue: string, replaceValue: string) => {
  return path.replace(searchValue, replaceValue);
};

export function isValidDate(date: FormDate) {
  if (
    !date ||
    String(new Date(date)) === 'Invalid Date' ||
    !isValid(parseISO(new Date(date).toISOString()))
  ) {
    return false;
  }
  return true;
}
export type FormDate = string | number | Date;

const checkTypeDate = (date: FormDate) => {
  let typedDate = date;
  if (typeof date === 'string' && isValidDate(date.replace(' ', 'T'))) {
    typedDate = date.replace(' ', 'T');
  }
  return typedDate;
};

export const formatDate = (date: FormDate, typeFormat?: string) => {
  if (!date) {
    return '';
  }

  return format(new Date(checkTypeDate(date)), typeFormat ? typeFormat : 'dd MMM yyyy');
};

export const formatCustomDate = (date: FormDate) => formatDate(date, 'dd MMM yyyy H:mm:ss');

export const formatDurationInHuman = (date: string | number | Date) => {
  if (date === '0000-00-00 00:00:00') {
    return 'N/A';
  }
  return formatDistanceToNow(new Date(checkTypeDate(date)), {
    includeSeconds: true,
  });
};

export const coverNameBed = (name: number) => {
  return `00${name}`.slice(-3);
};

export const getFullName = (firstName?: string, lastName?: string) => {
  return `${firstName || ''} ${lastName || ''}`;
};

const INVALID_CHARACTER_CONFIG: Record<InputType, RegExp> = {
  float: /[^\d.]/,
  integer: /[^\d]/,
  phoneNumber: /[^\d+]/,
  negativeFloat: /[^-?\d.]/,
};

export type InputType = 'float' | 'integer' | 'phoneNumber' | 'negativeFloat';

export const blockInvalidCharacters = (e: any, type: InputType) => {
  const key = e.key;
  if (key.length === 1 && INVALID_CHARACTER_CONFIG[type].test(key)) {
    e.preventDefault();
  }
};

export const coverPath = (path: string, params: object) => {
  let pathWithParams = path;
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      pathWithParams = pathWithParams.replace(':' + key, value as string);
    }
  }
  return pathWithParams;
};

export const getEnumKeyByValue = (type: any, value: string) => {
  const keyIndex = Object.values(type).indexOf(value);

  return Object.keys(type)[keyIndex];
};

export const trimValue = (value: any) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};

export const trimObject = (object: any) => {
  const newObject: Record<string, any> = {};
  Object.keys(object).forEach((key: string) => {
    newObject[key] = trimValue(object[key]);
  });
  return newObject;
};

export const setUrlParams = (params: { key: string; value: string }[]) => {
  const url = new URL(window.location.href);
  params.forEach(({ key, value }) => {
    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });
  window.history.pushState(null, '', url.toString());
};

export const useLocationQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getParamInUrl = (query: URLSearchParams, arrQuery?: string[]) => {
  const newArr = arrQuery || [
    QUERY_KEY.table.page,
    QUERY_KEY.table.limit,
    QUERY_KEY.table.orderBy,
    QUERY_KEY.table.orderDirection,
  ];
  const obj: {
    [key: string]: string | null;
  } = {};
  newArr.forEach((el) => {
    const value = query.get(el);
    obj[el] = value;
  });
  return obj;
};

export const exportFile = (data: string, type: string, name: string) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([data], { type: type }));
  a.setAttribute('download', name);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return;
  }
  ref.current.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

export const convertToCsvFormatData = (
  headers?: Record<string, any>[],
  bodies?: Record<string, any>[],
) => {
  const tableData = [headers?.map((header) => header.name)];
  bodies?.forEach((body: Record<string, any>) => {
    const arr: string[] = [];
    headers?.forEach((header) => {
      arr.push(body[header.key]?.toString() || '-');
    });
    tableData.push(arr);
  });
  return tableData;
};

export const getLabelAction = (qty: number) => (qty >= 0 ? 'Stock up' : 'Pick up');

export const isActiveLabelAction = (qty: number) => qty >= 0;

export const getUser = (user?: { firstName: string; lastName: string }) => {
  if (!isEmpty(user)) {
    return `${user.firstName} ${user.lastName}`;
  }
  return 'Undefined';
};

export const isChrome = () => {
  return navigator.userAgent.toLowerCase().includes('chrome');
};

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};

export const getTimezoneOffset = () => -new Date().getTimezoneOffset() / 60;
